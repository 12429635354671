/* eslint-disable array-callback-return */
import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import InputTextBox from "Components/common/InputTextBox";
import dialerStoreData from "Stores/DialerData";
import { URLS } from "Utils/apiURL";
import RadioButton from "Components/common/radioBtn";
import Utils from "Utils/utils";
import Lottie from "react-lottie";
import searchLoading from "Utils/LottieFiles/searchLoading.json";

class BuyNewNumberPopupState {
  @observable showPopup = false;
  @observable callBack = e => {};

  defaultPopupValue = {
    inputVal: {
      phPrefix: "+1 "
    }
  };

  @observable popupValues = this.defaultPopupValue;

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      this.popupValues = this.defaultPopupValue;
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallBack = val => {
    this.callBack = val;
  };

  @action
  setPopupValues = obj => {
    this.popupValues = obj;
  };
}

const buyNewNumberPopupState = new BuyNewNumberPopupState();

const BuyNewNumberPopup = observer(props => {
  const [inputVal, setInputVal] = useState(
    buyNewNumberPopupState.defaultPopupValue.inputVal
  );
  const [availableNumbersData, setAvailableNumbersData] = useState(null);
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
  const [showSearchLoad, setShowSearchLoad] = useState(false);
  const [inputError, setInputError] = useState("");

  const changeInputVal = (id, value) => {
    if (value.length > 3) {
      const countryCodeRemoved = value.replace("+1 ", "").trim(); // removing country code
      if (isNaN(countryCodeRemoved)) {
        setInputError("Please enter a valid number");
      } else {
        setInputError("");
        getAvailabeNumberFromApi(countryCodeRemoved, id);
      }
      updateInputState(id, value);
    } else {
      const inputObj = { ...inputVal };
      inputObj[id] = "+1 ";
      setInputVal(inputObj);
    }
  };

  const updateInputState = (id, value) => {
    const inputObj = { ...inputVal };
    inputObj[id] = value;
    setInputVal(inputObj);
  };

  const constructPhoneNumberResponse = response => {
    setShowSearchLoad(false);
    if (response.length) {
      const tempArr = [];
      response.map(value => {
        if (value) {
          const tempObj = { phNumber: value, selected: false };
          tempArr.push(tempObj);
        }
      });
      tempArr[0].selected = true;
      setSelectedPhoneNumber(tempArr[0].phNumber);
      setAvailableNumbersData(tempArr);
    } else {
      setAvailableNumbersData([]);
    }
  };

  const getAvailabeNumberFromApi = async (value, id) => {
    if (value && id && value.length > 2) {
      setShowSearchLoad(true);
      const response = await dialerStoreData.getAvailableNumbers(
        constructUrl(value)
      );
      if (response) {
        constructPhoneNumberResponse(response);
      } else {
        closeAction();
      }
    }
  };

  const constructUrl = number => {
    return `${URLS.getAvailablePhoneNumbers}?phPrefix=${number}`;
  };

  const closeAction = () => {
    setAvailableNumbersData(null);
    setSelectedPhoneNumber("");
    setShowSearchLoad(false);
    setInputVal(buyNewNumberPopupState.defaultPopupValue.inputVal);
    buyNewNumberPopupState.setShowPopup(false);
  };

  const radioOnchangeAction = (e, id = "") => {
    if (id) {
      setSelectedPhoneNumber(id);
      availableNumbersData.map(value => {
        if (value && value.phNumber && value.phNumber === id) {
          value.selected = true;
        } else {
          value.selected = false;
        }
      });
      setAvailableNumbersData(availableNumbersData);
    }
  };

  const purchaseNumber = async (e, key) => {
    Utils.preventDefaultFn(e);
    buyNewNumberPopupState.callBack &&
      buyNewNumberPopupState.callBack(selectedPhoneNumber, key);
    closeAction();
  };

  return buyNewNumberPopupState.showPopup ? (
    <div className="buyNewNumberPopup popupWrapper">
      <div className="confirmation-popup common-popup">
        <div className="newNumberPopupWrapper popup-wrap animated customzoomIn">
          <div className="newNumberCont">
            <div className="popupHeader">
              <div className="popupTitle">Buy US Phone Number</div>
              <div
                className="popup-close"
                title="Click here to close"
                onClick={closeAction}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
            <div className="popupBody">
              <div className="formInputGroupd">
                <InputTextBox
                  name="phPrefix"
                  id="phPrefix"
                  text="Enter Phone Number Prefix"
                  type="text"
                  value={inputVal.phPrefix}
                  change={changeInputVal}
                  err={inputError}
                  autoFocus={true}
                  cbk={e => {}}
                />
              </div>
              <div className="avaiableNumbers">
                {showSearchLoad ? (
                  <SerachLoading />
                ) : availableNumbersData && availableNumbersData.length > 0 ? (
                  <>
                    {availableNumbersData.map((obj, index) => {
                      const { phNumber, selected } = obj;
                      return (
                        <div
                          className="radioCont"
                          key={`radioCont${phNumber * index}`}
                        >
                          <RadioButton
                            id={phNumber}
                            value={Utils.getUSFormattedPhoneNumber(phNumber)}
                            name="phoneNumber"
                            defaultChecked={selected}
                            radioOnchange={radioOnchangeAction}
                          />
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <NoPhoneNumberResult data={availableNumbersData} />
                )}
              </div>
              <div className="actionBtn">
                <div className="btnCont">
                  <div
                    className="backCont btn"
                    onClick={e => purchaseNumber(e, "back")}
                  >
                    Back
                  </div>
                  <div
                    className={`purchaseBtn btn ${
                      availableNumbersData && availableNumbersData.length > 0
                        ? ""
                        : "disabled"
                    }`}
                    onClick={e => {
                      purchaseNumber(e);
                    }}
                  >
                    Add
                  </div>
                </div>
                <span className="chargeText">
                  Your first US phone number is free. Additional numbers can be
                  purchased for $1.15 each
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="common-popup-mask" />
    </div>
  ) : (
    ""
  );
});

const NoPhoneNumberResult = props => {
  const { data } = props;
  return (
    <div className="noNumbersResult">
      <div className="noNumbers">
        {data === null
          ? "Search to get available list of phone numbers"
          : "No results found. Try with different prefix"}
      </div>
    </div>
  );
};

const SerachLoading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: searchLoading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <Lottie
      options={defaultOptions}
      width={150}
      height={150}
      style={{ margin: "auto" }}
      speed={0.5}
    />
  );
};

export default BuyNewNumberPopup;
export { BuyNewNumberPopup, buyNewNumberPopupState };
