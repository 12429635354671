import React from "react";
import { observer } from "mobx-react";
import { Switch, Redirect, Route, withRouter } from "react-router-dom";
import CsvImports from "./CsvImports";
import LinkedInImports from "./LinkedInImports";
import Exports from "./Exports";
import Enrich from "./Enrich";

const ImportsRouting = observer((props = {}) => {
  return (
    <Switch>
      <Route
        exact
        path="/settings/imports/csv"
        render={() => <CsvImports {...props} />}
      />
      <Route
        exact
        path="/settings/imports/linkedin"
        render={() => <LinkedInImports {...props} />}
      />
      <Route
        exact
        path="/settings/imports/exports"
        render={() => <Exports {...props} />}
      />
      <Route
        exact
        path="/settings/imports/enrich"
        render={() => <Enrich {...props} />}
      />
      <Redirect from="/settings/imports" to="/settings/imports/csv" />
    </Switch>
  );
});

export default withRouter(ImportsRouting);
