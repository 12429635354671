/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import userDetail from "Stores/userDetail";
import queryString from "query-string";
import InputBox from "Components/Register/InputBox";
import Button from "Components/common/Button";
import { Utils } from "Utils/utils";
import { MP_EVENT, WEBSITE_URL } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import PhoneNumberInput from "Components/common/PhoneNumberInput";
import { captchaStoreValue } from "Stores/captcha";
import EmailVerification from "./EmailVerification";

const SignupComponent = props => {
  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let pluginUser = queryParams?.get("source")?.includes("plugin");
  pluginUser && localStorage.setItem("isPluginUser", true);

  const [firstName, setFirstName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPassword, setUserPassword] = useState({
    password: "",
    showPassword: false
  });
  const [isAgreeTC, setIsAgreeTC] = useState(false);
  const [isStorePersonal, setIsStorePersonal] = useState(false);
  const [isProductAnnouncement, setIsProductAnnouncement] = useState(false);
  const [firstNameErr, setFirstNameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [agreeTCErr, setAgreeTCErr] = useState("");
  const [storePersonalErr, setStorePersonalErr] = useState("");
  const [loader, setLoader] = useState(false);
  const timeZoneValidation = Utils.validateEUTimeZone();
  const userEuChecked = Utils.isEuCheckboxEnabled(isAgreeTC, isStorePersonal);
  const [phone, setPhoneNumber] = useState("");
  const [emailVerification, setEmailVerification] = useState(false);
  const [verificationData, setVerificationData] = useState({});

  const checkEuRegister = (
    firstNameValid,
    emailValid,
    passwordValid,
    phoneNumberValid
  ) => {
    let isValidInput = firstNameValid && emailValid && passwordValid;
    if (timeZoneValidation) {
      let isEuCheckboxEnabled = Utils.isEuCheckboxEnabled(
        isAgreeTC,
        isStorePersonal
      );
      return pluginUser
        ? isValidInput && isEuCheckboxEnabled
        : isValidInput && isEuCheckboxEnabled && phoneNumberValid;
    } else {
      return pluginUser ? isValidInput : isValidInput && phoneNumberValid;
    }
  };

  const signup = async token => {
    try {
      const config = {
        url: URLS.signupUser,
        method: "POST",
        data: {
          first_name: firstName,
          email: userEmail?.trim()?.toLowerCase() || "",
          password: userPassword?.password || "",
          phone,
          emailPreferences: isProductAnnouncement,
          source:
            localStorage.getItem("isPluginUser") || pluginUser
              ? "PLUGIN"
              : "WEB",
          type: pluginUser ? "PLUGIN" : "WEB",
          token,
          mixpanelInfo: {
            pageType:
              localStorage.getItem("isPluginUser") || pluginUser
                ? "PLUGIN"
                : "WEB"
          },
          utmSource: "" /* Utils.readCookie("uts") */,
          utmMedium: "" /* Utils.readCookie("utm") */,
          utmCampaign: "" /* Utils.readCookie("utc") */,
          utmContent: "" /* Utils.readCookie("uta")*/,
          userTimezone: {
            zone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
            zone_value: Utils.getCurrentUserTimeZone()
          },
          redirectUrl: "/signupSuccess?redirect=dashboard"
        }
      };
      let response = await makeApi(config);
      switch (response?.data?.toString()) {
        case "true":
          pluginUser && localStorage.removeItem("pluginUser");
          localStorage.setItem("isOnboarding", true);
          setVerificationData({
            ...verificationData,
            email: userEmail,
            token
          });
          setEmailVerification(true);
          if (window && window.sg) {
            var sg = window.sg;
            sg.identify(userEmail);
          }
          break;
        case "limit":
          mpFailedEventType("limit");
          setErrMsg(`We have noticed too many registrations from this domain. Please
                <a target="_blank" rel="noopener noreferrer" href="mailto:support@salesgear.io">contact support</a> for further help`);
          setLoader(false);
          break;
        case "business":
          mpFailedEventType("business");
          setEmailErr("Please enter a valid work email address");
          setLoader(false);
          break;
        case "invalid":
          setEmailErr("Please enter a valid email address");
          setLoader(false);
          break;
        case "false":
          setLoader(false);
          setEmailErr("Email address already exists");
          break;
        case "disposable":
          mpFailedEventType("disposable");
          setEmailErr("Please enter a valid email address");
          setLoader(false);
          break;
        default:
          mpFailedEventType("failed");
          setLoader(false);
          setErrMsg("Something went wrong");
          break;
      }
    } catch (err) {
      setLoader(false);
      console.error("Signup Error", err);
    }
  };

  const submitForm = e => {
    e.preventDefault();
    if (e.keyCode === 13) {
      Utils.removeInputRequired(e);
      executeCaptcha();
    }
  };

  const firstNameChange = value => {
    setFirstName(value);
    setFirstNameErr("");
    setErrMsg("");
  };

  const emailChange = value => {
    setUserEmail(value?.trim().toLowerCase() || "");
    setEmailErr("");
    setErrMsg("");
  };

  const passwordChange = value => {
    setUserPassword(value);
    setPasswordErr("");
    setErrMsg("");
  };

  const changePhoneNumber = number => {
    setPhoneNumber(number);
    setPhoneErr("");
    setErrMsg("");
  };

  const agreeTCClick = () => {
    setIsAgreeTC(!isAgreeTC);
    setAgreeTCErr("");
    setErrMsg("");
  };

  const storePersonalClick = () => {
    setIsStorePersonal(!isStorePersonal);
    setStorePersonalErr("");
    setErrMsg("");
  };

  const productAnnouncementClick = () => {
    setIsProductAnnouncement(!isProductAnnouncement);
  };

  const termsAndContAndPrivacy = () => {
    return (
      <span>
        By signing up, you are accepting our
        <a
          href={`${WEBSITE_URL}/terms-of-service`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Terms&nbsp;
        </a>{" "}
        and
        <a
          href={`${WEBSITE_URL}/privacy-policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          &nbsp;Privacy Policy.
        </a>
      </span>
    );
  };

  const executeCaptcha = e => {
    e && Utils.removeInputRequired(e);
    let isEmailValid = Utils.emailValidate(
      userEmail?.trim()?.toLowerCase() || ""
    );
    let isPasswordValid = Utils.validateStringLength(
      userPassword?.password || "",
      6
    );
    let isFirstNameValid = Utils.validateStringLength(firstName.trim(), 1);
    let isValidPhoneNumber = Utils.validatePhoneNumberLength(phone, 5);
    if (
      !emailErr &&
      checkEuRegister(
        isFirstNameValid,
        isEmailValid,
        isPasswordValid,
        isValidPhoneNumber
      )
    ) {
      setLoader(true);
      Utils.mixpanelTrack(MP_EVENT.SIGNUP_CLICKED, {
        pageType: "Signup",
        name: `${firstName}`,
        userEmail,
        phonenumber: phone,
        source: ""
      });
      captchaStoreValue.executeCaptcha(validateCaptchaFromAPi);
    } else if (!isEmailValid && !emailErr) {
      setEmailErr("Please enter a valid email address");
      setLoader(false);
    } else if (!isPasswordValid) {
      setPasswordErr(
        userPassword?.password
          ? "Password must be at least 6 characters"
          : "Password is required"
      );
      setLoader(false);
    } else if (!isFirstNameValid) {
      setFirstNameErr("Please enter the first name");
      setLoader(false);
    } else if (!isValidPhoneNumber) {
      setPhoneErr("Please enter a valid phone number");
      setLoader(false);
    } else if (!userEuChecked) {
      !isAgreeTC && setAgreeTCErr("Please accept our terms and privacy policy");
      !isStorePersonal &&
        setStorePersonalErr("Please give your consent to store information");
      setLoader(false);
      return false;
    } else {
      setLoader(false);
      setErrMsg("Please check your email address or password");
    }
  };

  const validateCaptchaFromAPi = async token => {
    token && signup(token);
  };

  const mpFailedEventType = (type = "failed") => {
    Utils.mixpanelTrack(MP_EVENT.SIGN_UP_FAILED, {
      pageType: "Signup Page",
      email: userEmail,
      type
    });
  };

  const validateBusinessEmail = async (token = "") => {
    if (token) {
      try {
        const config = {
          url: `${URLS.validateBusinessEmail}/${userEmail}/${token}`,
          method: "GET"
        };
        const { data = false, status = "" } = await makeApi(config);
        if (+status === 200) {
          setEmailErr(!data ? "Please enter a valid work email address" : "");
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  const validateBusinessEmailWithCaptcha = () => {
    if (userEmail && !pluginUser) {
      let isValidEmail = Utils.emailValidate(
        userEmail?.trim()?.toLowerCase() || ""
      );
      if (isValidEmail) {
        captchaStoreValue.executeCaptcha(validateBusinessEmail);
      } else {
        setEmailErr("Please enter a valid email address");
      }
      setErrMsg("");
    }
  };

  const inputBoxObjList = [
    {
      name: "firstName",
      id: "firstName",
      type: "text",
      value: firstName,
      changeAction: firstNameChange,
      keyupAction: submitForm,
      errMsg: firstNameErr,
      label: "First Name",
      isPassword: false,
      placeholder: "eg: John"
    },
    {
      name: "workEmail",
      id: "workEmail",
      type: "email",
      value: userEmail,
      changeAction: emailChange,
      keyupAction: submitForm,
      blurAction: validateBusinessEmailWithCaptcha,
      errMsg: emailErr,
      label: "Work Email",
      isPassword: false,
      placeholder: `eg: john@salesgear.io`,
      focus: true
    },
    {
      name: "password",
      id: "password",
      type: "password",
      value: userPassword,
      changeAction: value =>
        passwordChange({ ...userPassword, password: value }),
      keyupAction: submitForm,
      errMsg: passwordErr,
      label: "Password",
      className: "password",
      isPassword: true,
      placeholder: "Password",
      showPasswordAction: () =>
        setUserPassword({
          ...userPassword,
          showPassword: !userPassword?.showPassword
        })
    }
  ];

  const euCheckBoxObjList = [
    {
      id: "termCondition",
      name: "termCondition",
      type: "checkbox",
      className: "checkbox",
      checked: isAgreeTC,
      change: agreeTCClick
    },
    {
      id: "storePersonal",
      name: "storePersonal",
      type: "checkbox",
      className: "checkbox",
      checked: isStorePersonal,
      change: storePersonalClick,
      text: "I give my consent to store my personal information outside the European Union."
    },
    {
      id: "productAnnouncement",
      name: "productAnnouncement",
      type: "checkbox",
      className: "checkbox",
      checked: isProductAnnouncement,
      change: productAnnouncementClick,
      text: "I would like to receive email updates from Salesgear on new product announcements and upgrades."
    }
  ];
  const getInitialize = () => {
    const queryObject = queryString?.parse(location.search);
    const storedSignupEmail =
      queryObject["email"] || localStorage.getItem("signupEmail") || "";
    storedSignupEmail &&
      !["null", "undefined"].includes(storedSignupEmail) &&
      setUserEmail(storedSignupEmail || "");
    localStorage.setItem("signupEmail", storedSignupEmail);
    Utils.mixpanelTrack(MP_EVENT.SIGNUP_VISITED, {
      pageType: "Signup"
    });
  };

  useEffect(() => {
    getInitialize();
  }, []);

  return !emailVerification ? (
    <section
      className={`formInputBox ${loader ? "pointerUserSelectNone" : ""}`}
    >
      <h3>Get Started for Free</h3>
      <form id="signupform" autoComplete="on">
        {inputBoxObjList.map(item => (
          <InputBox {...item} key={item.id} />
        ))}

        <div className="inputBoxSection">
          <label className="staticLabel">Phone Number</label>
          <PhoneNumberInput
            setPhNumber={changePhoneNumber}
            allowExtension={false}
            inputProps={{ autoFocus: false }}
          />
          <span
            className="errMsgText"
            dangerouslySetInnerHTML={{
              __html: phoneErr || errMsg || ""
            }}
          />
        </div>
      </form>
      {!timeZoneValidation && (
        <div className="termsAndPolicyText">{termsAndContAndPrivacy()}</div>
      )}
      <Button
        id="signupBtn"
        name="signupBtn"
        btnText="Start for Free"
        className={
          loader
            ? "loading"
            : "signupBtn btn-new btn-rounded btn-orange btn-text btn-arrow"
        }
        loader={loader}
        type="button"
        click={e => {
          e.preventDefault();
          executeCaptcha(e);
        }}
      />
      {timeZoneValidation && (
        <div className="eu-checbox-section">
          <span className="err-eu-check">{agreeTCErr}&nbsp;</span>
          <span className="err-eu-check">{storePersonalErr}&nbsp;</span>
          <ul className="checkbox-tick">
            {euCheckBoxObjList.map(item => {
              const {
                id = "",
                name = "",
                type = "checkbox",
                checked = false,
                change = () => {},
                text = "",
                className = ""
              } = item || {};
              return (
                <li className="list-item" key={id}>
                  <label htmlFor={id}>
                    <input
                      id={id}
                      name={name}
                      type={type}
                      className={className}
                      checked={checked}
                      onChange={change}
                    />
                    <span>
                      {id === "termCondition" ? termsAndContAndPrivacy() : text}
                    </span>
                  </label>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </section>
  ) : (
    <EmailVerification verificationData={verificationData} />
  );
};

export default withRouter(SignupComponent);
