import React from "react";
import { ReactComponent as AndrewBlockImg } from "./images/Testimonials/andrewBlock.png";
import { ReactComponent as ErikPaulson } from "./images/Testimonials/erikPaulson.png";
import { ReactComponent as PradeepMuruganandam } from "./images/Testimonials/pradeepMuruganandam.png";
import SamGrimley from "./images/Testimonials/samGrimley.png";
import { ReactComponent as Saravanakumar } from "./images/Testimonials/saravanakumar.png";
import { ReactComponent as SomeshOjha } from "./images/Testimonials/someshOjha.png";
import { ReactComponent as SrikrishnaSwaminathan } from "./images/Testimonials/srikrishnaSwaminathan.png";
import SamBackground from "./images/Testimonials/SamBackground.png";

export const TESTIMONIALS = {
  lambdaTest: {
    name: "Somesh Ojha",
    title: "Director - Enterprise Account at LambdaTest",
    img: <SomeshOjha />,
    desc: `“At Lamdatest, sales teams close deals fast. Velocity is key for us. With Salesgear, our sales leaders are able to scale best practices in record time. Today, sales teams focus more time on closing deals and less on manual tasks.”`
  },
  adaptIo: {
    name: "Pradeep Muruganandam",
    title: "Founder & CEO at Adapt.io",
    img: <PradeepMuruganandam />,
    desc: `“Writing assistant has been a real lifesaver for me. One of the most notable improvements I've seen is the ability to generate more interesting and compelling subject lines for my emails.”`
  },
  revFuel: {
    name: "Andrew Block",
    title: "Founder at RevFuel",
    img: <AndrewBlockImg />,
    desc: `“I've used probably close to 10 different sales engagement platforms and prospecting tools since beginning my career. Salesgear is one of the easiest tools to use, hands down! It's super user friendly and makes it easy to use with all in one platform.“`
  },
  vendisys: {
    name: "Erik Paulson",
    title: "CEO at Vendisys",
    img: <ErikPaulson />,
    desc: `“As a lead generation company, we deliver 1000s of meetings to our customers. We use Salesgear to create and execute multiple sales sequences at once. The platform is super easy to use. Our SDRs love Salesgear because it helps them set up more meetings.“`
  },
  factorsAi: {
    name: "Srikrishna Swaminathan",
    title: "CEO at Factors.AI",
    img: <SrikrishnaSwaminathan />,
    desc: `“Having tested and compared sales engagement platforms over time, we can say with confidence that Salesgear offers a robust user experience, CRM integration, and scalability”`
  },
  kovaiDotCo: {
    name: "Saravana Kumar",
    title: "Founder and CEO at Kovai.co",
    img: <Saravanakumar />,
    desc: `“We are 100% confident that the emails sent through Salesgear always land in the inbox. We just love Salesgear's email warming and Domain Health Center.”`
  },
  infront: {
    name: "Sam Grimley",
    title: "Global Head of Sales | Infront Lab",
    img: SamGrimley,
    desc: `"Over the years, I have built outbound go-to-market strategies for multiple companies, used a variety of tools, and integrated and scaled the sales development motion. When I evaluated Salesgear, it showed me how much best practices and learnings can be shared across the team, while saving vast amounts of time. The simplicity and well-connected system save hours on setup and training, with ongoing learnings really clear to see. Supported by a fantastic customer support function, you can't go wrong with Salesgear, and it would get my highest recommendation."`
  }
};

const Testimonials = (props = {}) => {
  const { name = "" } = props || {};
  return <ContentBody {...TESTIMONIALS[name]} />;
};

const ContentBody = (props = {}) => {
  const { name = "", title = "", img = "", desc = "" } = props || {};
  return (
    <section className="section">
      <div className="mainContainer">
        <div className="content">
          <p
            className="userQuotes"
            dangerouslySetInnerHTML={{ __html: desc }}
          />
          <div className="userDetails">
            <img src={img} alt={name} className="samGrimleyImg" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
