/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useContext } from "react";
import { StepsContext } from "./Steps";
import SequenceUtils from "Utils/SequenceUtils";
import HoverCard from "./StageHoVerCard";
import Utils from "Utils/utils";
import makeApi, { URLS } from "Utils/apiURL";
import { MP_EVENT } from "Static/constant";
import { addStagesPopupState } from "Pages/Dashboard/sequences/Stages/AddStagesPopupState";
import { toasterState } from "Components/common/toaster";
import VariantToggle from "../VariantToggle";
import MoreOptionSlide from "Components/common/MoreOptionSlide";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";
import { confirmationPopupState } from "Components/common/confirmationPopup";

const StageVariantCard = props => {
  const {
    variant = "",
    name = "",
    isTask = false,
    isPhoneTask = false,
    isLinkedInAutoTask = false,
    data = {},
    totalContacts = 0,
    stepNo = 1,
    variantStep = 1,
    daysFromStart = 1,
    steps = {},
    seqId = "",
    totalVariant = 0,
    setShowStageLoader = () => {},
    analyticStepLoader = false,
    setAnalyticStepLoader = () => {},
    getSelectedSeqData = () => {},
    abTestingEnabled = false
  } = props || {};

  const { toggleVariants = () => {} } = useContext(StepsContext);

  const { planName = "", inFreePlan = false } =
    userDetail?.userFeatureFlag || {};
  const {
    attachments = [],
    content = "",
    subject = "",
    replyAsThread = false,
    notes = "",
    text = "",
    linkedInTaskType = "",
    status = "ACTIVE",
    type = "",
    daysLater = 0
  } = variant || {};

  const {
    sentCount = 0,
    openCount = 0,
    clickCount = 0,
    replyCount = 0,
    bounceCount = 0,
    liCrAcceptedCount = 0,
    liCrSentCount = 0,
    liMessageReplyCount = 0,
    liMessageSentCount = 0,
    liProfileViewsCount = 0,
    phoneCallAnalytics: { call = 0, answered = 0 } = {}
  } = data;

  const statsPercentColumn = [
    { name: "Sent", key: "sent" },
    { name: "Opens", key: "open" },
    { name: "Clicks", key: "click" },
    { name: "Replies", key: "reply" }
  ];

  const linkedInSentMsgColumn = [
    { name: "Sent", key: "liMessageSent", value: liMessageSentCount },
    { name: "Replies", key: "liMessageReply", value: liMessageReplyCount }
  ];

  const linkedInConnectionColumn = [
    { name: "Sent", key: "liCrSent", value: liCrSentCount },
    { name: "Accepted", key: "liCrAccepted", value: liCrAcceptedCount }
  ];

  const linkedInViewProfileColumn = [
    { name: "Views", key: "liViewProfile", value: liProfileViewsCount }
  ];

  const phoneCallOverviewColumn = [
    { name: "Call", key: "calls", value: call },
    { name: "Answer", key: "answers", value: answered }
  ];

  const subjectText = isTask
    ? Utils.convertTaskTypeEnumToString(linkedInTaskType || type)
    : subject;

  const contentText = isTask
    ? type?.toLowerCase() === "linkedinauto"
      ? text
        ? text?.length > 90
          ? `${text?.substring(0, 90)}...`
          : text
        : ""
      : notes
    : SequenceUtils.convertContentToString(content);

  const [showHoverCard, setShowHoverCard] = useState(false);
  const [loader, setLoader] = useState(false);
  const [toggleLoader, setToggleLoader] = useState(false);
  const [variantStatus, setVariantStatus] = useState(status === "ACTIVE");

  const editVariant = e => {
    Utils.preventDefaultFn(e);
    setShowHoverCard(false);
    const popupValues = {
      btnText: "Save",
      currentStep: stepNo,
      stageType: type,
      variantStep,
      daysLater,
      isEdit: true,
      daysFromStart,
      steps,
      seqId,
      name
    };
    if (isTask) {
      addStagesPopupState.setPopupValues({
        pageSource: "Sequences",
        ...popupValues,
        ...(type?.toLowerCase() === "linkedinauto" ? { text } : { notes }),
        subject,
        defaultTask: linkedInTaskType || "",
        defaultLiAutomaticTask: linkedInTaskType || "",
        steps
      });
      addStagesPopupState.setShowPopup(true);
    } else {
      const inputVal = {
        ...addStagesPopupState.popupValues.inputVal,
        content,
        subject,
        attachments,
        replyAsThread,
        notes,
        daysLater
      };
      addStagesPopupState.setPopupValues({
        pageSource: "Sequences",
        ...popupValues,
        inputVal,
        isVariantChange: true,
        steps
      });
      addStagesPopupState.setShowStepEmailEditorPopup(true);
    }
  };

  const pauseVariantAction = async (
    seqId = "",
    stepId = "",
    variantId = "",
    cbk = () => {}
  ) => {
    if (seqId && stepId && variantId) {
      try {
        let targetURL = URLS.pauseAndResumeSequenceStageVariant
          .replace("<<sequenceId>>", seqId)
          .replace("<<stepId>>", stepId)
          .replace("<<variantId>>", variantId);
        const config = {
          url: targetURL,
          method: "PUT"
        };
        const res = await makeApi(config);
        const { status = true } = res?.data || {};
        cbk({ data: status });
      } catch (err) {
        console.error("pausing variant error", err);
      }
    }
  };

  const deactivateVariant = async (event, value) => {
    if (abTestingEnabled) {
      Utils.preventDefaultFn(event);
      setToggleLoader(true);
      const newSteps = Utils.createObjWithoutRef(steps);
      const currentStepVariants = steps[stepNo]["variants"];
      let validOperation = true;
      let initialValue = 0;

      if (value === false) {
        for (let property in currentStepVariants) {
          if (currentStepVariants?.[property].status === "INACTIVE") {
            initialValue += 1;
          }
        }
        if (
          Object.keys(currentStepVariants).length === 1 ||
          initialValue === Object.keys(currentStepVariants).length - 1
        ) {
          validOperation = false;
        }
      }

      const afterUpdateCbk = async (response = {}) => {
        setToggleLoader(false);
        if (response?.data) {
          toasterState.setToastMsg(
            `Variant ${value ? "activated" : "deactivated"} successfully`,
            "success"
          );
          setVariantStatus(value);
          getSelectedSeqData(seqId);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "failure"
          );
        }
      };

      if (validOperation) {
        newSteps[stepNo]["variants"][variantStep].status =
          (value && "ACTIVE") || "INACTIVE";
        let variantId =
          newSteps?.[stepNo]?.["variants"]?.[variantStep]?.uniqueId;
        value
          ? toggleVariants(newSteps, seqId, afterUpdateCbk, true)
          : pauseVariantAction(
              seqId,
              newSteps?.[stepNo]?.uniqueId,
              variantId,
              afterUpdateCbk
            );
      } else {
        setToggleLoader(false);
        toasterState.setToastMsg(
          "A sequence step must have at least one active variant.",
          "failure"
        );
      }
    } else {
      if (+totalVariant === 1) {
        toasterState.setToastMsg(
          "A sequence step must have at least one active variant.",
          "failure"
        );
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "abTesting",
          false,
          planName,
          inFreePlan
        );
      }
    }
  };

  const cloneVariant = () => {
    if (abTestingEnabled) {
      if (totalVariant < 5) {
        Utils.mixpanelTrack(MP_EVENT.AB_TESTING_ADD_VARIANT_CLICKED, {
          trigger: "Clone Variant"
        });
        toasterState.setToastMsg("Please wait...", "info");
        const newSteps = Utils.createObjWithoutRef(steps);
        const {
          variants: currentVariants = {},
          greatestVariantUniqueId: tempGreatestVariantUniqueId = 0
        } = newSteps[stepNo];

        const greatestVariantUniqueId =
          SequenceUtils.getGreatestVariantUniqueId(
            tempGreatestVariantUniqueId,
            currentVariants
          );
        let newClonedVariant = newSteps?.[stepNo]?.["variants"]?.[variantStep];
        newClonedVariant = {
          ...newClonedVariant,
          uniqueId: SequenceUtils.getUniqueVariantId(
            greatestVariantUniqueId,
            currentVariants?.length || 0
          )
        };
        newSteps[stepNo]["variants"][greatestVariantUniqueId + 1] =
          newClonedVariant;
        newSteps[stepNo].greatestVariantUniqueId = greatestVariantUniqueId + 1;
        updateSequenceSteps(newSteps, "PUT", "cloneVariant");
      } else {
        toasterState.setToastMsg(
          "Limit reached: you can only add up to 5 variants per step",
          "failure"
        );
      }
    } else {
      PaywallUtils.upgradeUtilsFunction(
        "abTesting",
        false,
        planName,
        inFreePlan
      );
    }
  };

  const deleteVariantAction = async (
    seqId = "",
    stepId = "",
    variantId = ""
  ) => {
    if (seqId && stepId && variantId) {
      try {
        let targetURL = URLS.deleteSequenceStageVariant
          .replace("<<sequenceId>>", seqId)
          .replace("<<stepId>>", stepId)
          .replace("<<variantId>>", variantId);
        const config = {
          url: targetURL,
          method: "DELETE"
        };
        const response = await makeApi(config);
        const { status = true } = response?.data || {};
        if (status) {
          await getSelectedSeqData(seqId);
          toasterState.setToastMsg("Sequence updated successfully.", "success");
          pageLoading(false);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later.",
            "failure"
          );
        }
      } catch (err) {
        console.error("delete variant error", err);
      }
    }
  };

  const getStageActiveVariantCount = (newSteps = {}) => {
    let count = 0;
    const variantObject = newSteps?.[stepNo]?.["variants"];
    variantObject &&
      Object.keys(variantObject)?.length > 1 &&
      Object.values(variantObject).map(val => {
        if (val?.status === "ACTIVE") {
          count++;
        }
      });
    return count;
  };

  const deleteVariant = () => {
    if (totalVariant > 1) {
      const newSteps = { ...steps };
      let variantId = newSteps?.[stepNo]?.["variants"]?.[variantStep]?.uniqueId;
      let activeVaraintCount = getStageActiveVariantCount(newSteps);
      if (
        activeVaraintCount > 1 ||
        (activeVaraintCount >= 1 &&
          newSteps?.[stepNo]?.["variants"]?.[variantStep]?.status ===
            "INACTIVE")
      ) {
        const deleteConfirmationCbk = async () => {
          await deleteVariantAction(
            seqId,
            newSteps?.[stepNo]?.uniqueId,
            variantId
          );
          confirmationPopupState.setShowPopup(false);
        };
        confirmationPopupState.setPopupValues({
          content: (
            <>
              Are you sure want to delete this email variant?
              <div>
                Once deleted, all associated analytics for this variant will be
                lost.
              </div>
            </>
          ),
          dynamicDom: true,
          actionBtnText: "Yes",
          loadingMsg: "Please wait...",
          callback: deleteConfirmationCbk
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        toasterState.setToastMsg(
          "Sequence step must have at least one ACTIVE variant.",
          "failure"
        );
      }
    } else {
      toasterState.setToastMsg(
        "Sequence step must have at least one variant.",
        "failure"
      );
    }
  };

  const pageLoading = (value = false) => {
    setShowStageLoader(value);
    setLoader(value);
  };

  const updateSequenceSteps = (data = {}, method = "PUT", action = "") => {
    const { greatestUniqueId: tempGreatestUniqueId = 0 } =
      addStagesPopupState.selectedSequence;

    const afterUpdateCbk = () => {
      addStagesPopupState.setAction(action);
      pageLoading(false);
    };

    const greatestUniqueId =
      tempGreatestUniqueId ||
      (steps &&
        Object.keys(steps)?.length &&
        Math.max.apply(
          Math,
          Utils.convertObjectToArray(steps).map(function (item) {
            return item.uniqueId;
          })
        )) ||
      0;
    (seqId &&
      addStagesPopupState.createUpdateStep(
        {
          id: seqId,
          steps: data,
          greatestUniqueId,
          firstStageIsReply: Utils.getFirstEmailStatus(data)
        },
        afterUpdateCbk,
        method
      )) ||
      pageLoading(false);
  };

  const getPercentageValue = value => {
    if (value === "bounce") {
      return bounceCount && totalContacts
        ? Utils.getPercentage(bounceCount, totalContacts, 1) + "%"
        : sentCount
        ? "0%"
        : "--";
    } else if (sentCount && value) {
      switch (value) {
        case "sent":
          return Utils.formatNumberToStringInUSFormat(sentCount) || "0%";
        case "open":
          return openCount
            ? Utils.getPercentage(openCount, sentCount, 1) + "%"
            : "0%";
        case "click":
          return clickCount
            ? Utils.getPercentage(clickCount, sentCount, 1) + "%"
            : "0%";
        case "reply":
          return replyCount
            ? Utils.getPercentage(replyCount, sentCount, 1) + "%"
            : "0%";
        default:
          return Utils.formatNumberToStringInUSFormat(sentCount) || "0%";
      }
    }
    return "--";
  };

  const moreOptions = [
    {
      icon: "create",
      name: "Edit",
      key: "edit",
      method: editVariant
    },
    {
      icon: "delete",
      name: "Delete",
      key: "delete",
      method: deleteVariant
    },
    {
      icon: "file_copy",
      name: "Clone",
      key: "clone",
      method: cloneVariant
    }
  ];

  useEffect(() => {
    return () => {
      setShowHoverCard(false);
      setLoader(false);
    };
  }, []);

  useEffect(() => {
    setVariantStatus(status?.toUpperCase() === "ACTIVE");
  }, [status]);

  const linkedInOverviewColumn =
    linkedInTaskType?.toLowerCase() === "li_send_connection_request"
      ? linkedInConnectionColumn
      : linkedInTaskType?.toLowerCase() === "li_view_profile"
      ? linkedInViewProfileColumn
      : linkedInSentMsgColumn;

  return (
    <div
      className={`variantCard ${
        !variantStatus && !isTask ? "disabledVariant" : ""
      } ${
        isLinkedInAutoTask
          ? "linkedinAutoStage"
          : isPhoneTask
          ? "phoneStage"
          : isTask
          ? "taskStage"
          : ""
      } ${loader ? "disabledAction" : ""}`}
      onClick={editVariant}
      onMouseEnter={() => setShowHoverCard(true)}
      onMouseLeave={() => setShowHoverCard(false)}
    >
      {showHoverCard && <HoverCard />}
      {!isTask && (
        <div
          className="contentDisplay"
          onClick={e => Utils.preventDefaultFn(e)}
        >
          {name && <div className="index">{name}</div>}
          <VariantToggle
            action={deactivateVariant}
            isChecked={variantStatus}
            loading={toggleLoader}
          />
        </div>
      )}
      <div className="subject">
        {replyAsThread && !isTask
          ? `Re: [Previous email Subject Line]`
          : subjectText}
      </div>
      <div className="content">{contentText}</div>
      {type === "phone" && (
        <div className="stats">
          {phoneCallOverviewColumn?.map(item => {
            const { name = "", key = "", value = 0 } = item || {};
            return (
              <div className="statsCountTitleWrap" key={key}>
                {analyticStepLoader ? (
                  <span className="statsCount linear-background" />
                ) : (
                  <span className="statsCount">
                    {Utils.formatNumberToStringInUSFormat(value) || "--"}
                  </span>
                )}
                <span className="statsTitle">
                  {`${name}${value > 1 ? "s" : ""}`}
                </span>
              </div>
            );
          })}
        </div>
      )}
      {type?.toLowerCase() === "linkedinauto" && (
        <div className="stats">
          {linkedInOverviewColumn?.map(item => {
            const { name = "", key = "", value = 0 } = item || {};
            return (
              <div className="statsCountTitleWrap" key={key}>
                {analyticStepLoader ? (
                  <span className="statsCount linear-background" />
                ) : (
                  <span className="statsCount">
                    {Utils.formatNumberToStringInUSFormat(value) || "--"}
                  </span>
                )}

                <span className="statsTitle">{name}</span>
              </div>
            );
          })}
        </div>
      )}
      {!["linkedin", "phone", "customtask", "linkedinauto"].includes(
        type?.toLowerCase()
      ) && (
        <div className="stats">
          {statsPercentColumn.map(item => {
            return (
              <div className="statsCountTitleWrap" key={item.key}>
                {analyticStepLoader ? (
                  <span className="statsCount linear-background" />
                ) : (
                  <span className="statsCount">
                    {getPercentageValue(item.key) || "--"}
                  </span>
                )}
                <span className="statsTitle">{item.name}</span>
              </div>
            );
          })}
        </div>
      )}
      {!isTask && (
        <MoreOptionSlide
          dropdownOptions={moreOptions}
          id={seqId}
          stepNo={stepNo}
        />
      )}
    </div>
  );
};

export default StageVariantCard;
export { StageVariantCard };
