/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-expressions */
import React, { useState, useContext, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import Cookies from "js-cookie";
import ContactTableLoader from "./ContactTableLoader";
import CheckBox from "Components/common/CheckBox";
import PaginationComponent from "Components/common/PaginationComponent";
import { ContactSearchTableContext } from "./ContactSearchTable";
import { userDetail } from "Stores/userDetail";
import {
  getConfidenceColor,
  getConfidenceScoreText,
  getContactPurchasePayload,
  showPaginationLimitReachPopup,
  verifyBulkActionPerformedAndAllow
} from "../DataSearchUtils";
import SinglePurchaseLoader from "./SinglePurchaseLoader";
import { Utils } from "Utils/utils";
import { dataSearchState } from "../state";
import SocialLinks from "./SocialLinks";
import { isPurchaseEnabled } from "../DataSearchUtils";
import NoFilterApplied from "./NoFilterApplied";
import { showPhonePurchasePopup } from "../DataSearchUtils";
import { toasterState } from "Components/common/toaster";
import CustomTooltip from "Components/common/CustomTooltip";
import ContactRangeSelection from "./ContactRangeSelection";
import IconDescription from "./IconDescription";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { DOMAIN_KEY, MP_EVENT } from "Static/constant";
import { priceRangeSliderPopupState } from "Components/popup/PriceRangeSliderPopup/main";

const SearchTableBody = observer(() => {
  const contactBodyRef = useRef(null);
  const history = useHistory();
  const {
    tableLoading = false,
    setTableLoading = () => {},
    contactData = {},
    allTableContactChecked = false,
    setAllTableContactChecked = () => {},
    setContactData = () => {},
    resetAllDynamicState = () => {}
  } = useContext(ContactSearchTableContext);

  const {
    paginationLimit = 0,
    bulkPurchaseEnabled = false,
    bulkPurchaseLimit = 0
  } = userDetail?.userFeatureFlag || {};

  const [paginationForceRender, setPaginationForceRender] = useState(false); // Hack: used to reset pagintation to previous value

  const markSelectedContact = (value = false, contactId = "", limit = 0) => {
    if (["all", "custom", "bulk"].includes(contactId?.toLowerCase())) {
      if (!value && allTableContactChecked) {
        deselectAllCheckedContacts();
        dataSearchState.setRangeSelected("");
      } else {
        dataSearchState.setRangeSelected({ type: contactId, limit });
        setAllTableContactChecked(value);
        toggleSelectContacts("", limit);
      }
    } else {
      const performSelectContactAction = () => {
        if (value) {
          let tempSelectCount =
            dataSearchState.selectedContactData?.length || 0;
          let pageCount =
            dataSearchState?.contactTableData?.contacts?.length || 0;
          let isSelectAll = tempSelectCount === pageCount - 1;
          isSelectAll &&
            dataSearchState.setRangeSelected({ type: "all", limit: pageCount });
          setAllTableContactChecked(isSelectAll);
        } else {
          setAllTableContactChecked(false);
          dataSearchState.setRangeSelected("");
        }
        toggleSelectContacts(contactId);
      };
      if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
        verifyBulkActionPerformedAndAllow(performSelectContactAction);
      } else {
        performSelectContactAction();
      }
    }
  };

  const deselectAllCheckedContacts = () => {
    contactData?.contacts &&
      contactData.contacts.map(value => {
        value.isSelected = !value.isSelected;
      });
    setContactData(contactData);
    setAllTableContactChecked(false);
    dataSearchState.setSelectedContactData([]);
  };

  const toggleSelectContacts = (contactId = "", limit = 0) => {
    const clonedData = Utils.createObjWithoutRef(contactData);
    if (clonedData?.contacts && Utils.hasObject(clonedData?.contacts)) {
      let count = 0;
      clonedData.contacts.map(value => {
        if (contactId) {
          value?.id === contactId && (value.isSelected = !value.isSelected);
        } else {
          // Selecting all the contacts in the table
          if (limit > 0) {
            value.isSelected = limit > count;
            count++;
          } else {
            value.isSelected = true;
          }
        }
      });
    }
    setContactData(clonedData);
    updateSelectedDataInStore(clonedData);
  };

  const updateSelectedDataInStore = data => {
    const tempArr = [];
    data?.contacts?.map(value => {
      if (value?.isSelected) {
        tempArr.push(value.id);
      }
    });
    dataSearchState.setSelectedContactData(tempArr);
  };

  const paginate = pageObj => {
    if (!tableLoading) {
      const performPagination = () => {
        const pageNo = pageObj?.selected + 1 || 1;
        if (paginationLimit >= pageNo) {
          Utils.mixpanelTrack(MXP_EVENT.PAGINATION_CLICKED, {
            pageType: PAGE_TYPE.discover,
            pageNo
          });
          setTableLoading(true);
          resetAllDynamicState();
          dataSearchState.setCurrentPage(pageNo);
          dataSearchState.setIsFromSeoPage(false);
          dataSearchState.searchPageData();
          contactBodyRef &&
            contactBodyRef.current &&
            contactBodyRef.current.scrollTo({
              top: 0,
              behavior: "smooth"
            });
        } else {
          Utils.mixpanelTrack(MXP_EVENT.PAGINATION_LIMIT_REACHED_POPUP_SHOWN, {
            pageType: PAGE_TYPE.discover,
            pageNo
          });
          setPaginationForceRender(true);
          showPaginationLimitReachPopup(history);
          setTimeout(() => {
            setPaginationForceRender(false);
          }, 0);
        }
      };
      if (["bulk", "custom"].includes(dataSearchState?.rangeSelected?.type)) {
        verifyBulkActionPerformedAndAllow(performPagination, () => {
          setPaginationForceRender(true);
          setTimeout(() => {
            setPaginationForceRender(false);
          }, 0);
          dataSearchState.setCurrentPage(dataSearchState.currentPage);
        });
      } else {
        performPagination();
      }
    }
  };

  const singleContactPurchaseCbk = async (contactId, type, contact) => {
    const { firstName = "", lastName = "", companyName = "" } = contact || {};
    dataSearchState.setSinglePurchaseContactName(
      [firstName, lastName].filter(Boolean).join(" ")
    );
    dataSearchState.setContactCompanyName(companyName);
    if (type.toLowerCase() === "phone") {
      Utils.mixpanelTrack(MXP_EVENT.FIND_PHONE_NUMBER_CLICKED, {
        pageType: PAGE_TYPE.discover,
        contactName: `${contact?.firstName} ${contact?.lastName}`,
        companyName: contact?.companyName
      });
    }
    toggleSinglePurchaseLoader(contactId, type, true);
    const payload = getContactPurchasePayload([contactId], null, type);
    await dataSearchState.purchaseSingleContact(payload, history);
    setTimeout(() => {
      dataSearchState.setIsFindPhoneEmailClicked(false);
    }, 1000);
    toggleSinglePurchaseLoader(contactId, type, false, true);
  };

  const toggleSinglePurchaseLoader = (
    contactId,
    type,
    toggleValue,
    hasData = false
  ) => {
    dataSearchState.setContactId(contactId);
    hasData
      ? dataSearchState.setFoundData(true)
      : dataSearchState.setFoundData(false);
    type.toLowerCase() === "email"
      ? dataSearchState.setFindDataType("email")
      : dataSearchState.setFindDataType("phone");
    const clonedData = Utils.createObjWithoutRef(
      dataSearchState?.contactTableData
    );
    clonedData?.contacts &&
      clonedData.contacts.map(value => {
        if (value?.id === contactId) {
          if (type === "phone") {
            value.singlePurchasePhoneLoading = toggleValue;
          } else {
            value.singlePurchaseEmailLoading = toggleValue;
          }
          if (hasData && dataSearchState.isFromSeoPage) {
            value.isSelected = true;
          }
        }
      });
    if (clonedData?.contacts?.length > 0 && !dataSearchState.isFromSeoPage) {
      clonedData.contacts[0].isSelected = false;
    }
    if (dataSearchState?.isFromSeoPage) {
      updateSelectedDataInStore(clonedData);
    }
    dataSearchState.setContactTableData(clonedData);
    setContactData(clonedData);
  };

  const adjustHeight = () => {
    return contactData?.totalPages > 1 ? "adjustPaginationSpace" : "";
  };

  const hasTableData = () => {
    return (!dataSearchState?.selectedContactData?.length > 0 &&
      !contactData?.totalContacts > 0) ||
      !dataSearchState.isFilterApplied
      ? "noTableSearchData"
      : "";
  };

  return (
    <>
      <section
        className={`searchTableBody ${hasTableData()} ${adjustHeight()} ${
          dataSearchState?.isFromSeoPage ? "isFromSeoPage" : ""
        }`}
      >
        <div className="contactBody" ref={contactBodyRef}>
          {dataSearchState?.showInitialPage ||
          !dataSearchState?.isFilterApplied ? (
            <NoFilterApplied />
          ) : (
            <>
              <TableTitle
                totalContacts={contactData?.totalContacts}
                totalPages={contactData?.totalPages}
                markSelectedContact={markSelectedContact}
                bulkPurchaseLimit={bulkPurchaseLimit}
                bulkPurchaseEnabled={bulkPurchaseEnabled}
                deselectAllCheckedContacts={deselectAllCheckedContacts}
              />
              <TableContent
                markSelectedContact={markSelectedContact}
                singleContactPurchaseCbk={singleContactPurchaseCbk}
                toggleSelectContacts={toggleSelectContacts}
              />
            </>
          )}
        </div>
        <PaginationComponent
          callback={paginate}
          loading={tableLoading}
          totalPages={contactData?.totalPages}
          currentPage={dataSearchState?.currentPage || 1}
          forceRender={paginationForceRender}
        />
      </section>
      {contactData?.totalContacts > 0 &&
        dataSearchState?.isFromSeoPage &&
        !tableLoading && <IconDescription />}
    </>
  );
});

const TableTitle = observer(props => {
  const {
    bulkPurchaseLimit = 0,
    bulkPurchaseEnabled = false,
    totalContacts = 0,
    totalPages = 0,
    markSelectedContact = () => {},
    deselectAllCheckedContacts = () => {}
  } = props || {};

  const {
    allTableContactChecked = false,
    setAllTableContactChecked = () => {},
    tableLoading = false
  } = useContext(ContactSearchTableContext);

  let pagedContacts = dataSearchState?.contactTableData?.contacts?.length || 0;
  let topPurchaseLimit =
    totalContacts < bulkPurchaseLimit && totalContacts <= 500
      ? totalContacts
      : bulkPurchaseLimit < 500
      ? bulkPurchaseLimit
      : 500;
  let customBulkPurchaseLimit =
    totalContacts < bulkPurchaseLimit ? totalContacts : bulkPurchaseLimit;

  const [showRangeSelector, setShowRangeSelector] = useState(false);
  const [isCustomRangeSelector, setCustomRangeSelector] = useState(false);

  const toggleSelectAction = (value = false, id = "") => {
    if (totalPages > 1) {
      setShowRangeSelector(true);
      if (allTableContactChecked) {
        dataSearchState.setRangeSelected("");
        deselectAllCheckedContacts();
        setAllTableContactChecked(false);
      }
    } else {
      markSelectedContact(value, id, pagedContacts);
    }
  };

  return (
    <div className="tableHeader">
      <div className="ctSelectAll">
        {!tableLoading && totalContacts > 0 && (
          <>
            <CheckBox
              key={"all"}
              id={"all"}
              checked={allTableContactChecked}
              cbk={toggleSelectAction}
            />
            {(showRangeSelector && (
              <ContactRangeSelection
                bulkPurchaseEnabled={bulkPurchaseEnabled}
                allTableContactChecked={allTableContactChecked}
                pagedContacts={pagedContacts}
                topPurchaseLimit={topPurchaseLimit}
                customBulkPurchaseLimit={customBulkPurchaseLimit}
                isCustomRangeSelector={isCustomRangeSelector}
                setCustomRangeSelector={setCustomRangeSelector}
                setShowRangeSelector={setShowRangeSelector}
                markSelectedContact={markSelectedContact}
              />
            )) ||
              ""}
          </>
        )}
      </div>
      <div className="nameCont">Contact</div>
      <div className="titleCont">Title</div>
      <div className="companyCont">Company</div>
      <div className="emailCont">Email & Social</div>
      <div className="mobileCont">Mobile/Direct Dial</div>
    </div>
  );
});

const TableContent = observer(props => {
  const {
    singleContactPurchaseCbk = () => {},
    markSelectedContact = () => {},
    toggleSelectContacts = () => {}
  } = props || {};

  const { contactData = [], tableLoading } = useContext(
    ContactSearchTableContext
  );
  const data = contactData?.contacts || [];
  const [tooltipIndex, setTooltipIndex] = useState("");

  const getName = (fName, lName) => {
    return fName && lName ? `${fName} ${lName}` : fName || lName || "--";
  };

  const getLocation = address => {
    if (address?.[0]) {
      const { state = "", country = "" } = address[0];
      let location = state ? state + ", " : "";
      location += country || "";
      return location;
    }
  };

  const getFirstElem = (arr = []) => {
    if (arr?.length > 0) {
      return arr.filter(item => item && item !== "null" && item)?.[0] || "";
    }
    return "";
  };

  const getTooltipClassName = (id = "") => {
    if (
      id &&
      tooltipIndex &&
      tooltipIndex?.id === id &&
      tooltipIndex?.type &&
      tooltipIndex?.value
    ) {
      return tooltipIndex?.type === "email"
        ? "emailTooltip"
        : tooltipIndex?.type === "deliverablityDot"
        ? "deliverabilityDotTooltip"
        : "";
    }
    return "";
  };

  const getEmail = (
    email,
    emailOwned,
    linkedIn,
    facebook,
    twitter,
    confidenceScore,
    singlePurchaseEmailLoading,
    id,
    contact
  ) => {
    return emailOwned ? (
      <>
        <div className={`emailWrapper ${getTooltipClassName(id)}`}>
          <span
            className="darkText email"
            onClick={() =>
              copyEmail(email, "Email address copied successfully.", "email")
            }
            onMouseOver={() =>
              setTooltipIndex({ id, type: "email", value: true })
            }
            onMouseLeave={() => setTooltipIndex("")}
          >
            {email}
          </span>
          <span
            className="dot"
            style={{
              backgroundColor: getConfidenceColor(confidenceScore)
            }}
            onMouseOver={() =>
              setTooltipIndex({ id, type: "deliverablityDot", value: true })
            }
            onMouseLeave={() => setTooltipIndex("")}
          />
          {tooltipIndex &&
            tooltipIndex?.id === id &&
            ["deliverablityDot", "email"].includes(tooltipIndex?.type) &&
            tooltipIndex?.value && (
              <CustomTooltip
                text={
                  tooltipIndex?.type === "deliverablityDot"
                    ? `${getConfidenceScoreText(confidenceScore)} email
                  deliverability`
                    : "Click to copy"
                }
              />
            )}
        </div>
        <SocialLinks
          linkedIn={linkedIn}
          facebook={facebook}
          twitter={twitter}
          id={id}
          showOnlyLinkedInAndTwitter={true}
        />
      </>
    ) : (
      getMaskElem("email", id, singlePurchaseEmailLoading, contact)
    );
  };

  const getMaskElem = (type, id, singlePurchaseLoading, contact) => {
    return (
      <div
        className="maskData"
        onClick={() => singleContactPurchaseCbk(id, type, contact)}
      >
        {singlePurchaseLoading ? (
          <SinglePurchaseLoader />
        ) : (
          <span className="maskText">
            {type === "email" ? "View Email & Social" : "Find phone number"}
          </span>
        )}
      </div>
    );
  };

  const copyEmail = (val, msg, type = "") => {
    if (val) {
      Utils.mixpanelTrack(
        MXP_EVENT[
          type === "email" ? "COPY_EMAIL_CLICKED" : "COPY_PHONE_CLICKED"
        ],
        {
          pageType: PAGE_TYPE.discover
        }
      );
      Utils.copyToClipboard(val);
      toasterState.setToastMsg(msg, "success");
    }
  };

  const getPhoneNumberWithCopyTooltip = (id = "", phoneArr = [], type = "") => {
    let contactNumber = getFirstElem(phoneArr)?.number || "";
    return (
      <div
        className="darkText phone"
        onClick={() =>
          copyEmail(contactNumber, `Phone Number copied successfully`, "phone")
        }
        onMouseOver={() =>
          setTooltipIndex({
            id,
            type,
            value: true
          })
        }
        onMouseLeave={() => setTooltipIndex("")}
      >
        <span>{contactNumber}</span>
        {tooltipIndex &&
          tooltipIndex?.id === id &&
          tooltipIndex?.type === type &&
          tooltipIndex?.value && <CustomTooltip text={`Click to copy`} />}
      </div>
    );
  };

  const updateFilterValue = () => {
    const groupByVal = Cookies.get("sg-groupBy");
    const groupByValueKey = groupByVal && groupByVal?.split(",");
    const {
      id: contactId = "",
      companyId = "",
      companyName = ""
    } = dataSearchState?.contactTableData?.contacts?.[0] || {};
    let filterObj = {};
    let customFilterObj = {};
    let updatedFilterData = [];
    groupByValueKey?.length > 0 &&
      groupByValueKey.map(item => {
        const groupByValue =
          dataSearchState?.contactTableData?.contacts?.[0]?.[item];
        filterObj = {
          ...filterObj,
          [item]: [groupByValue]
        };
        customFilterObj = {
          ...customFilterObj,
          [item === "lastName" ? "name" : item]: [groupByValue]
        };
        if (item === "companyDomain") {
          updatedFilterData.push({
            checked: true,
            primaryDomain: groupByValue,
            id: companyId,
            name: companyName
          });
          dataSearchState.setSelectedFilteredList({
            ...dataSearchState?.selectedFilteredList,
            [item]: updatedFilterData
          });
        } else {
          updatedFilterData.push(groupByValue);
        }
      });
    dataSearchState.setFilterComponentData({
      ...dataSearchState.filterComponentData,
      excludeContactId: contactId,
      ...filterObj
    });
    dataSearchState.searchPageData(true);
    dataSearchState.setFilterComponentData({
      ...dataSearchState.filterComponentData,
      excludeContactId: contactId,
      ...customFilterObj
    });
    if (groupByVal) {
      Cookies.remove("sg-groupBy", { path: "", domain: DOMAIN_KEY });
    }
  };

  const loadMoreContacts = email => {
    if (
      dataSearchState?.isFromSeoPage &&
      (dataSearchState?.isEmailViewedFromSeoPage || email)
    ) {
      if (dataSearchState?.contactTableData?.contacts?.length > 0) {
        dataSearchState.contactTableData.contacts[0].isSelected = true;
      }
      updateFilterValue();
      const updatedFilterComponentData = {
        ...dataSearchState.filterComponentData
      };
      delete updatedFilterComponentData["excludeContactId"];
      delete updatedFilterComponentData["seoName"];
      delete updatedFilterComponentData["lastName"];
      dataSearchState.setFilterComponentData({
        ...updatedFilterComponentData
      });
    }
  };

  useEffect(() => {
    if (data?.length > 0) {
      dataSearchState.setHasData(true);
    }
  }, [data]);

  useEffect(() => {
    if (dataSearchState?.isFromSeoPage && dataSearchState?.hasData) {
      toggleSelectContacts(data?.[0]?.id);
    }
  }, [dataSearchState?.isFromSeoPage, dataSearchState?.hasData]);

  return (
    <div className="tableContent">
      {tableLoading ? (
        <ContactTableLoader />
      ) : data?.length > 0 ? (
        <>
          {data?.map(contact => {
            const {
              id = "",
              firstName = "",
              lastName = "",
              email = "",
              title = "",
              department = [],
              companyLinkedIn = "",
              companyFacebook = "",
              companyTwitter = "",
              address = [],
              companyAddress = [],
              companyName = "",
              companyDomain = "",
              linkedIn = "",
              facebook = "",
              twitter = "",
              phoneOwned = false,
              emailOwned = false,
              directPhone = [],
              mobilePhone = [],
              otherPhone = [],
              companyPhone = [],
              confidenceScore = "",
              isSelected = false,
              singlePurchaseEmailLoading = false,
              singlePurchasePhoneLoading = false,
              hasShow = false,
              phoneNumberUnavailable = false
            } = contact || {};
            const location = getLocation(address || companyAddress);
            const name = getName(firstName, lastName);

            return (
              <div className="contact" key={contact?.id} has-data="true">
                <div className="ctSelectAll">
                  <CheckBox
                    key={id}
                    id={id}
                    checked={isSelected}
                    cbk={markSelectedContact}
                  />
                </div>
                <div className="nameCont dataContainer">
                  {name && (
                    <span className="darkText name" title={name}>
                      {name}
                    </span>
                  )}
                  {location && (
                    <span className="lightText location" title={location}>
                      {location}
                    </span>
                  )}
                </div>
                <div className="titleCont dataContainer">
                  {title && (
                    <span className="darkText title" title={title}>
                      {title}
                    </span>
                  )}
                  <span className="lightText" title={department}>
                    {getFirstElem(department)}
                  </span>
                </div>
                <div className="companyCont dataContainer">
                  {(companyName || companyDomain) && (
                    <span
                      className="darkText companyName"
                      title={companyName || companyDomain}
                    >
                      {companyName || companyDomain}
                    </span>
                  )}
                  <SocialLinks
                    companyDomain={companyDomain}
                    linkedIn={companyLinkedIn}
                    facebook={companyFacebook}
                    twitter={companyTwitter}
                    companyNumber={getFirstElem(companyPhone)}
                    id={id}
                  />
                </div>
                <div className="emailCont dataContainer">
                  {getEmail(
                    email,
                    emailOwned,
                    linkedIn,
                    facebook,
                    twitter,
                    confidenceScore,
                    singlePurchaseEmailLoading,
                    id,
                    contact
                  )}
                  {dataSearchState?.isFromSeoPage &&
                    !dataSearchState?.isEmailViewedFromSeoPage &&
                    !email && (
                      <div className="clickHereToViewWrapper">
                        <div className="arrow">
                          <div className="upLine"></div>
                          <div className="upPoint"></div>
                        </div>
                        <div className="clickHereToView">{`Click here to view ${firstName} ${lastName}'s email address and social links`}</div>
                      </div>
                    )}
                </div>
                <div
                  className={`mobileCont dataContainer ${
                    hasShow ? "showCont" : " "
                  }`}
                >
                  {!phoneNumberUnavailable ? (
                    phoneOwned ? (
                      <>
                        {directPhone?.length > 0 &&
                          getPhoneNumberWithCopyTooltip(
                            id,
                            directPhone,
                            "directPhone"
                          )}
                        {mobilePhone?.length > 0 &&
                          getPhoneNumberWithCopyTooltip(
                            id,
                            mobilePhone,
                            "mobilePhone"
                          )}
                        {otherPhone?.length > 0 &&
                          getPhoneNumberWithCopyTooltip(
                            id,
                            otherPhone,
                            "otherPhone"
                          )}
                        {hasShow && (
                          <div
                            className="showBtn"
                            onClick={() =>
                              dataSearchState.setIsNoPhoneCredits(true)
                            }
                          >
                            Show
                          </div>
                        )}
                      </>
                    ) : (
                      getMaskElem(
                        "phone",
                        id,
                        singlePurchasePhoneLoading,
                        contact
                      )
                    )
                  ) : (
                    <span className="notFoundText">
                      Phone number unavailable
                    </span>
                  )}
                </div>
              </div>
            );
          })}
          {dataSearchState?.isFromSeoPage && data?.length > 0 && (
            <div
              className={`loadMoreContactsWrapper ${
                !dataSearchState.isEmailViewedFromSeoPage && !data?.[0]?.email
                  ? "applyOpacity"
                  : ""
              }`}
            >
              <p
                className="loadMoreContacts"
                onClick={() => loadMoreContacts(data?.[0]?.email)}
              >
                Click here to load more similar contacts
              </p>
            </div>
          )}
        </>
      ) : (
        <div className="noContactFound lightText">No Contacts Found</div>
      )}
    </div>
  );
});

export { SearchTableBody };
export default SearchTableBody;
