import React from "react";

const LinearProgressBar = ({
  width = 0,
  bgColor = "#38B892",
  creditsCardLoader = false
}) => {
  return (
    <div className={`linearProgressBar`}>
      <div className="backgroundProgress"></div>
      <div
        className={`linearProgress  ${
          creditsCardLoader ? "linear-background" : ""
        }`}
        style={{
          width: `${width}%`,
          background: `${bgColor}`
        }}
      ></div>
    </div>
  );
};

export default LinearProgressBar;
export { LinearProgressBar };
