/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, createContext } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import BreadCrumbComponent from "Components/common/breadcrumb";
import ContactsRouting from "Routes/ContactsRouting";
import contactStoreData from "Stores/contactData";
import userDetail from "Stores/userDetail";
import AddContactBtn from "./AddContact";
import { KnowMoreButton } from "Components/common/KnowMoreButton";
import CustomList from "Pages/Dashboard/dataSearch/CustomList";

const defaultBreadcrumbValue = [{ name: "Contacts", link: "/contacts" }];
const ContactStateContext = createContext({});

const Contact = observer(props => {
  let location = useLocation();

  let isActivityFeed = location.pathname?.substring(1)?.includes("activity");

  const { userFeatureFlag = {} } = userDetail || {};
  const { noOfContactsEligible = 0, noOfContactsUsed = 0 } =
    userFeatureFlag || {};

  const [breadcrumbArr, setBreadCrumbArr] = useState(defaultBreadcrumbValue);

  const [tableLoading, setTableLoading] = useState(true);
  const [contactLimit, setContactLimit] = useState(10);
  const [activeTab, setActiveTab] = useState("total");
  const [isContactUploading, setContactUploading] = useState(false);

  const remainingContactCount = () => {
    return noOfContactsEligible - noOfContactsUsed;
  };

  const updateBreadCrumb = name => {
    if (name) {
      setBreadCrumbArr([
        ...defaultBreadcrumbValue,
        {
          name,
          link: ""
        }
      ]);
    } else {
      setBreadCrumbArr(defaultBreadcrumbValue);
    }
  };

  useEffect(() => {
    updateBreadCrumb(contactStoreData.breadcrumbValue);
  }, [contactStoreData.breadcrumbValue]);

  return (
    <ContactStateContext.Provider
      value={{
        tableLoading,
        setTableLoading,
        contactLimit,
        setContactLimit,
        activeTab,
        setActiveTab,
        isContactUploading,
        setContactUploading
      }}
    >
      <div className="contactPage pageClass">
        <div className="sequenceCont pageCont">
          <section className="headerSection">
            <div className="leftSectionWrapper">
              <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
              {!isActivityFeed && (
                <CustomList hasAddNewList={false} isFromContactPage={true} />
              )}
            </div>
            <div className="headerBtnContainer">
              <KnowMoreButton
                redirectUrl={
                  "https://support.salesgear.io/en/collections/3172710-contacts"
                }
              />
              {!isActivityFeed && (
                <AddContactBtn
                  validContactUpload={remainingContactCount() > 0}
                  checkEligibleContactCount={remainingContactCount()}
                  tableLoading={tableLoading}
                  activeTab={activeTab}
                  contactLimit={contactLimit}
                  setContactUploading={setContactUploading}
                  pageType="contacts"
                  setActiveTab={setActiveTab}
                />
              )}
            </div>
          </section>
          <ContactsRouting />
        </div>
      </div>
    </ContactStateContext.Provider>
  );
});

export default withRouter(Contact);
export { Contact };
export { ContactStateContext };
