import React, { useState, useEffect, createContext } from "react";
import HeaderLayout from "Components/LoggedInLayout/HeaderLayout";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { LEARN_MORE_LINK } from "Static/constant";
import userDetail from "Stores/userDetail";
import TabNavigation from "Components/common/TabNavigation";
import { CALLS_TAB_NAVIGATION } from "Static/constant";
import CallsTabRouting from "Routes/CallsTabRouting";
import CallsFilter from "./CallsFilter";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { USER_SELECT_DROPDOWN_LIST } from "Static/constant";
import Utils from "Utils/utils";
import { openDialerCard } from "Global/commonUtils";

const CallsFilterContext = createContext({});

const defaultState = {
  teamLoader: true,
  teamList: "",
  userFilterList: [...USER_SELECT_DROPDOWN_LIST]
};

export const CallsModule = observer(props => {
  const breadcrumbArr = [{ name: "Calls", link: "/calls" }];
  const {
    noOfContactsAddedInTeam,
    inFreePlan = false,
    dialerEnabled = false
  } = userDetail?.userFeatureFlag || {};

  let location = useLocation();
  let isOverview = location?.pathname?.indexOf("overview") !== -1;
  let pageType = isOverview ? "Overview Page" : "Analytics Page";

  const [data, setData] = useState({ ...defaultState });

  const getTeamList = async () => {
    const resData = await Utils.userTeamList();
    userDetail.setUserInfo({
      ...userDetail.userInfo,
      hasTeamMember: resData?.length ? resData : false
    });
    setData({ ...data, ...{ teamLoader: false, teamList: resData } });
  };

  const resetUserFilterOption = () => {
    const newArr = [...data.userFilterList];
    newArr.map(item => (item.checked = newArr.id === "you"));
    setData({ ...defaultState, userFilterList: newArr });
  };

  useEffect(() => {
    getTeamList();
    return () => {
      resetUserFilterOption();
      upgradePopupState.setPopup(false);
    };
  }, []);

  return (
    <div className="callsSection">
      <HeaderLayout
        className="report"
        redirectUrl={LEARN_MORE_LINK.calls}
        breadcrumbArr={breadcrumbArr}
        {...props}
        btnType="calls"
        callbackAction={() => openDialerCard("calls")}
      />
      <div className={`callsContainer`}>
        <div className="callsBody">
          <div className="callsCard">
            <CallsFilterContext.Provider
              value={{
                ...data,
                isContactsAdded: noOfContactsAddedInTeam > 0,
                inFreePlan,
                isOverview,
                dialerEnabled,
                pageType
              }}
            >
              <div className="filterNaviagtionCard">
                <TabNavigation tabList={CALLS_TAB_NAVIGATION} />
                <CallsFilter />
              </div>
              <CallsTabRouting />
            </CallsFilterContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
});

export default CallsModule;
export { CallsFilterContext };
