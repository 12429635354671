import React from "react";

const ProcessingLoader = () => {
  return (
    <div className="circular-loader-container">
      <div className="circular-loader"></div>
    </div>
  );
};

export default ProcessingLoader;
export { ProcessingLoader };
