import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import PageLimit from "Components/common/PageLimit";
import Utils from "Utils/utils";
import PaginationComponent from "Components/common/PaginationComponent";
import { MP_EVENT } from "Static/constant";
import makeApi, { URLS } from "Utils/apiURL";
import CustomTooltip from "Components/common/CustomTooltip";
import { toasterState } from "Components/common/toaster";
import { ReactComponent as PlayIcon } from "./playIcon.svg";
import { ReactComponent as PauseIcon } from "./pauseIcon.svg";
import { ReactComponent as StopIcon } from "./stopIcon.svg";
import { ReactComponent as LinkedInsSvg } from "Assets/svg/linkedInSvg.svg";
import ImportProgressbar from "Components/common/ImportProgressbar";
import ProcessingLoader from "Components/common/ProcessingLoader";

const LinkedInImports = observer(() => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState(1);
  const [totalJobs, setTotalJobs] = useState(0);
  const [showTooltip, setShowTooltip] = useState({});
  const [actionTooltip, setActionTooltip] = useState({});

  const statusField = {
    IN_PROGRESS: {
      status: "In Progress",
      // color: "#36D593"
      component: <ProcessingLoader />
    },
    ACTIVE: {
      status: "Active",
      color: "#36D593"
    },
    COMPLETED: {
      status: "Completed",
      color: "#3953FB"
    },
    PAUSED: {
      status: "Paused",
      color: "#FF6057"
    },
    FAILED: {
      status: "Failed",
      color: "red"
    },
    STOPPED: {
      status: "Stopped",
      color: "red"
    }
  };

  const failedPercentage = (totalCount, successCount) => {
    if (totalCount === 0) {
      return 0;
    }
    return ((totalCount - successCount) / totalCount) * 100;
  };

  const successPercentage = (totalCount, successCount) => {
    if (totalCount === 0) {
      return 100;
    }
    return (successCount / totalCount) * 100;
  };

  const progressStatusField = {
    FAILED: {
      status: (totalCount, successCount) =>
        failedPercentage(totalCount, successCount),
      color: "#FF6057"
    },
    SUCCESS: {
      status: (totalCount, successCount) =>
        successPercentage(totalCount, successCount),
      color: "#00D274"
    }
  };

  const headerList = [
    { name: "Date", key: "createdDate" },
    { name: "Total", key: "totalContacts" },
    { name: "Extracted", key: "contactsExtracted" },
    { name: "Progress", key: "contactsPerDay" },
    // { name: "Contacts per day", key: "contactsPerDay" },
    { name: "Status", key: "status" },
    { name: "Search URL", key: "searchUrl" },
    // { name: "Actions", key: "actions" },
    { name: "Owner", key: "ownerName" }
  ];

  const getLiImportsData = async () => {
    try {
      const config = {
        url: URLS.linkedInImportsList,
        method: "POST",
        data: {
          limit,
          pageNo
        }
      };
      const res = await makeApi(config);
      const {
        jobs = [],
        pageNo: jobPageNo = 1,
        totalPages,
        totalJobs
      } = res?.data || {};
      if (res?.data) {
        if (totalJobs && jobs?.length) {
          setData(jobs);
          setPageNo(jobPageNo);
          setPages(totalPages);
          setTotalJobs(totalJobs);
        } else if (res?.response?.status === 401) {
          Utils.showLoggedOutPopup();
        }
      } else {
        Utils.showApiFailureResponse(res);
      }
      setLoader(false);
    } catch (err) {
      console.error("Fetching csv imports error", err);
    }
  };

  const paginate = pageNoObj => {
    setPageNo(pageNoObj?.selected + 1 || 1);
  };

  const handleActionByLimit = (limit = 10) => {
    setLimit(limit);
  };

  const handlePause = async (id = "") => {
    setLoader(true);
    try {
      const config = {
        url: URLS.pauseImportContact,
        method: "POST",
        data: {
          jobId: id
        }
      };
      const res = await makeApi(config);
      if (res?.data) {
        const { responseCode = "" } = res?.data || {};
        if (+responseCode === 200) {
          toasterState.setToastMsg("Import successfully paused");
          getLiImportsData();
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error("Fetching csv imports error", err);
    }
  };

  const handleResume = async id => {
    setLoader(true);
    try {
      const config = {
        url: URLS.resumeImportContact,
        method: "POST",
        data: {
          jobId: id
        }
      };
      const res = await makeApi(config);
      if (res?.data) {
        const { responseCode = "" } = res?.data || {};
        if (+responseCode === 200) {
          toasterState.setToastMsg("Import successfully resumed");
          getLiImportsData();
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error("Fetching csv imports error", err);
    }
  };

  const handleStop = async id => {
    setLoader(true);
    try {
      const config = {
        url: URLS.stopImportContact,
        method: "POST",
        data: {
          jobId: id
        }
      };
      const res = await makeApi(config);
      if (res?.data) {
        const { responseCode = "" } = res?.data || {};
        if (+responseCode === 200) {
          toasterState.setToastMsg("Import successfully stopped");
          getLiImportsData();
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    } catch (err) {
      console.error("Fetching csv imports error", err);
    }
  };

  useEffect(() => {
    setLoader(true);
    getLiImportsData();
  }, [pageNo, limit]);

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.CSV_IMPORTS_VIEWED);
    return () => {
      setLoader(true);
      setData([]);
      setLimit(10);
      setPageNo(1);
      setPages(1);
    };
  }, []);

  return (
    <div className="csvImportsSection">
      <div className="tableList">
        <div className="tableHeader tableHeaderLinkedIn">
          {headerList?.map((item, index) => {
            const { name = "", key = "" } = item || {};
            return (
              <div className={`titleRow ${key}`} key={`csvImports${index}`}>
                {name}
              </div>
            );
          })}
        </div>
        {!loader ? (
          <>
            {(data?.length && (
              <div className="tableRecordHeightAlign">
                {data?.map((item, index) => {
                  const {
                    id,
                    createTs,
                    owner,
                    status = "",
                    searchUrl = "",
                    totalContacts = "",
                    contactsPerDay = 0,
                    noOfContactsImported = 0
                  } = item || {};
                  // console.log("Status: ", status);
                  return (
                    <div
                      className={`tableBody tableBodyLinkedIn ${id}`}
                      key={`csvTableBody${index}`}
                    >
                      <div className={`createdDate titleRow`}>
                        {Utils.getDateFromString(createTs || "--")}
                      </div>
                      {/* <a
                        className={`searchUrl titleRow`}
                        href={searchUrl}
                        target="_blank"
                      >
                        {searchUrl || "--"}
                      </a> */}
                      <div className={`totalContacts titleRow`}>
                        {Utils.formatNumberToString(totalContacts) || "--"}
                      </div>
                      <div className={`contactsExtracted titleRow`}>
                        {Utils.formatNumberToString(noOfContactsImported) ||
                          "--"}
                      </div>
                      <div className={`contactsProgress titleRow`}>
                        {/* {Utils.formatNumberToString(contactsPerDay) || "--"} */}
                        {["IN_PROGRESS"].includes(status) ? (
                          <div className="progressbarWrapper">
                            <ImportProgressbar
                              total={totalContacts}
                              processed={noOfContactsImported}
                            />
                          </div>
                        ) : [
                            "ACTIVE",
                            "COMPLETED",
                            "STOPPED",
                            "PAUSED",
                            "FAILED"
                          ].includes(status) ? (
                          <>
                            <div className="progressStatusWrapper">
                              <div className={`progressStatus`}>
                                <div
                                  className={`statusDot`}
                                  style={{
                                    backgroundColor: `${progressStatusField["SUCCESS"]?.color}`
                                  }}
                                ></div>
                                <div>
                                  {statusField[status]
                                    ? progressStatusField["SUCCESS"]?.status(
                                        totalContacts,
                                        noOfContactsImported
                                      ) + "% Success"
                                    : "--"}
                                </div>
                              </div>
                              {/* <div className={`progressStatus`}>
                                <div
                                  className={`statusDot`}
                                  style={{
                                    backgroundColor: `${progressStatusField["FAILED"]?.color}`
                                  }}
                                ></div>
                                <div>
                                  {statusField[status]
                                    ? progressStatusField["FAILED"]?.status(
                                        totalContacts,
                                        noOfContactsImported
                                      ) + "% Failed"
                                    : "--"}
                                </div>
                              </div> */}
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {/* <ImportProgressbar /> */}
                      </div>
                      {/* <div className={`contactsPerDay titleRow`}>
                        {Utils.formatNumberToString(contactsPerDay) || "--"}
                      </div> */}
                      <div className={`status`}>
                        {status === "IN_PROGRESS" ? (
                          <div className="loader-wrapper">
                            {statusField[status]?.component}
                          </div>
                        ) : (
                          <div
                            className={`statusDot`}
                            style={{
                              backgroundColor: `${statusField[status]?.color}`
                            }}
                          ></div>
                        )}
                        <div>{statusField[status]?.status || "--"}</div>
                      </div>
                      {/* <div className="searchUrlIcon" onClick={}>
                        
                      </div> */}
                      <a
                        className={`searchUrl titleRow`}
                        href={searchUrl}
                        target="_blank"
                      >
                        <LinkedInsSvg />
                        {/* {searchUrl || "--"} */}
                      </a>
                      {/* <div className={`actions`}>
                        {status.toLowerCase() === "paused" ? (
                          <>
                            <span
                              onClick={() => handleResume(id)}
                              onMouseEnter={() => {
                                setActionTooltip({ key: id, value: "Resume" });
                              }}
                              onMouseLeave={() => {
                                setActionTooltip({});
                              }}
                            >
                              <PlayIcon />
                            </span>
                            {actionTooltip?.key === id &&
                              actionTooltip?.value === "Resume" && (
                                <CustomTooltip text={`Resume`} />
                              )}
                          </>
                        ) : status?.toLowerCase() === "inprogress" ||
                          status?.toLowerCase() === "active" ? (
                          <div className="pauseStop">
                            <div className="pauseButton">
                              <span
                                onClick={() => handlePause(id)}
                                onMouseEnter={() => {
                                  setActionTooltip({ key: id, value: "Pause" });
                                }}
                                onMouseLeave={() => {
                                  setActionTooltip({});
                                }}
                              >
                                <PauseIcon />
                              </span>
                              {actionTooltip?.key === id &&
                                actionTooltip?.value === "Pause" && (
                                  <CustomTooltip text={`Pause`} />
                                )}
                            </div>
                            <div className="stopButton">
                              <span
                                onClick={() => handleStop(id)}
                                onMouseEnter={() =>
                                  setActionTooltip({ key: id, value: "Stop" })
                                }
                                onMouseLeave={() => {
                                  setActionTooltip("");
                                }}
                              >
                                <StopIcon />
                              </span>
                              {actionTooltip?.key === id &&
                                actionTooltip?.value === "Stop" && (
                                  <CustomTooltip text={`Stop`} />
                                )}
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div> */}
                      <div className={`ownerName`}>
                        {(owner && (
                          <div
                            className={`contactOwner`}
                            onMouseEnter={() =>
                              setShowTooltip({ key: id, value: true })
                            }
                            onMouseLeave={() =>
                              setShowTooltip({ key: id, value: false })
                            }
                          >
                            <span className="circleOwner">
                              {Utils.getOwnerInitial(owner)}
                            </span>
                            {showTooltip?.key === id && showTooltip?.value && (
                              <CustomTooltip
                                text={`Added by ${Utils.getOwnerName(owner)}`}
                              />
                            )}
                          </div>
                        )) ||
                          "--"}
                      </div>
                    </div>
                  );
                })}
              </div>
            )) || (
              <div className="noDataFound">
                <i className="material-icons-outlined">list_alt</i>
                <span className="text">No data found</span>
              </div>
            )}
          </>
        ) : (
          <div className="adjustHt">
            {Array.from({ length: 8 }, (value, index) => (
              <div
                className="tableBodyLoader tableBodyLoaderLinkedIn"
                key={`tableBodyLoader${index + 1}`}
              >
                {headerList?.map((item, idx) => {
                  const { key = "" } = item || {};
                  return (
                    <div className={`${key}`} key={`${key}${index}${idx}`}>
                      <span className={`colLoader linear-background`} />
                    </div>
                  );
                })}
              </div>
            ))}{" "}
          </div>
        )}
      </div>
      <div className="inboxPaginationAndLimit">
        <PaginationComponent
          callback={paginate}
          loading={loader}
          totalPages={pages}
          currentPage={pageNo || 1}
        />
        <PageLimit
          limitPerPage={limit}
          text="Showing"
          cbk={handleActionByLimit}
          totalCount={totalJobs}
        />
      </div>
    </div>
  );
});

export default withRouter(LinkedInImports);
export { LinkedInImports };
