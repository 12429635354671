/* eslint-disable no-undef */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import InputTextBox from "Components/common/InputTextBox";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { withRouter } from "react-router-dom";
import CustomTooltip from "Components/common/CustomTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import Button from "Components/common/Button";

const UserBilling = (props = {}) => {
  const [billingData, setBillingData] = useState({});

  const constructBillingData = featureFlagValues => {
    if (Object.keys(featureFlagValues).length) {
      let {
        isAnnual,
        planName,
        subscriptionPlanId,
        noOfSeatsEligible,
        noOfSeatsUsed,
        endDate,
        amount,
        teamStatus,
        status,
        lifeTimeDeal,
        inFreePlan,
        taskEnabled,
        noOfInboxesEligible,
        noOfInboxesUsed
      } = featureFlagValues;

      const tempObj = {
        isAnnual,
        teamStatus,
        subscriptionPlanId,
        planName: getPlanName(
          planName,
          subscriptionPlanId,
          isAnnual,
          status,
          lifeTimeDeal,
          teamStatus
        ),
        noOfSeatsEligible,
        noOfSeatsUsed,
        endDate: endDate ? Utils.formatDate(endDate) : "--",
        amount: getAmountString(amount, isAnnual),
        lifeTimeDeal,
        inFreePlan,
        taskEnabled,
        additionalInboxAvailable: noOfInboxesEligible - noOfInboxesUsed,
        noOfInboxesEligible,
        noOfInboxesUsed
      };
      setBillingData(tempObj);
    }
  };

  const getPlanName = (
    planName,
    subscriptionPlanId,
    isAnnual,
    status,
    lifeTimeDeal,
    teamStatus
  ) => {
    if (planName && subscriptionPlanId) {
      if (isAnnual) {
        return status === "active" && lifeTimeDeal
          ? "Starter Plan Dealify LTD"
          : `Annual ${planName} Plan`;
      } else {
        return `Monthly ${planName} Plan`;
      }
    } else {
      return "Free Plan";
    }
  };

  const calculateAmount = (amount, isAnnual) => {
    if (isAnnual && amount) {
      return Math.abs(Math.ceil(amount / 12));
    } else if (amount) {
      return Math.abs(Math.ceil(amount));
    } else {
      return 0;
    }
  };

  const getAmountString = (amount, isAnnual) => {
    let amountValue = calculateAmount(amount, isAnnual);
    if (isAnnual) {
      amountValue += " (billed annually)";
    }
    return amountValue;
  };

  const calcualteSeatsRemaining = (noOfSeatsEligible, noOfSeatsUsed) => {
    return noOfSeatsEligible && noOfSeatsUsed
      ? noOfSeatsEligible - noOfSeatsUsed
      : 0;
  };

  const navigateToPricing = () => {
    if (!Utils.isAdmin()) {
      Utils.showMessagePopup();
    } else {
      props && props.history && props.history.push("/pricing");
    }
  };

  const upgradeButton = () => {
    return (
      <div className="buttonSection">
        <div
          className="upgradeBtn btn"
          onClick={e => {
            navigateToPricing(e);
          }}
        >
          <span className="upgradeText">Upgrade Now</span>
        </div>
      </div>
    );
  };

  const cancelSubscriptionButton = () => {
    return (
      <Button
        id="cancelSub"
        name="cancelSub"
        btnText="Cancel Subscription"
        icon={false}
        iconName=""
        type="button"
        className={"cancelSubBtn"}
        loader={false}
        click={e => {
          cancelSubscriptionPopup(e);
        }}
      />
    );
  };

  const checkAndAddUpgradeButton = () => {
    if (billingData?.planName?.toLowerCase() === "free plan") {
      return upgradeButton();
    }
  };

  const checkAndAddCancelSubscription = () => {
    if (billingData?.subscriptionPlanId) {
      return cancelSubscriptionButton();
    }
  };

  const getNextRenewalDate = () => {
    const { endDate } = billingData ? billingData : {};
    return endDate;
  };

  const cancelSubscriptionPopup = () => {
    confirmationPopupState.setPopupValues({
      content: `Write to us at <a href="mailto:support@salesgear.io"  target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to cancel your subscription`,
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  useEffect(() => {
    constructBillingData(userDetail.userFeatureFlag);
  }, [userDetail.userFeatureFlag]);

  return (
    billingData && (
      <div className={`billingSection`} id="billingSection">
        <div className="contentSection">
          <div className="dataContainer">
            <InputTextBox
              name="planName"
              id="planName"
              text="Current Plan"
              type="text"
              value={
                billingData?.inFreePlan ? "Free Plan" : billingData.planName
              }
              readOnly={true}
            />
          </div>
          <div className={"dataContainer"}>
            <>
              <InputTextBox
                name="amount"
                id="amount"
                text="Monthly Billed Amount"
                type="text"
                value={`${
                  billingData?.amount === "" || billingData?.lifeTimeDeal
                    ? "--"
                    : `$${billingData.amount}`
                }`}
                readOnly={true}
              />
            </>
          </div>
          <div className="dataContainer">
            <InputTextBox
              name="renewalDate"
              id="renewalDate"
              text="Next Renewal Date"
              type="text"
              value={getNextRenewalDate() || "--"}
              readOnly={true}
            />
          </div>
          <div className="dataContainer">
            <InputTextBox
              name="seatsRemaining"
              id="seatsRemaining"
              text="No of Seats Remaining"
              type="text"
              value={
                calcualteSeatsRemaining(
                  billingData.noOfSeatsEligible,
                  billingData.noOfSeatsUsed
                ) || "--"
              }
              readOnly={true}
            />
          </div>
          <div className="dataContainer">
            <InputTextBox
              name="seatsUsed"
              id="seatsUsed"
              text="No of Seats Used"
              type="text"
              value={billingData.noOfSeatsUsed}
              readOnly={true}
            />
          </div>
          <div className="dataContainer">
            <InputTextBox
              name="additionalInboxesAvailable"
              id="additionalInboxesAvailable"
              text="Additional Inboxes Remaining"
              type="text"
              value={billingData.additionalInboxAvailable || "0"}
              readOnly={true}
            />
          </div>
          <div className="dataContainer">
            <InputTextBox
              name="additionalInboxesUsed"
              id="additionalInboxesUsed"
              text="Additional Inboxes Used"
              type="text"
              value={billingData.noOfInboxesUsed || "0"}
              readOnly={true}
            />
          </div>
        </div>
        {checkAndAddUpgradeButton()}
        {checkAndAddCancelSubscription()}
      </div>
    )
  );
};

export default withRouter(UserBilling);
