import React, { useEffect, useState } from "react";
import { dataSearchState } from "Pages/Dashboard/dataSearch/state";
import userDetail from "Stores/userDetail";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PaywallUtils from "Utils/PaywallUtils";
import Utils from "Utils/utils";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import makeApi, { URLS } from "Utils/apiURL";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { ReactComponent as CheckMark } from "Assets/svg/discover/checkMark.svg";
import { ReactComponent as MobileIcon } from "Assets/svg/discover/mobile.svg";

const UpgradePlanPopup = () => {
  const history = useHistory();
  const [singlePurchaseContactName, setSinglePurchaseContactName] =
    useState("");
  const [isUnlimitedPlan, setIsUnlimitedPlan] = useState(false);
  const [selectedOption, setSelectedOption] = useState(
    isUnlimitedPlan ? 199 : 99
  );
  const [monthlyEmailCreditsRemaining, setMonthlyEmailCreditsRemaining] =
    useState("");
  const [monthlyPhoneCreditsRemaining, setMonthlyPhoneCreditsRemaining] =
    useState(0);
  const [emailCreditsMonthlyLimit, setEmailCreditsMonthlyLimit] = useState("");
  const [phoneCreditsMonthlyLimit, setPhoneCreditsMonthlyLimit] = useState(0);
  const [currentPlanName, setCurrentPlan] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [creditsData, setCreditsData] = useState({});
  const [foundMaskedNumber, setFoundMaskedNumber] = useState(false);

  const resetValues = () => {
    dataSearchState.setIsNoPhoneCredits(false);
    dataSearchState.setTotalEnrichContactsCount(0);
    setMonthlyEmailCreditsRemaining("");
    setEmailCreditsMonthlyLimit("");
    setIsUnlimitedPlan(false);
    setCurrentPlan("");
  };

  const closePopup = () => {
    dataSearchState.closePopup();
    resetValues();
  };

  const onClickContinue = () => {
    Utils.mixpanelTrack(MXP_EVENT.UPGRADE_POPUP_CONTINUE_CLICKED, {
      type: "Upgrade V2.0"
    });
    history.push("/pricing");
    dataSearchState.closePopup();
    resetValues();
  };

  const onClickChatWithUs = () => {
    Utils.mixpanelTrack(MXP_EVENT.UPGRADE_POPUP_TALKTO_CLICKED, {
      type: "Upgrade V2.0"
    });
    window.Intercom("show");
    dataSearchState.closePopup();
    resetValues();
  };

  const getCreditsUsage = async () => {
    if (await checkTeamSubscriptionActive()) {
      setIsLoading(true);
      try {
        const config = {
          url: URLS.getCreditsUsage
        };
        const res = await makeApi(config);
        if (res?.data && Object.keys(res?.data)?.length > 0) {
          setCreditsData(res?.data);
        }
        setIsLoading(false);
      } catch (err) {
        console.error(err, "Getting Credit Usage ERROR!");
      }
    }
    return;
  };

  useEffect(() => {
    setSinglePurchaseContactName(dataSearchState?.singlePurchaseContactName);
  }, [dataSearchState?.singlePurchaseContactName]);

  useEffect(() => {
    Utils.mixpanelTrack(MXP_EVENT.UPGRADE_POPUP_V2SHOWN, {
      type: "Upgrade V2.0"
    });
    getCreditsUsage();
  }, []);

  useEffect(() => {
    if (Object.keys(creditsData).length > 0) {
      const {
        phoneCreditsMonthlyLimit = 0,
        monthlyPhoneCreditsUsed = 0,
        monthlyEmailCreditsUsed = 0,
        emailCreditsMonthlyLimit = 0
      } = creditsData || {};

      setEmailCreditsMonthlyLimit(emailCreditsMonthlyLimit);
      setMonthlyEmailCreditsRemaining(
        emailCreditsMonthlyLimit - monthlyEmailCreditsUsed
      );
      setPhoneCreditsMonthlyLimit(phoneCreditsMonthlyLimit);
      const monthlyPhoneCreditsRemaining =
        phoneCreditsMonthlyLimit - monthlyPhoneCreditsUsed || 0;
      setMonthlyPhoneCreditsRemaining(monthlyPhoneCreditsRemaining);
    }
  }, [creditsData]);

  useEffect(() => {
    const { planName = "", inFreePlan = false } =
      userDetail?.userFeatureFlag || {};

    let planNameResult = PaywallUtils.getUpgradePopupPlanName(
      planName,
      inFreePlan
    );
    setCurrentPlan(planNameResult);
    if (["unlimited plan"].includes(planNameResult.toLowerCase())) {
      setIsUnlimitedPlan(true);
    }
  }, []);

  useEffect(() => {
    setSelectedOption(isUnlimitedPlan ? 199 : 99);
  }, [isUnlimitedPlan]);

  useEffect(() => {
    setFoundMaskedNumber(dataSearchState?.foundMaskedNumber);
  }, [dataSearchState?.foundMaskedNumber]);

  return (
    <div className="upgradePlanPopupWrapper">
      <div className="upgradePlanParentWrapper">
        <div className="upgradePlanSubParentWrapper">
          <div className="contactDataContent">
            <div className="closeButton" onClick={() => closePopup()}>
              <i className="material-icons closeIcon">close</i>
            </div>
            {foundMaskedNumber ? (
              <div className="headerDataParent">
                <div className="headerData">
                  <div className="fetchedData">{`${singlePurchaseContactName}’s Phone Number Found!`}</div>
                </div>
                <div className="fetchedMaskedData">
                  <div className="maskedContactDataContainer">
                    <div className="maskedContactFoundIcon">
                      <CheckMark />
                    </div>
                    <div className="maskedContactData">
                      <div className="mailDataContainer">
                        {`${singlePurchaseContactName}’s phone number is:`}{" "}
                      </div>
                      <div className="phoneDataContainer">
                        <div className="phoneIcon">
                          <MobileIcon />
                        </div>
                        <div className="phoneData">
                          {dataSearchState?.mobilePhone}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="headerDataParent">
                <div className="headerData">
                  <div className="fetchedData">{`🚀 Upgrade to Unlock Phone Numbers`}</div>
                </div>
                <div className="fetchProgress">
                  <div className="contactDataContainer">
                    <div className="contactDataHeader">
                      Oops! You don't have enough phone number credits to view{" "}
                      {singlePurchaseContactName ? (
                        <>
                          <span>{singlePurchaseContactName}</span>’s phone
                          number.
                        </>
                      ) : dataSearchState?.totalEnrichContactsCount > 0 ? (
                        ` ${dataSearchState.totalEnrichContactsCount} phone numbers.`
                      ) : (
                        "phone numbers."
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="toggleWrapper">
          <div className="toggleWrapperContent">
            {isLoading ? (
              <div className="remainingCreditsContentContainer isLoading">
                <>
                  <p
                    className="linear-background"
                    style={{
                      width: "100%",
                      height: "15px",
                      borderRadius: "5px",
                      marginBottom: "8px"
                    }}
                  ></p>
                  <p
                    className="linear-background"
                    style={{
                      width: "80%",
                      height: "15px",
                      borderRadius: "5px"
                    }}
                  ></p>
                </>
              </div>
            ) : (
              <div className="remainingCreditsContentContainer">
                <p className="remainingCreditsContent">
                  Your {currentPlanName} includes{" "}
                  <span>
                    {Utils.formatNumberToStringInUSFormat(
                      emailCreditsMonthlyLimit
                    )}{" "}
                    email credits
                  </span>{" "}
                  (
                  {monthlyEmailCreditsRemaining > 0
                    ? Utils.formatNumberToStringInUSFormat(
                        monthlyEmailCreditsRemaining
                      )
                    : 0}{" "}
                  remaining)
                  {isUnlimitedPlan ? (
                    <>
                      {" "}
                      and{" "}
                      <span>
                        {Utils.formatNumberToStringInUSFormat(
                          phoneCreditsMonthlyLimit
                        )}{" "}
                        phone numbers/mo
                      </span>{" "}
                      (
                      {monthlyPhoneCreditsRemaining > 0
                        ? Utils.formatNumberToStringInUSFormat(
                            monthlyPhoneCreditsRemaining
                          )
                        : 0}{" "}
                      remaining).
                    </>
                  ) : (
                    <>
                      {" "}
                      but does not include phone number credits—upgrade to
                      unlock them.
                    </>
                  )}
                </p>
              </div>
            )}
            <div className="toggleOptions">
              <div className="toggleHeading">
                Upgrade now to access more phone numbers:
              </div>
              {!isUnlimitedPlan && (
                <div
                  className={`toggleOption ${
                    selectedOption === 99 ? "selected" : ""
                  }`}
                  onClick={() => setSelectedOption(99)}
                >
                  <div className="radioButton">
                    {selectedOption === 99 && (
                      <span className="checkmark">✔</span>
                    )}
                  </div>
                  <div className="optionDetails">
                    <span className="price">$99</span>/month
                    <p className="option">
                      50 phone numbers/mo + 10,000 email credits/mo
                    </p>
                  </div>
                </div>
              )}
              <div
                className={`toggleOption ${
                  selectedOption === 199 ? "selected" : ""
                } ${isUnlimitedPlan ? "non-selectable" : ""}`}
                onClick={() => setSelectedOption(199)}
              >
                <div className="radioButton">
                  {selectedOption === 199 && (
                    <span className="checkmark">✔</span>
                  )}
                </div>
                <div className="optionDetails">
                  <span className="price">$199</span>/month
                  <p className="option">
                    250 phone numbers/mo + 25,000 email credits/mo
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="navigateButtonContainer">
            <div
              className="chatWithUsButtonWrapper"
              onClick={() => onClickChatWithUs()}
            >
              <div className="gotItButton">Chat with us</div>
            </div>
            {["free trial"].includes(currentPlanName) && (
              <div
                className="continueButtonWrapper"
                onClick={() => onClickContinue()}
              >
                <div className="gotItButton">Continue</div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="confirmation-popup-mask" />
    </div>
  );
};

export default UpgradePlanPopup;
