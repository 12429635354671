/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PricingTableContent from "./PricingTableContent";
import ConstantObj, { REQUEST_DEMO_CALENDAR_LINK } from "Static/constant";
import Utils from "Utils/utils";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrow.svg";
import Button from "Components/common/Button";

const PaymentSuccess = props => {
  useEffect(() => {
    setTimeout(() => {
      if (!Utils.isAdmin()) {
        Utils.showMessagePopup();
      } else {
        localStorage.removeItem("proceedToPay");
        props.history.push("/pricing");
      }
    }, 3000);
  }, []);

  return (
    <div className="pricingBody">
      <div className="pricingCard">
        <PricingTableContent />
        <div className="paymentSuccess">
          <div className="iconShadow">
            <i className="material-icons success">check_circle</i>
            <span className="shadow"></span>
          </div>
          <div className="thankYouMsgText">Payment Successful</div>
          <hr />
          <p className="infoText">You will be redirected to pricing page</p>
          <div className="spinnerLoader">
            <i className="fa fa-spinner fa-spin"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(PaymentSuccess);
