import React, { useContext, useState } from "react";
import PricingStore from "Stores/Pricing";
import { ReactComponent as ToggleMinus } from "Assets/Pricing/toggleMinus.svg";
import { ReactComponent as TogglePlus } from "Assets/Pricing/togglePlus.svg";
import { ReactComponent as MostPopular } from "Assets/Pricing/mostPopular.svg";
import { ReactComponent as Cross } from "Assets/Pricing/cross.svg";
import { ReactComponent as Check } from "Assets/Pricing/check.svg";
import ToggleContext from "./context/ToggleContext";
import CustomTooltip from "Components/common/CustomTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";

const FeatureComparisonTable = (props = {}) => {
  const { planData = [], subscriptionId = "" } = props || {};

  return (
    <section className="feature-comparison-section">
      <div className="container">
        <div className="table-container">
          <div
            className="mostPopularWrapper"
            id="comparePlans"
            style={{ position: "relative" }}
          >
            <div className="mostPopularBadgeText">
              To make Most Popular visible
            </div>
            <table className="comparison-table">
              <TableHeader
                planData={planData}
                subscriptionId={subscriptionId}
              />
              <TableBody planData={planData} />
            </table>
          </div>
        </div>
      </div>
    </section>
  );
};

const TableHeader = (props = {}) => {
  const { planData = [], subscriptionId = "" } = props || {};
  const { team, userLoggedIn } = userDetail.userInfo || {};
  const { planName, inFreePlan } = userDetail?.userFeatureFlag || {};
  let { isChecked, setIsChecked } = useContext(ToggleContext);

  const getPlanPrice = (planName = "") => {
    const plan = planData?.find(item => item?.name.toLowerCase() === planName);
    return isChecked ? plan?.annualPrice : plan?.price;
  };

  const TABLE_HEADER = [
    {
      name: "Free",
      color: "#3953FB",
      btnStyle: "primary",
      link: "signup",
      type: "redirect",
      id: "tryForFree",
      price: getPlanPrice("free"),
      priceText: "/Forever"
    },
    {
      name: "Basic",
      color: "#FA6B63",
      btnStyle: "outline",
      link: "signup",
      type: "redirect",
      id: "basicTryForFree",
      price: getPlanPrice("basic"),
      priceText: "/ User / Month"
    },
    {
      name: "Pro",
      color: "#01B018",
      btnStyle: "outline",
      link: "signup",
      type: "redirect",
      id: "proTryForFree",
      price: getPlanPrice("pro"),
      priceText: "/ User / Month",
      isPopular: true
    },
    {
      name: "Enterprise",
      color: "#AD007F",
      btnStyle: "outline",
      type: "chat",
      price: "Custom",
      priceText: ""
    }
  ];

  const [toogleType, setToogleType] = useState(
    PricingStore.paymentFormInfo.annual
  );

  const getTogglePlanType = value => {
    PricingStore.setPaymentFormInfo({ annual: value });
    setToogleType(value);
  };

  const upgradeBtn = (plan = {}) => {
    let planDisplayName = plan?.displayName?.toLowerCase();
    if (userLoggedIn) {
      if (!team) {
        confirmationPopupState.setPopupValues({
          title: "Pricing Purchase Request?",
          content: `Please contact your account administrator to upgrade your account`,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => {
            confirmationPopupState.setShowPopup(false);
          }
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        if (
          ["basic", "pro"].includes(planName?.toLowerCase()) &&
          ["unlimited", "pro", "basic"].includes(planDisplayName)
        ) {
          confirmationPopupState.setPopupValues({
            content: `Please reach out to <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer" >support@salesgear.io</a> to upgrade to ${plan?.displayName} plan`,
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });
          confirmationPopupState.setShowPopup(true);
        }
      }
    } else {
      if (planDisplayName !== "custom") {
        props.history.push("/signup");
      }
    }
  };

  const getActionButton = planObj => {
    let buttonValue = "";
    const { displayName } = planObj;

    let idValue =
      displayName?.toLowerCase() == "custom" ? "my_custom_link" : "";

    if (displayName?.toLowerCase() === "custom") {
      buttonValue = "Contact Sales";
    } else if (displayName?.toLowerCase() === "free") {
      buttonValue = "";
    } else {
      buttonValue = "Upgrade";
    }

    return (
      <div
        id={idValue}
        className={`${buttonValue ? "" : "noBorder"} upgradeBtn`}
        onClick={() => upgradeBtn(planObj)}
      >
        {buttonValue}
      </div>
    );
  };

  const showCurrentPlanDom = plan => {
    const { displayName = "" } = plan;

    if (subscriptionId !== null) {
      if (displayName?.toLowerCase() === planName?.toLowerCase()) {
        return true;
      }
    } else {
      return inFreePlan && displayName?.toLowerCase() === "free";
    }
    return false;
  };

  return (
    <thead>
      <tr>
        <th></th>
        {TABLE_HEADER.map(
          ({ name, color, price, priceText, isPopular }, index) => (
            <th
              key={index}
              style={
                index === 2
                  ? {
                      background: `#EBECF0`,
                      borderTop: `2px solid #3953FB`,
                      borderRight: `2px solid #3953FB`,
                      borderLeft: `2px solid #3953FB`,
                      position: `relative`,
                      overflow: `visible`
                    }
                  : {}
              }
            >
              {index === 2 && (
                <div className="mostPopularBadge">
                  <MostPopular />
                </div>
              )}
              <div
                className={`priceDataHeader ${
                  isPopular ? "isPopularPlanCard" : ""
                }`}
              >
                <h6 style={{ color }}>{name}</h6>
                <div className="priceData">
                  <span>
                    <sup className="superscript">$</sup>
                    {price}
                  </span>
                  <sub className="subscript">{priceText}</sub>
                </div>
                {showCurrentPlanDom(planData[index]) ? (
                  <div
                    className="currentPlanBtn"
                    onClick={() => props.history.push("/settings/billing")}
                  >
                    Current Plan
                  </div>
                ) : (
                  getActionButton(planData[index])
                )}
              </div>
            </th>
          )
        )}
      </tr>
    </thead>
  );
};

const TableBody = (props = {}) => {
  const { planData = [] } = props || {};
  const [collapsedSections, setCollapsedSections] = useState({});

  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const getTooltipContent = (planIdx, item) => {
    const tooltipData = [
      {
        snImportLimit:
          "Import 1 list from LinkedIn Sales Navigator, available for lifetime.",
        snContactsImport:
          "Import up to 500 contacts from LinkedIn Sales Navigator.",
        phnCredits:
          "Find up to 2 prospect phone numbers for free, available for lifetime.",
        freeUsphn:
          "Get a free US phone number to use as your ‘From’ number for outbound calls.",
        emailCredits:
          "Find up to 100 prospect email addresses per month, with a daily limit of 10.",
        noInboxes: "Connect up to 5 email accounts to send outreach emails.",
        emailSends:
          "Send up to 1,000 emails per month, with a limit of 20 per inbox per day.",
        callingMinutes:
          "Lifetime access to 60 free calling minutes for US & Canada."
      },
      {
        snImportLimit:
          "Import up to 2 lists from LinkedIn Sales Navigator per month.",
        snContactsImport:
          "Import up to 1,000 contacts from LinkedIn Sales Navigator per month.",
        phnCredits: "Find up to 10 prospect phone numbers per month.",
        freeUsphn:
          "Get a free US phone number to use as your ‘From’ number for outbound calls.",
        emailCredits:
          "Find up to 500 prospect email addresses per month, with a daily limit of 50.",
        noInboxes:
          "No limits—connect as many email accounts as needed for sending emails.",
        emailSends:
          "Send up to 25,000 emails per month, with no daily limit per inbox.",
        callingMinutes:
          "Get 100 free calling minutes per month for US & Canada."
      },
      {
        snImportLimit:
          "Import up to 10 lists from LinkedIn Sales Navigator per month.",
        snContactsImport:
          "Import up to 25,000 contacts from LinkedIn Sales Navigator per month.",
        phnCredits: "Find up to 50 prospect phone numbers per month.",
        freeUsphn:
          "Get a free US phone number to use as your ‘From’ number for outbound calls.",
        emailCredits:
          "Find up to 10,000 prospect email addresses per month, with no daily limit.",
        noInboxes:
          "No limits—connect as many email accounts as needed for sending emails.",
        emailSends:
          "Send up to 100,000 emails per month, ideal for high-volume outreach, with no per-inbox limit.",
        callingMinutes:
          "Get 500 free calling minutes per month for US & Canada."
      },
      {
        snImportLimit:
          "Import unlimited lists from LinkedIn Sales Navigator every month.",
        snContactsImport:
          "Import up to 100,000 contacts from LinkedIn Sales Navigator per month.",
        phnCredits:
          "Find up to 250 prospect phone numbers per month, or customize as needed.",
        freeUsphn:
          "Get a free US phone number to use as your ‘From’ number for outbound calls.",
        emailCredits: "Flexible email credits to match your prospecting needs.",
        noInboxes:
          "No limits—connect as many email accounts as needed for sending emails.",
        emailSends: "Flexible email sending limits tailored to your needs.",
        callingMinutes:
          "Flexible calling minutes tailored to your business needs."
      }
    ];

    return tooltipData[planIdx]?.[item] || "No information available.";
  };

  const handleTooltipVisibility = (planName, item, isVisible) => {
    if (isVisible) {
      setTooltipVisible(planName);
      setHoveredItem(item);
    } else {
      setTooltipVisible(null);
      setHoveredItem(null);
    }
  };

  const FEATURE_COMPARISON_TABLE = [
    {
      name: "Email Sequencing",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "Email warming",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "Dialer",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "LinkedIn Outreach",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "Sales navigator import",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "Discover Database",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },
    {
      name: "CRM Integrations",
      type: "feature",
      list: ["check", "check", "check", "check"]
    },

    {
      name: "Data & Usage Limits",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "Email Credits",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, indx) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return Utils.pricingNumberWithCommas(item?.emailCreditsEligible);
      }),
      tooltipFeatureText: "emailCredits"
    },
    {
      name: "Daily Email Credit Limit",
      type: "feature",
      list: planData?.map((item, indx) => {
        return item?.emailCreditsDailyLimit === 0
          ? "No Limit"
          : item.emailCreditsDailyLimit;
      })
    },
    {
      name: "Phone Number Credits",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        return Utils.pricingNumberWithCommas(item?.phoneCreditsEligible);
      }),
      tooltipFeatureText: "phnCredits"
    },
    {
      name: "Bulk Purchase",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.bulkPurchaseEnabled ? "check" : "close";
      })
    },
    {
      name: "Email Outreach",
      type: "title",
      paddingTop: "50px"
    },
    {
      name: "Number of Inboxes",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        return item?.noOfInboxesEligible > 5
          ? "Unlimited"
          : Utils.pricingNumberWithCommas(item.noOfInboxesEligible);
      }),
      tooltipFeatureText: "noInboxes"
    },
    {
      name: "Number of Email Warming Accounts",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.warmingAccountsEligible > 5
          ? "Unlimited"
          : Utils.pricingNumberWithCommas(item.warmingAccountsEligible);
      })
    },
    {
      name: "A/B Testing",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.abTestingEnabled ? "check" : "close";
      })
    },
    {
      name: "Email Sends Per Month",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return Utils.pricingNumberWithCommas(item?.emailSendLimit);
      }),
      tooltipFeatureText: "emailSends"
    },
    {
      name: "Email Sends Per Day",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.dailySendLimit > 20
          ? "No Limit"
          : Utils.pricingNumberWithCommas(item.dailySendLimit);
      })
    },
    {
      name: "Multiple Inboxes",
      type: "feature",
      list: planData?.map((item, idx) => {
        return item?.multipleInboxEnabled ? "check" : "close";
      })
    },
    {
      name: "Inbox Rotation",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },
    {
      name: "Secondary Domain Email Setup Assistance",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },

    {
      name: "Calling",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "Free US Phone Number",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        return Utils.pricingNumberWithCommas(item?.freePhoneNumbersEligible);
      }),
      tooltipFeatureText: "freeUsphn"
    },
    {
      name: "Add Additional US Phone Numbers",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },
    {
      name: "Calling Minutes (US/Canada)",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return Utils.pricingNumberWithCommas(
          item?.usFreeCallingMinutesEligible
        );
      }),
      tooltipFeatureText: "callingMinutes"
    },
    {
      name: "Rest of the World Calling",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },
    {
      name: "Add More Calling Credits",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },
    {
      name: "Call Recording",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.recordingEnabled ? "check" : "close";
      })
    },
    {
      name: "Custom Call Outcomes",
      type: "feature",
      list: ["close", "close", "check", "check"]
    },

    {
      name: "LinkedIn Outreach",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "Connection Requests",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.liMaxInvitePerMonth > 1000
          ? "Custom"
          : Utils.pricingNumberWithCommas(item.liMaxInvitePerMonth);
      })
    },

    {
      name: "Daily Connection Request Limit",
      type: "feature",
      list: planData?.map((item, ind) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return Utils.pricingNumberWithCommas(item?.liMaxInvitePerDay);
      })
    },
    {
      name: "Auto-Withdraw Connection Requests",
      type: "feature",
      list: ["close", "check", "check", "check"]
    },
    {
      name: "LinkedIn Messages Sent",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.liMaxMessagesPerMonth > 1000
          ? "Custom"
          : Utils.pricingNumberWithCommas(item.liMaxInvitePerMonth);
      })
    },
    {
      name: "Daily Messages Sent Limit",
      type: "feature",
      list: planData?.map((item, ind) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return Utils.pricingNumberWithCommas(item?.liMaxMessagesPerDay);
      })
    },
    {
      name: "Sales Navigator Import Limit",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        if (item?.snImportEligible >= 1000) {
          return "Custom";
        }
        return (
          item?.snImportEnabled &&
          Utils.pricingNumberWithCommas(item?.snImportEligible)
        );
      }),
      tooltipFeatureText: "snImportLimit"
    },
    {
      name: "Sales Navigator Contacts Import",
      type: "feature",
      isToolTip: true,
      list: planData?.map((item, ind) => {
        if (item.name.toLowerCase() === "custom") {
          return "Custom";
        }
        return (
          item?.snImportEnabled &&
          Utils.pricingNumberWithCommas(item?.snContactsEligible)
        );
      }),
      tooltipFeatureText: "snContactsImport"
    },
    {
      name: "Integrations",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "CRM Integrations",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.crmSyncEnabled ? "check" : "close";
      })
    },
    {
      name: "Slack Integration",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.slackIntegrationEnabled ? "check" : "close";
      })
    },
    {
      name: "Zapier Integration",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.zapierIntegrationEnabled ? "check" : "close";
      })
    },
    {
      name: "Custom Stages",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.contactStagesEnabled ? "check" : "close";
      })
    },
    {
      name: "Manual Tasks",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.taskEnabled ? "check" : "close";
      })
    },
    {
      name: "Triggers",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.triggersEnabled ? "check" : "close";
      })
    },
    {
      name: "Other Features",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "Branded URL",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.brandedUrlEnabled ? "check" : "close";
      })
    },
    {
      name: "Auto BCC",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.autoBccEnabled ? "check" : "close";
      })
    },
    {
      name: "Unsubscribe Link",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.unsubscribeEnabled ? "check" : "close";
      })
    },
    {
      name: "Email Validation",
      type: "feature",
      list: ["close", "Usage-based", "Usage-based", "Usage-based"]
    },

    {
      name: "Advanced Features",
      type: "title",
      paddingTop: "10px"
    },
    {
      name: "Custom Fields",
      type: "feature",
      list: planData?.map((item, ind) => {
        return (
          item?.customFieldsEnabled &&
          Utils.pricingNumberWithCommas(item?.customFieldsCount)
        );
      })
    },
    {
      name: "AI Writing Assistant",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.writingAssistantEnabled ? "check" : "close";
      })
    },
    {
      name: "Do Not Contact Domains",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.donotContactEnabled ? "check" : "close";
      })
    },
    {
      name: "Triggers",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.triggersEnabled ? "check" : "close";
      })
    },
    {
      name: "Custom Contact Stages",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.contactStagesEnabled ? "check" : "close";
      })
    },
    {
      name: "LinkedIn Chrome Extension",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.linkedInExtEnabled ? "check" : "close";
      })
    },
    {
      name: "Gmail Chrome Extension",
      type: "feature",
      list: planData?.map((item, ind) => {
        return item?.chromeExtEnabled ? "check" : "close";
      })
    }
  ];

  const toggleSection = index => {
    setCollapsedSections(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  let currentSectionIndex = -1;

  return (
    <tbody>
      {FEATURE_COMPARISON_TABLE.map(
        (
          {
            name = "",
            type = "",
            list = [],
            isToolTip = false,
            tooltipFeatureText = ""
          },
          index
        ) => {
          if (type === "title") {
            currentSectionIndex = index;
            return (
              <tr
                key={index}
                className="table-title"
                onClick={() => toggleSection(index)}
                style={{ cursor: "pointer" }}
              >
                <th colSpan="100%" className="collapsibleHeader">
                  <div className="headerContent">
                    {collapsedSections[index] ? (
                      <TogglePlus className="toggleIcon" />
                    ) : (
                      <ToggleMinus className="toggleIcon" />
                    )}
                    <span>{name}</span>
                  </div>
                </th>
              </tr>
            );
          }

          return (
            !collapsedSections[currentSectionIndex] && (
              <tr key={index} className="table-row">
                <th>{name}</th>
                {list.map((item, idx) => (
                  <td
                    key={idx}
                    style={{
                      ...(idx === 2
                        ? {
                            background: `#EBECF0`,
                            borderRight: `2px solid #3953FB`,
                            borderLeft: `2px solid #3953FB`
                          }
                        : {}),
                      ...(idx === 2 &&
                      index === FEATURE_COMPARISON_TABLE.length - 1
                        ? { borderBottom: `2px solid #3953FB` }
                        : {})
                    }}
                    className={
                      item === "check"
                        ? "check"
                        : item === "close"
                        ? "close"
                        : "text"
                    }
                  >
                    {isToolTip ? (
                      <div
                        className="tooltipTarget"
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline dotted"
                        }}
                        onMouseEnter={() =>
                          handleTooltipVisibility(`Plan ${idx + 1}`, name, true)
                        }
                        onMouseLeave={() =>
                          handleTooltipVisibility(
                            `Plan ${idx + 1}`,
                            name,
                            false
                          )
                        }
                      >
                        {item}
                        {tooltipVisible === `Plan ${idx + 1}` &&
                          hoveredItem === name && (
                            <CustomTooltip
                              text={getTooltipContent(
                                `${idx}`,
                                tooltipFeatureText
                              )}
                            />
                          )}
                      </div>
                    ) : item === "check" ? (
                      <Check />
                    ) : item === "close" ? (
                      <Cross />
                    ) : (
                      item
                    )}
                  </td>
                ))}
              </tr>
            )
          );
        }
      )}
    </tbody>
  );
};

export default FeatureComparisonTable;
