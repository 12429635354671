import React from "react";
import ReactDOMServer from "react-dom";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { userDetail } from "Stores/userDetail";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { toasterState } from "Components/common/toaster";
import { dataSearchState } from "./state";
import { popupLoaderState } from "Pages/Dashboard/dataSearch/PopupLoader";
import { Utils } from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import { exportEmailNotifyState } from "Components/popup/ExportEmailNotify";
import { exportProcessingState } from "Components/popup/ExportProcessing";
import { CLIENT_URL } from "Static/constant";
import { SequenceUtils } from "Utils/SequenceUtils";
import { ContactCardFunction } from "Components/ContactCard/ContactCardFunction";
import { priceRangeSliderPopupState } from "Components/popup/PriceRangeSliderPopup/main";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { settingsStoreData } from "Stores/settingsData";
import LimitErr from "Components/PreviewPopup/LimitErr";

export const getConfidenceColor = (confidenceScore = "") => {
  return +confidenceScore >= 95
    ? "#3953fb"
    : +confidenceScore >= 85
    ? "#A0ACFB"
    : "#d3d3d3";
};

export const getConfidenceScoreText = (confidenceScore = "") => {
  return +confidenceScore >= 95
    ? "High"
    : +confidenceScore >= 85
    ? "Medium"
    : "Low";
};

export const getSelectedContactsPayload = (ids = []) => {
  let payload = {};
  const { type = "", limit = 0 } = dataSearchState?.rangeSelected || {};
  if (type === "all" || !type) {
    payload.contactIds = ids || [];
  } else if (["bulk", "custom"].includes(type)) {
    payload.bulkPurchase = true;
    payload.searchCriteria = {
      ...dataSearchState.filterComponentData,
      limit
    };
  }
  return payload;
};

export const getContactPurchasePayload = (ids = [], listId = "", type = "") => {
  let payload = getSelectedContactsPayload(ids);
  payload.listId = listId;
  if ("phone" === type) {
    payload.phonePurchase = true;
  } else {
    payload.emailPurchase = true;
  }
  return payload;
};

export const getAddContactsToSequencePayload = (ids = [], sequenceId = "") => {
  let payload = getSelectedContactsPayload(ids);
  return { ...payload, sequenceId, emailPurchase: true };
};

export const isPurchaseEnabled = (purchaseType = "") => {
  if (["mobilePhone", "phone"]?.includes(purchaseType)) {
    return userDetail?.userFeatureFlag?.phoneEnabled;
  } else {
    return userDetail?.userFeatureFlag?.searchEnabled;
  }
};

export const showPaginationLimitReachPopup = history => {
  confirmationPopupState.setPopupValues({
    content: (
      <LimitErr
        headingContent={`Oops! You’ve reached the last page available on your current plan. Upgrade to discover more prospects.`}
        limitMessage={`Discover section pagination limit reached.`}
      />
    ),
    dynamicDom: true,
    actionBtnText: "Upgrade",
    cancelBtnText: "Cancel",
    cancelCbk: () => {
      confirmationPopupState.setShowPopup(false);
    },
    callback: () => {
      confirmationPopupState.setShowPopup(false);
      history && history.push("/pricing");
    }
  });
  confirmationPopupState.setShowPopup(true);
};

export const showPhonePurchasePopup = () => {
  dataSearchState.setIsNoPhoneCredits(true);
};

export const handleResponseForBulkAction = (
  isListType = false,
  isAddSequence = false
) => {
  let contactLink = `<a href='${CLIENT_URL}/contacts' target="_self" rel="noopener noreferrer">contact section</a>`;
  let addSequenceContent = `Adding contacts to your sequence. This may take few to several minutes based on the total number contacts selected. Once completed, You can access all your contacts from the ${contactLink}`;
  let viewContent = `Contact purchase has been initiated. This may take few to several minutes based on total number contacts selected. Once completed, You can access all your contacts from the ${contactLink}`;
  let listContent = `Adding contacts to your list. This may take few to several minutes based on total number contacts selected. Once completed, You can access all your contacts from the ${contactLink}`;
  confirmationPopupState.setPopupValues({
    content: isAddSequence
      ? addSequenceContent
      : isListType
      ? listContent
      : viewContent,
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => confirmationPopupState.setShowPopup(false)
  });
  confirmationPopupState.setShowPopup(true);
};

export const updateSelectedDataInStore = data => {
  const tempArr = [];
  data?.contacts?.length > 0 &&
    data.contacts.map(value => {
      if (value?.isSelected) {
        tempArr.push(value.id);
      }
    });
  dataSearchState.setSelectedContactData(tempArr);
};

export const handlePurchaseResponse = (
  respData = {},
  listName = "",
  history = [],
  actionType = "",
  currentSequence = {}
) => {
  let {
    creditsResponse = {},
    addContactToListResponse = {},
    statusCode = "",
    totalContactCount = 0,
    contacts = [],
    exportResponse = {},
    background = false
  } = respData || {};
  let isCsvExport = actionType?.toLowerCase() === "export";
  let isAddSequence = actionType?.toLowerCase() === "sequence";
  let updatedLinkedInUrl = "";
  let updatedContactInfo = {};
  const {
    emailCreditsReduced = 0,
    phoneCreditsReduced = 0,
    monthlyEmailCreditsRemaining = 0
  } = creditsResponse || {};
  const { successCount = 0 } = addContactToListResponse || {};
  const { type = "" } = exportResponse || {};
  statusCode = statusCode.toString();
  const temp = dataSearchState?.contactTableData?.contacts;

  let updatedContactData = temp.map(item => {
    contacts?.length > 0 &&
      contacts.map(contactItem => {
        if (item?.id === contactItem?.id) {
          updatedLinkedInUrl = contactItem?.linkedIn || "";
          updatedContactInfo = contactItem;
          setTimeout(() => {
            dataSearchState.setSinglePurchaseContactMail(contactItem?.email);
          }, 1000);
          if (
            contactItem?.mobilePhone !== null &&
            Array.isArray(contactItem?.mobilePhone) &&
            contactItem?.mobilePhone.length > 0 &&
            +statusCode === 201
          ) {
            setTimeout(() => {
              dataSearchState.setMobilePhone(
                contactItem?.mobilePhone[0].number
              );
              dataSearchState.setIsNoPhoneCredits(true);
              dataSearchState.setFoundMaskedNumber(true);
            }, 1000);
          }
          if (
            contactItem?.mobilePhone !== null &&
            Array.isArray(contactItem?.mobilePhone) &&
            contactItem?.mobilePhone.length > 0 &&
            +statusCode !== 201
          ) {
            setTimeout(() => {
              dataSearchState.setMobilePhone(
                contactItem?.mobilePhone[0].number
              );
              dataSearchState.setIsMobileNumberNotFound(true);
            }, 1000);
          }
          if (
            Array.isArray(contactItem?.mobilePhone) &&
            contactItem?.mobilePhone.length === 0
          ) {
            setTimeout(() => {
              dataSearchState.setSinglePurchaseContactMail("");
              dataSearchState.setIsMobileNumberNotFound(false);
            }, 1000);
          }
          if (
            dataSearchState?.isPhonePurchase &&
            contactItem?.mobilePhone === null
          ) {
            setTimeout(() => {
              dataSearchState.setSinglePurchaseContactMail("");
              dataSearchState.setIsMobileNumberNotFound(false);
            }, 1000);
          }
          const mixpanelConstant = {
            pageType: PAGE_TYPE.discover,
            email: contactItem?.email,
            linkedInUrl: updatedLinkedInUrl,
            contactName: `${contactItem?.firstName} ${contactItem?.lastName}`,
            companyName: contactItem?.companyName
          };
          if (+statusCode === 404) {
            Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_NOT_FOUND, {
              ...mixpanelConstant
            });
          }
          if (+statusCode === 201) {
            Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_MASKED, {
              ...mixpanelConstant
            });
          }
          item = {
            ...item,
            ...contactItem,
            hasShow: +statusCode === 201,
            phoneNumberUnavailable: +statusCode === 404
          };
        }
      });
    return item;
  });

  dataSearchState.setContactTableData({
    ...dataSearchState.contactTableData,
    contacts: [...updatedContactData]
  });
  dataSearchState.setRangeSelected("");
  if (isCsvExport || isAddSequence) {
    if (isCsvExport) {
      exportProcessingState.completeAnimation();
      exportProcessingState.setShowPopup(false);
    } else if (isAddSequence) {
      ContactCardFunction.resetUploadingFunc();
    }
    dataSearchState.setSelectedContactData([]);
    if (dataSearchState?.isFromSeoPage) {
      if (dataSearchState?.contactTableData?.contacts?.length > 0) {
        dataSearchState.contactTableData.contacts[0].isSelected = true;
      }
      dataSearchState.setContactTableData({
        ...dataSearchState.contactTableData,
        contacts: dataSearchState?.contactTableData?.contacts
      });
      updateSelectedDataInStore(dataSearchState?.contactTableData);
    }
  }

  if (isShowUpgradePopup(statusCode)) {
    popupLoaderState.setShowPopup(false);
    showUpgradePopup(statusCode);
  } else {
    if (background) {
      popupLoaderState.setShowPopup(false);
      if (!actionType || isAddSequence) {
        handleResponseForBulkAction(listName, isAddSequence);
      } else if (isCsvExport) {
        exportEmailNotifyState.setShowPopup(true);
      }
      return;
    }
  }
  isCsvExport &&
    Utils.hasObject(exportResponse) &&
    handleExportCsvResponse(exportResponse);
  (type !== "limit_reached" || !type) &&
    showSlidePopup(
      emailCreditsReduced,
      phoneCreditsReduced,
      successCount,
      listName,
      statusCode,
      totalContactCount,
      isAddSequence,
      currentSequence,
      monthlyEmailCreditsRemaining,
      history,
      updatedContactInfo
    );
};

const showSlidePopup = async (
  emailCreditsReduced,
  phoneCreditsReduced,
  successCount,
  listName,
  statusCode,
  totalContactCount,
  isAddSequence,
  currentSequence,
  monthlyEmailCreditsRemaining,
  history,
  contactInfo
) => {
  let content = await getSlidePopupMessageContent(
    emailCreditsReduced,
    phoneCreditsReduced,
    successCount,
    listName,
    statusCode,
    totalContactCount,
    isAddSequence,
    currentSequence,
    monthlyEmailCreditsRemaining,
    history,
    contactInfo
  );
  popupLoaderState.setShowPopup(false);
  if (content) {
    confirmationPopupState.setPopupValues({
      content,
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  }
};

const handleSequenceResponse = (
  emailCreditsReduced = 0,
  phoneCreditsReduced = 0,
  successCount = 0,
  currentSequence = {}
) => {
  const {
    folderId = "",
    id: sequenceId = "",
    shareType = "",
    name: sequenceName = ""
  } = currentSequence || {};
  let sequenceLink = sequenceId
    ? `/sequences/${
        shareType === "SHARED" ? "shared" : folderId || "all"
      }/${sequenceId}`
    : null;
  if (successCount > 0) {
    return `${successCount} contact${
      successCount > 1 ? "s" : ""
    } purchased and add to the sequence <a href="${sequenceLink}" target="_self" rel="noopener noreferrer">${sequenceName}</a> successfully. ${
      emailCreditsReduced > 0 ? emailCreditsReduced : "No"
    } email credits have been deducted.`;
  }
  return `Failed to add contact to <a href="${sequenceLink}" target="_self" rel="noopener noreferrer">${sequenceName}</a>. Please contact <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to get more details.`;
};

const getSlidePopupMessageContent = (
  emailCreditsReduced,
  phoneCreditsReduced,
  successCount,
  listName,
  statusCode,
  totalContactCount,
  isAddSequence,
  currentSequence,
  monthlyEmailCreditsRemaining,
  history,
  contactInfo
) => {
  let messageContent;
  return new Promise(resolve => {
    setTimeout(() => {
      if (statusCode === "200") {
        if (isAddSequence) {
          messageContent = handleSequenceResponse(
            emailCreditsReduced,
            phoneCreditsReduced,
            successCount,
            currentSequence
          );
        } else {
          messageContent = getSlidePopupSuccessMessage(
            emailCreditsReduced,
            phoneCreditsReduced,
            successCount,
            listName,
            totalContactCount,
            monthlyEmailCreditsRemaining,
            history,
            contactInfo
          );
        }
      } else if (statusCode === "404") {
        messageContent = getSlidePopupSuccessMessage(
          emailCreditsReduced,
          phoneCreditsReduced,
          successCount,
          listName,
          totalContactCount,
          monthlyEmailCreditsRemaining,
          history,
          contactInfo
        );
      } else {
        messageContent = getSlidePopupErrorMessage(statusCode, listName);
      }
      resolve(messageContent);
    }, 1000);
  });
};

const showToasterMsgWithCustomTimeout = (
  msg = "",
  timeout = 2000,
  statusType = "success",
  closeIcon = false,
  custom = false
) => {
  if (msg) {
    toasterState.setToasterTimeout(timeout);
    toasterState.setToastMsg(msg, statusType);
    toasterState.setPopupValues({
      ...toasterState.popupValues,
      showCloseIcon: closeIcon,
      custom
    });
  }
  return false;
};

const revealContactsDom = (props = {}) => {
  return `
    <span class="showRevealedContacts">
      Show Revealed Contacts
    </span>
  `;
};

const getSlidePopupSuccessMessage = (
  emailCreditsReduced,
  phoneCreditsReduced,
  successCount,
  listName,
  totalContactCount,
  monthlyEmailCreditsRemaining,
  history,
  contactInfo
) => {
  if (
    successCount > 1 &&
    emailCreditsReduced > 0 &&
    phoneCreditsReduced > 0 &&
    !listName
  ) {
    return `${totalContactCount} Contacts purchased successfully. ${emailCreditsReduced} email ${
      emailCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted. ${phoneCreditsReduced} phone number ${
      phoneCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted.`;
  } else if (
    successCount > 1 &&
    emailCreditsReduced > 0 &&
    phoneCreditsReduced > 0 &&
    listName
  ) {
    return `${totalContactCount} Contacts added to ${listName} list successfully. ${emailCreditsReduced} email  ${
      emailCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted. ${phoneCreditsReduced} phone number ${
      phoneCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted.`;
  } else if (successCount === 1 && emailCreditsReduced === 1 && listName) {
    showToasterMsgWithCustomTimeout(
      `Contact added to ${listName} list successfully. 1 email credit has been deducted.`
    );
  } else if (successCount === 1 && emailCreditsReduced === 1 && !listName) {
    const htmlString = `${revealContactsDom(history)}`;

    showToasterMsgWithCustomTimeout(
      `1 credit used. ${Utils.formatNumberToString(
        monthlyEmailCreditsRemaining
      )} more to go. ${htmlString}`,
      10000,
      "success",
      true,
      true
    );
    settingsStoreData.setMonthlyEmailCreditsRemaining(
      monthlyEmailCreditsRemaining
    );
  } else if (successCount === 1 && phoneCreditsReduced === 1 && listName) {
    showToasterMsgWithCustomTimeout(
      `Contact added to ${listName} list successfully. 1 phone number credit has been deducted.`
    );
  } else if (successCount === 1 && phoneCreditsReduced === 1 && !listName) {
    Utils.mixpanelTrack(MXP_EVENT.PHONE_NUMBER_FOUND, {
      pageType: PAGE_TYPE.discover,
      linkedInUrl: contactInfo?.linkedIn,
      contactName: `${contactInfo?.firstName} ${contactInfo?.lastName}`,
      companyName: contactInfo?.companyName
    });
    showToasterMsgWithCustomTimeout(`1 phone number credit has been deducted.`);
  } else if (successCount === 1 && emailCreditsReduced === 0 && listName) {
    showToasterMsgWithCustomTimeout(
      `Contact added to ${listName} list successfully.`
    );
  } else if (
    successCount === 1 &&
    (phoneCreditsReduced === 0 || emailCreditsReduced === 0) &&
    !listName
  ) {
    showToasterMsgWithCustomTimeout(
      `No credits has been deducted for this contact.`
    );
  } else if (successCount > 1 && emailCreditsReduced > 0 && listName) {
    return `${totalContactCount} Contacts added to ${listName} list successfully. ${emailCreditsReduced} email ${
      emailCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted.`;
  } else if (
    successCount > 1 &&
    emailCreditsReduced === 0 &&
    phoneCreditsReduced === 0 &&
    listName
  ) {
    return `${totalContactCount} Contacts added to ${listName} list successfully.`;
  } else if (successCount > 1 && emailCreditsReduced > 0 && !listName) {
    return `${totalContactCount} Contacts purchased successfully. ${emailCreditsReduced} email ${
      emailCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted.`;
  } else if (
    successCount > 1 &&
    emailCreditsReduced === 0 &&
    phoneCreditsReduced === 0 &&
    !listName
  ) {
    return `${totalContactCount} Contacts purchased successfully.`;
  } else if (successCount === 1 && phoneCreditsReduced === 0 && listName) {
    showToasterMsgWithCustomTimeout(
      `Contact added to ${listName} list successfully.`
    );
  } else if (successCount === 1 && phoneCreditsReduced === 0 && !listName) {
    showToasterMsgWithCustomTimeout(
      `No credits has been deducted for this contact.`
    );
  } else if (successCount > 1 && phoneCreditsReduced > 0) {
    return `${totalContactCount} Contacts added to ${listName} list successfully. ${phoneCreditsReduced} phone number ${
      phoneCreditsReduced === 1 ? "credit has" : "credits have"
    } been deducted.`;
  }
};

const getSlidePopupErrorMessage = (statusCode, listName) => {
  if (statusCode === "500") {
    return `Failed to ${
      listName ? `add contacts to ${listName} list` : "purchase contacts"
    }. Please try again later`;
  } else if (statusCode === "402") {
    return `Insufficient credits. Please contact <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer">support@salesgear.io</a> to get more credits.`;
  }
};

const PaidUserStatusCodePopup = {
  101: "data_EmailDailyLimitReached",
  102: "data_EmailMonthlyLimitReached",
  103: "data_PhoneDailyLimitReached",
  104: "data_PhoneMonthlyLimitReached",
  105: "data_PhoneAnnualLimitReached",
  409: "data_BulkPurchaseNotEnabled"
};

const FreeUserStatusCodePopup = {
  101: "data_F_EmailDailyLimitReached",
  102: "data_F_EmailMonthlyLimitReached",
  103: "data_F_PhoneNumberLimitReached",
  104: "data_F_PhoneNumberLimitReached",
  105: "data_F_PhoneNumberLimitReached",
  409: "data_F_BulkPurchaseNotEnabled"
};

export const showUpgradePopup = (statusCode = "") => {
  if ([103, 104, 105, 202].includes(+statusCode)) {
    setTimeout(() => {
      dataSearchState.setIsNoPhoneCredits(true);
    }, 1000);
  } else {
    let popupType = PaidUserStatusCodePopup?.[+statusCode] || "";
    if (popupType) {
      setTimeout(() => {
        upgradePopupState.setPopupValues({ type: popupType });
        upgradePopupState.setPopup(true);
      }, 1000);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  }
};

export const isShowUpgradePopup = (statusCode = "") => {
  return ["101", "102", "103", "104", "105", "409", "202"].includes(statusCode);
};

const handleExportFail = () => {
  exportProcessingState.setShowPopup(false);
  toasterState.setToastMsg(
    "Failed to download selected contacts. Please try again later.",
    "failure"
  );
};

const handleExportResponse = downloadLink => {
  if (!downloadLink) {
    handleExportFail();
  } else {
    exportProcessingState.completeAnimation();
    setTimeout(() => {
      Utils.downloadExportedContact(downloadLink);
      exportProcessingState.setShowPopup(false);
      Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
        pageType: "Discover Contacts"
      });
    }, 1000);
  }
};

const handleExportCsvResponse = (data = {}) => {
  exportProcessingState.completeAnimation();
  exportProcessingState.setShowPopup(false);
  popupLoaderState.setShowPopup(false);
  if (Utils.hasObject(data)) {
    const { status = "", type = "", contactData = [] } = data || {};
    if (status === 200 && type === "limit_reached") {
      Utils.mixpanelTrack(MP_EVENT.CONTACT_EXPORTED, {
        pageType: "Discover Contacts",
        type: "Limit Reached"
      });
      exportEmailNotifyState.setShowPopup(true);
    } else if (contactData?.length > 0) {
      Utils.constructExportToCsvData(contactData, handleExportResponse);
    } else {
      handleExportFail();
    }
  } else {
    handleExportFail();
  }
};

export const verifyBulkActionPerformedAndAllow = (
  performActionCbk = () => {},
  cancelCbk = () => {}
) => {
  confirmationPopupState.setPopupValues({
    content:
      "This action will reset the contact selection. Are you sure you want to proceed?",
    actionBtnText: "Confirm",
    cancelBtnText: "Cancel",
    cancelCbk: () => {
      cancelCbk();
      confirmationPopupState.setShowPopup(false);
    },
    callback: () => {
      dataSearchState.setSelectionReset(true);
      performActionCbk();
    }
  });
  confirmationPopupState.setShowPopup(true);
};

export const verifyEmailValidationEnabled = (
  flagObj = {},
  history = {},
  addContactsToSequenceCbk = () => {},
  resetAllCbk = () => {}
) => {
  const {
    sequenceEmailValidationEnabled = false,
    emailValidationEnabled,
    emailValidationCredits
  } = flagObj || {};
  let count = 0;
  if (sequenceEmailValidationEnabled) {
    const { type = "", limit = 0 } = dataSearchState?.rangeSelected || {};
    if (type === "all" || !type) {
      count = dataSearchState?.selectedContactData?.length || 0;
    } else if (["bulk", "custom"].includes(type)) {
      count = limit;
    }
    if (emailValidationEnabled && emailValidationCredits < count) {
      SequenceUtils.promptEmailValidationNoCredits(
        count - emailValidationCredits,
        history,
        addContactsToSequenceCbk,
        resetAllCbk,
        "discover"
      );
      return;
    }
  }
  addContactsToSequenceCbk();
};
