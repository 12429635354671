import React from "react";
import { ReactComponent as Horizm } from "./images/horizm.svg";
import { ReactComponent as Rsz } from "./images/rsz.svg";
import { ReactComponent as Tpg } from "./images/tpg.svg";
import { ReactComponent as Protecto } from "./images/protecto.svg";
import { ReactComponent as Greyt } from "./images/greyt.svg";
import { ReactComponent as ParalleDots } from "./images/paralleDots.svg";
import { ReactComponent as Vservelogo } from "./images/vservelogo.svg";
import { ReactComponent as FissionLabs } from "./images/fissionLabs.svg";
import { ReactComponent as Infront } from "./images/infront.svg";
import { ReactComponent as Latent } from "./images/latent.svg";
import { ReactComponent as ProgrammersIo } from "./images/programmersIo.svg";
import { ReactComponent as FutureBridge } from "./images/futureBridge.svg";

const Clients = () => {
  const clients = [
    {
      icon: <Horizm />
    },
    {
      icon: <Rsz />
    },
    {
      icon: <Tpg />
    },
    {
      icon: <Protecto />
    },
    {
      icon: <Greyt />
    },
    {
      icon: <ParalleDots />
    },
    {
      icon: <Vservelogo />
    },
    {
      icon: <FissionLabs />
    },
    {
      icon: <Infront />
    },
    {
      icon: <Latent />
    },
    {
      icon: <ProgrammersIo />
    },
    {
      icon: <FutureBridge />
    }
  ];
  return (
    <div className="clients">
      <div className="container">
        <h2 className="sectionTitle">
          <span className="highlight">Empowering 1,000+ sales leaders</span>
          and representatives to build a stronger sales pipeline.
        </h2>
        <div className="clients-list">
          {clients.map((client, index) => {
            return (
              <div key={index} className="client">
                {client.icon}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default Clients;
