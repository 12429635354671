import React, { useState, useEffect, useContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import {
  callsStore,
  defaultCustomDate,
  defaultFilterObject
} from "Stores/Calls";
import { toasterState } from "Components/common/toaster";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import CallsUtils from "Utils/CallsUtils";
import { MP_EVENT } from "Static/constant";
import UserFilterDropDown from "./UserFilterDropDown";
import DateRangePicker from "Pages/Dashboard/reports/DateRangePicker";
import { CallsFilterContext } from "./CallsModule";

const CallsFilter = observer(() => {
  const { teamLoader, isContactsAdded, inFreePlan, isOverview, pageType } =
    useContext(CallsFilterContext);

  let location = useLocation();
  const {
    team = false,
    hasTeamMember = false,
    memberId = "",
    isTeamOwner = false
  } = userDetail?.userInfo || {};

  const [data, setData] = useState(callsStore.filterValues);

  const dateObjFunction = {
    today: 0,
    week: 6, // Doesn't include current day of the week
    month: 29, // Doesn't include current day of the month
    yesterday: 1,
    customDate: "custom"
  };

  const updateData = (obj = {}) => {
    let newObject = { ...data, ...obj };
    setData(newObject);
    callsStore.setFilterValues(newObject);
  };

  const getSelectedUsers = obj => {
    const memberIds =
      obj && typeof obj === "object" && Object.keys(obj)?.length > 0
        ? obj
        : obj?.length && obj?.toLowerCase() === "you"
        ? new Array(memberId)
        : "";
    return memberIds;
  };

  const makeFilterRequest = (payload = {}, user, obj = {}) => {
    const selectedIds = getSelectedUsers(user);
    if (selectedIds?.length > 0) {
      payload.memberIds = selectedIds;
    } else {
      payload.memberIds = (!team && [memberId]) || null;
    }
    callsStore.setFilterPayload(payload);
    filterByDateTime(payload);
  };

  const resetToDefaultPage = () => {
    callsStore.setFilterPayload({
      pageNo: 1
    });
  };

  const getTabValue = value => {
    if (!isOverview && data?.activeDateFilter !== value) {
      resetToDefaultPage();
    }
    let filterObj = {
      activeDateFilter: value
    };
    if (data.activeDateFilter === "customDate" && value !== "customDate") {
      filterObj = {
        ...filterObj,
        ...{
          customDateValue: "Custom",
          customDateFormat: "",
          customDate: defaultCustomDate,
          previousDateFilter: value
        }
      };
    }
    updateData(filterObj);
    const payload =
      (value !== "custom" &&
        CallsUtils.getFromToDateWithTimeZoneByDaysCount(
          dateObjFunction[value]
        )) ||
      "";
    payload.pageNo = 1;
    Utils.mixpanelTrack(MP_EVENT.CALLS_FILTERED_BY_USER, {
      filterType: value,
      pageType
    });
    makeFilterRequest(payload, data.selectedUser, {
      value,
      days: dateObjFunction[value]
    });
  };

  const getUserFilterPayload = () => {
    if (data.activeDateFilter === "customDate") {
      const { start: startDate, end: endDate } =
        callsStore?.filterValues?.customDateFormat || false;
      Utils.mixpanelTrack(MP_EVENT.CALLS_FILTERED_BY_USER, { pageType });
      return {
        fromDate: Utils.convertFromDateToLocalString(startDate),
        to: Utils.convertToDateToLocalString(endDate),
        timeZone: Utils.getCurrentUserTimeZone()
      };
    } else {
      return CallsUtils.getFromToDateWithTimeZoneByDaysCount(
        dateObjFunction[data.activeDateFilter]
      );
    }
  };

  const getSelectedUser = value => {
    !isOverview && resetToDefaultPage();
    updateData({
      selectedUser: value
    });
    makeFilterRequest(getUserFilterPayload(), value, false);
  };

  const constructCustomDatePayload = (start, end) => {
    if (!isOverview && Utils.getDateDiffNumber(start, end) > 91) {
      toasterState.setToastMsg(
        "The from and to dates cannot be more than 91 days apart",
        "info"
      );
      return;
    }
    !isOverview && resetToDefaultPage();
    const payload = {
      fromDate: Utils.convertFromDateToLocalString(start),
      to: Utils.convertToDateToLocalString(end),
      timeZone: Utils.getCurrentUserTimeZone(),
      pageNo: 1
    };
    Utils.mixpanelTrack(MP_EVENT.CALLS_FILTERED_BY_USER, {
      filterType: "Custom Date",
      pageType
    });
    makeFilterRequest(payload, data.selectedUser, {
      value: "customDate",
      days: Utils.getDateDiffNumber(start, end)
    });
    let dateValue =
      (start &&
        end &&
        `${Utils.formatDate(start)} -  ${Utils.formatDate(end)}`) ||
      "Custom";
    updateData({
      showDatePicker: false,
      customDateValue: dateValue,
      customDateFormat: { start, end }
    });
  };

  const getCustomDatePayload = () => {
    const { startDate = "", endDate = "" } =
      callsStore?.filterValues?.customDate?.[0] || false;
    startDate &&
      endDate &&
      data.activeDateFilter === "customDate" &&
      constructCustomDatePayload(startDate, endDate);
  };

  const openDatePicker = () => {
    if (isContactsAdded) {
      updateData({
        showDatePicker: !data.showDatePicker,
        previousDateFilter: data.activeDateFilter,
        activeDateFilter: "customDate"
      });
    }
  };

  const hideDatePicker = () => {
    const { start = new Date(), end = null } = data.customDateFormat || {};
    const customDate =
      data.customDateValue === "Custom"
        ? defaultCustomDate
        : [
            {
              endDate: end,
              startDate: start,
              key: "selection"
            }
          ];
    updateData({
      showDatePicker: !data.showDatePicker,
      activeDateFilter: data.previousDateFilter,
      customDate
    });
  };

  const getAnalyticsData = payload => {
    if (location?.pathname?.indexOf("analytics") !== -1) {
      callsStore.getTeamSummaryAnalyticsData(
        CallsUtils.getAnalyticsPayload(payload)
      );
      callsStore.getCallsAnalyticsData(CallsUtils.getAnalyticsPayload(payload));
    }
  };

  const getOverviewData = (payload = {}, isInit = false) => {
    if (location?.pathname?.indexOf("overview") !== -1) {
      const newObj = {
        ...payload,
        ...callsStore.filterPayload
      };
      callsStore.setResetCheckbox(true);
      callsStore.getCallsOutcomeFilterData(
        CallsUtils.getPayload(newObj, true),
        isInit
      );
      callsStore.getCallsOverviewData(CallsUtils.getPayload(newObj));
    }
  };

  const filterByDateTime = (data = {}, isInit = false) => {
    const payload = {
      ...data,
      pageNo: callsStore.filterPayload.pageNo || 1,
      limit: 10
    };
    if (location?.pathname?.indexOf("overview") !== -1) {
      getOverviewData(payload, isInit);
    } else if (location?.pathname?.indexOf("analytics") !== -1) {
      getAnalyticsData(payload);
    }
  };

  const getCustomDatePayloadObj = () => {
    const { startDate = "", endDate = "" } =
      callsStore?.filterValues?.customDate?.[0] || false;
    if (startDate && endDate) {
      if (!isOverview && Utils.getDateDiffNumber(startDate, endDate) > 91) {
        updateData({
          customDateValue: "Custom",
          activeDateFilter: "month",
          previousDateFilter: "yesterday",
          customDate: defaultCustomDate
        });
        return CallsUtils.getFromToDateWithTimeZoneByDaysCount(89); // Indicates month(30 days) but current reduce by one
      } else {
        return {
          fromDate: Utils.convertFromDateToLocalString(startDate),
          to: Utils.convertToDateToLocalString(endDate),
          timeZone: Utils.getCurrentUserTimeZone()
        };
      }
    } else {
      const { start = "", end = "" } =
        callsStore?.filterValues?.customDateFormat || false;
      if (start && end) {
        return {
          fromDate: Utils.convertFromDateToLocalString(start),
          to: Utils.convertToDateToLocalString(end),
          timeZone: Utils.getCurrentUserTimeZone()
        };
      }
    }
    return {};
  };

  const getInitialReportData = () => {
    const payload = constructFilterPayload();
    if (payload && Object.keys(payload)?.length) {
      filterByDateTime(payload, true);
      Utils.mixpanelTrack(MP_EVENT.CALLS_VIEWED, {
        pageType: isOverview ? "Overview Page" : "Analytics Page"
      });
    }
    callsStore.isLogUpdated && callsStore.setIsLogUpdated(false);
  };

  const constructFilterPayload = () => {
    const payload =
      data.activeDateFilter !== "customDate"
        ? CallsUtils.getFromToDateWithTimeZoneByDaysCount(
            dateObjFunction[data.activeDateFilter]
          )
        : getCustomDatePayloadObj();
    const selectedIds = getSelectedUsers(data.selectedUser);
    if (selectedIds?.length > 0) {
      payload.memberIds = selectedIds;
    } else {
      payload.memberIds = (!team && [memberId]) || null;
    }
    callsStore.setFilterPayload(payload);
    return payload || {};
  };

  const handleCustomDate = customDate => {
    updateData({ customDate });
  };

  useEffect(() => {
    callsStore.isLogUpdated && getInitialReportData();
  }, [callsStore.isLogUpdated]);

  useEffect(() => {
    return () => {
      updateData(defaultFilterObject);
    };
  }, []);

  useEffect(() => {
    getInitialReportData();
  }, [location.pathname]);

  return (
    <div className="callsFilter">
      {((team && hasTeamMember?.length > 1) || isTeamOwner) && !teamLoader && (
        <UserFilterDropDown team={team} selectedUserCbk={getSelectedUser} />
      )}
      <div className="dateFilter">
        {data?.dateFilterList?.length &&
          data.dateFilterList.map(item => {
            const { name = "", id = "", value = "" } = item || {};
            return (
              <div
                className={`tabItem ${
                  data.activeDateFilter === value ? "active" : ""
                }`}
                id={id}
                key={id}
                onClick={() =>
                  value === "customDate" ? openDatePicker() : getTabValue(value)
                }
              >
                {name === "Custom" && (
                  <span className="material-icons-outlined dateRange">
                    date_range
                  </span>
                )}
                <span className="filterText">
                  {name === "Custom" ? data?.customDateValue : name}
                </span>
              </div>
            );
          })}
      </div>
      {data?.showDatePicker && (
        <DateRangePicker
          className="dateRangePicker"
          customDate={data.customDate}
          filterCbk={getCustomDatePayload}
          cancelCbk={hideDatePicker}
          insightPage={!isOverview}
          inFreePlan={inFreePlan}
          pageType="calls"
          upgradePopupName="callsCustomDate"
          handleCustomDate={handleCustomDate}
          customDateObj={callsStore.filterValues.customDate?.[0] || false}
        />
      )}
      {data?.showDatePicker && (
        <div className="overlayCustomDateFilter" onClick={hideDatePicker} />
      )}
    </div>
  );
});

export default withRouter(CallsFilter);
export { CallsFilter };
