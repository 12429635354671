import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import userDetail from "Stores/userDetail";
import PricingStore from "Stores/Pricing";
import Utils from "Utils/utils";
import { ReactComponent as MostPopular } from "Assets/Pricing/mostPopular.svg";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import CustomTooltip from "Components/common/CustomTooltip";
import ToggleSwitch from "Components/common/ToggleSwitch";
import ToggleContext from "./context/ToggleContext";

export const pricingPlanObj = {
  free: {
    subText: "Get started with multi-channel outreach",
    color: "#3953fb",
    planAmountSubText: "/ Forever"
  },
  basic: {
    subText: "Designed for small teams scaling outbound",
    color: "#FA6B63",
    planAmountSubText: "/ User/Month"
  },
  pro: {
    subText: "Maximize outreach with automation",
    color: "#01B018",
    isPopular: true,
    planAmountSubText: "/ User/Month"
  },
  custom: {
    subText: "Enterprise-grade outbound at scale",
    color: "#AD007F"
  }
};

const PricingPlans = (props = {}) => {
  const { planData = [], subscriptionId = "" } = props || {};
  const { team, userLoggedIn } = userDetail.userInfo || {};
  const { planName, inFreePlan } = userDetail?.userFeatureFlag || {};
  let { isChecked, setIsChecked } = useContext(ToggleContext);
  const history = useHistory();

  const [tooltipVisible, setTooltipVisible] = useState(null);
  const [hoveredItem, setHoveredItem] = useState(null);

  const getTogglePlanType = value => {
    PricingStore.setPaymentFormInfo({ annual: value });
    setIsChecked(value);
  };

  const upgradeBtn = (plan = {}) => {
    let planDisplayName = plan?.displayName?.toLowerCase();
    if (userLoggedIn) {
      if (!team) {
        confirmationPopupState.setPopupValues({
          title: "Pricing Purchase Request?",
          content: `Please contact your account administrator to upgrade your account`,
          actionBtnText: "Ok",
          needCancelBtn: false,
          callback: () => {
            confirmationPopupState.setShowPopup(false);
          }
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        if (
          ["basic", "pro"].includes(planName?.toLowerCase()) &&
          ["unlimited", "pro", "basic"].includes(planDisplayName)
        ) {
          confirmationPopupState.setPopupValues({
            content: `Please reach out to <a href="mailto:support@salesgear.io" target="_blank" rel="noopener noreferrer" >support@salesgear.io</a> to upgrade to ${plan?.displayName} plan`,
            actionBtnText: "Ok",
            needCancelBtn: false,
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });
          confirmationPopupState.setShowPopup(true);
        } else if (planDisplayName !== "custom") {
          PricingStore.setPaymentFormInfo(plan);
          localStorage.setItem("proceedToPay", true);
          history.push("pricing/payment");
        }
      }
    } else {
      if (planDisplayName !== "custom") {
        history.push("/signup");
      }
    }
  };

  const getActionButton = planObj => {
    let buttonValue = "";
    const { displayName } = planObj;

    let idValue =
      displayName?.toLowerCase() == "custom" ? "my_custom_link" : "";

    if (displayName?.toLowerCase() === "custom") {
      buttonValue = "Contact Sales";
    } else if (displayName?.toLowerCase() === "free") {
      buttonValue = "";
    } else {
      buttonValue = "Upgrade";
    }

    return (
      <div
        id={idValue}
        className={`${buttonValue ? "" : "noBorder"} upgradeBtn`}
        onClick={() => upgradeBtn(planObj)}
      >
        {buttonValue}
      </div>
    );
  };

  const showCurrentPlanDom = plan => {
    const { displayName = "" } = plan;

    if (subscriptionId !== null) {
      if (displayName?.toLowerCase() === planName?.toLowerCase()) {
        return true;
      }
    } else {
      return inFreePlan && displayName?.toLowerCase() === "free";
    }

    return false;
  };

  const getTooltipContent = (planName, item) => {
    const tooltipData = {
      free: {
        emailCredits:
          "Find up to 100 prospect email addresses per month, with a daily limit of 10",
        phoneNumbers:
          "Find up to 2 prospect phone numbers for free, available for lifetime.",
        callingMinutes:
          "Lifetime access to 60 free calling minutes for US & Canada.",
        emailSends:
          "Send up to 1,000 emails per month, with a limit of 20 per inbox per day."
      },
      basic: {
        emailCredits:
          "Find up to 500 prospect email addresses per month, with a daily limit of 50.",
        phoneNumbers: "Find up to 10 prospect phone numbers per month.",
        callingMinutes:
          "Get 100 free calling minutes per month for US & Canada.",
        emailSends:
          "Send up to 25,000 emails per month, with no daily limit per inbox."
      },
      pro: {
        emailCredits:
          "Find up to 10,000 prospect email addresses per month, with no daily limit.",
        phoneNumbers: "Find up to 50 prospect phone numbers per month.",
        callingMinutes:
          "Get 500 free calling minutes per month for US & Canada.",
        emailSends:
          "Send up to 100,000 emails per month, ideal for high-volume outreach, with no per-inbox limit."
      },
      custom: {
        emailCredits: "Flexible email credits to match your prospecting needs",
        phoneNumbers:
          "Find up to 250 prospect phone numbers per month, or customize as needed.",
        callingMinutes:
          "Flexible calling minutes tailored to your business needs.",
        emailSends: "Flexible email sending limits tailored to your needs."
      }
    };

    const planKey = planName.toLowerCase();
    return tooltipData[planKey]?.[item] || "No information available.";
  };

  const handleTooltipVisibility = (planName, item, isVisible) => {
    if (isVisible) {
      setTooltipVisible(planName);
      setHoveredItem(item);
    } else {
      setTooltipVisible(null);
      setHoveredItem(null);
    }
  };

  const getCreditsData = plan => {
    const {
      emailCreditsMonthlyLimit = 0,
      phoneCreditsMonthlyLimit = 0,
      emailSendLimit = 0,
      usFreeCallingMinutesEligible = 0,
      displayName = "",
      liMaxInvitePerMonth = 0
    } = plan;

    const isCustom = displayName.toLowerCase() === "custom";
    return (
      <>
        <div className="emailCredits">All Features Included</div>
        <div
          className="emailCredits"
          style={{
            cursor: "pointer",
            textDecoration: "underline dotted"
          }}
          onMouseEnter={() =>
            handleTooltipVisibility(displayName, "emailCredits", true)
          }
          onMouseLeave={() =>
            handleTooltipVisibility(displayName, "emailCredits", false)
          }
        >
          {`${
            isCustom
              ? "Custom"
              : Utils.numberWithCommas(emailCreditsMonthlyLimit)
          } email credits`}
          {tooltipVisible === displayName && hoveredItem === "emailCredits" && (
            <CustomTooltip
              text={getTooltipContent(displayName, "emailCredits")}
              //placement="bottom"
            />
          )}
        </div>
        <div
          className="phoneNumbers"
          style={{ cursor: "pointer", textDecoration: "underline dotted" }}
          onMouseEnter={() =>
            handleTooltipVisibility(displayName, "phoneNumbers", true)
          }
          onMouseLeave={() =>
            handleTooltipVisibility(displayName, "phoneNumbers", false)
          }
        >
          {`${
            isCustom
              ? "Custom"
              : Utils.numberWithCommas(phoneCreditsMonthlyLimit)
          } phone numbers`}
          {tooltipVisible === displayName && hoveredItem === "phoneNumbers" && (
            <CustomTooltip
              text={getTooltipContent(displayName, "phoneNumbers")}
            />
          )}
        </div>
        <div
          className="emailSends"
          style={{ cursor: "pointer", textDecoration: "underline dotted" }}
          onMouseEnter={() =>
            handleTooltipVisibility(displayName, "emailSends", true)
          }
          onMouseLeave={() =>
            handleTooltipVisibility(displayName, "emailSends", false)
          }
        >
          {`${
            isCustom ? "Custom" : Utils.numberWithCommas(emailSendLimit)
          } email sends`}
          {tooltipVisible === displayName && hoveredItem === "emailSends" && (
            <CustomTooltip
              text={getTooltipContent(displayName, "emailSends")}
            />
          )}
        </div>
        <div
          className="usCallingMinutes"
          style={{ cursor: "pointer", textDecoration: "underline dotted" }}
          onMouseEnter={() =>
            handleTooltipVisibility(displayName, "callingMinutes", true)
          }
          onMouseLeave={() =>
            handleTooltipVisibility(displayName, "callingMinutes", false)
          }
        >
          {`${
            isCustom
              ? "Custom"
              : Utils.numberWithCommas(usFreeCallingMinutesEligible)
          } calling minutes`}
          {tooltipVisible === displayName &&
            hoveredItem === "callingMinutes" && (
              <CustomTooltip
                text={getTooltipContent(displayName, "callingMinutes")}
              />
            )}
        </div>
        <div className="maxInvite">{`${
          isCustom ? "Custom" : Utils.numberWithCommas(liMaxInvitePerMonth)
        } LinkedIn outreach actions`}</div>
      </>
    );
  };

  const toggleBtn = {
    leftText: "Monthly",
    checked: isChecked,
    rightText: "Annual",
    offerText: `Save 20%`,
    showOffer: true,
    action: getTogglePlanType
  };

  useEffect(() => {
    PricingStore.getActivePlanDetails();
  });

  return (
    <div className="planCardSection">
      <div className="planCardSectionPricingToogle">
        <ToggleSwitch {...toggleBtn} />
      </div>
      <div className="container">
        <div className="planCardWrapper">
          {planData.map(item => {
            const {
              displayName = "",
              price = "",
              annualPrice = ""
            } = item || {};
            const displayValue = pricingPlanObj?.[displayName?.toLowerCase()];
            return (
              <div className="planCardItem">
                {displayValue?.isPopular && (
                  <div className="mostPopularBadge">
                    <MostPopular />
                  </div>
                )}
                <div
                  className={`planCard ${
                    displayValue?.isPopular ? "isPopularPlanCard" : ""
                  }`}
                >
                  <div
                    className="planName"
                    style={{ color: displayValue?.color }}
                  >
                    {displayName?.toLowerCase() === "custom"
                      ? "Enterprise"
                      : displayName}
                  </div>
                  <div className="planSubText">{displayValue?.subText}</div>
                  {displayName?.toLowerCase() === "custom" ? (
                    <div className="planAmount">Custom</div>
                  ) : (
                    <div className="planAmount">
                      <div className={`planString  ${displayName}`}>
                        <sup className="planDollar">$</sup>
                        <span className="planAmount">
                          {PricingStore.paymentFormInfo.annual
                            ? annualPrice
                            : price}
                        </span>
                        <div className="flexColumn">
                          <p
                            className={`planAmountSubText ${displayName}`}
                          >{`${displayValue?.planAmountSubText}`}</p>
                          <p className="planAmountMonthlyText">
                            {displayValue?.billedMonthly && (
                              <p>{`(Billed Monthly)`}</p>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}
                  {showCurrentPlanDom(item) ? (
                    <div
                      className="currentPlanBtn"
                      onClick={() => history.push("/settings/billing")}
                    >
                      Current Plan
                    </div>
                  ) : (
                    getActionButton(item)
                  )}
                  {getCreditsData(item)}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export { PricingPlans };
export default PricingPlans;
