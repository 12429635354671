/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useReducer, useEffect } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import TaskUtils from "Utils/TaskUtils";
import BreadCrumbComponent from "Components/common/breadcrumb";
import ButtonComponent from "Components/common/Button";
import TaskTable from "./TaskTable";
import { URLS, makeApi } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import taskStoreData from "Stores/taskData";
import { MP_EVENT } from "Static/constant";
import FilterByDateTab from "Components/common/FilterByDateTab";
import DateFilterLoader from "Components/common/DateFilterLoader";
import PricingDiscountBanner from "Components/common/PricingDiscountBanner";
import AddStepBtn from "Components/common/addStepBtn";
import InsertContactCard from "Components/ContactCard/InsertContactCard";
import { ReactComponent as StartIcon } from "Assets/svg/resume.svg";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { KnowMoreButton } from "Components/common/KnowMoreButton";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import TokenUtils from "Utils/TokenUtils";
import BulkActionButton from "./BulkActionButton";
import ContactSelectedCount from "Components/common/ContactSelectedCount";
import BulkTasksResponseMessageHandler from "./BulkTasksResponseMessageHandler";
import EditBulkTasks from "./EditBulkTasks";
import globalCallState from "Stores/GlobalCallState";
import { ReactComponent as FilterIcon } from "Assets/svg/filter.svg";
import AllFilters from "./FilterComponent/AllFilters";
import filterComponentState from "./FilterComponent/filterComponentState";
import PaywallUtils from "Utils/PaywallUtils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { PRESENT_BULK_TASKS_TYPE, PAST_BULK_TASKS_TYPE } from "Model/model";
import FilterNavBar from "../Accounts/FilterNavBar";
import { accountsState } from "../Accounts/AccountsState";
import DateUtils from "Utils/DateUtils";
import SequenceUtils from "Utils/SequenceUtils";
import { addStagesPopupState } from "../sequences/Stages/AddStagesPopupState";

const initialState = {
  selectAll: false,
  selectedTaskIds: [],
  allTasksSelected: false,
  showEditTaskPopup: false,
  notes: "",
  noteFocused: false,
  taskDueString: new Date()
};
const defaultFilterObj = [
  {
    name: "Due",
    id: "due",
    count: 0
  },
  {
    name: "Upcoming",
    id: "upcoming",
    count: 0
  },
  {
    name: "Completed",
    id: "completed",
    count: 0
  },
  {
    name: "Skipped",
    id: "skipped",
    count: 0
  }
];

const logReducer = (state = {}, action = () => {}) => {
  switch (action.type) {
    case "UPDATE_LOG_STATE":
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

const Tasks = props => {
  const { userFeatureFlag = {} } = userDetail || {};
  const {
    taskEnabled = false,
    inFreePlan = false,
    planName = ""
  } = userFeatureFlag || {};

  const defaultBreadValue = [{ name: "Tasks", link: "/tasks" }];
  const [activeTab, setActiveTab] = useState(["total"]);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterLoading, setFilterLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const [btnLoader, setBtnLoader] = useState(true);
  const [taskData, setTaskData] = useState({});
  const [taskCount, setTaskCount] = useState(0);
  const [showContactCard, setShowContactCard] = useState(false);
  const [slideAnim, setSlideAnim] = useState(false);
  const [selectedContact, setSelecedContact] = useState({});
  const [logState, dispatch] = useReducer(logReducer, initialState);
  const [editTasksLoader, setEditTasksLoader] = useState(false);
  const [filterTabData, setFilterTabData] = useState([...defaultFilterObj]);

  const updateLogState = (newState = {}) => {
    dispatch({
      type: "UPDATE_LOG_STATE",
      payload: newState
    });
  };
  const initialSelectedOptions = {};

  const [selectedTask, setSelectedTask] = useState(null);
  const [showTaskTypeDropdown, setShowTaskTypeDropdown] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({});

  const handleTaskTypeAction = (value = false, id = "") => {
    let updatedSelectedOptions = { ...selectedOptions };

    if (id?.toLowerCase() === "total") {
      TASK_TYPE_LIST.forEach(task => {
        updatedSelectedOptions[task?.id] = value ? true : false;
      });
    } else {
      updatedSelectedOptions = { ...updatedSelectedOptions, [id]: value };

      if (!value) {
        updatedSelectedOptions.total = false;
      } else {
        const allSelected = TASK_TYPE_LIST.every(task => {
          return task?.id === "total" || updatedSelectedOptions[task?.id];
        });
        updatedSelectedOptions.total = allSelected;
      }
    }
    const selectedArr = Object?.keys(updatedSelectedOptions)?.filter(
      key => updatedSelectedOptions[key]
    );

    setSelectedOptions(updatedSelectedOptions);
    setSelectedTask(id);
    taskStoreData.setFilter(id);
    filterAction(selectedArr);
  };

  const filterAction = (filterId = "", count = 0) => {
    Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
      taskCount: count,
      taskType: filterId,
      pageType: "Tasks"
    });
    taskStoreData.setFilter(filterId);
    setActiveTab(filterId);
    setTableLoading(true);
    const dateFilterValue = taskStoreData?.dateFilterValue || null;
    const statusFilterValue = taskStoreData?.statusFilterValue || null;
    taskStoreData.getTaskData(
      "",
      ["total"]?.includes(filterId) ? null : filterId,
      null,
      ["total"]?.includes(filterId) ? true : null,
      null,
      20,
      dateFilterValue || null,
      statusFilterValue
    );
  };

  const TASK_TYPE_LIST = [
    {
      value: "All",
      key: "total",
      id: "total",
      method: handleTaskTypeAction,
      checked: selectedOptions?.total
    },
    {
      value: "Manual Email",
      key: "email",
      id: "email",
      method: handleTaskTypeAction,
      checked: selectedOptions?.email
    },
    {
      value: "LinkedIn",
      key: "linkedin",
      id: "linkedin",
      method: handleTaskTypeAction,
      checked: selectedOptions?.linkedin
    },
    {
      value: "Phone Call",
      key: "phone",
      id: "phone",
      method: handleTaskTypeAction,
      checked: selectedOptions?.phone
    },
    {
      value: "Custom",
      key: "customTask",
      id: "customTask",
      method: handleTaskTypeAction,
      checked: selectedOptions?.customTask
    }
  ];

  TASK_TYPE_LIST.forEach(task => {
    initialSelectedOptions[task?.id] = true;
  });

  const productDiscount = Utils.showProductDiscountBanner();

  const triggerMissingTokenPrompt = missingTokens => {
    confirmationPopupState.setPopupValues({
      content: `Please update the contact with the following mandatory fields to create a task<br/> ${TokenUtils.getMissingTokenList(
        missingTokens
      )}`,
      actionBtnText: "Ok",
      needCancelBtn: false,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
  };

  const createTaskActionCbk = async (taskData = {}, loaderCbk = () => {}) => {
    if (taskData && Object.keys(taskData)?.length) {
      try {
        const payload = TaskUtils.getPayload(taskData);
        const response = await save(payload);
        const respData = response?.data || {};
        if (respData?.task && respData?.responseCode === 200) {
          Utils.mixpanelTrack(MXP_EVENT.TASK_CREATED, {
            taskType: respData?.task?.task?.taskType || "Task",
            pageType: "Tasks Page"
          });
          handleCreateTaskSuccess(respData.task, loaderCbk);
        } else if (respData?.responseCode === 406) {
          if (respData?.missingFields?.length) {
            loaderCbk(true);
            triggerMissingTokenPrompt(respData?.missingFields);
          } else {
            handleCreateTaskFailure(respData);
            loaderCbk();
          }
        } else {
          handleCreateTaskFailure(respData);
          loaderCbk();
        }
      } catch (e) {
        toasterState.setToastMsg("Task creation failed", "failure");
        loaderCbk();
      }
    }
  };

  const createTaskCbk = param => {
    addStagesPopupState.setPopupValues({
      pageSource: "Tasks",
      isTaskCreation: true,
      stageType: "manualEmail",
      btnText: "Next",
      createCbk: createTaskActionCbk
    });
    addStagesPopupState.setShowPopup(true);
  };

  const save = payload => {
    const config = {
      url: URLS.createTask,
      method: "POST",
      data: payload
    };
    return makeApi(config);
  };

  const handleCreateTaskSuccess = (task, loaderCbk) => {
    let message = "Task created successfully";
    toasterState.setToastMsg(message, "success");
    taskStoreData.getTaskFilterData();
    taskStoreData.getTaskData();
    setTableLoading(true);
    setFilterLoading(true);
    loaderCbk();
  };

  const handleCreateTaskFailure = respData => {
    if (respData?.responseCode === 404) {
      toasterState.setToastMsg(
        "Contact not found. Please add this contact before creating a task",
        "failure"
      );
    } else {
      toasterState.setToastMsg("Failed to create task", "failure");
    }
  };

  const startAlltask = async e => {
    setLoader(true);
    const dateFilterValue = taskStoreData?.dateFilterValue;
    const statusFilterValue = taskStoreData?.statusFilterValue;
    await taskStoreData.getTaskIdsFromSelectedPage(
      "",
      null,
      "",
      null,
      "",
      1,
      dateFilterValue,
      statusFilterValue,
      "",
      true
    );
    taskStoreData.setCurrentTaskId(taskStoreData?.taskIds?.[0] || "");
    props.history.push(`/tasks/start/${taskStoreData?.currentTaskId}`);
    setLoader(false);
  };

  const enableStartAllBtn = (filterData = "") => {
    setTaskCount(
      filterData[["email"]?.includes(activeTab) ? "manualEmail" : activeTab] ||
        filterData
    );
    setBtnLoader(false);
  };

  const constructFilterRenderData = (filteredDataArray = []) => {
    setFilterLoading(false);
    if (filteredDataArray && filteredDataArray?.length > 0) {
      const filterData = filteredDataArray.reduce(
        (obj, item) => Object.assign(obj, { [item?.taskType]: item?.count }),
        {}
      );
      enableStartAllBtn(filterData);
    }
  };

  const getCardFilterPayload = activeTab => {
    return activeTab?.includes?.["total"] ? null : activeTab;
  };

  const setFilterDateInStore = filterDateValue => {
    const value =
      filterDateValue?.filterFromDate || filterDateValue?.filterToDate
        ? filterDateValue
        : null;
    taskStoreData.setDateFilterValue(value);
  };

  const setFilterStatusInStore = filterStatusValue => {
    const value = filterStatusValue?.status ? filterStatusValue : null;
    taskStoreData.setStatusFilterValue(value);
  };

  const getFilterByDate = (value = "") => {
    setFilterLoading(true);
    setTableLoading(true);
    setFilterDateInStore(value);
    taskStoreData.getTaskFilterData(value);
    taskStoreData.setDateFilterValue(
      value?.filterFromDate || value?.filterToDate
        ? { ...value, filterApplied: true }
        : null
    );
    taskStoreData.getTaskData(
      "",
      getCardFilterPayload(activeTab),
      null,
      true,
      null,
      20,
      value,
      taskStoreData.statusFilterValue
    );
  };

  const getFilterByStatus = value => {
    setFilterLoading(true);
    setTableLoading(true);
    setFilterStatusInStore(value);
    if (["upcoming", "due"]?.includes(value?.key?.toLowerCase())) {
      setFilterDateInStore(value);
    } else {
      setFilterDateInStore(
        value?.status === "ACTIVE" ? taskStoreData.dateFilterValue : null
      );
    }
    taskStoreData.getTaskFilterData(value);
    taskStoreData.getTaskData(
      "",
      getCardFilterPayload(activeTab),
      null,
      true,
      null,
      20,
      taskStoreData.dateFilterValue,
      value
    );
  };

  // Contact Card
  const openContactCard = (contact = {}) => {
    if (contact && Object.keys(contact)?.length) {
      setSlideAnim(true);
      setShowContactCard(true);
      setSelecedContact(contact);
    }
  };

  const closeContactCard = () => {
    if (globalCallState.popupValues?.isContactCard) {
      globalCallState.setPopupValues({
        ...globalCallState.popupValues,
        isContactCard: false
      });
    }
    setSlideAnim(false);
    setTimeout(() => {
      setShowContactCard(false);
    }, 300);
  };

  const getCommonPayloadObject = (isInit = false) => {
    let payload = {
      timeZone: Utils.getCurrentUserTimeZone(),
      status: taskStoreData?.statusFilterValue?.status,
      memberIds: [userDetail?.userInfo?.memberId]
    };
    if (isInit || activeTab?.includes?.["due"]) {
      payload.dateFilter = {
        toDate: DateUtils.getTimeStamp(new Date(), "end")
      };
    }
    if (
      !isInit &&
      taskStoreData?.dateFilterValue &&
      Object.keys(taskStoreData?.dateFilterValue)?.length > 0
    ) {
      const { filterFromDate = null, filterToDate = null } =
        taskStoreData?.dateFilterValue || {};
      payload.dateFilter = {
        ...(filterFromDate && {
          fromDate: DateUtils.getTimeStamp(filterFromDate, "start")
        }),
        ...(filterToDate && {
          toDate: DateUtils.getTimeStamp(filterToDate, "end")
        })
      };
    }
    payload.taskType =
      (["total"]?.includes(taskStoreData?.filter) || !taskStoreData?.filter
        ? null
        : ["email", "manualemail"].includes(taskStoreData.filter)
        ? ["email", "manualEmail"]
        : TaskUtils.getTasktypeFilter(taskStoreData?.filter)) || null;
    return payload;
  };

  const getAnalyticsPayload = (isInit = false) => {
    return { ...getCommonPayloadObject(isInit), pageNo: 1 };
  };

  const getAnalyticsFilterData = async (isInit = false) => {
    let payload = getAnalyticsPayload(isInit);
    let response = await accountsState.getAccountTasksFilterAnalytics(payload);
    if (
      response?.message?.message === "cancel" ||
      response?.message === "cancel"
    ) {
      console.error("Request cancelled");
    } else {
      const { status = "", data = {} } = response || {};
      if (
        +status === 200 &&
        data?.analyticsV2 &&
        Object.keys(data?.analyticsV2)?.length > 0
      ) {
        let tempList = [...filterTabData].map(item => {
          item.count =
            data?.analyticsV2?.[`${item?.id?.toLowerCase()}Count`] || 0;
          return item || {};
        });
        setFilterTabData(tempList);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  const reloadTaskPage = () => {
    taskStoreData.getTaskFilterData();
    taskStoreData.getTaskData();
    getAnalyticsFilterData(!taskStoreData?.dateFilterValue?.filterApplied);
  };

  const resetFilterState = () => {
    filterComponentState.setFilterComponentData({});
    filterComponentState.setSelectedFilteredList({});
    let contactFields = {
      contactFields: [
        {
          fieldName: "",
          operator: "EQUALS",
          value: ""
        }
      ]
    };
    filterComponentState.setContactFieldsData({ ...contactFields });
    taskStoreData.setDateFilterValue(null);
    taskStoreData.setStatusFilterValue({ status: "ACTIVE" });
    taskStoreData.getTaskData();
    taskStoreData.getTaskFilterData();
  };

  const resetSelectedCheckbox = (reloadPage = false) => {
    toggleRecord("all", false);
    updateLogState({ ...initialState });
  };

  const startTaskBtnObj = {
    id: "startNoTask",
    name: "startNoTask",
    btnText: `Start ${taskStoreData?.taskData?.totalTasks} Task${
      taskStoreData?.taskData?.totalTasks > 1 ? "s" : ""
    }`,
    icon: false,
    svg: <StartIcon />,
    type: "button",
    className: `startAllTask ${loader ? "loading" : ""}`,
    loader,
    click: e => {
      e.preventDefault();
      startAlltask(e);
    }
  };

  const updateTasksState = (data = {}) => {
    if (data && Object.keys(data)?.length) {
      setTableLoading(false);
      filterComponentState.setBtnLoading(false);
      let tempData = { ...data };
      if (tempData?.tasks?.length > 0) {
        tempData = tempData.tasks.map(item => {
          item.checked = false;
          return item;
        });
      }
      setTaskData(data);
    }
  };

  const toggleRecord = (type = "", value = false) => {
    let tempData = { ...taskData } || {};
    let totalLen = tempData?.tasks?.length || 0;
    let count = 0;
    let ids = [];
    if (tempData?.tasks?.length > 0) {
      tempData = tempData?.tasks.map(item => {
        if (type === "all" || item?.task?.id === type) {
          item.checked = value;
        }
        if (item?.checked) {
          ids.push(item?.task?.id);
          count += 1;
        }
        return item;
      });
    }
    setTaskData({ ...taskData, ...tempData });
    return { allChecked: +totalLen === +count, ids }; // verifying all records are selected return boolen value
  };

  // Bulk Task Operations
  const handleResponsePrompt = async (response = {}, type = "") => {
    const {
      background = false,
      totalCount = 0,
      bulkOperationResult = {},
      activeJob = false
    } = response || {};
    let content = "";
    if (activeJob && !bulkOperationResult) {
      content = `All the task${
        totalCount > 1 ? "s" : ""
      } are being inprogress, it may take few to several minutes to process.`;
    } else if (background) {
      content = `All the task${totalCount > 1 ? "s" : ""} are being ${
        PAST_BULK_TASKS_TYPE[type]
      }, it may take few to several minutes based on the number of task${
        totalCount > 1 ? "s" : ""
      } selected.`;
    } else if (
      bulkOperationResult &&
      Object.keys(bulkOperationResult)?.length
    ) {
      content = (
        <div className="retryResponse">
          <BulkTasksResponseMessageHandler
            {...bulkOperationResult}
            type={type}
          />
        </div>
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later.",
        "fail"
      );
    }
    confirmationPopupState?.showPopup &&
      confirmationPopupState.setShowPopup(false);
    if (content) {
      confirmationPopupState.setPopupValues({
        content,
        dynamicDom: true,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    }
  };

  const getBulkTasksPayload = (type = "", taskId = "") => {
    let payoad = {
      all: logState?.allTasksSelected || false,
      source: "TASK",
      type,
      filter: {},
      totalCount: taskId
        ? 1
        : (logState?.allTasksSelected
            ? taskCount
            : logState?.selectedTaskIds?.length) || 0
    };
    if (!logState?.allTasksSelected) {
      payoad.filter.taskIds = taskId
        ? [taskId]
        : logState?.selectedTaskIds || [];
    }
    if (type?.toUpperCase() === "EDIT_TASK_DUE_DATE") {
      const { taskDueString = new Date(), notes = "" } = logState || {};
      payoad.data = {
        taskDueString: Utils.createScheduleDate(taskDueString),
        notes,
        timeZone: Utils.getCurrentUserTimeZone()
      };
    }
    return payoad;
  };

  const performBulkAction = async (type = "", taskId = "") => {
    if (type && (logState?.selectedTaskIds?.length > 0 || taskId)) {
      let payload = getBulkTasksPayload(type, taskId);
      let res = await taskStoreData.performBulkTasksAction(payload);
      const {
        success = false,
        background = false,
        activeJob = false
      } = res?.data || {};
      Utils.mixpanelTrack(MXP_EVENT.TASK_BULK_ACTION_PERFORMED, {
        actionType: type,
        pageType: PAGE_TYPE.task,
        payload,
        bulkAction: taskId ? "Single" : "Bulk",
        status: success ? "SUCESS" : "FAILED"
      });
      editTasksLoader && setEditTasksLoader(false);
      updateLogState({ ...initialState });
      if (success) {
        if (activeJob) {
          SequenceUtils.showActiveJobPopupForBulkActions();
        } else {
          if (!background) {
            setFilterLoading(true);
            setTableLoading(true);
            await taskStoreData.getTaskData();
            await taskStoreData.getTaskFilterData();
          }
          handleResponsePrompt(res?.data, type, taskId);
        }
      } else {
        confirmationPopupState.setShowPopup(false);
        toggleRecord("all", false);
        toasterState.setToastMsg(
          "Something went wrong. Please try again later.",
          "fail"
        );
      }
    }
  };

  const getSingleTaskEditDueNotesObject = (selectedTaskIds = []) => {
    if (selectedTaskIds?.length == 1) {
      let { task = {} } =
        [...taskData?.tasks].find(
          item => item?.task?.id === selectedTaskIds?.[0]
        ) || {};
      let { touchPoint = {}, taskDue = new Date(), note = "" } = task || {};
      return {
        taskDueString: new Date(
          touchPoint?.scheduleDate || taskDue || new Date()
        ),
        notes: note || touchPoint?.notes || ""
      };
    }
    return {};
  };

  const handleBulkActionConfirmPrompt = async (type = "", taskId = "") => {
    const { selectedTaskIds = [], allTasksSelected = false } = logState || {};
    if (type && (selectedTaskIds?.length > 0 || taskId)) {
      if (type?.toUpperCase() === "EDIT_TASK_DUE_DATE") {
        let existingValue =
          getSingleTaskEditDueNotesObject(selectedTaskIds) || {};
        updateLogState({
          ...logState,
          showEditTaskPopup: true,
          ...existingValue
        });
      } else {
        confirmationPopupState.setPopupValues({
          content: `Are you sure you want to ${PRESENT_BULK_TASKS_TYPE[type]} ${
            taskId || selectedTaskIds?.length === 1
              ? "1 selected Task"
              : `${
                  allTasksSelected ? taskCount : selectedTaskIds?.length
                } selected Task${selectedTaskIds?.length > 1 ? "s" : ""}`
          }?`,
          actionBtnText: "Yes",
          loadingMsg: "Please wait...",
          callback: () => {
            performBulkAction(type, taskId);
          }
        });
        confirmationPopupState.setShowPopup(true);
      }
    }
  };

  const handleInputCheckboxAction = (
    value = false,
    type = "",
    allTasksSelected = false
  ) => {
    let { allChecked = false, ids = [] } = toggleRecord(type, value);
    updateLogState({
      ...logState,
      selectedTaskIds: ids,
      allTasksSelected: allTasksSelected === "" ? false : allTasksSelected,
      selectAll: type?.toLowerCase() === "all" ? value : allChecked
    });
  };

  const getInit = () => {
    setTableLoading(true);
    setFilterLoading(true);
    resetFilterState();
    if (!taskEnabled) {
      PaywallUtils.upgradeUtilsFunction(
        "taskPage",
        false,
        planName,
        inFreePlan
      );
      upgradePopupState.setClickOutsideClose(false);
    }
    Utils.mixpanelTrack(MP_EVENT.TK_PAGE_VIEWED, { pageType: "Tasks" });
  };

  const contactCardObj = {
    pageType: "Tasks Page",
    reloadTaskPage: true,
    contact: selectedContact,
    isContactSlider: true,
    closeCbk: closeContactCard,
    handleSuccessCallback: reloadTaskPage,
    cardType: "tasks",
    isReloadCbk: reloadTaskPage
  };

  const handleTabFilterAction = (event = "") => {
    event && Utils.preventDefaultFn(event);
    if (event) {
      let listElem = event?.target?.closest("li") || "";
      if (listElem) {
        let key = listElem?.getAttribute("data-key") || "";
        if (key) {
          const today = new Date();
          let tomorrow = new Date();
          // Add a day
          tomorrow.setDate(tomorrow.getDate() + 1);
          let filterPayload = {
            status: ["due", "upcoming"].includes(key?.toLowerCase())
              ? "ACTIVE"
              : key?.toUpperCase() || "ACTIVE"
          };
          if (key?.toLowerCase() === "upcoming") {
            filterPayload = {
              ...filterPayload,
              key,
              filterFromDate: Utils.convertFromDateToLocalString(tomorrow)
            };
          }
          if (key?.toLowerCase() === "due") {
            filterPayload = {
              ...filterPayload,
              key,
              filterToDate: Utils.convertToDateToLocalString(today)
            };
          }
          Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
            pageType: Utils.getPageType() || "",
            status: key
          });
          taskStoreData.setStatus(key);
          taskStoreData.setActiveStatusTab(key);
          getFilterByStatus(filterPayload);
        }
      }
    }
  };
  const tabFilterObj = {
    type: "tasks",
    filterArr: [...filterTabData],
    navBarLoading: btnLoader,
    activeTab: taskStoreData?.activeStatusTab || "due",
    filterAccountsCbk: handleTabFilterAction
  };

  useEffect(() => {
    updateLogState({ ...initialState });
    updateTasksState(taskStoreData?.taskData || {});
    getAnalyticsFilterData(!taskStoreData?.dateFilterValue?.filterApplied);
  }, [taskStoreData.taskData]);

  useEffect(() => {
    if (logState?.selectedTaskIds?.length > 0) {
      updateLogState({ ...initialState });
    }
  }, [taskStoreData?.activeStatusTab, activeTab]);

  useEffect(() => {
    constructFilterRenderData(taskStoreData.taskFilterData);
  }, [taskStoreData.taskFilterData]);

  useEffect(() => {
    getInit();
    return () => {
      resetSelectedCheckbox();
      taskStoreData.setActiveDueTab("allTask");
      taskStoreData.setActiveStatusTab("due");
      upgradePopupState.setClickOutsideClose(true);
      upgradePopupState.setPopup(false);
      updateLogState({ ...initialState });
      setEditTasksLoader(false);
      setSelectedTask(null);
    };
  }, []);

  useEffect(() => {
    const initialOptions = {};
    TASK_TYPE_LIST.forEach(task => {
      initialOptions[task?.id] = false;
    });
    setSelectedOptions(initialOptions);
  }, []);

  return (
    <div className={`taskPage pageClass ${!taskEnabled ? "blurBg" : ""}`}>
      {logState?.showEditTaskPopup && (
        <EditBulkTasks
          logState={logState}
          initialState={initialState}
          editTasksLoader={editTasksLoader}
          updateLogState={updateLogState}
          action={performBulkAction}
          setEditTasksLoader={setEditTasksLoader}
        />
      )}
      <div className="taskCont pageCont">
        <section className="headerSection">
          <BreadCrumbComponent breadCrumbValue={defaultBreadValue} />
          <div className="headerBtnContainer">
            <KnowMoreButton
              redirectUrl={
                "https://support.salesgear.io/en/articles/5646640-how-do-you-create-tasks-in-salesgear"
              }
            />
            <div className="add-new-dropdown addStepsButton">
              <AddStepBtn
                cbk={createTaskCbk}
                flag="task"
                isTaskCreation={true}
              />
            </div>
          </div>
        </section>
        {!productDiscount && <PricingDiscountBanner />}
        <section
          className={`bodySection ${!productDiscount ? "productDiscount" : ""}`}
        >
          <InsertContactCard
            animate={slideAnim}
            show={showContactCard}
            data={contactCardObj}
          />
          <div className="layoutBody">
            <div className="filterContents seq-contact-nav-section">
              <FilterNavBar {...tabFilterObj} />
            </div>
            <div
              className={`taskSectionWrapperCont ${
                taskStoreData.openFilter
                  ? "taskSectionWrapperGrid"
                  : "displayContents"
              } `}
            >
              <div
                className={`taskSectionContainer ${
                  taskData?.totalPages > 1 ? "" : "hidePagination"
                }`}
              >
                <div className="filterTopHeader">
                  <div className="taskTopHeader">
                    {logState?.selectedTaskIds?.length <= 0 ? (
                      <>
                        <div className="headerContents">
                          {!btnLoader &&
                            !tableLoading &&
                            taskCount > 0 &&
                            taskStoreData?.statusFilterValue?.status?.toUpperCase() ===
                              "ACTIVE" && (
                              <>
                                <ButtonComponent {...startTaskBtnObj} />
                              </>
                            )}
                          {(btnLoader || tableLoading) && (
                            <div className="startAllTaskloader linear-background" />
                          )}
                        </div>
                        {!btnLoader &&
                          taskStoreData?.statusFilterValue?.status?.toUpperCase() ===
                            "ACTIVE" &&
                          taskStoreData?.activeStatusTab?.toLowerCase() !==
                            "upcoming" && (
                            <FilterByDateTab
                              activeDueTab={taskStoreData?.activeDueTab}
                              setActiveDueTab={taskStoreData.setActiveDueTab}
                              cbk={getFilterByDate}
                            />
                          )}
                      </>
                    ) : (
                      <div className="bulkActionCont">
                        {!btnLoader &&
                          logState?.selectedTaskIds?.length > 0 && (
                            <ContactSelectedCount
                              selectedCount={
                                logState?.selectedTaskIds?.length || 0
                              }
                              totalCount={taskStoreData?.taskData?.totalTasks}
                              loading={btnLoader}
                              allContactSelected={logState?.allTasksSelected}
                              setAllContactSelected={val =>
                                handleInputCheckboxAction(val, "all", val)
                              }
                              resetCbk={resetSelectedCheckbox}
                              selectedType="Task"
                              pageType="task"
                            />
                          )}
                        <BulkActionButton
                          action={handleBulkActionConfirmPrompt}
                          logState={logState}
                          updateLogState={updateLogState}
                        />
                      </div>
                    )}
                    {(btnLoader || tableLoading) &&
                      ["due"].includes(activeTab) && <DateFilterLoader />}
                    <div
                      className={`filterIconWraper ${
                        taskStoreData.openFilter ? "showFilter" : ""
                      }`}
                      onClick={() =>
                        taskStoreData.setOpenFilter(!taskStoreData.openFilter)
                      }
                    >
                      <FilterIcon />
                    </div>
                  </div>
                </div>
                <TaskTable
                  taskData={taskData}
                  setTaskData={setTaskData}
                  loading={tableLoading}
                  tableLoadingFn={setTableLoading}
                  filterLoadingFn={setFilterLoading}
                  activeTab={taskStoreData.filter}
                  openContactCard={openContactCard}
                  logState={logState}
                  handleInputCheckboxAction={handleInputCheckboxAction}
                  singleTaskAction={handleBulkActionConfirmPrompt}
                  showTaskTypeDropdown={showTaskTypeDropdown}
                  setShowTaskTypeDropdown={setShowTaskTypeDropdown}
                  selectedTask={
                    selectedTask?.toLowerCase() === "total"
                      ? "All"
                      : selectedTask
                  }
                  taskTypeList={[...TASK_TYPE_LIST]}
                  reloadAccountFilterAnalytics={() =>
                    getAnalyticsFilterData(
                      !taskStoreData?.dateFilterValue?.filterApplied
                    )
                  }
                  selectedOptions={selectedOptions}
                  setSelectedOptions={setSelectedOptions}
                />
              </div>
              {taskStoreData.openFilter && (
                <AllFilters
                  tableLoadingFn={setTableLoading}
                  filterLoadingFn={setFilterLoading}
                />
              )}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default withRouter(observer(Tasks));
export { Tasks };
