/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import PricingPayment from "Pages/Dashboard/pricing/PaymentForm/PricingPayment";
import PaymentSuccess from "Pages/Dashboard/pricing/PaymentSuccess";
import userDetail from "Stores/userDetail";
import PricingV2 from "../Pages/Dashboard/pricing/PricingV2/PricingV2";
import PricingFeatureTable from "../Pages/Dashboard/pricing/PricingFeatureTable";

const PricingRouting = () => {
  const { team } = userDetail.userInfo;

  const [adminTeamUser, setAdminTeamUser] = useState(team);

  const { subscriptionPlanId } = userDetail.userFeatureFlag;
  const [paidCustomer, setPaidCustomer] = useState(subscriptionPlanId);

  const checkPaidUser = () => {
    let proceedToPay =
      localStorage.proceedToPay !== undefined
        ? localStorage.getItem("proceedToPay")
        : false;
    if ((paidCustomer !== null && !proceedToPay) || !adminTeamUser) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    setPaidCustomer(userDetail.userFeatureFlag.subscriptionPlanId);
  }, [userDetail.userFeatureFlag.subscriptionPlanId]);

  useEffect(() => {
    setAdminTeamUser(userDetail?.userInfo?.team);
  }, [userDetail.userInfo.team]);

  return (
    <Switch>
      {checkPaidUser() && (
        <Route
          exact
          path="/pricing/success"
          render={() => <PaymentSuccess />}
        />
      )}
      {checkPaidUser() && (
        <Route
          exact
          path="/pricing/payment"
          render={() => <PricingPayment />}
        />
      )}
      <Route exact path="/pricing/" render={() => <PricingV2 />} />
      <Redirect from="/" to="/pricing" />
    </Switch>
  );
};
export default withRouter(PricingRouting);
