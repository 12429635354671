/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import PricingStore from "Stores/Pricing";
import PricingTableContent from "./../PricingTableContent";
import { withRouter } from "react-router-dom";
import SeatsPurchaseBilling from "./SeatsPurchaseBilling";
import StripeBillingCard from "./StripeBillingCard";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";

const PricingPayment = props => {
  const { displayName = "", annual = false } = PricingStore.paymentFormInfo;
  const [planName, setPlanName] = useState(displayName);
  const [planType, setPlanType] = useState(annual);

  const backToPricingTable = () => {
    props.history.push("pricing");
  };

  useEffect(() => {
    const { displayName = "", annual = false } = PricingStore.paymentFormInfo;
    setPlanName(displayName);
    setPlanType(annual);
  }, [PricingStore.paymentFormInfo]);

  useEffect(() => {
    Utils.mixpanelTrack(MP_EVENT.CHECKOUT_PAGE_VIEWED, {
      pageType: "Pricing",
      planType: displayName
    });
  });

  return (
    <div className="pricingBody">
      <div className="pricingCard">
        <PricingTableContent />
        <div className="pricingContentBody">
          <div className="wrapPlanNameType">
            <div className="backBtn" onClick={backToPricingTable}>
              <i className="material-icons">keyboard_backspace</i>
              <span>Back</span>
            </div>
            <div className="planNameType">
              {planName} - {planType ? "Annual" : "Monthly"}
            </div>
          </div>
          <div className="pricingPaymentSection">
            <SeatsPurchaseBilling />
            <StripeBillingCard />
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(observer(PricingPayment));
export { PricingPayment };
