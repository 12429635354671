import React, { useState, useEffect } from "react";
import userDetail from "Stores/userDetail";
import PricingStore from "Stores/Pricing";
import {
  WEBSITE_URL,
  PopularPlanName,
  FREQ_ASK_QUESTIONS
} from "Static/constant";
import { ReactComponent as TickIcon } from "./images/roundedTick.svg";
import { ReactComponent as EnterpriseIcon } from "./images/enterPriseLeft.svg";
import GmailImg from "Assets/Pricing/gmail.png";
import HubspotImg from "Assets/Pricing/hubspot.png";
import PipeDriveImg from "Assets/Pricing/pipedrive.png";
import SalesforceImg from "Assets/Pricing/salesforce.png";
import ZohoImg from "Assets/Pricing/zoho.png";
import CollapsiveContent from "./CollapsiveContent";
import PricingPlans from "./PricingPlans";
import FeatureComparisonTable from "./FeatureComparisonTable";
import ToggleContext from "./context/ToggleContext";
import Clients from "./Clients";
import Testimonials from "./Testimonials";

const superChargeList = ["No Contracts", "No Obligations", "Cancel any time"];

const CrmIconList = [
  { id: "gmailCrm", img: GmailImg },
  { id: "sfCrm", img: SalesforceImg },
  { id: "hsCrm", img: HubspotImg },
  { id: "pdCrm", img: PipeDriveImg },
  { id: "zohoCrm", img: ZohoImg }
];

const PricingV2 = (props = {}) => {
  const { isLogoutPricing = false } = props || {};
  const [annualDiscount, setAnnualDiscount] = useState("");
  const [toogleType, setToogleType] = useState(
    PricingStore.paymentFormInfo.annual
  );
  const [planDetails, setPlanDetails] = useState(PricingStore.activePlanData);
  const {
    subscriptionPlanId,
    isAnnual,
    planName,
    teamStatus,
    inFreePlan = false,
    calendarIntegrationEnabled = false
  } = userDetail?.userFeatureFlag || {};
  const [isChecked, setIsChecked] = useState(false);

  const [subscriptionId, setSubscriptionId] = useState(subscriptionPlanId);

  const constructActivePlanDetails = planData => {
    if (planData && planData.length) {
      planData.map(value => {
        if (value?.name && isPopularPlan(value.name)) {
          value.isSelectedPlan = true;
          setAnnualDiscount(20);
        } else {
          value.isSelectedPlan = false;
        }
      });
      setPlanDetails(planData);
    }
  };

  const getTogglePlanType = value => {
    PricingStore.setPaymentFormInfo({ annual: value });
    setToogleType(value);
    setAnnualDiscount(20);
  };

  const isPopularPlan = planName => {
    if (
      planName &&
      PopularPlanName &&
      planName?.toLowerCase() === PopularPlanName?.toLowerCase()
    ) {
      return true;
    }
  };

  const triggerFeatureEvent = id => {
    if (id === "email") {
      window.location.href = "mailto:sales@salesgear.io";
    } else if (id === "questions") {
      window.location.hash = "customPricing";
    }
  };

  useEffect(() => {
    constructActivePlanDetails(PricingStore.activePlanData);
  }, [PricingStore.activePlanData]);

  useEffect(() => {
    setSubscriptionId(userDetail.userFeatureFlag.subscriptionPlanId);
  }, [userDetail.userFeatureFlag.subscriptionPlanId]);

  return (
    <ToggleContext.Provider value={{ isChecked, setIsChecked }}>
      <div
        className={`pricingPageSection ${
          isLogoutPricing ? "logoutPricing" : "loginPricing"
        }`}
      >
        <div className="pricingWrapper">
          <div className="pricingContainer">
            <div className="pricingTableSection">
              <div className="pricingTextSection">
                <div className={`sectionContainer container-fluid`}>
                  <h1
                    className={`title mb-0 mb-md-1`}
                  >{`Plan for Every Stage of Your Sales`}</h1>
                  <p className={`desc mb-3 mb-md-4`}>
                    {`Whether you're just starting out or scaling to new heights, our plans grow with you. All features included—tailored usage for your needs.`}
                  </p>
                  <div className="flexBtnWrapper">
                    <a
                      href="#comparePlans"
                      className={`comparePlans align-items-center justify-content-center`}
                      rel="noreferrer noopener"
                    >
                      Compare Plans
                    </a>
                  </div>
                  <PricingPlans
                    planData={planDetails}
                    subscriptionId={subscriptionId}
                  />
                  <div className="enterprisePlanSection">
                    <div className="enterpriseContainer container">
                      <div className="leftEnterpriseSection">
                        <EnterpriseIcon />
                      </div>
                      <div className="rightEnterpriseSection">
                        <p className="desc">
                          Have any questions related to pricing?
                        </p>
                        <p className="subDesc">
                          We'd love to help you get started.
                        </p>
                        <div
                          className="contactUsBtn"
                          onClick={() => triggerFeatureEvent("email")}
                        >
                          Contact us
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clientsWrapper">
                    <Clients />
                  </div>
                  <div className="featureComparision">
                    <FeatureComparisonTable
                      planData={planDetails}
                      subscriptionId={subscriptionId}
                    />
                  </div>
                  <div className="testimonialWrapper">
                    <Testimonials name="infront" />
                  </div>
                  <div className="crmSection">
                    <h4 className="title">Works with all popular CRMs</h4>
                    <div className=" crmIconList">
                      {CrmIconList.map(item => {
                        return (
                          <div className="crmIcon" key={item.id}>
                            <img src={item.img} alt="" />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="freqAskQuesSection">
                    <h4 className="title">Frequently Asked Questions</h4>
                    <div className="freqAskQuesRow">
                      <div className="contentCol">
                        {FREQ_ASK_QUESTIONS.map((item, index) => {
                          return (
                            <CollapsiveContent
                              key={`freqAskQuestion${index + 1}`}
                              title={item.title}
                              content={item.description}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ToggleContext.Provider>
  );
};

export { PricingV2 };
export default PricingV2;
