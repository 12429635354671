/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from "react";
import { observer } from "mobx-react";
import { reportStore, defaultCustomDate } from "Stores/Reports";
import { toasterState } from "Components/common/toaster";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import DateUtils from "Utils/DateUtils";
import { MP_EVENT } from "Static/constant";
import { DATE_FILTER_LIST } from "Static/constant";
import UserFilterDropDown from "./UserFilterDropdown";
import DateRangePicker from "./DateRangePicker";
import { ReportFilterContext } from "./ReportsModule";

const ReportsFilter = () => {
  const { teamLoader, isContactsAdded, inFreePlan, isSummary, pageType } =
    useContext(ReportFilterContext);

  const {
    team = false,
    memberId = "",
    hasTeamMember = false,
    isTeamOwner = false
  } = userDetail?.userInfo || {};

  const [customDate, setCustomDate] = useState(reportStore.customDate);
  const [activeDateFilter, setActiveDateFilter] = useState("week");
  const [previousDateFilter, setPreviousDateFilter] = useState("week");
  const [selectedUser, setSelectedUser] = useState("all");
  const [showDatePicker, setDatePicker] = useState(false);
  const [customDateValue, setCustomDateValue] = useState("Custom");
  const [customDateFormat, setCustomDateFormat] = useState("");

  const dateObjFunction = {
    today: 0,
    week: 6, // Doesn't include current day of the week
    month: 29, // Doesn't include current day of the month
    yesterday: 1,
    customDate: "custom"
  };

  const limit = 10;

  const getSelectedUserPayload = data => {
    return typeof data === "object"
      ? data?.length && data
      : data?.length && data.toLowerCase() === "you"
      ? new Array(memberId)
      : "";
  };

  const makeFilterRequest = (payload, user, obj) => {
    const selectedIds = getSelectedUserPayload(user);
    if (!team) {
      payload.memberIds = [memberId];
    } else if (selectedIds?.length) {
      payload.memberIds = selectedIds;
    }
    obj &&
      Object.keys(obj).length &&
      reportStore.setFilterPayload({ ...payload, ...obj });
    filterByDateTime(payload);
  };

  const resetDateFilterValue = value => {
    if (activeDateFilter === "customDate" && value !== "customDate") {
      setCustomDateValue("Custom");
      setCustomDateFormat("");
      setCustomDate(defaultCustomDate);
      reportStore.setCustomDate(defaultCustomDate);
    }
    setPreviousDateFilter(value);
  };

  const getTabValue = value => {
    !isSummary && activeDateFilter !== value && reportStore.setCurrentPage(0);
    resetDateFilterValue(value);
    setActiveDateFilter(value);
    const {
      filterFromDate = "",
      filterToDate = "",
      timeZone = ""
    } = Utils.getFromToDateTimeStamp(value) || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate, timeZone };
    const payload = value !== "custom" && dateFilter;
    Utils.mixpanelTrack(MP_EVENT.REPORT_FILTERED_BY_USER, {
      filterType: value,
      pageType
    });
    makeFilterRequest(payload, selectedUser, {
      value,
      days: dateObjFunction[value]
    });
  };

  const getUserFilterPayload = () => {
    if (activeDateFilter === "customDate") {
      const { startDate, endDate } = reportStore.customDate?.[0] || false;
      Utils.mixpanelTrack(MP_EVENT.REPORT_FILTERED_BY_USER, { pageType });
      return {
        filterFromDate: Utils.convertFromDateToLocalString(startDate),
        filterToDate: Utils.convertToDateToLocalString(endDate),
        timeZone: Utils.getCurrentUserTimeZone()
      };
    } else {
      return Utils.getFromToDateTimeStamp(activeDateFilter);
    }
  };

  const getSelectedUser = value => {
    !isSummary && reportStore.setCurrentPage(0);
    setSelectedUser(value);
    makeFilterRequest(getUserFilterPayload(), value, false);
  };

  const constructCustomDatePayload = (start, end) => {
    if (!isSummary && Utils.getDateDiffNumber(start, end) > 91) {
      toasterState.setToastMsg(
        "The from and to dates cannot be more than 90 days apart",
        "info"
      );
      return;
    }
    !isSummary && reportStore.setCurrentPage(0);
    let fromDate = DateUtils.getTimeStamp(start, "start") || "";
    let toDate = DateUtils.getTimeStamp(end, "end") || "";
    let payload = { fromDate, toDate };
    Utils.mixpanelTrack(MP_EVENT.REPORT_FILTERED_BY_USER, {
      filterType: "Custom Date",
      pageType
    });
    makeFilterRequest(payload, selectedUser, {
      value: "customDate",
      days: Utils.getDateDiffNumber(start, end)
    });
    setDatePicker(false);
    let dateValue =
      (start &&
        end &&
        `${Utils.formatDate(start)} -  ${Utils.formatDate(end)}`) ||
      "Custom";
    setCustomDateValue(dateValue);
    setCustomDateFormat({ start, end });
  };

  const getCustomDatePayload = () => {
    const { startDate, endDate } = reportStore.customDate?.[0] || false;
    startDate &&
      endDate &&
      activeDateFilter === "customDate" &&
      constructCustomDatePayload(startDate, endDate);
  };

  const openDatePicker = () => {
    if (isContactsAdded) {
      setDatePicker(!showDatePicker);
      setPreviousDateFilter(activeDateFilter);
      setActiveDateFilter("customDate");
    }
  };

  const updateCustomDateValue = () => {
    const { start = new Date(), end = null } = customDateFormat || {};
    reportStore.setCustomDate([
      {
        endDate: end,
        startDate: start,
        key: "selection"
      }
    ]);
  };

  const hideDatePicker = () => {
    setDatePicker(!showDatePicker);
    customDateValue === "Custom"
      ? reportStore.setCustomDate(defaultCustomDate)
      : updateCustomDateValue();
    setActiveDateFilter(previousDateFilter);
  };

  const resetToInitalState = () => {
    reportStore.setCustomDate(defaultCustomDate);
    setActiveDateFilter("yesterday");
    setPreviousDateFilter("yesterday");
    setSelectedUser("all");
    setDatePicker(false);
    setCustomDateValue("Custom");
    setCustomDateFormat("");
  };

  const getSummaryReport = (payload = {}) => {
    reportStore.setSummaryLoader(true);
    reportStore.getSummaryData(payload);
  };

  const getSequenceInsightReport = data => {
    reportStore.setSequenceInsightLoader(true);
    const { sortParam = "SENT_COUNT", direction = "DESC" } =
      reportStore.sortObj || {};
    const payload = {
      ...data,
      pageNo: reportStore.currentPage || 1,
      limit,
      sortParam,
      direction
    };
    reportStore.getSequenceInsightsData(payload);
  };

  const getEmailInsightReport = (payload = {}) => {
    reportStore.setEmailInsightLoader(true);
    reportStore.getEmailInsightData(payload);
  };

  const getInsightData = (payload = {}) => {
    getSequenceInsightReport(payload);
    getEmailInsightReport(payload);
  };

  const filterByDateTime = (payload = {}) => {
    isSummary ? getSummaryReport(payload) : getInsightData(payload);
  };

  const getCustomDatePayloadObj = () => {
    const { startDate = "", endDate = "" } =
      reportStore.customDate?.[0] || false;
    if (startDate && endDate) {
      if (!isSummary && Utils.getDateDiffNumber(startDate, endDate) > 91) {
        reportStore.setCustomDate(defaultCustomDate);
        setCustomDateValue("Custom");
        setActiveDateFilter("month");
        setPreviousDateFilter("yesterday");
        return Utils.getFromToDateWithTimeZoneByDaysCount(89); // Indicates month(30 days) but current reduce by one
      } else {
        return {
          filterFromDate: Utils.convertFromDateToLocalString(startDate),
          filterToDate: Utils.convertToDateToLocalString(endDate),
          timeZone: Utils.getCurrentUserTimeZone()
        };
      }
    }
    return {};
  };

  const getInitialReportData = () => {
    const payload = constructFilterPayload();
    if (payload && Object.keys(payload).length) {
      isSummary ? getSummaryReport(payload) : getInsightData(payload);
      Utils.mixpanelTrack(MP_EVENT.RP_VIEWED, {
        pageType: isSummary ? "Summary Page" : "Email Insights Page"
      });
    }
  };

  const constructFilterPayload = () => {
    const {
      filterFromDate = "",
      filterToDate = "",
      timeZone = ""
    } = Utils.getFromToDateTimeStamp(activeDateFilter) || {};
    let fromDate = DateUtils.getTimeStamp(filterFromDate, "start") || "";
    let toDate = DateUtils.getTimeStamp(filterToDate, "end") || "";
    let dateFilter = { fromDate, toDate, timeZone };
    const payload =
      activeDateFilter !== "customDate"
        ? dateFilter
        : getCustomDatePayloadObj();
    const selectedIds = getSelectedUserPayload(selectedUser);
    if (!team) {
      payload.memberIds = [memberId];
    } else if (selectedIds?.length) {
      payload.memberIds = selectedIds;
    }
    return payload || {};
  };

  const handleCustomDate = value => {
    reportStore.setCustomDate(value);
  };

  useEffect(() => {
    getSequenceInsightReport(constructFilterPayload());
  }, [reportStore.sortObj]);

  useEffect(() => {
    reportStore?.currentPage > 0 &&
      getSequenceInsightReport(constructFilterPayload());
  }, [reportStore.currentPage]);

  useEffect(() => {
    setCustomDate(reportStore.customDate);
  }, [reportStore.customDate]);

  useEffect(() => {
    return () => {
      resetToInitalState();
    };
  }, []);

  useEffect(() => {
    getInitialReportData();
  }, [isSummary]);

  return (
    <div className="reportsFilter">
      {((team && hasTeamMember?.length > 1) || isTeamOwner) && !teamLoader && (
        <UserFilterDropDown team={team} selectedUserCbk={getSelectedUser} />
      )}
      <div className="dateFilter">
        {DATE_FILTER_LIST?.length &&
          DATE_FILTER_LIST.map(item => {
            const { name = "", id = "", value = "" } = item || {};
            return (
              <div
                className={`tabItem ${
                  activeDateFilter === value ? "active" : ""
                }`}
                id={id}
                key={id}
                onClick={() =>
                  value === "customDate" ? openDatePicker() : getTabValue(value)
                }
              >
                {name === "Custom" && (
                  <span className="material-icons-outlined dateRange">
                    date_range
                  </span>
                )}
                <span>{name === "Custom" ? customDateValue : name}</span>
              </div>
            );
          })}
      </div>
      {showDatePicker && (
        <DateRangePicker
          className="dateRangePicker"
          customDate={customDate}
          filterCbk={getCustomDatePayload}
          cancelCbk={hideDatePicker}
          inFreePlan={inFreePlan}
          pageType="reports"
          upgradePopupName="reportsCustomDate"
          handleCustomDate={handleCustomDate}
          customDateObj={reportStore.customDate?.[0] || false}
        />
      )}
      {showDatePicker && (
        <div className="overlayCustomDateFilter" onClick={hideDatePicker} />
      )}
    </div>
  );
};

export default observer(ReportsFilter);
export { ReportsFilter };
