import React, { useEffect, useState } from "react";
import { action, observable } from "mobx";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import {
  TASK_DATE_FILTER_LIST,
  ACCOUNT_TASK_DATE_FILTER_LIST
} from "Static/constant";
import userDetail from "Stores/userDetail";
import DateRangePicker from "Pages/Dashboard/reports/DateRangePicker";
import { toasterState } from "./toaster";
import { MXP_EVENT } from "Static/MixPanelEvents";
import trackingStore from "Pages/Tracking/trackingStore";

export const defaultCustomDate = [
  {
    startDate: new Date(),
    endDate: null,
    key: "selection"
  }
];

class FilterByDateState {
  @observable customDate = defaultCustomDate;

  @action
  setCustomDate = val => {
    this.customDate = val;
  };
}

export const filterByDateState = new FilterByDateState();

const FilterByDateTab = observer((props = {}) => {
  const {
    cbk = () => {},
    activeDueTab = "allTask",
    setActiveDueTab = () => {},
    isTaskPage = true,
    disableFilter = false,
    pageType = ""
  } = props || {};

  let filterList =
    pageType === "ACCOUNT_TASK"
      ? ACCOUNT_TASK_DATE_FILTER_LIST
      : TASK_DATE_FILTER_LIST;

  const { team = false, memberId = "" } = userDetail?.userInfo || {};

  const [showDatePicker, setDatePicker] = useState(false);
  const [customDateValue, setCustomDateValue] = useState("Custom");
  const [customDateFormat, setCustomDateFormat] = useState("");

  const filterByDueDate = (key = "") => {
    resetDateFilterValue();
    if (key) {
      const today = new Date();
      let filterPayload = {};
      setActiveDueTab(key);
      Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
        dateFilter: key
      });
      switch (key) {
        case "today":
          filterPayload = {
            filterFromDate: Utils.convertFromDateToLocalString(today),
            filterToDate: Utils.convertToDateToLocalString(today)
          };
          break;
        case "yesterday":
          const yesterdayObj = Utils.getFromToDateWithTimeZoneByDaysCount(1);
          filterPayload = {
            filterFromDate: yesterdayObj?.filterFromDate,
            filterToDate: yesterdayObj?.filterToDate
          };
          break;
        case "week":
          const sevenDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(6);
          filterPayload = {
            filterFromDate: sevenDateObj?.filterFromDate,
            filterToDate: sevenDateObj?.filterToDate
          };
          break;
        case "month":
          const thirtyDateObj = Utils.getFromToDateWithTimeZoneByDaysCount(29);
          filterPayload = {
            filterFromDate: thirtyDateObj?.filterFromDate,
            filterToDate: thirtyDateObj?.filterToDate
          };
          break;
        case "allTask":
          filterPayload = {
            filterToDate: Utils.convertToDateToLocalString(today)
          };
          break;
        default:
          return {};
      }
      if (filterPayload) {
        filterPayload.timeZone = Utils.getCurrentUserTimeZone();
        cbk(filterPayload);
      }
    }
  };

  const getSelectedUserPayload = data => {
    return typeof data === "object"
      ? data?.length && data
      : data?.length && data.toLowerCase() === "you"
      ? new Array(memberId)
      : "";
  };

  const makeFilterRequest = (payload, user, obj) => {
    const selectedIds = getSelectedUserPayload(user);
    if (!team) {
      payload.memberIds = [memberId];
    } else if (selectedIds?.length) {
      payload.memberIds = selectedIds;
    }
    cbk(payload);
  };

  const resetDateFilterValue = () => {
    setCustomDateValue("Custom");
    setCustomDateFormat("");
    filterByDateState.setCustomDate(defaultCustomDate);
  };

  const constructCustomDatePayload = (start, end) => {
    if (Utils.getDateDiffNumber(start, end) > 91) {
      toasterState.setToastMsg(
        "The from and to dates cannot be more than 90 days apart",
        "info"
      );
      return;
    }
    const payload = {
      filterFromDate: Utils.convertFromDateToLocalString(start),
      filterToDate: Utils.convertToDateToLocalString(end),
      timeZone: Utils.getCurrentUserTimeZone()
    };
    Utils.mixpanelTrack(MXP_EVENT.TASK_FILTER_CLICKED, {
      dateFilter: "customDate",
      pageType: "task page"
    });
    makeFilterRequest(payload, activeDueTab, {
      value: "customDate",
      days: Utils.getDateDiffNumber(start, end)
    });
    setDatePicker(false);
    let dateValue =
      (start &&
        end &&
        `${Utils.formatDate(start)} - ${Utils.formatDate(end)}`) ||
      "Custom";
    setCustomDateValue(dateValue);
    if (["websitetracking"].includes(pageType?.toLowerCase())) {
      trackingStore.setCustomDate(dateValue);
    }
    setCustomDateFormat({ start, end });
  };

  const getCustomDatePayload = () => {
    const { startDate, endDate } = filterByDateState?.customDate?.[0] || {};
    startDate &&
      endDate &&
      activeDueTab === "customDate" &&
      constructCustomDatePayload(startDate, endDate);
  };

  const updateCustomDateValue = () => {
    const { start = new Date(), end = null } = customDateFormat || {};
    filterByDateState.setCustomDate([
      {
        endDate: end,
        startDate: start,
        key: "selection"
      }
    ]);
  };

  const hideDatePicker = () => {
    setDatePicker(!showDatePicker);
    customDateValue === "Custom"
      ? filterByDateState.setCustomDate(defaultCustomDate)
      : updateCustomDateValue();
  };

  const handleCustomDate = (value = []) => {
    filterByDateState.setCustomDate([...value]);
  };

  const openDatePicker = (key = "") => {
    setActiveDueTab(key);
    setDatePicker(!showDatePicker);
  };

  useEffect(() => {
    if (["websitetracking"].includes(pageType?.toLowerCase())) {
      setCustomDateValue(trackingStore?.customDate);
    }
  }, [pageType]);

  useEffect(() => {
    return () => {
      if (pageType !== "ACCOUNT_TASK") {
        setActiveDueTab("allTask");
        resetDateFilterValue();
      }
    };
  }, []);

  return (
    <div className={`filterByDateTab ${disableFilter ? "disabled" : ""}`}>
      {isTaskPage && <span className="filterTitle">Due:</span>}
      <div className="dateFilter">
        {[...filterList]?.length &&
          [...filterList].map((item, index) => {
            if (isTaskPage ? index >= 0 : index >= 1) {
              const { name = "", id = "", key = "" } = item;
              return (
                <div
                  className={`tabItem ${activeDueTab === key ? "active" : ""} `}
                  id={id}
                  key={key}
                  onClick={() =>
                    key === "customDate"
                      ? openDatePicker(key)
                      : filterByDueDate(key)
                  }
                >
                  {name === "Custom" && (
                    <i className="material-icons-outlined dateRange">
                      date_range
                    </i>
                  )}
                  <span className="text">
                    {name?.toLowerCase() === "custom" ? customDateValue : name}
                  </span>
                </div>
              );
            }
          })}
      </div>
      {showDatePicker && (
        <DateRangePicker
          className="dateRangePicker"
          customDate={filterByDateState?.customDate}
          filterCbk={getCustomDatePayload}
          cancelCbk={hideDatePicker}
          pageType="task page"
          upgradePopupName="reportsCustomDate"
          handleCustomDate={handleCustomDate}
          customDateObj={filterByDateState.customDate?.[0] || false}
        />
      )}
      {showDatePicker && (
        <div className="overlayCustomDateFilter" onClick={hideDatePicker} />
      )}
    </div>
  );
});

export default FilterByDateTab;
