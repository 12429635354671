/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import dialerStoreData from "Stores/DialerData";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import { ReactComponent as StarIcon } from "Assets/svg/star.svg";
import { ReactComponent as DeleteIcon } from "Assets/svg/bin.svg";
import YellowStarIcon from "Assets/png/yellowStar.png";
import userDetail from "Stores/userDetail";
import Utils from "Utils/utils";
import { withRouter } from "react-router-dom";
import { toasterState } from "Components/common/toaster";
import { makeApi, URLS } from "Utils/apiURL";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { buyNewNumberPopupState } from "./BuyNewNumberPopup";
import { buyCreditsPopupState } from "./BuyCreditsPopup";
import { transactionAnimationState } from "Components/common/TransactionAnimation";
import twinklingStar from "Utils/LottieFiles/twinklingStar.json";
import Lottie from "react-lottie";
import { MP_EVENT } from "Static/constant";
import { addNumberPopupState } from "./AddNumberPopup";
import { addMyOwnNumberPopupState } from "./AddMyOwnNumberPopup";
import { verifyNumberPopupState } from "./VerifyNumberPopup";
import DialerUpgradePopup from "./DialerUpgradePopup";
import DialerLoadingCards from "./DialerLoadingCards";
import ToggleBtnType from "Components/common/ToggleBtnType";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import CustomTooltip from "Components/common/CustomTooltip";
import LimitErr from "Components/PreviewPopup/LimitErr";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const Dialer = observer(props => {
  const history = useHistory();
  let isAdminUser = Utils.isAdmin();
  const {
    dialerEnabled = false,
    subscriptionPlanId = "",
    recordingEnabled = ""
  } = userDetail.userFeatureFlag || {};

  let callRecordingEnabled =
    dialerEnabled && dialerStoreData.callRecordingEnabled;

  const [loading, setLoading] = useState(true);
  const [myPhoneNumbers, setMyphoneNumbers] = useState([]);
  const [defaultNumber, setDefaultNumber] = useState("");
  const [callRecording, setCallRecording] = useState(callRecordingEnabled);
  const [callRecordLoader, setCallRecordLoader] = useState(
    recordingEnabled === ""
  );

  const constructPhoneNumberDetails = data => {
    if (data?.defaultPhNumber && data?.phoneNumbers) {
      const { phoneNumbers, defaultPhNumber } = data;
      const numberWithDetails = addstarAttributeWithResponse(phoneNumbers);
      setMyphoneNumbers(numberWithDetails);
      setDefaultNumber(defaultPhNumber);
    } else {
      setMyphoneNumbers([]);
      setDefaultNumber("");
    }
    setLoading(false);
  };

  const addstarAttributeWithResponse = numbers => {
    if (numbers?.length) {
      const tempArr = [];
      numbers.map(phNumber => {
        const {
          number,
          numberType,
          nextRenewalDate = "",
          freePurchase = false
        } = phNumber;
        const tempObj = {
          starClicked: false,
          value: number,
          type: numberType,
          nextRenewalDate: nextRenewalDate,
          freePurchase
        };
        tempArr.push(tempObj);
      });
      return tempArr;
    }
  };

  const toggleStarFlag = number => {
    if (number) {
      myPhoneNumbers.map(obj => {
        if (obj?.value === number) {
          obj.starClicked = !obj.starClicked;
        }
      });
      setMyphoneNumbers(JSON.parse(JSON.stringify(myPhoneNumbers)));
    }
  };

  const renderPhoneNumbers = () => {
    return loading ? (
      <DialerLoadingCards cards={4} />
    ) : (
      <>
        <div className="numbersCard">
          <PhoneNumbersCard
            myPhoneNumbers={myPhoneNumbers}
            defaultNumber={defaultNumber}
            toggleStarFlag={toggleStarFlag}
            isAdminUser={isAdminUser}
          />
        </div>
      </>
    );
  };

  const renderCallCredits = () => {
    return loading ? (
      <DialerLoadingCards cards={1} />
    ) : (
      <>
        <div className="numbersCard">
          <CallCredits />
        </div>
      </>
    );
  };

  const getNumberAndCreditsForEnabledUser = () => {
    if (dialerEnabled) {
      dialerStoreData.checkAndCreateTwilioSubAccount();
      dialerStoreData.getMyPhoneNumbers();
    }
  };

  const handleRecordingChange = async value => {
    const { recordingEnabled = false, inFreePlan = false } =
      userDetail.userFeatureFlag || {};
    if (inFreePlan) {
      confirmationPopupState.setPopupValues({
        content: (
          <LimitErr
            headingContent={`Oops! you’re trying to record calls, but this feature isn’t available on your plan.`}
            limitMessage={`Please upgrade to one of our premium plan to add call credits and enable call recording.`}
          />
        ),
        dynamicDom: true,
        actionBtnText: "Okay",
        cancelBtnText: "Upgrade",
        cancelCbk: () => {
          confirmationPopupState.setShowPopup(false);
          history && history.push("/pricing");
        },
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
      return;
    }

    if (isAdminUser) {
      try {
        setCallRecordLoader(true);
        const config = {
          url: URLS.callRecordingSetting,
          method: "POST",
          data: { recordingEnabled: value }
        };
        const res = await makeApi(config);
        if (res?.data?.status === "success") {
          setCallRecording(value);
          userDetail.setUserFeatureFlag({
            ...userDetail.userFeatureFlag,
            recordingEnabled: value
          });
          toasterState.setToastMsg("Successfully Updated", "success");
        } else {
          setCallRecording(callRecording);
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
        setCallRecordLoader(false);
      } catch (e) {
        console.error("Call recording failed", e);
      }
    } else {
      contactAdminPopup();
    }
  };

  const contactAdminPopup = () => {
    let msg =
      "Please contact your account administrator to enable/disable your call recording";
    Utils.showMessagePopup(msg);
  };

  useEffect(() => {
    getNumberAndCreditsForEnabledUser();
    Utils.mixpanelTrack(MP_EVENT.DIALER_SETTINGS_VIEWED);
  }, []);

  useEffect(() => {
    !dialerStoreData?.myPhoneNumbers?.myPhoneNumbers &&
      constructPhoneNumberDetails(dialerStoreData.myPhoneNumbers);
  }, [dialerStoreData.myPhoneNumbers]);

  useEffect(() => {
    setCallRecording(dialerStoreData.callRecordingEnabled);
  }, [dialerStoreData.callRecordingEnabled]);

  return (
    <div className={`dialerWrapper`}>
      <div className="dialerInnercont">
        {dialerEnabled ? (
          <>
            <div className="numbersWrapper">
              <div className="dialerTitle">My Phone Numbers</div>
              {renderPhoneNumbers()}
            </div>
            <div className="creditsWrapper">
              <div className="dialerTitle">Phone Call Credits</div>
              {renderCallCredits()}
            </div>
            <CallRecordings
              state={recordingEnabled}
              setCbk={handleRecordingChange}
              isAdminUser={isAdminUser}
              contactAdminCbk={contactAdminPopup}
              isLoading={callRecordLoader}
            />
          </>
        ) : (
          <DialerUpgradePopup {...props} />
        )}
      </div>
    </div>
  );
});

const PhoneNumbersCard = observer(props => {
  const {
    myPhoneNumbers = [],
    defaultNumber = "",
    toggleStarFlag = () => {},
    isAdminUser = true
  } = props || {};

  const purchaseCallBack = async (selectedPhoneNumber, key) => {
    if (key === "back") {
      showAddNumberOptions();
    } else {
      purchasePhoneNumber(selectedPhoneNumber);
    }
  };

  const purchaseCreditsCbk = async (amount = 0, stripeToken = "") => {
    if (amount && !stripeToken) {
      amount.setShowPopup(true);
      const payload = { amount };
      await dialerStoreData.purchaseCredits(payload);
      transactionAnimationState.setShowPopup(false);
    } else if (amount && stripeToken) {
      transactionAnimationState.setShowPopup(true);
      const payload = { chargeAmount: amount, stripeToken };
      await dialerStoreData.purchaseCreditsWithoutSubscription(payload);
      transactionAnimationState.setShowPopup(false);
    }
  };

  const buyCredits = async () => {
    if (await checkTeamSubscriptionActive()) {
      const cardResponse = Utils.checkCustomerIdAvailable();
      if (cardResponse === 200) {
        buyCreditsPopupState.setNewPayment(false);
        buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
        buyCreditsPopupState.setPopupValue({ type: "DIALER" });
        buyCreditsPopupState.setShowPopup(true);
      } else if (Utils.isEligibleToShowPaymentPopup(cardResponse)) {
        buyCreditsPopupState.setNewPayment(true);
        buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
        buyCreditsPopupState.setPopupValue({ type: "DIALER" });
        buyCreditsPopupState.setShowPopup(true);
      } else if (cardResponse === 203) {
        upgradePopupState.setPopupValues({
          type: "data_AddCreditsToDialer"
        });
        upgradePopupState.setPopup(true);
      }
    }
  };

  const purchasePhoneNumber = async (ph_number, token) => {
    transactionAnimationState.setShowPopup(true);
    const payload = { ph_number, token };
    await dialerStoreData.purchaseNumber(payload, buyCredits);
    transactionAnimationState.setShowPopup(false);
  };

  const ccPopupCbk = (phoneNumber, token, key) => {
    if (key === "back") {
      showAddNumberOptions();
    } else {
      purchasePhoneNumber(phoneNumber, token);
    }
  };

  const configureNumberCbk = (key, phNumber, extCode) => {
    if (key === "back") {
      showAddNumberOptions();
    } else if (key === "call") {
      verifyCall(phNumber, extCode);
    }
  };

  const buyNumberAction = () => {
    let msg = "";
    if (isAdminUser) {
      const { dialerEnabled = false } = userDetail?.userFeatureFlag || {};
      if (dialerEnabled) {
        buyNewNumberPopupState.setCallBack(purchaseCallBack);
        buyNewNumberPopupState.setShowPopup(true);
      } else {
        msg =
          "Access to buy new number is denied. Please contact your administrator";
        Utils.showMessagePopup(msg);
      }
    } else {
      msg =
        "Please contact your account administrator to change your call settings";
      Utils.showMessagePopup(msg);
    }
  };

  const configureNumberAction = e => {
    addMyOwnNumberPopupState.setCallBack(configureNumberCbk);
    addMyOwnNumberPopupState.setShowPopup(true);
  };

  const selectedNumberAction = key => {
    if (key) {
      if (key === "configureNumber") {
        configureNumberAction();
      } else if (key === "newNumber") {
        buyNumberAction();
      }
    }
  };

  const addNewNumberAction = async event => {
    if (await checkTeamSubscriptionActive()) {
      showAddNumberOptions(event);
    }
  };

  const showAddNumberOptions = e => {
    Utils.preventDefaultFn(e);
    addNumberPopupState.setCallback(selectedNumberAction);
    addNumberPopupState.setShowPopup(true);
  };

  const BuyNewNumberCard = () => {
    return (
      <div
        className="card newNumberCard"
        title="Click here to add"
        onClick={e => addNewNumberAction(e)}
      >
        <div className="phoneNumberCardheader">
          <div className="addNewNumber">+ Add a New Number</div>
        </div>
      </div>
    );
  };

  const updateDefaultNumber = async (e, number) => {
    Utils.preventDefaultFn(e);
    if (number) {
      toggleStarFlag(number);
      toasterState.setToastMsg("Updating...", "info");
      const payload = { ph_number: number };
      await dialerStoreData.updateDefaultPhoneNumber(payload);
      toggleStarFlag(number);
    }
  };

  const deletePhoneNumber = (e, number, type) => {
    Utils.preventDefaultFn(e);
    if (number) {
      if (Utils.isAdmin()) {
        const deleteCbk = async () => {
          toasterState.setToastMsg("Please Wait...", "info");
          confirmationPopupState.setShowPopup(true);
          const url = `${URLS.deletePhoneNumber}/${number}/${type}`;
          await dialerStoreData.deletePhoneNumber(url, number);
        };

        confirmationPopupState.setPopupValues({
          content: "Are you sure you want to delete this phone number?",
          actionBtnText: "Yes",
          callback: deleteCbk
        });
        confirmationPopupState.setShowPopup(true);
      } else {
        let msg =
          "Please contact your account administrator to change your call settings";
        Utils.showMessagePopup(msg);
      }
    }
  };

  const DefaultNumber = props => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: twinklingStar,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice"
      }
    };

    const { number = "", starClicked = false } = props || {};
    let tempNumber = number?.replace("+", "");
    let tempDefaultNumber = defaultNumber?.replace("+", "");

    if (tempNumber === tempDefaultNumber) {
      return (
        <div className="defaultNumber">
          <div className="star filled icon">
            <img src={YellowStarIcon} alt="Default Number" />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`star icon ${starClicked ? "animatedStar" : ""}`}
          onClick={e => updateDefaultNumber(e, number)}
        >
          {!starClicked ? (
            <StarIcon />
          ) : (
            <Lottie
              options={defaultOptions}
              width={20}
              height={14}
              style={{ margin: "0 auto" }}
              speed={1.5}
            />
          )}
        </div>
      );
    }
  };

  const constructVerifyUrl = (phNumber, extCode) => {
    return `${URLS.verifyMyPhNumber}/${phNumber}${
      phNumber && extCode?.length > 0 ? `?ext=${extCode}` : ""
    }`;
  };

  const verifyCall = async (phNumber, extCode) => {
    verifyNumberPopupState.setPhoneNumber(phNumber);
    verifyNumberPopupState.setShowPopup(true);
    const url = constructVerifyUrl(phNumber, extCode);
    const response = await dialerStoreData.verifyCallerId(url);
    if (response) {
      const { errorCode, code } = response;
      if (code) {
        verifyNumberPopupState.setCode(code);
      } else if (errorCode === 21450) {
        toasterState.setToastMsg(
          "Phone number already verified. Please use a different number",
          "failure"
        );
        verifyNumberPopupState.setShowPopup(false);
      } else {
        showError();
      }
    } else {
      showError();
    }
  };

  const showError = () => {
    verifyNumberPopupState.setShowPopup(false);
    toasterState.setToastMsg(
      "Something went wrong. Please try again later",
      "failure"
    );
  };

  const getRenewalText = date => {
    if (date) {
      const endDate = new Date(date);
      const currentDate = new Date();
      const diffTime = endDate - currentDate;
      const diffInDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      if (diffInDays > 0) {
        return `Renews in ${diffInDays} ${diffInDays > 1 ? "Days" : "Day"}`;
      } else {
        return `Renews today`;
      }
    }
  };

  return (
    <div className="cardWrapper">
      <BuyNewNumberCard />
      {myPhoneNumbers?.length > 0 &&
        myPhoneNumbers.map((obj, key) => {
          const {
            value = "",
            starClicked = false,
            type = "",
            nextRenewalDate = "",
            freePurchase = false
          } = obj;
          return (
            <div className="card" id={value} key={`numberCard${key + value}`}>
              <div className="phoneNumberCardheader">
                <>
                  {freePurchase ? (
                    <div className="messageCont freeTextCont">
                      <div className="messageText">Free</div>
                    </div>
                  ) : nextRenewalDate ? (
                    <div className="messageCont">
                      <i className="fa fa-clock-o messageIcon"></i>
                      <div className="messageText">
                        {getRenewalText(nextRenewalDate)}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="messageCont"
                      style={{ backgroundColor: "#d3ffc4" }}
                    >
                      <i className="fa fa-check-circle messageIcon"></i>
                      <div className="messageText">Verified number</div>
                    </div>
                  )}
                  <DefaultNumber number={value} starClicked={starClicked} />
                </>
              </div>
              <div className="otherDetails">
                <div className="phoneNumber cardRow">
                  <span className="value phoneNumber">
                    {Utils.getUSFormattedPhoneNumber(value)}
                  </span>
                  <div
                    className="delete icon"
                    onClick={e => deletePhoneNumber(e, value, type)}
                  >
                    <DeleteIcon />
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
});

const CallCredits = () => {
  const [credits, setCredits] = useState("");
  const [freeCalling, setFreeCalling] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const { inFreePlan = false, planName = "" } =
    userDetail?.userFeatureFlag || {};

  const purchaseCreditsCbk = async (selectedRechargeAmount, stripeToken) => {
    if (selectedRechargeAmount && !stripeToken) {
      transactionAnimationState.setShowPopup(true);
      const payload = { amount: selectedRechargeAmount };
      await dialerStoreData.purchaseCredits(payload);
      transactionAnimationState.setShowPopup(false);
    } else if (selectedRechargeAmount && stripeToken) {
      transactionAnimationState.setShowPopup(true);
      const payload = { chargeAmount: selectedRechargeAmount, stripeToken };
      await dialerStoreData.purchaseCreditsWithoutSubscription(payload);
      transactionAnimationState.setShowPopup(false);
    }
  };

  const buyCredits = async () => {
    if (inFreePlan) {
      upgradePopupState.setPopupValues({
        type: "dialer_addCredits"
      });
      upgradePopupState.setPopup(true);
    } else {
      if (await checkTeamSubscriptionActive()) {
        const cardResponse = Utils.checkCustomerIdAvailable();
        if (cardResponse === 200) {
          buyCreditsPopupState.setNewPayment(false);
          buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
          buyCreditsPopupState.setPopupValue({ type: "DIALER" });
          buyCreditsPopupState.setShowPopup(true);
        } else if (Utils.isEligibleToShowPaymentPopup(cardResponse)) {
          buyCreditsPopupState.setNewPayment(true);
          buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
          buyCreditsPopupState.setPopupValue({ type: "DIALER" });
          buyCreditsPopupState.setShowPopup(true);
        } else if (cardResponse === 203) {
          upgradePopupState.setPopupValues({
            type: "data_AddCreditsToDialer"
          });
          upgradePopupState.setPopup(true);
        }
      }
    }
  };

  useEffect(() => {
    setCredits(userDetail?.userFeatureFlag?.dialerCredits || 0.0);
  }, [userDetail.userFeatureFlag]);

  useEffect(() => {
    setFreeCalling(
      userDetail?.userFeatureFlag?.usFreeCallingMinutesEligible -
        userDetail?.userFeatureFlag?.usFreeCallingMinutesUsed || 0
    );
  }, [userDetail.userFeatureFlag]);

  return (
    <div className="card availableCredits">
      <div className="creditsCont">
        <div className="cardRow">Available</div>
        <div className="creditsData">
          $ {parseFloat(credits)?.toFixed(4) || "0.0"}
        </div>
        {freeCalling > 0 && (
          <div className="freecalling">
            <span className="freecallingText">{`Free US calling minutes remaining: ${freeCalling} min`}</span>
            <span
              className="freecallingIcon"
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
            >
              <i className="material-icons-outlined">info</i>
            </span>
            {showTooltip && (
              <CustomTooltip
                text={
                  inFreePlan
                    ? `Your plan includes 60 free calling minutes to US and Canada. And also includes 1 free calling US number.`
                    : `Your Basic/Pro/Enterprise plan includes 60 US calling minutes per month and 1 free US phone number.`
                }
              />
            )}
          </div>
        )}
      </div>
      <div
        className="actionBtns buyMoreText"
        onClick={e => buyCredits(e)}
        title="Click here to buy"
      >
        Add more credits
      </div>
    </div>
  );
};

const CallRecordings = props => {
  const {
    state = true,
    setCbk = () => {},
    isAdminUser = true,
    contactAdminCbk = () => {},
    isLoading = ""
  } = props || {};
  return (
    <div className="callRecordingWrapper">
      <div className="dialerTitle">Call Recording</div>
      <div className="callRecordingToggleBtn">
        <ToggleBtnType
          defaultValue={state}
          cbk={value => setCbk(value)}
          lastText="Automatically record all outgoing calls"
          isAllowed={isAdminUser}
          pageType="dialer"
          popupCbk={contactAdminCbk}
          enableLoading={isLoading}
        />
      </div>
      <div
        style={{
          paddingTop: "10px",
          color: "#878787",
          fontSize: "12px"
        }}
      >
        Please consult your legal counsel regarding the requirements for
        recording calls. Additional charges apply. Learn more about call
        recording charges{" "}
        <a
          target="_blank"
          style={{ textDecoration: "none", color: "#3953fb" }}
          rel="noopener noreferrer"
          href="https://support.salesgear.io/en/articles/6058343-dialer-call-credit-charges"
        >
          here.
        </a>
      </div>
    </div>
  );
};

export default withRouter(Dialer);
export { Dialer };
