import React from "react";

const ToggleSwitch = ({
  id = "",
  leftText = "",
  rightText = "",
  offerText = "",
  checked = true,
  showOffer = false,
  action = () => {}
}) => {
  const handleAction = (event = "") => {
    event && action(!checked, id);
  };

  return (
    <div
      className={`checkboxContainer d-flex align-items-center justify-content-end container-fluid`}
    >
      {leftText && (
        <div
          className={`leftText ${!checked ? "checked" : "unchecked"}`}
          onClick={handleAction}
        >
          {leftText}
        </div>
      )}
      {rightText && (
        <div
          className={`rightText ${checked ? "checked" : "unchecked"}`}
          onClick={handleAction}
        >
          {rightText}
        </div>
      )}
      {showOffer && offerText && (
        <span className={`offerText`}>{offerText}</span>
      )}
    </div>
  );
};

export default ToggleSwitch;
