import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import { checkTeamSubscriptionActive } from "Utils/commonAPI";
import userDetail from "Stores/userDetail";
import makeApi, { URLS } from "Utils/apiURL";
import Utils from "Utils/utils";
import Button from "Components/common/Button";
import dialerStoreData from "Stores/DialerData";
import LinkedinCredits from "./Credits/LinkedinCredits";
import CallCredits from "./Credits/CallCredits";
import EmailCredits from "./Credits/EmailCredits";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { buyCreditsPopupState } from "Pages/Dashboard/settings/BuyCreditsPopup";
import { transactionAnimationState } from "Components/common/TransactionAnimation";
import { toasterState } from "Components/common/toaster";
import { RECHARGE_ERR_MSG } from "Model/model";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import PaywallUtils from "Utils/PaywallUtils";
import { ReactComponent as EmailValidationSvg } from "Assets/svg/creditsOverview/emailValidation.svg";
import { ReactComponent as EmailCreditsSvg } from "Assets/svg/creditsOverview/EmailCredits.svg";
import { ReactComponent as SendEmailSvg } from "Assets/svg/creditsOverview/sendEmail.svg";
import { ReactComponent as CallCreditsSvg } from "Assets/svg/creditsOverview/callCredits.svg";
import { ReactComponent as BuyPhoneCreditsSvg } from "Assets/svg/creditsOverview/buyPhoneCredits.svg";
import { ReactComponent as LinkedinOutreachSvg } from "Assets/svg/creditsOverview/linkedinOutreach.svg";
import { ReactComponent as SnImportSvg } from "Assets/svg/creditsOverview/snImport.svg";
import { useHistory } from "react-router-dom";

const defaultObject = {
  annualPhoneCreditsUsed: 0,
  dailyEmailCreditsUsed: 0,
  dailyLimit: false,
  dailyPhoneCreditsUsed: 0,
  emailCreditsDailyLimit: 0,
  emailCreditsMonthlyLimit: 0,
  emailCreditsReduced: 0,
  monthlyCreditsResetDate: "",
  monthlyEmailCreditsUsed: 0,
  monthlyPhoneCreditsUsed: 0,
  phoneCreditsAnnualLimit: 0,
  phoneCreditsDailyLimit: 0,
  phoneCreditsMonthlyLimit: 0,
  phoneCreditsReduced: 0
};

export const CreditsUsage = withRouter(
  observer(props => {
    const {
      planName = "",
      inFreePlan = false,
      emailValidationEnabled = false
    } = userDetail?.userFeatureFlag || {};
    console.log("planName: ", planName);

    const isFreeUser = inFreePlan;

    const [data, setData] = useState(defaultObject);
    const [loader, setLoader] = useState(false);
    const [addCreditsLoader, setAddCreditsLoader] = useState(false);
    const [creditsCardLoader, setCreditsCardLoader] = useState(true);
    const history = useHistory();

    const getCreditsUsage = async () => {
      if (await checkTeamSubscriptionActive()) {
        if (!loader) {
          try {
            const config = {
              url: URLS.getCreditsUsage
            };
            const res = await makeApi(config);
            if (res?.data && Object.keys(res?.data)?.length > 0) {
              setData(res?.data);
              setCreditsCardLoader(false);
            }
          } catch (err) {
            console.error(err, "Getting Credit Usage ERROR!");
          }
        }
      }
      return;
    };

    const addEmailPhoneNumberCredits = () => {
      if (isFreeUser) {
        props.history.push("/pricing");
      } else {
        PaywallUtils.upgradeUtilsFunction(
          "data_AddCreditsToDataSearch",
          true,
          planName,
          inFreePlan
        );
      }
    };

    const showRechargeErrCodeMsg = response => {
      let code = response?.data?.responseCode || response?.data?.status;
      if (code === 207) {
        toasterState.setToastMsg("UNAUTHORIZED Access", "fail");
      } else {
        let content = RECHARGE_ERR_MSG[code] || "";
        if (content) {
          confirmationPopupState.setPopupValues({
            content,
            actionBtnText: "Ok",
            needCancelBtn: false,
            dynamicContent: true,
            callback: () => confirmationPopupState.setShowPopup(false)
          });
          confirmationPopupState.setShowPopup(true);
        } else {
          toasterState.setToastMsg(
            "Something went wrong. Please try again later",
            "failure"
          );
        }
      }
    };

    const purchaseEmailValidationCredits = async (amount = 0) => {
      if (amount) {
        try {
          const config = {
            url: URLS.purchaseEmailValidationCredits,
            method: "POST",
            data: { amount }
          };
          let response = await makeApi(config);
          if (response?.data?.responseCode === 200) {
            await Utils.reloadFeatureFlag(false);
            toasterState.setToastMsg(
              "Credits added to your account successfully",
              "success"
            );
          } else {
            showRechargeErrCodeMsg(response);
          }
        } catch (e) {
          console.error("Recharge email validation credits failed", e);
        }
      }
    };

    const purchaseCreditsCbk = async (amount = 0, stripeToken = "") => {
      if (amount && !stripeToken) {
        transactionAnimationState.setShowPopup(true);
        await purchaseEmailValidationCredits(amount);
        transactionAnimationState.setShowPopup(false);
      } else {
        showRechargeErrCodeMsg(201);
      }
    };

    const addEmailValidationCredits = async () => {
      if (await checkTeamSubscriptionActive()) {
        if (emailValidationEnabled && !isFreeUser) {
          setAddCreditsLoader(true);
          const cardResponse = Utils.checkCustomerIdAvailable();
          if (cardResponse === 200) {
            buyCreditsPopupState.setNewPayment(false);
            buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
            buyCreditsPopupState.setPopupValue({
              type: "EV_CREDITS"
            });
            buyCreditsPopupState.setShowPopup(true);
          } else if (Utils.isEligibleToShowPaymentPopup(cardResponse)) {
            buyCreditsPopupState.setNewPayment(true);
            buyCreditsPopupState.setCallBack(purchaseCreditsCbk);
            buyCreditsPopupState.setPopupValue({
              type: "EV_CREDITS"
            });
            buyCreditsPopupState.setShowPopup(true);
          } else {
            PaywallUtils.upgradeUtilsFunction(
              cardResponse === 203
                ? "data_AddCreditsToEV"
                : "emailValidationUpgrade",
              true,
              planName,
              inFreePlan
            );
          }
        }
        setAddCreditsLoader(false);
      }
    };

    const purchaseDialerCreditsCbk = async (
      selectedRechargeAmount,
      stripeToken
    ) => {
      if (selectedRechargeAmount && !stripeToken) {
        transactionAnimationState.setShowPopup(true);
        const payload = { amount: selectedRechargeAmount };
        await dialerStoreData.purchaseCredits(payload);
        transactionAnimationState.setShowPopup(false);
      } else if (selectedRechargeAmount && stripeToken) {
        transactionAnimationState.setShowPopup(true);
        const payload = { chargeAmount: selectedRechargeAmount, stripeToken };
        await dialerStoreData.purchaseCreditsWithoutSubscription(payload);
        transactionAnimationState.setShowPopup(false);
      }
    };

    const buyCredits = async () => {
      if (await checkTeamSubscriptionActive()) {
        const cardResponse = Utils.checkCustomerIdAvailable();
        if (cardResponse === 200) {
          buyCreditsPopupState.setNewPayment(false);
          buyCreditsPopupState.setCallBack(purchaseDialerCreditsCbk);
          buyCreditsPopupState.setPopupValue({ type: "DIALER" });
          buyCreditsPopupState.setShowPopup(true);
        } else if (Utils.isEligibleToShowPaymentPopup(cardResponse)) {
          buyCreditsPopupState.setNewPayment(true);
          buyCreditsPopupState.setCallBack(purchaseDialerCreditsCbk);
          buyCreditsPopupState.setPopupValue({ type: "DIALER" });
          buyCreditsPopupState.setShowPopup(true);
        } else if (cardResponse === 203) {
          upgradePopupState.setPopupValues({
            type: "data_AddCreditsToDialer"
          });
          upgradePopupState.setPopup(true);
        }
      }
    };

    const navigateToPricing = () => {
      history.push("/pricing");
    };

    const buttonObject = {
      refresh: {
        id: "refreshCreditsUsage",
        name: "refreshCreditsUsage",
        btnText: "Refresh",
        type: "button",
        loader,
        icon: true,
        iconName: "refresh",
        className: `refreshCreditsUsage ${loader ? "loading" : ""}`,
        click: () => getInitPage(true)
      },
      addEmailCreditsBtn: {
        id: "addEmailCreditsBtn",
        name: "addEmailCreditsBtn",
        btnText: isFreeUser ? "Upgrade" : "Add Credits",
        type: "button",
        className: "addCreditsBtn",
        click: addEmailPhoneNumberCredits
      },
      addPhoneCreditsBtn: {
        id: "addPhoneCreditsBtn",
        name: "addPhoneCreditsBtn",
        btnText: isFreeUser ? "Upgrade" : "Add Credits",
        type: "button",
        className: "addCreditsBtn",
        click: addEmailPhoneNumberCredits
      },
      addEmailValidationCreditsBtn: {
        id: "addEmailValidationCreditsBtn",
        name: "addEmailValidationCreditsBtn",
        btnText:
          isFreeUser || !emailValidationEnabled ? "Upgrade" : "Add Credits",
        type: "button",
        loader: addCreditsLoader,
        className: "addCreditsBtn",
        click: addEmailValidationCredits
      }
    };

    const getInitPage = async (refresh = false) => {
      refresh && setLoader(true);
      await Utils.reloadFeatureFlag(false);
      await getCreditsUsage();
      refresh && setLoader(false);
    };

    const CreditsLoaderComponent = () => {
      return (
        <div>
          <div className="creditsUsageBody">
            {Array.from({ length: 3 }, (item, index) => (
              <>
                <div className="creditsContainer">
                  {Array.from({ length: 3 }, (item, index) => (
                    <div className="card">
                      <h3 className="linear-background"></h3>
                      <p className="linear-background"></p>

                      <div className="linearProgessWrapper">
                        <div className="linearProgessLoaderBody">
                          <div className="limit linear-background" />
                          <div className="limit linear-background" />
                        </div>
                      </div>

                      <div className="buttonWrapper linear-background"></div>
                    </div>
                  ))}
                  <div class="add-creditsLoader linear-background"></div>
                </div>
                <hr class="creditsSeparator" />
              </>
            ))}
          </div>
        </div>
      );
    };

    const creditsButtonObj = {
      emailLimits: {
        id: "emailLimits",
        name: "emailLimits",
        btnText: "Add More Email Credits",
        type: "button",
        className: "emailCreditsBtn",
        click: navigateToPricing
      },
      emailSendingLimits: {
        id: "emailSendingLimits",
        name: "emailSendingLimits",
        btnText: "Increase Email Sending Limit",
        type: "button",
        className: "emailCreditsBtn",
        click: navigateToPricing
      },
      emailValidationLimits: {
        id: "emailValidationLimits",
        name: "emailValidationLimits",
        btnText: "Add Validation Credits",
        type: "button",
        className: "emailCreditsBtn",
        click: addEmailValidationCredits
      },
      callingLimits: {
        id: "callingLimits",
        name: "callingLimits",
        btnText: "Add Calling Credits",
        type: "button",
        className: "emailCreditsBtn",
        click: buyCredits
      },
      phoneLookupLimits: {
        id: "phoneLookupLimits",
        name: "phoneLookupLimits",
        btnText: "Add Phone Number Lookup Credits",
        type: "button",
        className: "emailCreditsBtn",
        click: navigateToPricing
      },
      liOutReachLimits: {
        id: "liOutReachLimits",
        name: "liOutReachLimits",
        btnText: "Increase LinkedIn Outreach Limits",
        type: "button",
        className: "emailCreditsBtn",
        click: navigateToPricing
      },
      snImportLimits: {
        id: "liOutReachLimits",
        name: "liOutReachLimits",
        btnText: "Increase Sales Navigator Import Limits",
        type: "button",
        className: "emailCreditsBtn",
        click: navigateToPricing
      }
    };

    const creditsData = {
      emailCreditsData: [
        {
          id: "emailMonthlyCredits",
          heading: "Email Credits",
          icon: <EmailCreditsSvg />,
          paraContent:
            "Used for finding verified emails of prospects from Salesgear Discover or LinkedIn Chrome Extension.",
          type: "text",
          btnObj: creditsButtonObj?.emailLimits,
          usageLimits: [
            {
              id: "monthly",
              heading: "Monthly",
              tooltip: isFreeUser
                ? "Your monthly credits reset on the 1st of every month."
                : "Your credits reset on your billing cycle date.",
              classNameText: "monthlyEmailCredits",
              totalCredits: data?.emailCreditsMonthlyLimit || 0,
              remainingCredits:
                data?.emailCreditsMonthlyLimit - data?.monthlyEmailCreditsUsed <
                0
                  ? 0
                  : data?.emailCreditsMonthlyLimit -
                    data?.monthlyEmailCreditsUsed,
              readOnly: true
            },
            {
              id: "daily",
              heading: "Daily",
              tooltip: "Your daily credits reset at 12 AM PST.",
              classNameText: "dailyEmailCredits",
              totalCredits: data?.emailCreditsDailyLimit || 0,
              remainingCredits:
                data?.emailCreditsDailyLimit - data?.dailyEmailCreditsUsed < 0
                  ? 0
                  : data?.emailCreditsDailyLimit - data?.dailyEmailCreditsUsed,
              readOnly: true
            }
          ]
        },
        {
          id: "emailValidationCredits",
          heading: "Email Validation Credits (Pay-as-you-go)",
          icon: <EmailValidationSvg />,
          paraContent:
            "Used to validate email addresses before sending emails. Powered by ZeroBounce email validation service.",
          type: "text",
          btnObj: creditsButtonObj.emailValidationLimits,
          usageLimits: [
            {
              id: "lifetime",
              heading: "Lifetime",
              tooltip:
                "Your validation credits are pay-as-you-go and remain valid until used.",
              classNameText: "emailValidation",
              totalCredits: data?.emailValidationCreditsRemaining || 0,
              remainingCredits: data?.emailValidationCreditsRemaining || 0,
              readOnly: true
            }
          ]
        },
        {
          id: "emailMonthlyLimit",
          heading: "Email sending limit",
          icon: <SendEmailSvg />,
          paraContent:
            "The maximum cold emails & follow-ups a user can send per month from Salesgear.",
          type: "text",
          btnObj: creditsButtonObj.emailSendingLimits,
          usageLimits: [
            {
              id: "emailSendingLimit",
              heading: "Monthly",
              tooltip:
                "Your monthly email sending limit resets on the 1st of every month.",
              classNameText: "monthlyEmailSendCredits",
              totalCredits: data?.monthlyEmailSendingLimit || 0,
              remainingCredits:
                data?.monthlyEmailSendingLimit - data?.monthlyEmailSent < 0
                  ? 0
                  : data?.monthlyEmailSendingLimit - data?.monthlyEmailSent,
              readOnly: true
            }
          ]
        }
      ],
      callCreditsData: [
        {
          id: "callCredits",
          heading: "Calling Credits",
          icon: <CallCreditsSvg />,
          paraContent:
            "Used to make calls anywhere in the world using Salesgear’s built-in dialer.",
          type: "text",
          btnObj: creditsButtonObj?.callingLimits,
          usageLimits: [
            {
              id: "callingMinCanadaUs",
              heading: "Free calling minutes left for US/Canada",
              tooltip: isFreeUser
                ? "Includes 60 free minutes to US/Canada — valid for lifetime."
                : planName.toLowerCase() === "pro"
                ? "Includes 500 free minutes to US/Canada every month. Resets based on your billing cycle."
                : "Includes 100 free minutes to US/Canada every month. Resets based on your billing cycle.",
              totalCredits: data?.usFreeCallingMinutesEligible || 0,
              classNameText: "callingCanadaUs",
              remainingCredits:
                data?.usFreeCallingMinutesEligible -
                  data?.usFreeCallingMinutesUsed <
                0
                  ? 0
                  : data?.usFreeCallingMinutesEligible -
                    data?.usFreeCallingMinutesUsed,
              readOnly: true
            },
            {
              id: "callCreditsRemaining",
              heading: "Call credits remaining",
              tooltip:
                "Your calling credits are pay-as-you-go and remain valid until used.",
              totalCredits:
                data?.dialerCreditsRemaining < 0
                  ? 0
                  : parseFloat(data?.dialerCreditsRemaining)?.toFixed(4),
              classNameText: "callCreditsRemaining",
              remainingCredits:
                data?.dialerCreditsRemaining < 0
                  ? 0
                  : parseFloat(data?.dialerCreditsRemaining)?.toFixed(4),
              readOnly: true
            }
          ]
        },
        {
          id: "phoneLookUpCredits",
          heading: "Phone Number Lookup Credits",
          icon: <BuyPhoneCreditsSvg />,
          paraContent:
            "Used for finding a prospect’s phone number from Salesgear Discover or LinkedIn Chrome Extension.",
          type: "text",
          btnObj: creditsButtonObj?.phoneLookupLimits,
          usageLimits: [
            {
              id: "lifetime",
              heading: "Lifetime",
              tooltip: isFreeUser
                ? "Your monthly credits reset on the 1st of every month."
                : "Your credits reset on your billing cycle date.",
              classNameText: "phoneLookupLimits",
              totalCredits: inFreePlan
                ? data?.phoneCreditsAnnualLimit || 0
                : data?.phoneCreditsMonthlyLimit || 0,
              remainingCredits: inFreePlan
                ? data?.phoneCreditsAnnualLimit - data?.annualPhoneCreditsUsed <
                  0
                  ? 0
                  : data?.phoneCreditsAnnualLimit - data?.annualPhoneCreditsUsed
                : data?.phoneCreditsMonthlyLimit -
                    data?.monthlyPhoneCreditsUsed <
                  0
                ? 0
                : data?.phoneCreditsMonthlyLimit -
                  data?.monthlyPhoneCreditsUsed,
              readOnly: true
            }
          ]
        }
      ],
      linkedinCreditsData: [
        {
          id: "linkedinCredits",
          heading: "LinkedIn Outreach Limits",
          icon: <LinkedinOutreachSvg />,
          paraContent:
            "Used for automated LinkedIn outreach in your sequences.",
          type: "text",
          btnObj: creditsButtonObj?.liOutReachLimits,
          subLimits: [
            {
              subHeading: "Connection Requests",
              usageLimits: [
                {
                  id: "monthly",
                  heading: "Monthly",
                  tooltip: isFreeUser
                    ? "Your monthly limit reset on the 1st of every month."
                    : "Your limit reset on your billing cycle date.",
                  classNameText: "requestMonthly",
                  totalCredits: data?.liMonthlyInviteEligible || 0,
                  remainingCredits:
                    data?.liMonthlyInviteEligible - data?.liMonthlyInviteUsed <
                    0
                      ? 0
                      : data?.liMonthlyInviteEligible -
                        data?.liMonthlyInviteUsed,
                  readOnly: true
                },
                {
                  id: "daily",
                  heading: "Daily",
                  tooltip: "Your daily limit reset at 12 AM PST.",
                  classNameText: "requestDaily",
                  totalCredits: data?.maxInvitePerDay || 0,
                  remainingCredits:
                    data?.maxInvitePerDay - data?.liDailyInviteUsed < 0
                      ? 0
                      : data?.maxInvitePerDay - data?.liDailyInviteUsed,
                  readOnly: true
                }
              ]
            },
            {
              subHeading: "Messages",
              usageLimits: [
                {
                  id: "monthly",
                  heading: "Monthly",
                  tooltip: isFreeUser
                    ? "Your monthly limit reset on the 1st of every month."
                    : "Your limit reset on your billing cycle date.",
                  classNameText: "messageMonthly",
                  totalCredits: data?.liMonthlyMessageEligible || 0,
                  remainingCredits:
                    data?.liMonthlyMessageEligible -
                      data?.liMonthlyMessageUsed <
                    0
                      ? 0
                      : data?.liMonthlyMessageEligible -
                        data?.liMonthlyMessageUsed,
                  readOnly: true
                },
                {
                  id: "daily",
                  heading: "Daily",
                  tooltip: "Your daily limit reset at 12 AM PST.",
                  classNameText: "messageDaily",
                  totalCredits: data?.maxMessagesPerDay || 0,
                  remainingCredits:
                    data?.maxMessagesPerDay - data?.liDailyMessageUsed < 0
                      ? 0
                      : data?.maxMessagesPerDay - data?.liDailyMessageUsed,
                  readOnly: true
                }
              ]
            }
          ]
        },
        {
          id: "salesNavigator",
          heading: "LinkedIn Sales navigator import",
          icon: <SnImportSvg />,
          paraContent:
            "Used for extracting contacts from LinkedIn Sales Navigator search results.",
          type: "text",
          btnObj: creditsButtonObj?.snImportLimits,
          usageLimits: [
            {
              id: "importLimit",
              heading: "Import Limit",
              tooltip: isFreeUser
                ? "Allows 1 Sales Navigator import. Each import counts as one. Limit does not reset."
                : planName.toLowerCase() === "pro"
                ? "Allows 10 Sales Navigator imports per month. Each import counts as one. Resets based on your billing cycle."
                : "Allows 2 Sales Navigator imports per month. Each import counts as one. Resets based on your billing cycle.",
              classNameText: "snImport",
              totalCredits: data?.snImportEligible || 0,
              remainingCredits:
                data?.snImportEligible - data?.snImportUsed < 0
                  ? 0
                  : data?.snImportEligible - data?.snImportUsed,
              readOnly: true
            },
            {
              id: "contactsImported",
              heading: "Contacts Imported",
              tooltip: isFreeUser
                ? "Allows up to 500 contact imports in total from Sales Navigator. Limit does not reset."
                : planName.toLowerCase() === "pro"
                ? "Allows up to 25,000 contact imports per month. Resets based on your billing cycle."
                : "Allows up to 1,000 contact imports per month. Resets based on your billing cycle.",
              classNameText: "snContactImport",
              totalCredits: data?.snContactsEligible || 0,
              remainingCredits:
                data?.snContactsEligible - data?.snContactsUsed < 0
                  ? 0
                  : data?.snContactsEligible - data?.snContactsUsed,
              readOnly: true
            }
          ]
        }
      ]
    };

    useEffect(() => {
      getInitPage();
    }, []);

    return (
      <div className="creditsUsageSection">
        <div className="creditsUsageHeader">
          <h6 className="titleHeader">Credit Usage</h6>
          <div className="flexEnd">
            <Button {...buttonObject["refresh"]} />
          </div>
        </div>
        <div className="creditsUsageBody">
          {creditsCardLoader ? (
            <CreditsLoaderComponent />
          ) : (
            <>
              <div className="creditsContainer">
                <EmailCredits
                  creditsData={creditsData}
                  creditsCardLoader={creditsCardLoader}
                />
              </div>
              <hr class="creditsSeparator" />
              <div className="creditsContainer">
                <CallCredits
                  creditsData={creditsData}
                  creditsCardLoader={creditsCardLoader}
                />
              </div>
              <hr class="creditsSeparator" />
              <div className="creditsContainer">
                <LinkedinCredits
                  creditsData={creditsData}
                  creditsCardLoader={creditsCardLoader}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  })
);

export default CreditsUsage;
