import React from "react";
import BreadCrumbComponent from "Components/common/breadcrumb";
import { KnowMoreButton } from "Components/common/KnowMoreButton";

export const HeaderSection = () => {
  const breadcrumbArr = [{ name: "Inbox", link: "/inbox" }];

  return (
    <section className="headerSection">
      <div className="breadCrumbWrapper">
        <BreadCrumbComponent breadCrumbValue={breadcrumbArr} />
      </div>
      <div className="headerBtnContainer">
        <KnowMoreButton
          redirectUrl={
            "https://support.salesgear.io/en/collections/3174605-outbox"
          }
        />
      </div>
    </section>
  );
};

export default HeaderSection;
