import React, { useEffect, useState } from "react";
import { DAILY_EMAIL_SEND_LIMIT } from "Static/constant";
import Utils from "Utils/utils";
import userDetail from "Stores/userDetail";
import { settingsStoreData } from "Stores/settingsData";
import NumberInputBox from "Components/common/NumberInputBox";
import Button from "Components/common/Button";
import SecondaryCheckbox from "Components/common/SecondaryCheckbox";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const SafetySettings = (props = {}) => {
  const history = useHistory();
  const { planName = "" } = userDetail?.userFeatureFlag || {};
  const { loader = false } = props;
  const {
    inFreePlan = false,
    dailySendLimit = "",
    emailIntervalInSeconds = 120,
    ootoResumeDays: defaultOotoResumeDays = 10
  } = userDetail?.userFeatureFlag || {};
  const [maximumEmailsPerDay, setMaximumEmailsPerDay] =
    useState(dailySendLimit);
  const [minimumDelayBtwEmails, setMinimumDelayBtwEmails] = useState(
    emailIntervalInSeconds
  );
  const [ootoResumeDays, setOotoResumeDays] = useState(defaultOotoResumeDays);

  const [maximumEmailsError, setMaximumEmailsError] = useState("");
  const [minimumDelayError, setMinimumDelayError] = useState("");
  const [minimumOOODayError, setMinimumOOODayError] = useState("");
  const [openTrack, setOpenTrack] = useState(
    userDetail?.userFeatureFlag?.openTrackingEnabled
  );
  const [linkTrack, setLinkTrack] = useState(
    userDetail?.userFeatureFlag?.clickTrackingEnabled
  );
  const [btnLoader, setBtnLoader] = useState(false);

  const changeMaximumEmailsPerDay = value => {
    setMaximumEmailsPerDay(value);
  };

  const changeMinimumDelayBtwEmails = value => {
    setMinimumDelayBtwEmails(value);
  };

  const changeDefaultDaysforOOOEmails = value => {
    setOotoResumeDays(value);
  };

  const showAdminPopup = () => {
    let msg =
      "Please contact your account administrator to change your daily limit";
    Utils.showMessagePopup(msg);
    setMaximumEmailsPerDay(userDetail.userFeatureFlag.dailySendLimit);
  };

  const validateEmailLimit = limit => {
    if (limit <= DAILY_EMAIL_SEND_LIMIT && limit > 0) {
      setMaximumEmailsError("");
      return true;
    } else {
      setMaximumEmailsError(
        "The emails that can be sent should be between 1 and 1000"
      );
    }
  };

  const validateDelayBetweenEmails = delay => {
    if (delay >= 15 && delay <= 9999) {
      setMinimumDelayError("");
      return true;
    } else {
      setMinimumDelayError(
        "The delay between emails should be between 15 and 9999"
      );
    }
  };

  const validateOOODays = value => {
    if (value >= 1 && value <= 30) {
      setMinimumOOODayError("");
      return true;
    } else {
      setMinimumOOODayError(
        "The default OOO days for sending emails should be between 1 and 30"
      );
    }
  };

  const saveSafetySettings = async () => {
    if (Utils.isAdmin()) {
      if (
        validateEmailLimit(maximumEmailsPerDay) &&
        validateDelayBetweenEmails(minimumDelayBtwEmails) &&
        validateOOODays(ootoResumeDays)
      ) {
        setBtnLoader(true);
        await settingsStoreData.updateEmailSettings(
          {
            dailyLimit: maximumEmailsPerDay,
            emailInterval: minimumDelayBtwEmails,
            openTrackingEnabled: openTrack,
            clickTrackingEnabled: linkTrack,
            ootoResumeDays: ootoResumeDays
          },
          history,
          planName
        );
        setBtnLoader(false);
      }
    } else {
      showAdminPopup();
    }
  };

  const submitAction = event => {
    event.preventDefault();
    event.keyCode === 13 && saveSafetySettings();
  };

  const markOpenTrack = value => {
    setOpenTrack(value);
  };

  const markLinkTrack = value => {
    if (!openTrack) {
      setLinkTrack(true);
      return;
    }

    setLinkTrack(value);
  };

  const safetySettingsConfig = [
    {
      type: "number",
      id: "maximumEmailsPerDay",
      changeCbk: changeMaximumEmailsPerDay,
      min: 1,
      max: 1000,
      value: maximumEmailsPerDay,
      name: "maximumEmailsPerDay",
      placeholder: "Enter a value between 1 to 1000",
      label: "Max emails per day",
      subText: `Maximum number of sequence emails that a user from your team can send from each connected inbox per day. To ensure your organization's domain stays healthy, we recommend you set this limit to less than 200.`,
      errMsg: maximumEmailsError,
      submitCbk: submitAction,
      loader: loader,
      upgradePlanType: "emailDailyLimit"
    },
    {
      type: "number",
      id: "minimumDelayBtwEmails",
      changeCbk: changeMinimumDelayBtwEmails,
      min: 15,
      max: 9999,
      value: minimumDelayBtwEmails,
      name: "minimumDelayBtwEmails",
      placeholder: "Enter a value between 15 and 9999",
      label: "Min delay between emails",
      subLabel: "(in seconds)",
      subText: `Minimum delay between emails sent from your sequences.`,
      errMsg: minimumDelayError,
      submitCbk: submitAction,
      loader: loader,
      upgradePlanType: "emailIntervalInSeconds"
    },
    {
      type: "number",
      id: "ootoResumeDays",
      changeCbk: changeDefaultDaysforOOOEmails,
      min: 1,
      max: 30,
      value: ootoResumeDays,
      name: "ootoResumeDays",
      placeholder: "",
      label: "Default days to wait for OOO emails",
      subText: `If return date is not available within the OOO emails, Salesgear will automatically wait for the below mentioned days before resuming the contact in the respective sequence.`,
      errMsg: minimumOOODayError,
      submitCbk: submitAction,
      loader: loader
    }
  ];

  const trackingSectionConfig = [
    {
      label: "Enable open tracking",
      id: "openTrack",
      cbk: markOpenTrack,
      checked: openTrack
    },
    {
      label: "Enable link tracking",
      id: "linkTrack",
      cbk: markLinkTrack,
      checked: linkTrack
    }
  ];

  useEffect(() => {
    !openTrack && setLinkTrack(true);
  }, [openTrack]);

  return (
    <section className="safetySettingsWrapper">
      <div className="safetySettingsContainer">
        <h3 className="safetySettingsTitle">Email Settings</h3>
        {safetySettingsConfig?.map(item => (
          <NumberInputBox {...item} key={item.id} />
        ))}
        <section className="trackingWrapper">
          {trackingSectionConfig?.map(item => (
            <SecondaryCheckbox key={item?.id} {...item} />
          ))}
        </section>
        <Button
          id="saveSafetySettings"
          name="saveSafetySettings"
          btnText="Save Changes"
          type="button"
          className={`saveSafetySettings ${btnLoader ? "loading" : ""}`}
          loader={btnLoader}
          click={saveSafetySettings}
        />
      </div>
    </section>
  );
};

export { SafetySettings };
export default SafetySettings;
