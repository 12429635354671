import React from "react";
import Tooltip from "Components/common/Tooltip";
import Utils from "./utils";
import { MXP_EVENT, PAGE_TYPE } from "Static/MixPanelEvents";
import { toasterState } from "Components/common/toaster";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { MATCH_SENDER_TOKEN, SEQ_CONTACT_TAB_FILTER_OBJECT } from "Model/model";
import { sequenceStoreData } from "Stores/sequenceData";
import userDetail from "Stores/userDetail";
import { SEQ_BACKGROUND_MSG } from "Model/model";
import { MessageResponseHandlerForResume } from "Pages/Dashboard/sequences/SequencePage/MessageResponseHandler";
import { settingsStoreData } from "Stores/settingsData";
import { sequenceRenderState } from "Pages/Dashboard/sequences/SequencePage/sequenceProperties";
import InboxUtils from "Utils/InboxUtils";

const SequenceUtils = {};

SequenceUtils.checkEmailSteps = sequence => {
  const steps = sequence?.steps || [];

  const hasEmailStep = Object.values(steps).some(step =>
    Object.values(step.variants).some(
      variant => variant.type === "manualEmail" || variant.type === "email"
    )
  );

  return hasEmailStep;
};

SequenceUtils.checkSelectedInboxId = sequence => {
  const activeConnectedInbox = InboxUtils.getActiveConnectedInbox();
  const inboxIds =
    activeConnectedInbox?.length > 0
      ? activeConnectedInbox?.map(item => item?.id)
      : [];
  const isInboxAvailable = sequence.inboxIds.some(value => {
    return inboxIds.includes(value);
  });
  return isInboxAvailable;
};

SequenceUtils.isEmailAccountConnected = props => {
  if (Utils.isAccountConnected()) {
    return true;
  } else {
    Utils.showAddAccountPopup(props);
    return false;
  }
};

const hasSteps = (props, sequence, id, pageName) => {
  if (SequenceUtils.hasSteps(sequence)) {
    return true;
  } else SequenceUtils.showAddStagePopup(props, id, pageName);
  return false;
};

SequenceUtils.hasSteps = (sequence = {}) => {
  return sequence?.steps && Object.keys(sequence?.steps)?.length > 0;
};

SequenceUtils.isLinkedinAccountConnected = () => {
  const liAccounts = userDetail?.activeLinkedInAccounts || [];
  return liAccounts?.length > 0 || false;
};

SequenceUtils.isFirstStageReplyThread = sequence => {
  if (!sequence?.firstStageIsReply) {
    return true;
  } else {
    SequenceUtils.showContactReplyThreadPopup();
    return false;
  }
};

SequenceUtils.checkAndAllowContactUpload = (
  props = {},
  sequence = {},
  id = "",
  pageName = ""
) => {
  if (SequenceUtils.checkEmailSteps(sequence)) {
    return (
      SequenceUtils.isEmailAccountConnected(props) &&
      hasSteps(props, sequence, id, pageName) &&
      SequenceUtils.isFirstStageReplyThread(sequence)
    );
  } else {
    return (
      hasSteps(props, sequence, id, pageName) &&
      SequenceUtils.isFirstStageReplyThread(sequence)
    );
  }
};

SequenceUtils.showAddStagePopup = (props, id, pageName) => {
  let isContactPage = pageName === "contacts";
  const navigateToSeq = () => {
    if (id && props?.history && !isContactPage) {
      let location = window.location.pathname;
      location += "/" + id;
      props.history.push(location);
    }
  };
  confirmationPopupState.setPopupValues({
    title: "Add steps to continue",
    content: "Add steps before adding contacts to the sequence",
    actionBtnText: "Ok",
    needCancelBtn: isContactPage ? false : true,
    callback: isContactPage
      ? () => confirmationPopupState.setShowPopup(false)
      : navigateToSeq
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.showContactReplyThreadPopup = () => {
  confirmationPopupState.setPopupValues({
    title: "First step is reply thread",
    content:
      "You can only add contacts to a followup sequence (step 1 as reply) from Gmail.",
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => confirmationPopupState.setShowPopup(false)
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.getOwnerName = (owner = "") => {
  const { firstName: fn = "", lastName: ln = "", email = "" } = owner || {};
  let abbrName =
    fn && ln
      ? `${fn[0]}${ln[0]}`
      : fn?.length > 2
      ? fn.substring(0, 2)
      : ln?.length > 2
      ? ln.substring(0, 2)
      : email?.substring(0, 2);
  let fullName =
    fn && ln
      ? `${fn} ${ln}`
      : fn?.length > 2
      ? fn
      : ln?.length > 2
      ? ln
      : email;
  return (
    <Tooltip placement="right" tooltip={`Shared by ${fullName}`}>
      <span className="owner">{abbrName}</span>
    </Tooltip>
  );
};

SequenceUtils.getNextScheduledEstimated = (
  nextStep = 0,
  nextEmailDate = ""
) => {
  return (
    (nextStep !== 0 && (
      <>
        <i className="material-icons-outlined">email</i>
        &nbsp;
        {nextStep &&
          `Step #${nextStep} ${
            nextEmailDate !== null
              ? " - " + Utils.formatDate(nextEmailDate)
              : ""
          }`}
      </>
    )) ||
    "--"
  );
};

SequenceUtils.showMissingSenderTokenPopup = props => {
  const updateMissingSenderTokens = () => {
    return sequenceStoreData?.missingSenderTokens
      .map(item => MATCH_SENDER_TOKEN[item])
      .join(", ");
  };

  confirmationPopupState.setPopupValues({
    title: "",
    content: `<div>The following sender variables are missing. <br/> Please update them before adding contacts to your sequence
      </div><div class="matchSenderTokens">( ${updateMissingSenderTokens()} )</div>`,
    actionBtnText: "Ok",
    callback: () => {
      confirmationPopupState.setShowPopup(false);
      props && props.history && props.history.push("/settings/general/profile");
    }
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.hasTask = steps => {
  let hasTask = false;
  if (steps && Object.keys(steps).length) {
    for (let property in steps) {
      const variants = steps?.[property]?.variants;
      for (let variantProperty in variants) {
        const stageType = variants?.[variantProperty]?.type;
        if (!["email"].includes(stageType)) {
          hasTask = true;
          break;
        }
      }
    }
  }
  return hasTask;
};

SequenceUtils.checkEligibleSequenceCount = () => {
  const { noOfSequencesEligible = 0, noOfSequencesUsed = 0 } =
    userDetail.userFeatureFlag || {};
  let count = noOfSequencesEligible - noOfSequencesUsed;
  return count > 0;
};

SequenceUtils.convertContentToString = content => {
  if (content) {
    content = document.createRange().createContextualFragment(content);
    return Utils.addSpaceInHtmlToStringConversion(content);
  }
  return content;
};

SequenceUtils.getGreatestVariantUniqueId = (
  tempGreatestVariantUniqueId,
  currentVariants
) => {
  return (
    tempGreatestVariantUniqueId ||
    (Object.keys(currentVariants).length &&
      Math.max.apply(
        Math,
        Utils.convertObjectToArray(currentVariants).map(function (item) {
          return item.uniqueId;
        })
      )) ||
    0
  );
};

SequenceUtils.getUniqueVariantId = (greatestUniqueId, stageLen = 0) => {
  return greatestUniqueId ? greatestUniqueId + 1 : stageLen + 1;
};

SequenceUtils.showNoInboxconnectedPopup = (props, redirectLink = "") => {
  confirmationPopupState.setPopupValues({
    content:
      "Please assign your inbox to the sequence before adding contacts to that sequence.",
    actionBtnText: "Ok",
    callback: () => {
      confirmationPopupState.setShowPopup(false);
      redirectLink &&
        props &&
        props.history &&
        props.history.push(redirectLink);
    },
    needCancelBtn: false
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.checkInboxIdsExists = currentSeqData => {
  let updatedData = [];
  if (currentSeqData?.inboxIds) {
    if (currentSeqData?.inboxIds?.length > 0) {
      updatedData =
        userDetail?.connectedInboxes?.length &&
        userDetail.connectedInboxes.filter(
          item =>
            currentSeqData?.inboxIds?.includes(item?.id) &&
            item?.status === "ACTIVE"
        );
    }
  }
  return updatedData?.length > 0;
};

SequenceUtils.getInvalidTokensPrompt = (subject = "", content = "") => {
  const {
    invalidTokenList: subInvalidTokenList = [],
    hasInvalidTokens: hasInvalidTokensInSubject
  } =
    subject &&
    Utils.replaceTokenWithValue(
      subject,
      false,
      true,
      settingsStoreData?.customFields
    );
  const {
    invalidTokenList: contentInvalidTokenList = [],
    hasInvalidTokens: hasInvalidTokensInContent
  } =
    content &&
    Utils.replaceTokenWithValue(
      content,
      false,
      true,
      settingsStoreData?.customFields
    );
  const mergedTokenList = [
    ...new Set([...subInvalidTokenList, ...contentInvalidTokenList])
  ];
  const PopupContent = () => {
    return (
      <div class="invalidVariablesText">
        Please update the following invalid variables before saving:{" "}
        {SequenceUtils.tokensOrderList(mergedTokenList)}
      </div>
    );
  };

  if (hasInvalidTokensInSubject || hasInvalidTokensInContent) {
    confirmationPopupState.setPopupValues({
      content: <PopupContent />,
      actionBtnText: "Ok",
      needCancelBtn: false,
      dynamicDom: true,
      callback: () => {
        confirmationPopupState.setShowPopup(false);
      }
    });
    confirmationPopupState.setShowPopup(true);
    return true;
  }
  return false;
};

SequenceUtils.getSequencePath = (currentSeq, folderIdFromURL) => {
  return `/sequences/${
    currentSeq?.shareType?.toLowerCase() === "shared"
      ? "shared"
      : folderIdFromURL || "all"
  }/${currentSeq?.id}#settings`;
};

SequenceUtils.getActiveSequenceListFromLocal = () => {
  const data = localStorage.getItem("activeSequenceList");
  const parsedData = JSON.parse(data) || [];
  return [...new Set(parsedData?.ids)] || [];
};

SequenceUtils.removeActiveSequenceList = () => {
  localStorage.removeItem("activeSequenceList");
  sequenceStoreData.setActiveSequenceList([]);
};

SequenceUtils.addActiveSequenceIdToLocalStorage = (list = []) => {
  const { inFreePlan = false, noOfSequencesEligible = 3 } =
    userDetail.userFeatureFlag || {};
  if (inFreePlan) {
    let activeSequenceList = SequenceUtils.getActiveSequenceListFromLocal();
    let activeSequenceListLength = activeSequenceList?.length || 0;
    let newList = activeSequenceListLength ? activeSequenceList : [];
    if (activeSequenceListLength < noOfSequencesEligible) {
      list.filter(item => {
        if (
          !(
            activeSequenceListLength && activeSequenceList?.includes(item?.id)
          ) &&
          newList?.length < noOfSequencesEligible
        ) {
          newList.push(item.id);
        }
      });
      localStorage.setItem(
        "activeSequenceList",
        JSON.stringify({
          ids: [...new Set(newList)]
        })
      );
    }
    sequenceStoreData.setActiveSequenceList(newList);
  } else {
    SequenceUtils.removeActiveSequenceList();
  }
};

SequenceUtils.removeActiveSequenceIdFromLocalStorage = (id = "") => {
  let localData = SequenceUtils.getActiveSequenceListFromLocal();
  let newList = localData.filter(item => item !== id);
  localStorage.setItem(
    "activeSequenceList",
    JSON.stringify({
      ids: newList
    })
  );
  sequenceStoreData.setActiveSequenceList(newList);
};

SequenceUtils.tokensOrderList = (tokenList = []) => {
  if (tokenList?.length) {
    return (
      <ol className="tokensOrderList">
        {tokenList.map(item => (
          <li key={`token${item}`}>{`${item}`}</li>
        ))}
      </ol>
    );
  }
  return "";
};

SequenceUtils.createSequenceRedirectUrl = (
  seqId = "",
  folderId = "",
  sharedType = "",
  email = "",
  hashId = ""
) => {
  const { email: currentUserEmail = "" } = userDetail?.userInfo || {};
  if (currentUserEmail === email || sharedType) {
    return `/sequences/${
      sharedType && currentUserEmail !== email ? "shared" : folderId || "all"
    }/${seqId}${hashId}`;
  } else {
    toasterState.setToastMsg(
      "Sorry, you cannot access this private sequence",
      "fail"
    );
  }
};

SequenceUtils.getSequenceStatus = (data = {}) => {
  const { pauseStatus = "", resumeStatus = "", status = "" } = data || {};
  if (!pauseStatus && !resumeStatus) {
    return {
      status: status === "PAUSED" ? "PAUSE" : status,
      loading: false
    };
  } else if (pauseStatus?.toUpperCase() === "INPROGRESS" && !status) {
    return {
      status: "PAUSE",
      loading: true
    };
  } else if (
    resumeStatus?.toUpperCase() === "INPROGRESS" &&
    status === "PAUSED"
  ) {
    return {
      status: "RESUME",
      loading: true
    };
  } else {
    return { status, loading: false };
  }
};

SequenceUtils.resumeSequenceEnabled = (props = {}, type = "", seqData = {}) => {
  if (["ACTIVE", "RESUME"].includes(type?.toUpperCase())) {
    if (SequenceUtils.checkEmailSteps(seqData) && !Utils.isAccountConnected()) {
      Utils.showAddAccountPopup(
        props,
        "Please connect your email account before resuming the sequences"
      );
      return false;
    }
  }
  return true;
};

SequenceUtils.assignInboxPopup = (props = {}, redirectUrl = "") => {
  confirmationPopupState.setPopupValues({
    content:
      "Please assign your inbox to the sequence to resume your contacts.",
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => {
      props.history.push(
        redirectUrl ? `${redirectUrl}/#settings` : "#settings"
      );
    }
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.handleSuccessResponseForPauseResume = (
  background = false,
  type = ""
) => {
  if (background) {
    let content = SEQ_BACKGROUND_MSG?.[type?.toLowerCase()];
    if (content) {
      confirmationPopupState.setPopupValues({
        content,
        actionBtnText: "Ok",
        needCancelBtn: false,
        callback: () => confirmationPopupState.setShowPopup(false)
      });
      confirmationPopupState.setShowPopup(true);
    }
  } else {
    toasterState.setToastMsg(
      `Sequence successfully ${
        ["PAUSE", "PASUED"].includes(type) ? "paused" : "resumed"
      }`,
      "success"
    );
  }
};

SequenceUtils.handleSuccessFailedResponseForResume = (
  props = {},
  redirectUrl = "",
  errorObj = {},
  bulkOperationResult = {}
) => {
  const { success = 0, failed = 0 } = bulkOperationResult || {};
  if (errorObj && Object.keys(errorObj)?.length > 0) {
    const { respCode = "", inboxError = {} } = errorObj || {};
    if (+respCode === 407) {
      SequenceUtils.assignInboxPopup(props, redirectUrl);
    } else if (+respCode === 403) {
      toasterState.setToastMsg(
        "Please contact the sequence owner to pause or resume this sequence",
        "failure"
      );
    } else if (+respCode === 408) {
      let toastMsgTxt =
        failed > 0
          ? `Oops! Failed to resume ${failed} ${
              failed > 1 ? "contacts" : "contact"
            }.`
          : "Oops! Failed to resume selected contacts ";
      toasterState.setToastMsg(toastMsgTxt, "failure");
    } else if (
      +respCode === 200 ||
      (inboxError && Object.keys(inboxError)?.length)
    ) {
      confirmationPopupState.setPopupValues({
        content: (
          <div className="retryResponse">
            <MessageResponseHandlerForResume resumeResponse={errorObj} />
          </div>
        ),
        dynamicDom: true,
        actionBtnText: "Ok",
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        },
        needCancelBtn: false
      });
      confirmationPopupState.setShowPopup(true);
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  }
};

SequenceUtils.handleFailedResponseForPauseResume = (
  props = {},
  redirectUrl = "",
  errorObj = {},
  response = ""
) => {
  if (errorObj && Object.keys(errorObj)?.length > 0) {
    for (const property in errorObj) {
      if (+property === 407) {
        SequenceUtils.assignInboxPopup(props, redirectUrl);
      } else if (+property === 408) {
        toasterState.setToastMsg(
          "Oops! Failed to resume selected contacts.",
          "failure"
        );
      } else if (+property === 403) {
        toasterState.setToastMsg(
          "Please contact the sequence owner to pause or resume this sequence",
          "failure"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    }
  } else {
    let responseStatus = +response?.response?.status || "";
    if (
      response?.message?.message === "cancel" ||
      response?.message === "cancel"
    ) {
      console.error("Request cancelled");
    } else if (responseStatus === 401) {
      Utils.showLoggedOutPopup();
    } else if (responseStatus === 406) {
      toasterState.setToastMsg(
        "This contact has invalid subject line",
        "failure"
      );
    } else if (responseStatus === 426) {
      Utils.showAccountSuspendedPopup();
    } else if (responseStatus === 408) {
      toasterState.setToastMsg("Request Timed Out", "failure");
    } else if (responseStatus === 409) {
      toasterState.setToastMsg(
        `Oops! Something went wrong. To resolve the issue, please contact us at support@salesgear.io`,
        "failure"
      );
    } else if (responseStatus === 407) {
      SequenceUtils.assignInboxPopup(props, redirectUrl);
    } else if (responseStatus === 403) {
      toasterState.setToastMsg(
        "Please contact the sequence owner to pause or resume this sequence",
        "failure"
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "fail"
      );
    }
  }
};

SequenceUtils.promptEmailValidationNoCredits = (
  count = 1,
  history = "",
  processCbk = () => {},
  closeCbk = () => {},
  pagename = ""
) => {
  confirmationPopupState.setPopupValues({
    content: `Insufficient validation credits to validate ${count} contact${
      count > 1 ? "s" : ""
    }. Continue adding to sequence without email validation?`,
    actionBtnText: "Continue",
    cancelBtnText: "Add credits",
    callback: () => {
      Utils.mixpanelTrack(MXP_EVENT.ADD_CREDITS_EMAIL_VALIDATION, {
        pageType: PAGE_TYPE[pagename]
      });
      confirmationPopupState.setShowPopup(false);
      processCbk();
    },
    cancelCbk: () => {
      closeCbk();
      confirmationPopupState.setShowPopup(false);
      history && history.push("/settings/creditUsage");
    }
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.getSelectedTab = (value = "") => {
  const temp = value || sequenceRenderState?.currentStage || "";
  return [
    ...(temp?.toLowerCase() === "ongoing"
      ? ["ACTIVE"]
      : temp?.toLowerCase() === "auto_reply"
      ? ["AUTO_REPLY", "PAUSED_OOTO"]
      : [temp?.toUpperCase()])
  ];
};

SequenceUtils.showActiveJobPopupForBulkActions = (
  customText = `Another process is currently in progress; this may take a few to several minutes. Please wait or try again later.`
) => {
  confirmationPopupState.setPopupValues({
    content: customText,
    actionBtnText: "Ok",
    needCancelBtn: false,
    callback: () => confirmationPopupState.setShowPopup(false)
  });
  confirmationPopupState.setShowPopup(true);
};

SequenceUtils.inboxConnected = (sequence = {}) => {
  return SequenceUtils.checkInboxIdsExists(sequence);
};

SequenceUtils.isSequenceOwner = (sequenceMemberId = "") => {
  const { memberId = "" } = userDetail?.userInfo || {};
  return sequenceMemberId === memberId;
};

SequenceUtils.getActiveTabFilterPayload = (tab = "") => {
  let filters = { all: false };
  if (Utils.isEligibleContactFilter(tab)) {
    filters.statuses = SequenceUtils.getSelectedTab(tab);
  } else if (tab?.toLowerCase() !== "total") {
    let fields = [
      {
        fieldName: SEQ_CONTACT_TAB_FILTER_OBJECT[tab?.toLowerCase()] || tab,
        operator: "GREATER_THAN",
        value: 0
      }
    ];
    filters.fields = fields;
  }
  return filters;
};

SequenceUtils.getFirstEmailstep = data => {
  for (let i = 0; i < data.length; i++) {
    const obj = data[i];
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const variants = obj[key].variants;
        for (const variantKey in variants) {
          if (variants.hasOwnProperty(variantKey)) {
            const variant = variants[variantKey];
            if (
              variant.type.toLowerCase() === "email" ||
              variant.type.toLowerCase() === "manualemail"
            ) {
              return true;
            }
          }
        }
      }
    }
  }
  return false;
};

SequenceUtils.getProccessedResponseData = resData => {
  let finalData = [];
  if (Array.isArray(resData) && resData.length) {
    resData.map(value => {
      let sequenceData = {};
      if (value?.name) {
        const {
          id,
          folderId,
          clickCount,
          contactsAdded,
          name,
          openCount,
          replyCount,
          steps,
          status,
          sentCount,
          accessType,
          shareType,
          owner,
          memberId,
          createTs,
          scheduleWindowId = "",
          firstStageIsReply = false,
          inboxIds = [],
          settings,
          excludeContactEnabled,
          pauseStatus = "",
          resumeStatus = "",
          emailValidationEnabled = false,
          stopEmailOnMeetingBooked = false,
          stopEmailOnReplyReceived = false,
          stopEmailOnLinkedInReplyReceived = false
        } = value || {};

        let background = SequenceUtils.getSequenceStatus(value);
        sequenceData = {
          id,
          folderId,
          clickCount,
          contactsAdded,
          name,
          openCount,
          replyCount,
          sentCount,
          status,
          accessType,
          shareType,
          owner,
          memberId,
          createTs,
          scheduleWindowId,
          inboxIds,
          firstStageIsReply,
          settings,
          steps: steps || {},
          excludeContactEnabled,
          pauseStatus,
          resumeStatus,
          background,
          emailValidationEnabled,
          stopEmailOnMeetingBooked,
          stopEmailOnReplyReceived,
          stopEmailOnLinkedInReplyReceived
        };
        finalData.push(sequenceData);
      }
    });
  }
  return finalData;
};

export default SequenceUtils;
export { SequenceUtils };
