import React, { useState } from "react";
import { ReactComponent as AddIcon } from "./images/addIcon.svg";
import { ReactComponent as RemoveIcon } from "./images/removeIcon.svg";

const CollapsiveContent = ({ title = "", content = "" }) => {
  const [active, setActive] = useState(false);

  const collapseBtn = e => {
    e.stopPropagation();
    setActive(!active);

    let contentDiv = e.target.closest(".collapsible")?.nextElementSibling;
    if (!contentDiv) return;

    if (contentDiv.style.maxHeight) {
      contentDiv.style.maxHeight = null;
      contentDiv.style.padding = "0px 18px";
    } else {
      contentDiv.style.padding = "8px 18px";
      contentDiv.style.maxHeight = contentDiv.scrollHeight + 16 + "px";
    }
  };

  return (
    <>
      <div className="collapsible" onClick={collapseBtn}>
        <span className="addRemoveIcon">
          {!active ? <AddIcon /> : <RemoveIcon />}
        </span>
        {title}
      </div>
      <div className="collapsiveContentBody">
        <p
          className="collapsiveTextTp"
          dangerouslySetInnerHTML={{ __html: content }}
        />
      </div>
    </>
  );
};

export default CollapsiveContent;
