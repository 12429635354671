import React from "react";
import LimitErr from "Components/PreviewPopup/LimitErr";
import { URLS, makeApi } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { CommonUtils } from "./commonUtils";
import { confirmationPopupState } from "Components/common/confirmationPopup";
const { observable, action } = require("mobx");

class PullContactsStore {
  @observable pasteInputValue = "";
  @observable showPopup = false;
  @observable searchLoading = false;
  @observable pullContactsCount = 0;
  @observable extractBtn = false;
  @observable extractBtnLoading = false;
  @observable showListSeq = false;
  @observable addToListChecked = false;
  @observable addToSeqChecked = false;
  @observable totalContactList = [];
  @observable listInfo = {};
  @observable disableExtractBtn = false;
  @observable sequenceList = [];
  @observable selectedSequence = {};
  @observable createListError = "";
  @observable allowSequence = true;

  @action
  setAllowSequence = (val = "") => {
    this.allowSequence = val;
  };

  @action
  setPasteInputValue = (value = "") => {
    this.pasteInputValue = value;
  };

  @action
  setShowPopup = (value = false) => {
    this.showPopup = value;
  };

  @action
  setSearchLoading = (value = false) => {
    this.searchLoading = value;
  };

  @action
  setPullContactsCount = (value = false) => {
    this.pullContactsCount = value;
  };

  @action
  setExtractBtn = (value = false) => {
    this.extractBtn = value;
  };

  @action
  setExtractBtnLoading = (value = false) => {
    this.extractBtnLoading = value;
  };

  @action
  setShowListSeq = (value = false) => {
    this.showListSeq = value;
  };

  @action
  setAddToListChecked = (value = false) => {
    this.addToListChecked = value;
  };

  @action
  setAddToSeqChecked = (value = false) => {
    this.addToSeqChecked = value;
  };

  @action
  setTotalContactList = (val = []) => {
    this.totalContactList = val;
  };

  @action
  setListInfo = (value = {}) => {
    this.listInfo = value;
  };

  @action
  setDisableExtractBtn = (value = false) => {
    this.disableExtractBtn = value;
  };

  @action
  setSequenceList = (list = []) => {
    this.sequenceList = list;
  };

  @action
  setSelectedSequence = (seq = {}) => {
    this.selectedSequence = seq;
  };

  @action
  setCreateListError = (val = "") => {
    this.createListError = val;
  };

  @action
  resetFunc = () => {
    this.setSelectedSequence({});
    this.setListInfo({});
    this.setExtractBtn(false);
    this.setExtractBtnLoading(false);
    this.setDisableExtractBtn(false);
    this.setShowListSeq(false);
    this.setShowPopup(false);
    this.setAddToListChecked(false);
    this.setAddToSeqChecked(false);
    this.setPasteInputValue("");
    this.setCreateListError("");
  };

  @action
  getSequenceData = async (data = {}) => {
    if (data && Object.keys(data)?.length > 0) {
      const config = {
        url: URLS.listAllTriggerSequences,
        method: "POST",
        data
      };
      let response = await makeApi(config);
      if (response?.data) {
        this.setSequenceList([
          ...this.sequenceList,
          ...response?.data?.campaignSequenceResponseList
        ]);
        return response?.data;
      } else {
        Utils.showApiFailureResponse(response);
        return {};
      }
    }
  };

  @action
  getContactList = async () => {
    try {
      const config = {
        url: URLS.getContactList,
        method: "POST",
        data: {
          limit: 250,
          pageNo: 1
        }
      };
      let res = await makeApi(config);
      if (res?.data) {
        const { listResponse = {} } = res?.data || {};
        const { statusCode = "", list = [] } = listResponse || {};
        if (statusCode === 200) {
          this.setTotalContactList(list);
        }
      }
    } catch (err) {
      console.error("Error in fetching contact list", err);
    }
  };

  @action
  createContactList = async listName => {
    try {
      const config = {
        url: URLS.createContactList,
        method: "POST",
        data: { listName }
      };
      let res = await makeApi(config);
      if (res?.data?.statusCode === 200) {
        const { listResponse = {} } = res?.data || {};
        if (listResponse && Object.keys(listResponse)?.length > 0) {
          const { statusCode = "" } = listResponse || {};
          if (statusCode === 200) {
            this.setCreateListError("");
            this.getContactList();
            this.setDisableExtractBtn(false);
            this.setListInfo(listResponse?.list?.[0]);
          } else {
            if (statusCode === 409) {
              this.setCreateListError("List name already exists");
            } else {
              this.setCreateListError(
                "Create list failed. Please contact support"
              );
            }
            this.setDisableExtractBtn(true);
          }
        }
      } else {
        this.setDisableExtractBtn(true);
        this.setCreateListError("Create list failed. Please contact support");
      }
    } catch (err) {
      console.error("Error in creating contact list", err);
    }
  };

  @action
  lookupContacts = async () => {
    const config = {
      url: URLS.lookupContacts,
      method: "POST",
      data: {
        url: this.pasteInputValue
      }
    };
    let res = await makeApi(config);
    this.setSearchLoading(false);
    this.setExtractBtn(true);
    const { data = {} } = res || {};
    const responseCode = data?.responseCode;
    if (+responseCode === 200) {
      this.setPullContactsCount(data.count || 0);
      if (data.count === 0) {
        this.setExtractBtn(false);
      }
    } else {
      this.setExtractBtn(false);
      if (+responseCode === 100) {
        toasterState.setToastMsg(
          "You’ve reached your daily search limit. Contact support.",
          "failure"
        );
        this.setExtractBtn(false);
      } else if (+responseCode === 403) {
        toasterState.setToastMsg(
          "Sales navigator account not connected",
          "failure"
        );
      } else if (responseCode === 404) {
        toasterState.setToastMsg(
          "No contacts found for this URL. Please check and try again.",
          "failure"
        );
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "failure"
        );
      }
    }
  };

  @action
  extractContacts = async history => {
    const config = {
      url: URLS.extractContacts,
      method: "POST",
      data: {
        url: this.pasteInputValue,
        listId: this.listInfo.id ? [this.listInfo.id] : [],
        sequenceId: this.addToSeqChecked ? this.selectedSequence.id : "",
        totalContacts: this.pullContactsCount || 0
      }
    };
    let res = await makeApi(config);
    const { data = {} } = res || {};
    this.resetFunc();
    const responseCode = data?.responseCode;

    if (+res?.data?.code === 202) {
      confirmationPopupState.setPopupValues({
        content: (
          <LimitErr
            headingContent={`Oops! You’re trying to configure limits beyond what’s available in your plan. Upgrade to a higher plan to increase your limit.`}
            limitMessage={`Sales Navigator Imports Limit reached.`}
          />
        ),
        dynamicDom: true,
        actionBtnText: "Okay",
        cancelBtnText: "Upgrade",
        cancelCbk: () => {
          confirmationPopupState.setShowPopup(false);
          history && history.push("/pricing");
        },
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
      return;
    }
    if (+responseCode === 200) {
      confirmationPopupState.setPopupValues({
        content: `Your LinkedIn contacts extract is successfully scheduled. The contacts will be added in a few minutes. You can view the status on the Imports page.`,
        actionBtnText: "Go to Imports",
        callback: () => {
          history.push("/settings/imports/linkedin");
          confirmationPopupState.setShowPopup(false);
        }
      });
      confirmationPopupState.setShowPopup(true);
    } else if (+responseCode === 201) {
      const htmlString = `${CommonUtils.viewAllJobsDom()}`;
      CommonUtils.showToasterMsgWithCustomTimeout(
        `An active sync job is already in progress. Please pause the active job or wait till it completes. ${htmlString}`,
        2000,
        "failure",
        true,
        true
      );
    } else if (+responseCode === 100) {
      toasterState.setToastMsg(
        "You’ve reached your daily search limit. Contact support.",
        "failure"
      );
    } else if (+responseCode === 403) {
      Utils.showAddLinkedInAccountPopup(
        history,
        "Connect your LinkedIn Sales Navigator account to continue. "
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong. Please try again later",
        "failure"
      );
    }
  };
}

const pullContactState = new PullContactsStore();

export { pullContactState };
