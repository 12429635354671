/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useLocation, useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import DashboardRouting from "Routes/DashboardRouting";
import {
  getStripeInformation,
  fetchConnectedEmails,
  fetchLinkedInAccounts
} from "Utils/commonAPI";
import SideBar from "Global/SideBar/main";
import InputBoxPopup from "Components/common/inputPopup";
import ConfirmationPopup from "Components/common/confirmationPopup";
import Toaster from "Components/common/toaster";
import { settingsStoreData } from "Stores/settingsData";
import { sequenceStoreData } from "Stores/sequenceData";
import AddSequenceInContactPopup from "./contacts/addSeqInContactPopup";
import SnoozeTaskPopup from "Pages/Dashboard/tasks/PerformTask/snoozeTaskPopup";
import Utils from "Utils/utils";
import UpgradeCard from "Components/common/UpgradeCard";
import UpgradeSettingsCard from "Components/common/UpgradeSettingsCard";
import userDetail from "Stores/userDetail";
import HeaderNotification from "Components/common/HeaderNotification";
import ConstantObj from "Static/constant";
import ExportEmailNotify from "Components/popup/ExportEmailNotify";
import ExportProcessing from "Components/popup/ExportProcessing";
import { salesforceConnectedData } from "Pages/Dashboard/settings/Crm/salesforce/SalesforceConnectedData";
import BuyNewNumberPopup from "./settings/BuyNewNumberPopup";
import BuyCreditsPopup from "./settings/BuyCreditsPopup";
import TransactionAnimation from "Components/common/TransactionAnimation";
import AddNumberPopup from "./settings/AddNumberPopup";
import AddMyOwnNumberPopup from "./settings/AddMyOwnNumberPopup";
import VerifyNumberPopup from "./settings/VerifyNumberPopup";
import NewCardPaymentPopup from "./settings/NewCardPayment";
import DeleteCustomURLPopup from "./settings/CustomUrl/DeleteCustomUrlPopup";
import CustomUrlSuccessPopup from "./settings/CustomUrl/CustomUrlSuccessPopup";
import FileLimitExceedPopup from "Components/FileAttachment/FileLimitExceedPopup";
import ComingSoonPopup from "Components/common/ComingSoonPopup";
import TagPopup from "Components/common/tags/TagPopup";
import { tagsStoreData } from "Stores/TagsData";
import { AddBulkContactPopup } from "Components/common/bulkContactPopup/AddBulkContactPopup";
import EditContactPopup from "./contacts/EditContactPopup";
import AddSingleContactPopup from "Components/common/AddSingleContactPopup";
import PricingStore from "Stores/Pricing";
import DealifyPopup from "Pages/Register/Dealify/DealifyPopup";
import EditSequencePopup from "Components/common/EditSequencePopup";
import SuccessPopup from "Components/common/SuccessPopup";
import DeselectCheckedPopup from "Components/common/DeselectCheckedPopup";
import TrackingDelayBanner from "Components/common/Banner/TrackingDelayBanner";
import OutboxCardPopup from "./outbox/OutboxCardPopup";
import GmailMessagePopup from "./settings/ConnectInbox/GmailMessagePopup";
import UpgradePopup from "Components/Paywall/UpgradePopup";
import StepEmailCreateEditorPopup from "Pages/Dashboard/sequences/Stages/StepEmailCreateEditorPopup";
import TestEmailPopup from "Pages/Dashboard/sequences/Stages/TestEmailPopup";
import AddStagesPopup from "Pages/Dashboard/sequences/Stages/AddStagesPopup";
import ContactsSchedulePopup from "Components/popup/ContactsSchedulePopup";
import InitialWelcomePopup from "Components/common/InitialWelcomePopup";
import BulkAddTagsPopup from "Components/common/ContactFilter/BulkOperations/BulkAddTagsPopup";
import BulkRemoveTagsPopup from "Components/common/ContactFilter/BulkOperations/BulkRemoveTagsPopup";
import ContactUploadingToSequence from "Components/ContactCard/ContactUploadingToSequence";
import MissingFieldsValidationPopup from "Components/ContactCard/MissingFieldsValidationPopup";
import { DialerServiceData } from "Stores/DialerServiceStore";
import CreateEditListPopup from "Pages/Dashboard/dataSearch/CustomList/createEditListPopup";
import PopupLoader from "./dataSearch/PopupLoader";
import ContactAdminMessagePopup from "Pages/Dashboard/settings/MessagePopup";
import EmailPreviewContentPopup from "Components/PreviewPopup/EmailPreviewContentPopup";
import CalendarBookMeetingPopup from "Components/ContactCard/CalendarBookMeeting/Main";
import { EmailVerificationLoggedIn } from "../Register/EmailVerificationLoggedIn";
import CreateTeamPopup from "Pages/Dashboard/settings/UsersAndTeams/Teams/CreateTeamPopup";
import AddMembersPopup from "Pages/Dashboard/settings/UsersAndTeams/Teams/TeamMember/AddMembersPopup";
import CreateContactStagePopup from "Pages/Dashboard/settings/ContactStages/CreateContactStagePopup";
import CreateCallStagePopup from "Pages/Dashboard/settings/Calls/Disposition/CreateCallStagePopup";
import DialerSliderCard from "Components/DailerService/DialerSliderCard";
import globalCallState from "Stores/GlobalCallState";
import UpdateContactOwner from "Components/common/UpdateContactOwner";
import customListState from "Pages/Dashboard/dataSearch/CustomList/state";
import trackingStore from "../Tracking/trackingStore";
import { writingAssistantStoreData } from "Stores/WritingAssistantData";
import Header from "Global/Header/main";
import GoogleOAuthPopup from "./settings/ConnectInbox/googleOAuthPopup";
import AddDefaultInbox from "./settings/ConnectInbox/AddDefaultInbox";
import InitialScheduleInfoPopup from "./sequences/SequencePage/InitialScheduleInfoPopup";
import CreateSlackNotification from "./settings/Triggers/AddEditTrigger/CreateSlackNotification";
import NotificationBanner from "Components/common/Banner/NotificationBanner";
import ExportToSalesforcePopup from "Components/common/ExportToSalesforcePopup";
import LinkedInNotConnectedPopup from "./settings/ConnectedAccounts/LinkedInAccounts/popup/LinkedInNotConnectedPopup";
import ConnectLinkedInLoading from "./settings/ConnectedAccounts/LinkedInAccounts/popup/ConnectLinkedInLoading";
import LinkedInDetailsPopup from "./settings/ConnectedAccounts/LinkedInAccounts/popup/LinkedInDetailsPopup";
import LinkedInReplyContentPopup from "Components/PreviewPopup/LinkedInReplyContentPopup";
import { outboxStoreData } from "./outbox/outboxStore";
import onboardingStoreData from "Stores/onBoardingData";
import SyncConfirmationPopup from "./settings/Crm/SyncConfirmationPopup";
import SyncLogsHistoryPopup from "./settings/Crm/syncLogsHistoryPopup/main";
import PriceRangeSliderPopup from "Components/popup/PriceRangeSliderPopup/main";
import CreditUsagePopup from "Components/common/CreditsUsagePopup";
import OnBoardingPopup from "Components/common/onboarding/OnBoardingPopup";
import PullContacts from "../ImportContactsFromLinkedIn/PullContacts";
import EnrichProcessing from "Components/popup/EnrichProcessing";
import FindContactPopup from "Components/common/discoverPopup/FindContactPopup";
import FoundContactPopup from "Components/common/discoverPopup/FoundContactPopup";
import { dataSearchState } from "./dataSearch/state";
import PhoneNotFound from "Components/common/discoverPopup/PhoneNotFound";
import UpgradePlanPopup from "Components/common/discoverPopup/UpgradePlanPopup";

const DashboardModule = (props = {}) => {
  let location = useLocation();
  let history = useHistory();
  if (location?.hash === "#my_custom_link") {
    window && window.Intercom("show");
  }
  let queryParams = Utils.getQueryParams(location.search);

  //send count set to -1 during initialization these two will get compared
  const {
    emailSendLimit = 0,
    emailSentCount = -1,
    noOfSequencesUsed = 0
  } = userDetail?.userFeatureFlag || {};

  const [showSideBar, setSideBar] = useState(true);
  const [isTeamSuspended, setTeamSuspendedStatus] = useState(false);
  const [showUpgradePopup, setShowUpgradePopup] = useState(false);
  const [showUpgradeSettingsPopup, setShowUpgradeSettingsPopup] =
    useState(false);
  const [showPluginBanner, setPluginBanner] = useState(false);
  const [showTrackingDelayBanner, setShowTrackingDelayBanner] = useState(false);
  const [revokeAccessBanner, setRevokeAccessBanner] = useState(false);
  const [isLowDialerCredits, setLowDialerCredits] = useState(false);
  const [showDialerServiceCard, setDialerServiceCard] = useState(false);
  const [customUrlBanner, setCustomUrlBanner] = useState(false);
  const [showBanner, setShowBanner] = useState(true);
  const [foundData, setFoundData] = useState(false);
  const [singlePurchaseContactMail, setSinglePurchaseContactMail] =
    useState("");
  const [mobilePhone, setMobilePhone] = useState("");
  const [isMobileNumberFound, setIsMobileNumberFound] = useState(true);
  const [isNoPhoneCredits, setIsNoPhoneCredits] = useState(false);

  let pluginUser = queryParams?.get("source")?.includes("plugin");
  pluginUser && localStorage.setItem("isPluginUser", true);

  const findTeamStatus = teamStatus => {
    switch (teamStatus?.toLowerCase()) {
      case "suspended":
        setTeamSuspendedStatus(true);
        break;
      default:
        setTeamSuspendedStatus(false);
        break;
    }
  };

  const checkPluginInstalled = () => {
    setTimeout(() => {
      const isPluginInstalled = userDetail.pluginInstalled;
      if (!isTeamSuspended && !isPluginInstalled) {
        setPluginBanner(true);
      } else {
        setPluginBanner(false);
      }
    }, 6000);
  };

  const hidePluginBanner = (value = false) => {
    setPluginBanner(value);
  };

  const checkLowDialerCredits = () => {
    const {
      dialerCredits,
      usFreeCallingMinutesEligible = 0,
      usFreeCallingMinutesUsed = 0
    } = userDetail?.userFeatureFlag;
    const purchasedNumbers = DialerServiceData.purchasedNumbers;
    const purchasedNumberCount = purchasedNumbers?.length;
    const freeCallingMinutes =
      usFreeCallingMinutesEligible - usFreeCallingMinutesUsed;
    if (freeCallingMinutes <= 0 && dialerCredits < purchasedNumberCount) {
      setLowDialerCredits(true);
    }
  };

  const getBlockedDomainList = async () => {
    const response = (await Utils.fetchBlockedDomains()) || {};
    if (response) {
      userDetail.setBlockedDomainList(response?.data || []);
    }
  };

  const getInitChmlnScript = () => {
    if (userDetail?.userInfo?.memberId) {
      if (window && window.chmln) {
        var chmln = window.chmln;
        if (chmln) {
          chmln.identify(userDetail.userInfo.memberId, {
            email: userDetail?.userInfo?.email || "",
            name: Utils.getFullNameFromUserInfo(userDetail?.userInfo),
            role: userDetail?.userInfo?.role || 0
          });
          chmln.on("app:navigate", opts => {
            opts?.to && history.push(opts?.to);
          });
        }
      }
    }
  };

  const verifyCustomUrlIsSet = () => {
    const { domain = "" } = userDetail?.brandUrlInfo || {};
    if (!domain) {
      setCustomUrlBanner(true);
    }
  };

  const makeReplyCountApi = async () => {
    const isEmailConnected = await onboardingStoreData.getOnBoardingData();
    if (noOfSequencesUsed > 0 || isEmailConnected) {
      setInterval(() => {
        outboxStoreData.getOutboxUnreadCount();
      }, 900000);
    }
  };

  useEffect(() => {
    checkLowDialerCredits();
  }, [DialerServiceData.purchasedNumbers]);

  useEffect(() => {
    fetchLinkedInAccounts();
    makeReplyCountApi();
    trackingStore.getInstalledScriptDetails();
    Utils.registerMixpanel();
    outboxStoreData.getOutboxUnreadCount();
    settingsStoreData.getSettingsData();
    settingsStoreData.getUnsubscribeData();
    sequenceStoreData.getAllSequenceFolders();
    salesforceConnectedData.getSalesForceOauthData();
    writingAssistantStoreData.getPrompts();
    tagsStoreData.getAllTags();
    customListState.getContactList();
    settingsStoreData.getCustomFields();
    PricingStore.getActivePlanDetails();
    getStripeInformation();
    fetchConnectedEmails();
    getBlockedDomainList();
    getInitChmlnScript();
    verifyCustomUrlIsSet();
    return () => {
      setShowBanner(true);
    };
  }, []);

  useEffect(() => {
    if (window && window.sg) {
      var sg = window.sg;
      sg.identify(userDetail?.userInfo?.email);
    }
  }, [userDetail?.userInfo]);

  useEffect(() => {
    setShowUpgradePopup(userDetail.showUpgradePopup);
  }, [userDetail.showUpgradePopup]);

  useEffect(() => {
    setShowUpgradeSettingsPopup(userDetail.showUpgradeSettingsPopup);
  }, [userDetail.showUpgradeSettingsPopup]);

  useEffect(() => {
    findTeamStatus(userDetail.userFeatureFlag.teamStatus);
  }, [userDetail.userFeatureFlag.teamStatus]);

  useEffect(() => {
    setSideBar(
      !["/pluginSuccess", "/signupSuccess"].includes(location.pathname)
    );
    globalCallState.setPopupValues({
      ...globalCallState.popupValues,
      isContactCard: false
    });
  }, [location]);

  useEffect(() => {
    checkPluginInstalled();
  }, [userDetail.pluginInstalled]);

  useEffect(() => {
    setRevokeAccessBanner(userDetail?.connectInboxAccessRevoke);
  }, [userDetail.connectInboxAccessRevoke]);

  useEffect(() => {
    setDialerServiceCard(globalCallState.showCallSlider);
  }, [globalCallState.showCallSlider]);

  useEffect(() => {
    verifyCustomUrlIsSet();
  }, [userDetail.brandUrlInfo]);

  useEffect(() => {
    setFoundData(dataSearchState?.foundData);
  }, [dataSearchState?.foundData]);

  useEffect(() => {
    setSinglePurchaseContactMail(dataSearchState?.singlePurchaseContactMail);
  }, [dataSearchState?.singlePurchaseContactMail]);

  useEffect(() => {
    setMobilePhone(dataSearchState?.mobilePhone);
  }, [dataSearchState?.mobilePhone]);

  useEffect(() => {
    setIsMobileNumberFound(dataSearchState?.isMobileNumberNotFound);
  }, [dataSearchState?.isMobileNumberNotFound]);

  useEffect(() => {
    setIsNoPhoneCredits(dataSearchState?.isNoPhoneCredits);
  }, [dataSearchState?.isNoPhoneCredits]);

  return (
    <>
      {!showSideBar && <DashboardRouting />}
      <DealifyPopup />
      <Header />
      {showSideBar && (
        <>
          <aside>
            <SideBar />
          </aside>
          {showDialerServiceCard && (
            <DialerSliderCard
              data={globalCallState.popupValues.contactData}
              slideAnimation={true}
              setDialerServiceCard={setDialerServiceCard}
              dialerLogAfterCallEnd={
                globalCallState.popupValues.dialerLogAfterCallEnd
              }
              performTaskCbk={globalCallState.popupValues.performCbkFunction}
              isFromContactSection={
                globalCallState.popupValues.isFromContactSection
              }
              pageType={globalCallState.popupValues.pageType}
              isContactCard={globalCallState.popupValues.isContactCard}
              selectedNumber={globalCallState.popupValues.selectedNumber}
            />
          )}
          <ConfirmationPopup />
          {/* {!isWelcomeVideoShown && <WelcomePopup cbk={welcomeVideoShown} />} */}
          <InputBoxPopup />
          <Toaster />
          <SnoozeTaskPopup />
          <AddSequenceInContactPopup {...props} />
          <EditContactPopup />
          <ExportProcessing />
          <EnrichProcessing />
          <ExportEmailNotify />
          <BuyNewNumberPopup />
          <BuyCreditsPopup />
          <TransactionAnimation />
          <AddNumberPopup />
          <AddMyOwnNumberPopup />
          <VerifyNumberPopup />
          <NewCardPaymentPopup />
          <DeleteCustomURLPopup />
          <CustomUrlSuccessPopup />
          <FileLimitExceedPopup />
          <EditSequencePopup />
          <EmailVerificationLoggedIn />
          <ComingSoonPopup />
          {/* <BookNowBanner /> */}
          <TagPopup />
          <AddBulkContactPopup />
          <SuccessPopup />
          <DeselectCheckedPopup />
          <AddSingleContactPopup />
          <OutboxCardPopup />
          <GmailMessagePopup />
          <UpgradePopup />
          <StepEmailCreateEditorPopup />
          <TestEmailPopup />
          <AddStagesPopup />
          <ContactsSchedulePopup {...props} />
          <BulkAddTagsPopup />
          <BulkRemoveTagsPopup />
          <ContactUploadingToSequence />
          <MissingFieldsValidationPopup />
          {showUpgradePopup && <UpgradeCard />}
          {showUpgradeSettingsPopup && <UpgradeSettingsCard />}
          <InitialWelcomePopup />
          <CreateEditListPopup />
          <CreateTeamPopup />
          <CreateContactStagePopup />
          <CreateCallStagePopup />
          <AddMembersPopup />
          <AddDefaultInbox />
          <InitialScheduleInfoPopup />
          <ContactAdminMessagePopup />
          <PopupLoader />
          <EmailPreviewContentPopup />
          <LinkedInReplyContentPopup />
          <CalendarBookMeetingPopup />
          <UpdateContactOwner />
          <ExportToSalesforcePopup />
          <GoogleOAuthPopup />
          <CreateSlackNotification />
          <LinkedInNotConnectedPopup />
          <ConnectLinkedInLoading />
          <LinkedInDetailsPopup />
          <SyncConfirmationPopup />
          <SyncLogsHistoryPopup />
          <PriceRangeSliderPopup />
          <CreditUsagePopup />
          <PullContacts />
          <div className="seqBulkPopupWrapper" id="seqBulkPopupWrapper">
            <div className="seqBulkCont" />
            <div className="common-popup-mask bulkMask" />
          </div>
          <section
            className={`site-content ${
              isTeamSuspended && !(emailSentCount >= emailSendLimit)
                ? "singleBanner"
                : "installBannerHidden"
            }`}
          >
            {showBanner && (
              <WarningBanner
                isTeamSuspended={isTeamSuspended}
                revokeAccessBanner={revokeAccessBanner}
                isLowDialerCredits={isLowDialerCredits}
                showPluginBanner={showPluginBanner}
                emailSendLimit={emailSendLimit}
                showTrackingDelayBanner={showTrackingDelayBanner}
                emailLimitBanner={emailSentCount >= emailSendLimit}
                customUrlBanner={customUrlBanner}
                dhcBanner={
                  localStorage.getItem("domainHealthCenterVisited") === null
                }
                setShowBanner={setShowBanner}
              />
            )}
            <main className="mainPage">
              <OnBoardingPopup />
              <DashboardRouting />
              {dataSearchState?.isFindPhoneEmailClicked && <FindContactPopup />}
              {foundData &&
                (singlePurchaseContactMail ||
                  (mobilePhone && isMobileNumberFound)) && (
                  <FoundContactPopup key={dataSearchState?.mobilePhone} />
                )}

              {!isMobileNumberFound && <PhoneNotFound />}
              {isNoPhoneCredits && <UpgradePlanPopup />}
            </main>
          </section>
        </>
      )}
    </>
  );
};

const WarningBanner = (props = {}) => {
  const {
    isTeamSuspended = false,
    revokeAccessBanner = false,
    isLowDialerCredits = false,
    showTrackingDelayBanner = false,
    emailLimitBanner = false,
    showPluginBanner = false,
    emailSendLimit = 0,
    customUrlBanner = false,
    dhcBanner = localStorage.getItem("domainHealthCenterVisited") === null,
    setShowBanner = () => {}
  } = props || {};
  const getBannerType = () => {
    if (emailLimitBanner) {
      return "EMAIL_LIMIT";
    } else if (isTeamSuspended && !emailLimitBanner) {
      return "TEAM_SUSPENDED";
    } else if (isLowDialerCredits && !emailLimitBanner && !isTeamSuspended) {
      return "DAILER_CREDITS";
    } else if (
      revokeAccessBanner &&
      !emailLimitBanner &&
      !isTeamSuspended &&
      !isLowDialerCredits
    ) {
      return "CONNECT_INBOX";
    } else if (
      showTrackingDelayBanner &&
      !revokeAccessBanner &&
      !emailLimitBanner &&
      !isTeamSuspended &&
      !isLowDialerCredits
    ) {
      return "TRACKING_DELAY";
    } else if (
      customUrlBanner &&
      !showTrackingDelayBanner &&
      !revokeAccessBanner &&
      !emailLimitBanner &&
      !isTeamSuspended &&
      !isLowDialerCredits
    ) {
      return "CUSTOM_URL";
    } else if (
      dhcBanner &&
      !customUrlBanner &&
      !showTrackingDelayBanner &&
      !revokeAccessBanner &&
      !emailLimitBanner &&
      !isTeamSuspended &&
      !isLowDialerCredits
    ) {
      return "DHC";
    } else if (
      showPluginBanner &&
      !dhcBanner &&
      !customUrlBanner &&
      !showTrackingDelayBanner &&
      !revokeAccessBanner &&
      !emailLimitBanner &&
      !isTeamSuspended &&
      !isLowDialerCredits
    ) {
      return "EXTENSION";
    }
  };

  let bannerType = getBannerType();

  return isTeamSuspended &&
    !emailLimitBanner &&
    ["TEAM_SUSPENDED"].includes(bannerType?.toUpperCase()) ? (
    <HeaderNotification message={ConstantObj.teamSuspendedMessage} />
  ) : (
    [
      "EMAIL_LIMIT",
      "DAILER_CREDITS",
      "CONNECT_INBOX",
      "CUSTOM_URL",
      "DHC",
      "EXTENSION"
    ].includes(bannerType) && (
      <NotificationBanner
        emailSendLimit={emailSendLimit}
        hideBanner={setShowBanner}
        bannerType={bannerType}
      />
    )
  );
};

export default withRouter(observer(DashboardModule));
