import React, { useState, useEffect } from "react";
import { observer } from "mobx-react";
import { settingsStoreData } from "Stores/settingsData";
import { MP_EVENT } from "Static/constant";
import { Utils } from "Utils/utils";
import ApiKeyWithCopyButton from "Components/common/ApiKeyWithCopyButton";
import InstallButton from "../InstallButton";

const ZapierAction = props => {
  const { isFeatureEnabled = false } = props;
  const [apiKey, setApiKey] = useState("");

  const handleConfigureBtnClick = () => {
    Utils.mixpanelTrack(MP_EVENT.ZAPIER_CONFIGURATION_CLICKED, {
      pageType: Utils.getPageType() || ""
    });
    window.open(
      "https://zapier.com/apps/salesgear/integrations ",
      "_blank",
      "noopener"
    );
  };

  useEffect(() => {
    setApiKey(settingsStoreData?.userProfileData?.zapierApiKey);
  }, [settingsStoreData?.userProfileData?.zapierApiKey]);

  useEffect(() => {
    settingsStoreData.getUserProfile();
  }, []);

  return !isFeatureEnabled ? (
    <InstallButton {...props} />
  ) : (
    <div className="zapierApiIntegrationWrapper">
      <ApiKeyWithCopyButton apiKey={apiKey} />
      <div className="buttonCont wrapConnectedBtn">
        <div
          className={`connectedBtnCont btn ${
            isFeatureEnabled ? "" : "disabled"
          }`}
          onClick={handleConfigureBtnClick}
        >
          <i className="material-icons-outlined">settings</i>
          Configure
        </div>
      </div>
    </div>
  );
};

export default observer(ZapierAction);
export { ZapierAction };
