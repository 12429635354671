import { observer } from "mobx-react";
import React, { useEffect, useState, useReducer } from "react";
import Button from "Components/common/Button";
import NumberInputBox from "Components/common/NumberInputBox";
import { linkedInReplyState } from "./state";
import LinkedInProfileCard from "./common/LinkedInProfileCard";
import FilterDropdown from "../../Triggers/AddEditTrigger/FilterDropdown";
import makeApi, { URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import CustomNumberInput from "./common/CustomNumberInput";
import { VariantToggle } from "Pages/Dashboard/sequences/SequencePage/VariantToggle";
import { DateUtils } from "Utils/DateUtils";
import { ReactComponent as RefreshIcon } from "Assets/svg/refresh.svg";
import { ReactComponent as WarningIcon } from "Assets/svg/warning_filed.svg";
import { CustomTooltip } from "Components/common/CustomTooltip";
import { confirmationPopupState } from "Components/common/confirmationPopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import LimitErr from "Components/PreviewPopup/LimitErr";
import ToggleBtnType from "Components/common/ToggleBtnType";
import DropDownMenu from "Components/common/DropDownMenu";
import { userDetail } from "Stores/userDetail";

const initialState = {
  maxInvitePerDay: 0,
  maxMessagesPerDay: 0,
  liTaskDueDays: 0,
  liStepFailureAction: "SKIP_TO_NEXT_STEP",
  liTaskDueAction: "SKIP_TO_NEXT_STEP",
  mailFailureAction: "SEND_AS_MESSAGE",
  withdrawPendingRequestEnabled: true,
  maxConnectionWithdrawPerDay: 15,
  inviteWithDrawInterval: "SENT_1_MONTH_AGO"
};

const formReducer = (state = {}, action = {}) => {
  const { type = "", payload = {}, value = "", field = "" } = action || {};
  switch (type) {
    case "SET_ENTIRE_STATE":
      return payload;
    case "CHANGE_FIELD":
      return {
        ...state,
        [field]: value
      };
    case "TOGGLE_FIELD":
      return {
        ...state,
        [field]: !state[field]
      };
    default:
      return state;
  }
};

const ConnectedLinkedInConfigure = observer((props = {}) => {
  const { planName = "" } = userDetail?.userFeatureFlag || {};
  const history = useHistory();
  const [state, dispatch] = useReducer(formReducer, initialState);
  const [errors, setErrors] = useState({});
  const [activeDropdownIndex, setActiveDropdownIndex] = useState(0);
  const [saveLoader, setSaveLoader] = useState(false);
  const [total, setTotal] = useState(0);
  const [pendingInvitationData, setPendingInvitationData] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  const isPremiumAccount =
    !(
      linkedInReplyState?.configureData?.linkedinAccountType?.toLowerCase() ===
      "classic"
    ) || false;

  const handleChange = (value = "", field = "") => {
    dispatch({ type: "CHANGE_FIELD", field, value });
  };

  const toggleChange = (field = "") => {
    dispatch({ type: "TOGGLE_FIELD", field });
  };

  const handleDropdownChange = (key, value) => {
    dispatch({ type: "CHANGE_FIELD", field: value?.field, value: value?.key });
    setActiveDropdownIndex("");
  };

  const handleCheckBox = (_, value, id) => {
    dispatch({ type: "TOGGLE_FIELD", field: id, value: value });
  };

  const checkError = () => {
    const newErrors = {};
    let hasError = false;
    let maxCount = isPremiumAccount ? 100 : 50;
    Object.entries(initialFormFieldsConfig).forEach(([field, config]) => {
      if (config?.required) {
        if (state[field] === "") {
          newErrors[field] = "Field is empty";
          hasError = true;
        } else if (
          field === "maxInvitePerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum connection requests per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxMessagesPerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[field] = isPremiumAccount
            ? `Maximum messages/InMails per day should be less than or equal to ${maxCount}`
            : `Maximum messages per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxProfileViewsPerDay" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum profile views per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "maxSearchPerDay" &&
          (state[field] < 1 || state[field] > maxCount) &&
          isPremiumAccount
        ) {
          newErrors[
            field
          ] = `Maximum api limit for search per day should be less than or equal to ${maxCount}`;
          hasError = true;
        } else if (
          field === "liTaskDueDays" &&
          (state[field] < 1 || state[field] > maxCount)
        ) {
          newErrors[
            field
          ] = `Maximum task due days should be less than or equal to ${maxCount}`;
          hasError = true;
        }
      }
    });
    setErrors(newErrors);
    if (hasError) {
      return true;
    }
    return false;
  };

  const saveSettings = async () => {
    let totalCount = 100;
    if (isPremiumAccount) {
      totalCount = 150;
    }
    if (total > totalCount) {
      toasterState.setToastMsg(
        `The total activities per day should be less than or equal to ${totalCount}.`,
        "failure"
      );
      return;
    }
    setSaveLoader(true);
    if (!isPremiumAccount) {
      delete state.maxSearchPerDay;
    }
    const config = {
      url: URLS?.saveSettingsInLinkedIn,
      method: "POST",
      data: {
        accountId: linkedInReplyState?.configureData?.id,
        settings: state
      }
    };
    let response = await makeApi(config);
    const { responseCode = "", message = "" } = response?.data || {};

    if (+responseCode === 400) {
      const match = message?.match(/<<(\d+)>>/);
      const dailyLimit = match ? match[1] : "N/A";
      let limitMessage = "";

      if (message.includes("profile view per day")) {
        limitMessage = `Daily Profile Views Limit: ${dailyLimit} per day on ${planName} plan.`;
      } else if (message.includes("message per day")) {
        limitMessage = `Daily Messages Sent Limit: ${dailyLimit} per day on ${planName} plan.`;
      } else if (message.includes("invite per day")) {
        limitMessage = `Daily Connection Request Limit: ${dailyLimit} per day on ${planName} plan.`;
      } else if (message.includes("connection withdraw per day")) {
        limitMessage = `Daily Connection Withdrawal Limit: ${dailyLimit} per day on ${planName} plan.`;
      }

      confirmationPopupState.setPopupValues({
        content: (
          <LimitErr
            headingContent={`Oops! You’re trying to configure limits beyond what’s available in
              your plan.`}
            limitMessage={limitMessage}
          />
        ),
        dynamicDom: true,
        actionBtnText: "Okay",
        cancelBtnText: "Upgrade",
        cancelCbk: () => {
          confirmationPopupState.setShowPopup(false);
          history && history.push("/pricing");
        },
        callback: () => {
          confirmationPopupState.setShowPopup(false);
        }
      });

      confirmationPopupState.setShowPopup(true);
      setSaveLoader(false);
      return;
    }

    if (+responseCode === 200) {
      const { settings: linkedInSettings = {} } = response?.data?.data || {};

      if (linkedInSettings) {
        dispatch({
          type: "SET_ENTIRE_STATE",
          payload: linkedInSettings
        });
        toasterState.setToastMsg(
          "LinkedIn settings saved successfully.",
          "success"
        );
      }
      linkedInReplyState.getLinkedInAccounts();
    } else if (+responseCode === 401) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else if (+responseCode === 403) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else if (+responseCode === 406) {
    } else if (+responseCode === 500) {
    } else if (total > 100) {
      toasterState.setToastMsg(
        "You don't have access to LinkedIn settings.",
        "failure"
      );
    } else {
      toasterState.setToastMsg(
        "Something went wrong, please try again later",
        "failure"
      );
    }
    setSaveLoader(false);
  };

  const handleSave = () => {
    if (!checkError()) {
      saveSettings();
    }
  };

  const getPendingRequests = async () => {
    const config = {
      url: URLS?.getPendingInvitationCount,
      method: "GET"
    };
    let response = await makeApi(config);
    const { pendingInvitationCount = "", lastPendingReqLookupTs } =
      response?.data || {};
    setPendingInvitationData({
      pendingInvitationCount,
      lastPendingReqLookupTs
    });
  };

  let maxCount = isPremiumAccount ? 100 : 50;

  const initialFormFieldsConfig = {
    maxInvitePerDay: {
      label: "Number of connection requests per day",
      subLabel: `(Recommended : ${
        isPremiumAccount ? 40 : 20
      }, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    maxMessagesPerDay: {
      label: isPremiumAccount
        ? "Number of messages/InMails per day"
        : "Number of messages per day",
      subLabel: `(Recommended : ${
        isPremiumAccount ? 40 : 30
      }, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    maxProfileViewsPerDay: {
      label: "Number of profile views per day ",
      subLabel: `(Recommended : 20, Max : ${maxCount})`,
      type: "number",
      required: true,
      min: 1,
      max: maxCount,
      displayFlag: true
    },
    ...(isPremiumAccount && {
      maxSearchPerDay: {
        label: "Number of search API calls per day",
        subLabel: `(Recommended : ${
          isPremiumAccount ? 40 : 30
        }, Max : ${maxCount})`,
        type: "number",
        required: true,
        min: 1,
        max: maxCount,
        displayFlag: true,
        hasTooltip: true
      }
    }),
    liStepFailureAction: {
      label: "Whenever an automated LinkedIn step fails in a sequence",
      type: "dropdown",
      options: [
        {
          value: "Skip the contact to the next step",
          key: "SKIP_TO_NEXT_STEP",
          method: handleDropdownChange,
          field: "liStepFailureAction"
        },
        {
          value: "Mark the contact as failed",
          key: "MARK_AS_FAILED",
          method: handleDropdownChange,
          field: "liStepFailureAction"
        }
      ],
      displayFlag: true
    },
    ...(isPremiumAccount && {
      mailFailureAction: {
        label: "When sending InMail fails (due to limits or other reasons)",
        type: "dropdown",
        options: [
          {
            value: "Send it as a message",
            key: "SEND_AS_MESSAGE",
            method: handleDropdownChange,
            field: "mailFailureAction"
          },
          {
            value: "Skip the contact to the next step",
            key: "SKIP_TO_NEXT_STEP",
            method: handleDropdownChange,
            field: "mailFailureAction"
          },
          {
            value: "Mark the contact as failed",
            key: "MARK_AS_FAILED",
            method: handleDropdownChange,
            field: "mailFailureAction"
          }
        ],
        displayFlag: true
      }
    })
    // liTaskDueAction: {
    //   label1: "If automatic LinkedIn task is due in",
    //   label2: "Days",
    //   type: "dropdownWithNumberInput",
    //   className: "dropdownWithNumberInput",
    //   fieldName: "liTaskDueDays",
    //   hasLabel: false,
    //   options: [
    //     {
    //       value: "Convert automatic task to manual task",
    //       key: "AUTOMATIC_TO_MANUAL",
    //       method: handleDropdownChange,
    //       field: "liTaskDueAction"
    //     },
    //     {
    //       value: "Skip task",
    //       key: "SKIP_TO_NEXT_STEP",
    //       method: handleDropdownChange,
    //       field: "liTaskDueAction"
    //     }
    //   ]
    // }
  };

  const mappingObj = {
    liTaskDueAction: {
      SKIP_TO_NEXT_STEP: "Skip task and proceed to next",
      AUTOMATIC_TO_MANUAL: "Convert automatic tasks to manual"
    },
    liStepFailureAction: {
      SKIP_TO_NEXT_STEP: "Skip the contact to the next step",
      MARK_AS_FAILED: "Mark the contact as failed"
    },
    mailFailureAction: {
      SEND_AS_MESSAGE: "Send it as a message",
      SKIP_TO_NEXT_STEP: "Skip the contact to the next step",
      MARK_AS_FAILED: "Mark the contact as failed"
    },
    inviteWithDrawInterval: {
      SENT_2_WEEKS_AGO: "2 weeks",
      SENT_3_WEEKS_AGO: "3 weeks",
      SENT_1_MONTH_AGO: "1 month",
      SENT_2_MONTHS_AGO: "2 months",
      SENT_3_MONTHS_AGO: "3 months"
    }
  };

  const handleWithdrawalDropdownChange = (t, item) => {
    handleChange(item.key, "inviteWithDrawInterval");
    setShowDropdown(false);
  };

  const withdrawIntervalOptions = [
    {
      value: "2 weeks",
      key: "SENT_2_WEEKS_AGO",
      method: handleWithdrawalDropdownChange
    },
    {
      value: "3 weeks",
      key: "SENT_3_WEEKS_AGO",
      method: handleWithdrawalDropdownChange
    },
    {
      value: "1 month",
      key: "SENT_1_MONTH_AGO",
      method: handleWithdrawalDropdownChange
    },
    {
      value: "2 months",
      key: "SENT_2_MONTHS_AGO",
      method: handleWithdrawalDropdownChange
    },
    {
      value: "3 months",
      key: "SENT_3_MONTHS_AGO",
      method: handleWithdrawalDropdownChange
    }
  ];

  useEffect(() => {
    let newTotal = 0;
    Object.entries(initialFormFieldsConfig).forEach(([field, value]) => {
      if (value.type === "number" && !isNaN(state[field])) {
        newTotal += parseInt(state[field], 10);
      }
    });
    setTotal(newTotal);
  }, [state, initialFormFieldsConfig]);

  useEffect(() => {
    const {
      settings = {},
      pendingInvitationCount = 0,
      lastPendingReqLookupTs
    } = linkedInReplyState?.configureData;
    dispatch({
      type: "SET_ENTIRE_STATE",
      payload: { ...state, ...settings }
    });
    setPendingInvitationData({
      pendingInvitationCount,
      lastPendingReqLookupTs
    });
  }, [linkedInReplyState?.configureData]);

  useEffect(() => {
    return () => {
      linkedInReplyState.setShowConfigure(false);
    };
  }, []);

  return (
    <div className="connectedLinkedInConfigure">
      <Header />
      <div className="settingsWrapper">
        <LinkedInProfileCard
          {...linkedInReplyState?.configureData}
          showTitle={true}
          hasLinkedInIcon={true}
        />
        <div className="linkedInSettings">
          <div className="warmingSettingsCont">
            <div className="warmingSettingsHeader">
              LinkedIn Account Settings
            </div>
            <div className={`warmingSettingsBody`}>
              {Object.entries(initialFormFieldsConfig).map(
                (
                  [
                    field,
                    {
                      label,
                      label1,
                      label2,
                      type,
                      subLabel,
                      min,
                      max,
                      options,
                      fieldName,
                      hasLabel,
                      className,
                      hasTooltip,
                      handleCheckBox,
                      checkBoxId
                    }
                  ],
                  index
                ) => {
                  return (
                    <>
                      {type === "number" && (
                        <>
                          <NumberInputBox
                            type={type}
                            changeCbk={value => handleChange(value, field)}
                            min={min}
                            max={max}
                            value={state[field]}
                            errMsg={errors[field]}
                            loader={false}
                            readOnly={false}
                            label={label}
                            subLabel={subLabel}
                            hasTooltip={hasTooltip}
                          />
                        </>
                      )}

                      {type === "dropdown" && (
                        <div className="dropdownWithLabelWrapper">
                          <FilterDropdown
                            label={label}
                            options={options}
                            showDropdown={activeDropdownIndex === `${index}`}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            index={`${index}`}
                            defaultValue={
                              mappingObj?.[field]?.[state?.[field]] ||
                              options?.[0]?.value
                            }
                          />
                        </div>
                      )}
                      {type === "dropdownWithNumberInput" && (
                        <div className="dropdownWithLabelWrapper dropdownWithNumberInputWrapper">
                          <CustomNumberInput
                            type={"number"}
                            changeCbk={value => handleChange(value, fieldName)}
                            min={min}
                            max={max}
                            value={state[fieldName] || 7}
                            errMsg={errors[fieldName]}
                            loader={false}
                            readOnly={false}
                            label1={label1}
                            label2={label2}
                            className={className}
                          />
                          <FilterDropdown
                            options={options}
                            showDropdown={activeDropdownIndex === `${index}`}
                            setActiveDropdownIndex={setActiveDropdownIndex}
                            index={`${index}`}
                            defaultValue={
                              mappingObj?.[field]?.[state?.[field]] ||
                              options?.[0]?.value
                            }
                            hasLabel={hasLabel}
                          />
                        </div>
                      )}
                    </>
                  );
                }
              )}
            </div>
            <div className="withdrawRequestsWrapper">
              <ToggleBtnType
                id="withdrawPendingRequestEnabled"
                defaultValue={state.withdrawPendingRequestEnabled}
                lastText={
                  <>
                    <span>
                      Automatically withdraw connection requests pending greater
                      than{" "}
                    </span>
                    <div className="withdrawalIntervalDropdown">
                      <div
                        onClick={() => {
                          if (state.withdrawPendingRequestEnabled)
                            setShowDropdown(!showDropdown);
                        }}
                        className={`dropdownToggle ${
                          state.withdrawPendingRequestEnabled ? "enabled" : ""
                        }`}
                      >
                        <span>
                          {
                            mappingObj.inviteWithDrawInterval[
                              state.inviteWithDrawInterval
                            ]
                          }{" "}
                        </span>
                        <i className="material-icons dropdownIcon">
                          {showDropdown
                            ? "keyboard_arrow_up"
                            : "keyboard_arrow_down"}
                        </i>
                      </div>
                      {showDropdown && (
                        <>
                          <div
                            className="dropdownMask"
                            onClick={() => setShowDropdown(false)}
                          />
                          <DropDownMenu
                            selected={state.inviteWithDrawInterval}
                            options={withdrawIntervalOptions}
                            referenceId="withdrawIntervalOptions"
                            direction="right"
                          />
                        </>
                      )}
                    </div>
                  </>
                }
                isAllowed={true}
                cbk={() => {
                  toggleChange("withdrawPendingRequestEnabled");
                }}
              />
              {state.withdrawPendingRequestEnabled && (
                <div className="withdrawRequestContent">
                  <NumberInputBox
                    changeCbk={value =>
                      handleChange(
                        parseInt(value),
                        "maxConnectionWithdrawPerDay"
                      )
                    }
                    min={0}
                    max={25}
                    value={state.maxConnectionWithdrawPerDay}
                    errMsg={errors.maxConnectionWithdrawPerDay}
                    loader={false}
                    readOnly={false}
                    label="Daily Withdrawal Limit Selection"
                    subLabel="(Recommended: 15, Max: 25)"
                  />
                  <PendingRequests
                    pendingInvitationData={{
                      ...pendingInvitationData,
                      getPendingRequests
                    }}
                  />
                </div>
              )}
            </div>
            <ButtonComponent handleSave={handleSave} saveLoader={saveLoader} />
          </div>
        </div>
      </div>
    </div>
  );
});

const Header = observer(() => {
  return (
    <div className="sectionHeader">
      <div className="backBtnAndHeader">
        <span
          className="navigateToInbox"
          onClick={() => {
            linkedInReplyState.setShowConfigure(false);
          }}
        >
          <i className="material-icons backBtn">keyboard_backspace</i>
        </span>
        <h6>Connected Accounts</h6>
      </div>
    </div>
  );
});

const PendingRequests = ({ pendingInvitationData }) => {
  const {
    pendingInvitationCount = 0,
    lastPendingReqLookupTs,
    getPendingRequests = () => {}
  } = pendingInvitationData;

  const [showWarningTooltip, setShowWarningTooltip] = useState(false);
  const [isRefresh, setRefresh] = useState(false);

  const { date: lastSyncedDate = "", time: lastSyncedTime = "" } =
    lastPendingReqLookupTs
      ? DateUtils.getFormattedDateAndTimeForCrm(lastPendingReqLookupTs)
      : {};

  const refreshRequests = async () => {
    setRefresh(true);
    await getPendingRequests();
    setRefresh(false);
  };

  return (
    <div className="pendingRequestWrapper">
      <div className="pendingRequestsTopCont">
        <div className="label">Total Pending Connection Requests</div>
        <div className="refreshCont">
          <div className={`refreshIconCont ${isRefresh ? "loading" : ""}`}>
            <span className="refreshIcon">
              <RefreshIcon />
            </span>
            <div className="refreshBtn" onClick={refreshRequests}>
              Refresh
            </div>
          </div>
        </div>
      </div>
      <div className="openRequestsCont">
        <div className="openRequestsContent">
          <div className="noOfOpenRequests">
            <span style={{ fontWeight: "600" }}>{pendingInvitationCount} </span>
            Open Requests
          </div>
          {lastPendingReqLookupTs && (
            <div className="lastUpdatedDate">
              <span>Last Updated: </span>
              <span>{`${lastSyncedDate}, ${lastSyncedTime}`}</span>
            </div>
          )}
        </div>
        {pendingInvitationCount > 800 && (
          <div className="warning">
            <WarningIcon />
            {showWarningTooltip && (
              <CustomTooltip
                text={`You have more than 800 pending requests.
                      Some new requests may not go through.
                      Withdraw old requests to avoid failures.<br />
                      Visit <a href="https://www.linkedin.com/mynetwork/invitation-manager/sent/" target=_blank>Linkedin Invitation Manager</a> to manually
                      withdraw requests.`}
                className={"withdrawInfoTooltip"}
              />
            )}
            <span
              className="warningText"
              onMouseEnter={() => setShowWarningTooltip(true)}
              onMouseLeave={() => setShowWarningTooltip(false)}
            >
              Some requests may fail
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

const ButtonComponent = (props = {}) => {
  const { saveLoader = false, handleSave = () => {} } = props || {};

  const buttonList = [
    {
      id: "back",
      name: "back",
      btnText: "Back",
      type: "button",
      className: "cancelButton",
      click: () => {
        linkedInReplyState.getLinkedInAccounts();
        linkedInReplyState.setShowConfigure(false);
      }
    },
    {
      id: "save",
      name: "save",
      btnText: "Save",
      type: "button",
      className: `saveButton ${saveLoader ? "loading" : ""}`,
      click: handleSave,
      loader: saveLoader
    }
  ];

  return (
    <div className="buttonActionList">
      {buttonList.map(item => (
        <Button {...item} key={`${item?.id}Btn`} />
      ))}
    </div>
  );
};

export { ConnectedLinkedInConfigure };
export default ConnectedLinkedInConfigure;
