/* eslint-disable no-unused-expressions */
import React from "react";
import { observable, action } from "mobx";
import { makeApi, URLS } from "Utils/apiURL";
import { toasterState } from "Components/common/toaster";
import Utils from "Utils/utils";
import { MP_EVENT } from "Static/constant";
import userDetail from "./userDetail";
import { confirmationPopupState } from "../Components/common/confirmationPopup";
import LimitErr from "Components/PreviewPopup/LimitErr";

let cancelEmailPrefer = null;
let cancelUnsubscribeChanges = null;

class SettingsData {
  defaultCustomFields = {
    customField1: "",
    customField2: "",
    customField3: "",
    customField4: "",
    customField5: "",
    customField6: "",
    customField7: "",
    customField8: "",
    customField9: "",
    customField10: "",
    customField11: "",
    customField12: "",
    customField13: "",
    customField14: "",
    customField15: "",
    customField16: "",
    customField17: "",
    customField18: "",
    customField19: "",
    customField20: "",
    customField21: "",
    customField22: "",
    customField23: "",
    customField24: "",
    customField25: "",
    customField26: "",
    customField27: "",
    customField28: "",
    customField29: "",
    customField30: ""
  };

  @observable settingsData = [];
  @observable userProfileData = null;
  @observable unsubscribeData = null;
  @observable customFields = this.defaultCustomFields;
  @observable globalUnsubscribeData = "";
  @observable fromContacts = false;
  @observable saveFromContacts = false;
  @observable monthlyEmailCreditsRemaining = 0;

  @action
  setCustomFields = value => {
    this.customFields = value;
  };

  @action
  setSettingsData(value) {
    this.settingsData = value;
  }

  @action
  setUserProfileData = obj => {
    this.userProfileData = { ...this.userProfileData, ...obj };
  };

  @action
  setEmailPrefernce = (value, key) => {
    if (this.userProfileData.emailPreferences) {
      this.userProfileData.emailPreferences["PRODUCT_ANNOUNCEMENTS"] = value;
    }
  };

  @action
  setUnsubscribeData = obj => {
    this.unsubscribeData = { ...this.unsubscribeData, ...obj };
  };

  @action
  setGlobalUnsubscribeData = obj => {
    this.globalUnsubscribeData = { ...this.globalUnsubscribeData, ...obj };
  };

  @action
  setFromContacts = value => {
    this.fromContacts = value;
  };

  @action
  setSaveFromContacts = value => {
    this.saveFromContacts = value;
  };

  @action
  setMonthlyEmailCreditsRemaining = value => {
    this.monthlyEmailCreditsRemaining = value;
  };

  @action
  getSettingsData = async cbk => {
    try {
      const config = {
        url: URLS.getSettingsData ? URLS.getSettingsData : "",
        method: "GET"
      };
      let response = await makeApi(config);
      if (response && response.status && response.status === 200) {
        this.settingsData = response.data || null;
        cbk ? cbk() : "";
      } else Utils.showApiFailureResponse(response);
    } catch (error) {
      console.error("Settings Data error ", error);
    }
  };

  @action
  getUserProfile = async () => {
    try {
      const config = {
        url: URLS.getUserProfile ? URLS.getUserProfile : "",
        method: "GET"
      };
      let res = await makeApi(config);
      if (res && res.data) {
        this.userProfileData = res.data;
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (error) {
      console.error("Getting User Profile Data error ", error);
    }
  };

  @action
  updateUserProfile = async (data, cbk, token) => {
    try {
      const config = {
        url: URLS.updateUserProfile
          ? `${URLS.updateUserProfile}?token=${token}`
          : "",
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.updateUserInfo(res.data);
        this.setUserProfileData(data);
        cbk(false, "success");
        toasterState.setToastMsg("Successfully Updated", "success");
      } else {
        cbk(true);
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (error) {
      console.error("Update User Profile Data error ", error);
    }
  };

  @action
  updateEmailPreference = async (value, key, updateState, token) => {
    try {
      const data = {};
      data[key] = value;
      const config = {
        url: URLS.emailPreferences
          ? `${URLS.emailPreferences}?token=${token}`
          : "",
        method: "POST",
        data: data
      };
      if (cancelEmailPrefer !== null) {
        cancelEmailPrefer("cancel");
        cancelEmailPrefer = null;
      }
      const advCancelCallback = value => {
        cancelEmailPrefer = value;
      };
      let res = await makeApi(config, advCancelCallback);
      if (res?.data) {
        this.setEmailPrefernce(value, key);
        updateState(true);
        toasterState.setToastMsg("Successfully Updated", "success");
      } else {
        updateState(false);
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (error) {
      console.error("Update Email Preference Data error ", error);
    }
  };

  @action
  getUnsubscribeData = async () => {
    try {
      const config = {
        url: URLS.getUnsubscribe ? URLS.getUnsubscribe : ""
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.setUnsubscribeData(res.data);
      }
    } catch (error) {
      console.error("Getting Unsubscribe data failure error ", error);
    }
  };

  @action
  saveUnsubscribeChanges = async (data, cbk) => {
    try {
      const config = {
        url: URLS.getUnsubscribe ? URLS.getUnsubscribe : "",
        method: "POST",
        data
      };
      if (cancelUnsubscribeChanges !== null) {
        cancelUnsubscribeChanges("cancel");
        cancelUnsubscribeChanges = null;
      }
      const advCancelCallback = value => {
        cancelUnsubscribeChanges = value;
      };
      let res = await makeApi(config, advCancelCallback);
      if (res?.data) {
        toasterState.setToastMsg("Successfully Updated", "success");
        this.getUnsubscribeData();
        cbk(true);
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
        cbk(false);
      }
    } catch (error) {
      console.error("Add unsubscribe changes update failure Error", error);
    }
  };

  @action
  getCustomFields = async cbk => {
    try {
      const config = {
        url: URLS.customFields ? URLS.customFields : ""
      };
      let res = await makeApi(config);
      cbk && cbk();
      if (
        res?.data?.customFields &&
        Object.keys(res.data.customFields)?.length
      ) {
        this.setCustomFields(res.data.customFields);
      } else {
        this.setCustomFields(this.defaultCustomFields);
      }
    } catch (error) {
      console.error("Fetching custom fields data failure error ", error);
    }
  };

  @action
  updateCustomFields = async (data, cbk) => {
    try {
      const config = {
        url: URLS.customFields ? URLS.customFields : "",
        method: "POST",
        data
      };
      let res = await makeApi(config);
      if (res?.data) {
        this.setCustomFields(res.data);
        cbk && cbk(true, res);
        Utils.mixpanelTrack(MP_EVENT.CF_CREATED, {
          pageType: window.location.pathname
        });
      } else {
        cbk && cbk(false, res);
      }
    } catch (error) {
      cbk && cbk(false);
      console.error("Updating custom fields data failure error ", error);
    }
  };

  @action
  updateEmailSettings = async (data, history, planName) => {
    try {
      const config = {
        url: URLS.saveEmailSettings ? URLS.saveEmailSettings : "",
        method: "POST",
        data
      };
      const res = await makeApi(config);
      if (res?.data) {
        if (+res?.data?.code === 400) {
          const match = res?.data?.message?.match(/<<(\d+)>>/);
          const dailyLimit = match ? match[1] : "N/A";

          confirmationPopupState.setPopupValues({
            content: (
              <LimitErr
                headingContent={`Oops! You’re trying to configure limits beyond what’s available in
                          your plan.`}
                limitMessage={`Daily Email Sends Limit: ${dailyLimit} per inbox per day on ${planName} plan.`}
              />
            ),
            dynamicDom: true,
            actionBtnText: "Okay",
            cancelBtnText: "Upgrade",
            cancelCbk: () => {
              confirmationPopupState.setShowPopup(false);
              history && history.push("/pricing");
            },
            callback: () => {
              confirmationPopupState.setShowPopup(false);
            }
          });

          confirmationPopupState.setShowPopup(true);
          return;
        }
      }

      if (res?.data) {
        const {
          dailySendLimit = 200,
          emailIntervalInSeconds = 120,
          openTrackingEnabled = true,
          clickTrackingEnabled = false
        } = res?.data || {};
        let userFeatureFlags = Utils.createObjWithoutRef(
          userDetail.userFeatureFlag
        );
        if (userFeatureFlags) {
          userFeatureFlags = {
            ...userFeatureFlags,
            dailySendLimit,
            emailIntervalInSeconds,
            openTrackingEnabled,
            clickTrackingEnabled
          };
          userDetail.setUserFeatureFlag(userFeatureFlags);
          toasterState.setToastMsg(
            "Changes have been successfully updated across your team.",
            "success"
          );
        }
      } else {
        toasterState.setToastMsg(
          "Something went wrong. Please try again later",
          "fail"
        );
      }
    } catch (error) {
      console.error("Updating daily limit data failure", error);
    }
  };

  @action
  updateUserInfo = async data => {
    if (data) {
      const existingData = userDetail?.userInfo;
      existingData.firstName = data.firstName;
      existingData.lastName = data.lastName;
      existingData.email = data.email;
      userDetail.setUserInfo(existingData);
    }
  };
}

const settingsStoreData = new SettingsData();

export { SettingsData, settingsStoreData };
export default SettingsData;
