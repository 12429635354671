import React from "react";
import { observable, action } from "mobx";
import { observer } from "mobx-react";
import Utils from "Utils/utils";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrow.svg";

class AddNumberPopupState {
  @observable showPopup = false;

  @observable callback = () => {};

  @action
  setShowPopup = val => {
    this.showPopup = val;
    if (val) {
      document.getElementsByTagName("body")[0].classList.add("scrollHidden");
    } else {
      document.getElementsByTagName("body")[0].classList.remove("scrollHidden");
    }
  };

  @action
  setCallback = val => {
    this.callback = val;
  };
}

const addNumberPopupState = new AddNumberPopupState();

const options = [
  {
    key: "newNumber",
    title: "Buy New Number",
    desc: "Your first US phone number is free. Additional numbers can be purchased for $1.15 each"
  },
  {
    key: "configureNumber",
    title: "Add Your Number",
    desc: "You can add your own Phone number into Salesgear"
  }
];

const AddNumberPopup = observer(props => {
  const closeAction = () => {
    addNumberPopupState.setShowPopup(false);
  };

  return addNumberPopupState.showPopup ? (
    <>
      <div className="addNumberPopupWrapper popupWrapper">
        <div className="confirmation-popup common-popup">
          <div className="addNumberPopup animated customzoomIn">
            <div className="popupHeader">
              <div className="popupTitle">Add a New Phone Number</div>
              <div
                className="popup-close"
                title="Click here to close"
                onClick={closeAction}
              >
                <i className="material-icons">close</i>
              </div>
            </div>
            <div className="popupBody">
              <AddingNumberOptions />
            </div>
          </div>
        </div>
        <div className="common-popup-mask" />
      </div>
    </>
  ) : (
    ""
  );
});

const AddingNumberOptions = () => {
  const optionClickAction = (e, value) => {
    Utils.preventDefaultFn(e);
    if (value) {
      addNumberPopupState.callback && addNumberPopupState.callback(value);
      addNumberPopupState.setShowPopup(false);
    }
  };

  return (
    <div className="optionsCont">
      {options &&
        options.map(value => {
          const { key, desc, title } = value;
          return (
            <div className="partition" key={`AddingNumberoption${key}`}>
              <div
                className="option"
                key={key}
                onClick={e => optionClickAction(e, key)}
              >
                <div className="optionTitle">{title}</div>
                <div className="optionDesc">{desc}</div>
              </div>
              <span className="rightArrow">
                <RightArrow />
              </span>
            </div>
          );
        })}
    </div>
  );
};

export default AddNumberPopup;
export { AddNumberPopup, addNumberPopupState };
