import React from "react";
import LinearProgressBar from "Components/common/LinearProgressBar";
import CustomTooltip from "Components/common/CustomTooltip";
import Button from "Components/common/Button";

const LinkedinCredits = props => {
  const { creditsData = {}, creditsCardLoader = false } = props || {};

  return (
    <>
      {creditsData?.linkedinCreditsData?.map((emailItem, ind) => {
        const {
          heading = "",
          paraContent = "",
          usageLimits = [],
          subLimits = [],
          icon = "",
          btnObj = {}
        } = emailItem || {};

        return (
          <div className="linkedinCard" key={ind}>
            <div className="cardHeader">
              <div className="iconWrapper">{icon}</div>
              <div className="headingWrapper">
                <h3>{heading}</h3>
                <p>{paraContent}</p>
              </div>
            </div>
            {subLimits.length > 0 ? (
              <div className="liOutReachWrapper">
                <div className="subLimitProgreeWrapper">
                  {subLimits.map((item, subIdx) => {
                    const { subHeading = "", usageLimits = [] } = item;
                    return (
                      <div key={subIdx} className="subLimitContainer">
                        <div className="subHeading">{subHeading}</div>
                        <div className="linearProgessWrapper">
                          {usageLimits.map((creditsLimit, idx) => {
                            const {
                              id,
                              heading = "",
                              tooltip = "",
                              totalCredits = "",
                              remainingCredits = "",
                              classNameText = ""
                            } = creditsLimit || {};

                            const total = +totalCredits || 0;
                            const remaining = +remainingCredits || 0;
                            return (
                              <div
                                className="linearProgessBody"
                                key={id || idx}
                              >
                                <div className="limit">
                                  <div className="limitContainer">
                                    {heading}
                                    <span className="freecallingIcon">
                                      <i className="material-icons-outlined">
                                        info
                                      </i>
                                      <div
                                        className={`tooltipWrapper ${classNameText}`}
                                      >
                                        <CustomTooltip text={tooltip} />
                                      </div>
                                    </span>
                                  </div>
                                  <span>
                                    {remaining}/{total}
                                  </span>
                                </div>
                                <LinearProgressBar
                                  width={(remaining / total) * 100 || 0}
                                  bgColor="#3953fb"
                                  creditsCardLoader={creditsCardLoader}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              <div className="linearProgessWrapper">
                {usageLimits.map((creditsLimit, idx) => {
                  const {
                    id,
                    heading = "",
                    tooltip = "",
                    totalCredits = "",
                    remainingCredits = "",
                    classNameText = ""
                  } = creditsLimit || {};

                  const total = Number(totalCredits) || 0;
                  const remaining = Number(remainingCredits) || 0;

                  return (
                    <div className="linearProgessBody" key={id || idx}>
                      <div className="limit">
                        <div className="limitContainer">
                          {heading}
                          <span
                            className="freecallingIcon"
                            // onMouseEnter={() => setHoveredTooltip(id)}
                            // onMouseLeave={() => setHoveredTooltip(null)}
                          >
                            <i className="material-icons-outlined">info</i>
                            <div className={`tooltipWrapper ${classNameText}`}>
                              {/* {hoveredTooltip === id && ( */}
                              <CustomTooltip text={tooltip} />
                              {/* )} */}
                            </div>
                          </span>
                        </div>

                        <span>
                          {remaining}/{total}
                        </span>
                      </div>
                      <LinearProgressBar
                        width={(remaining / total) * 100 || 0}
                        bgColor="#3953fb"
                        creditsCardLoader={creditsCardLoader}
                      />
                    </div>
                  );
                })}
              </div>
            )}

            <div className="buttonWrapper">
              <div className="buttonContainer">
                <Button {...btnObj} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default LinkedinCredits;
export { LinkedinCredits };
