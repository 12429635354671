/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Utils from "Utils/utils";
import { REPORT_DATE_FILTER_MIN_DATE } from "Static/constant";
import UpgradeIconTooltip from "Components/Paywall/UpgradeIconTooltip";
import userDetail from "Stores/userDetail";
import PaywallUtils from "Utils/PaywallUtils";

const DateRangePicker = props => {
  const {
    className = "",
    filterCbk = () => {},
    cancelCbk = () => {},
    customDate,
    inFreePlan = false,
    pageType = "",
    upgradePopupName = "",
    handleCustomDate = () => {},
    customDateObj = ""
  } = props || {};

  const { planName = "" } = userDetail?.userFeatureFlag || {};

  const highlightErrMsg = (id, value) => {
    const errDom = document.getElementById(id);
    let toggleClass = errDom && errDom.classList;
    value
      ? toggleClass.add("highlightErr")
      : toggleClass.remove("highlightErr");
  };

  const handleChange = (item = {}) => {
    const { startDate = false, endDate = false } = item?.selection || {};
    let exceedRange = Utils.getDateDiffNumber(startDate, endDate) > 91 || "";
    let applyBtn = document.getElementById("applyCustomDateFilter");
    highlightErrMsg("customDateFilterErrMsg", exceedRange);
    applyBtn &&
      endDate &&
      startDate &&
      (applyBtn.disabled = exceedRange || false);
    handleCustomDate([item.selection]);
  };

  const createApplyButton = () => {
    const dom = document.querySelector(`.dateRangePicker`);
    const parentDom = document.createElement("div");
    parentDom.setAttribute("class", "dateBtnSection");
    const cancelBtn = document.createElement("input");
    cancelBtn.setAttribute("type", "button");
    cancelBtn.setAttribute("value", "Cancel");
    cancelBtn.setAttribute("class", "cancelCustomDateFilter");
    cancelBtn.setAttribute("id", "cancelCustomDateFilter");
    cancelBtn.addEventListener("click", e => {
      cancelCbk();
    });
    parentDom.appendChild(cancelBtn);
    const applyBtn = document.createElement("input");
    applyBtn.setAttribute("type", "button");
    applyBtn.setAttribute("value", "Apply");
    applyBtn.setAttribute("class", "applyCustomDateFilter");
    applyBtn.setAttribute("id", "applyCustomDateFilter");
    const { startDate = false, endDate = false } = customDateObj;
    (!startDate || !endDate) && applyBtn.setAttribute("disabled", true);
    applyBtn.addEventListener("click", e => {
      if (!inFreePlan) {
        filterCbk();
      } else {
        PaywallUtils.upgradeUtilsFunction(
          upgradePopupName,
          true,
          planName,
          inFreePlan
        );
        cancelCbk();
      }
    });
    parentDom.appendChild(applyBtn);
    const errDom = document.createElement("div");
    errDom.setAttribute("id", "customDateFilterErrMsg");
    errDom.innerText = "*Date range limited to 90 days";
    parentDom.appendChild(errDom);
    if (inFreePlan) {
      var upgradeIcon = document.createElement("span");
      upgradeIcon.setAttribute("class", "wrapUpgradeIconTooltip");
      upgradeIcon.setAttribute("id", "wrapUpgradeIconTooltip");
      ReactDOM.render(
        <UpgradeIconTooltip type={upgradePopupName} enableTooltip={false} />,
        upgradeIcon
      );
      parentDom.appendChild(upgradeIcon);
    }
    dom.appendChild(parentDom);
  };

  useEffect(() => {
    createApplyButton();
  }, []);

  return (
    <DateRange
      className={className}
      editableDateInputs={false}
      onChange={handleChange}
      moveRangeOnFirstSelection={false}
      ranges={customDate}
      minDate={new Date(REPORT_DATE_FILTER_MIN_DATE)}
      maxDate={new Date()}
    />
  );
};

export default DateRangePicker;
export { DateRangePicker };
