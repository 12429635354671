import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { observer } from "mobx-react";
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { STRIPE_KEY } from "Static/constant";
import userDetail from "Stores/userDetail";
import { getFeatureFlagWithTeam } from "Utils/commonAPI";
import Utils from "Utils/utils";
import PricingStore from "Stores/Pricing";
import InputTextBox from "Components/common/InputTextBox";
import { toasterState } from "Components/common/toaster";
import {
  invalidCodeConfig,
  transactionDeclinedConfig,
  insufficientFundsConfig
} from "Static/stripeCardConstants";
import { SequenceUtils } from "Utils/SequenceUtils";

const stripePromise = loadStripe(STRIPE_KEY);

const paymentFormObj = {
  address1: "",
  city: "",
  state: "",
  country: "",
  phone: ""
};

const StripeBillingForm = withRouter(
  observer(props => {
    const stripe = useStripe();
    const elements = useElements();

    let formFieldValue = paymentFormObj;

    const { annualPrice, annual, seats, price } = PricingStore.paymentFormInfo;

    const [calculatedPrice, setCalculatedPrice] = useState(0);
    const [cardErr, setCardErr] = useState("");
    const [inputVal, setInputVal] = useState(formFieldValue);
    const [errMsgAddress, setErrMsgAddress] = useState("");
    const [errMsgCity, setErrMsgCity] = useState("");
    const [errMsgState, setErrMsgState] = useState("");
    const [errMsgCountry, setErrMsgCountry] = useState("");
    const [errMsgPhone, setErrMsgPhone] = useState("");
    const [btnLoader, setBtnLoader] = useState(false);
    const [errUserField, setErrUserField] = useState([]);

    const changeInputVal = (id, data) => {
      const stateObj = { ...inputVal };
      stateObj[id] = data;
      updateErrMsgField(id);
      setInputVal(stateObj);
    };

    const updateErrMsgField = id => {
      if (errUserField.length > 0 && errUserField.indexOf(id) !== -1) {
        errUserField.filter(item => item !== id);
        switch (id) {
          case "address1":
            setErrMsgAddress("");
            break;
          case "city":
            setErrMsgCity("");
            break;
          case "state":
            setErrMsgState("");
            break;
          case "country":
            setErrMsgCountry("");
            break;
          case "phone":
            setErrMsgPhone("");
            break;
          default:
            break;
        }
      }
    };

    const validateFormField = async () => {
      let errField = [];
      const { address1, city, state, country, phone } = inputVal;
      if (!Utils.validateStringLength(address1, 1)) {
        setErrMsgAddress("Address is required");
        errField.push("address1");
      }
      if (!Utils.validateStringLength(city, 1)) {
        setErrMsgCity("City is required");
        errField.push("city");
      }
      if (!Utils.validateStringLength(state, 1)) {
        setErrMsgState("State is required");
        errField.push("state");
      }
      if (!Utils.validateStringLength(country, 1)) {
        setErrMsgCountry("Country is required");
        errField.push("country");
      }
      if (!Utils.validateStringLength(phone, 1)) {
        setErrMsgPhone("Phone number is required");
        errField.push("phone");
      } else if (!Utils.isValidPhone(phone)) {
        setErrMsgPhone("Please enter a valid phone number");
        errField.push("phone");
      }
      setErrUserField(errField);
      return errField.length;
    };

    const getStripeCardErrorMsg = errorCode => {
      let errorMsg = "Something went wrong";
      if (errorCode) {
        if (invalidCodeConfig?.errorCodes?.includes(errorCode)) {
          errorMsg = invalidCodeConfig?.errorMsg;
        } else if (transactionDeclinedConfig?.errorCodes?.includes(errorCode)) {
          errorMsg = transactionDeclinedConfig?.errorMsg;
        } else if (insufficientFundsConfig?.errorCodes?.includes(errorCode)) {
          errorMsg = insufficientFundsConfig?.errorMsg;
        }
      }
      return errorMsg;
    };

    const paymentSubmit = async event => {
      Utils.preventDefaultFn(event);
      setBtnLoader(true);
      let errCount = await validateFormField();
      if (errCount < 1) {
        const card = elements.getElement(CardElement);
        const { error, token } = await stripe.createToken(card, inputVal);
        if (error && Object.keys(error).length > 0) {
          setCardErr(error.message);
          setBtnLoader(false);
          setTimeout(() => {
            setCardErr("");
          }, 2000);
          return false;
        }
        if (token?.id) {
          const handleCbk = async (value, respData) => {
            if (value) {
              userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
              setBtnLoader(false);
              SequenceUtils.removeActiveSequenceList();
              props.history.push({
                pathname: "/pricing/success"
              });
            } else {
              setBtnLoader(false);
              const errorMsg = getStripeCardErrorMsg(respData?.errorMessage);
              setCardErr(errorMsg);
              toasterState.setToastMsg("Transaction failed", "failure");
              setTimeout(() => {
                setCardErr("");
              }, 2000);
            }
          };
          const { annual, seats, monthlyPlanId, annualPlanId } =
            PricingStore.paymentFormInfo;
          const payload = {
            stripeToken: token.id,
            numberOfSeats: 5,
            annual,
            interval: 1
          };
          payload.numberOfSeats = seats;
          if (annual) {
            payload.planId = annualPlanId;
            payload.interval = 12;
          } else {
            payload.planId = monthlyPlanId;
            payload.interval = 1;
          }
          PricingStore.payment(payload, handleCbk);
        }
      } else {
        setBtnLoader(false);
      }
    };

    const getPaymentPrice = () => {
      if (seats && price) {
        let totalPrice = seats * price;
        if (annual) {
          totalPrice = seats * annualPrice * 12;
        }
        setCalculatedPrice(Utils.numberWithCommas(totalPrice));
      }
    };

    const getChargeNote = () => {
      return (
        <div className="chargeNote">
          You will be charged $1 to verify your payment method. It will be
          refunded immediately.
        </div>
      );
    };

    useEffect(() => {
      getPaymentPrice();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      PricingStore.paymentFormInfo.seats,
      PricingStore.paymentFormInfo.price,
      PricingStore.paymentFormInfo.annualPrice,
      PricingStore.paymentFormInfo.annual
    ]);

    return (
      <form
        onSubmit={paymentSubmit}
        autoComplete="off"
        name="paymentForm"
        className="paymentForm"
        noValidate={true}
      >
        <h4>Payment Information</h4>
        <div className="cardInputWrapper">
          <CardElement className="cardInput" />
          <span className="errMsgText">{cardErr}</span>
        </div>
        <div className="formDivider">
          <span className="halfCircle right"></span>
          <span className="dashedLine"></span>
          <span className="halfCircle left"></span>
        </div>
        <div className="popupBody">
          <InputTextBox
            name="address1"
            id="address1"
            text="Address"
            type="text"
            value={inputVal.address1}
            change={changeInputVal}
            err={errMsgAddress}
            readOnly={false}
          />
          <div className="formInputGroup">
            <InputTextBox
              name="city"
              id="city"
              text="City"
              type="text"
              value={inputVal.city}
              change={changeInputVal}
              err={errMsgCity}
              readOnly={false}
            />
            <InputTextBox
              name="state"
              id="state"
              text="State"
              type="text"
              value={inputVal.state}
              change={changeInputVal}
              err={errMsgState}
              readOnly={false}
            />
          </div>
          <InputTextBox
            name="country"
            id="country"
            text="Country"
            type="text"
            value={inputVal.country}
            change={changeInputVal}
            err={errMsgCountry}
            readOnly={false}
          />
          <InputTextBox
            name="phone"
            id="phone"
            text="Phone Number"
            type="text"
            value={inputVal.phone}
            change={changeInputVal}
            err={errMsgPhone}
            readOnly={false}
          />
        </div>
        <div className="submitSection">
          <button
            type="submit"
            disabled={!stripe}
            className={`animatedButton ${btnLoader ? "loading" : ""}`}
          >
            {!btnLoader && (
              <span>
                Pay {calculatedPrice !== null ? `$${calculatedPrice}.00` : ""}
              </span>
            )}
            {btnLoader && <span className="load-text"></span>}
          </button>
        </div>
      </form>
    );
  })
);

const StripeBillingCard = props => {
  return (
    <Elements stripe={stripePromise}>
      <StripeBillingForm {...props} />
    </Elements>
  );
};

export default withRouter(observer(StripeBillingCard));
