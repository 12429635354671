import React, { useEffect, useState } from "react";

const ImportProgressbar = ({ total = 0, processed = 0 }) => {
  // const [progress, setProgress] = useState(0);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setProgress(prev => (prev < 100 ? prev + 5 : 100));
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);
  const progress = total ? Math.min((processed / total) * 100, 100) : 0;

  return (
    <div className="progress-container">
      <div className="progress-bar" style={{ width: `${progress}%` }}>
        <span className="progress-text">{progress}%</span>
        <span
          className="progress-after"
          style={{
            position: "absolute",
            right: "-6px",
            width: "12px",
            height: "100%",
            background: "#3b82f6",
            clipPath: "path('M 0 0 L 6 0 A 3 3 0 0 1 6 12 L 0 12 z')",
            opacity: progress > 15 ? 1 : 0, // Show only after 15%
            transition: "opacity 0.3s ease-in-out"
          }}
        />
      </div>
    </div>
  );
};

export default ImportProgressbar;
