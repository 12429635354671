import React from "react";
import Lottie from "react-lottie";
import { useHistory } from "react-router-dom";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { userDetail } from "Stores/userDetail";
import { Utils } from "Utils/utils";
import WelcomePopupLottie from "Utils/LottieFiles/welcomePopupLottie.json";
import { Button } from "Components/common/Button";
import { ReactComponent as CheckSvg } from "Assets/svg/checkIcon.svg";
import { ReactComponent as UpgradeNew } from "Assets/svg/upgradeNew.svg";
import { ReactComponent as FreeCredits } from "Assets/svg/freeCredits.svg";
import { ReactComponent as Automate } from "Assets/svg/automate.svg";
import { ReactComponent as Workflow } from "Assets/svg/workflow.svg";
import onboarding from "./Onboarding";
import {
  checkTeamStatus,
  createFreePlan,
  getFeatureFlagWithTeam
} from "Utils/commonAPI";

class InitialWelcomePopupState {
  @observable showPopup = false;
  @observable restrictOnboardingPopup = false;
  @observable isOldFreeUser = false;

  @action
  setShowPopup = val => {
    this.showPopup = val;
  };

  @action
  setRestrictOnboardingPopup = val => {
    this.restrictOnboardingPopup = val;
  };

  @action
  setIsOldFreeUser = val => {
    this.isOldFreeUser = val;
  };
}

const initialWelcomePopupState = new InitialWelcomePopupState();

const InitialWelcomePopup = props => {
  const {
    userInfo: {
      name: userName = "",
      firstName: userFirstName = "",
      lastName: userLastName = ""
    } = {}
  } = userDetail;
  const history = useHistory();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: WelcomePopupLottie,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  const createFreePlanFromWlcmPopup = async () => {
    let data = {};
    let userData = {};
    const {
      hasTeam = false,
      email = "",
      firstName = "",
      status = 0,
      phone = ""
    } = userDetail?.userInfo;

    data = {
      businessEmail: email?.trim()?.toLowerCase(),
      phoneNumber: phone,
      firstName
    };
    const userEmail = data?.businessEmail || data?.email;
    const resData = await createFreePlan();

    if ([200, "200"].includes(resData?.data?.statusCode)) {
      userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
      userData.team = await checkTeamStatus();
      userDetail.setIsTeamCreated(true);
      userData.email = userEmail?.trim()?.toLowerCase() || "";
      userData.userLoggedIn = true;
      userDetail.setUserInfo(userData);
      initialWelcomePopupState.setIsOldFreeUser(false);
      initialWelcomePopupState.setShowPopup(false);
    }
  };

  const handleLetsGoBtnClick = () => {
    initialWelcomePopupState.setShowPopup(false);
    initialWelcomePopupState.setRestrictOnboardingPopup(true);
    onboarding.setPopup(true);
    if (window?.Intercom?.booted) {
      setTimeout(() => {
        return Utils.initiateIntercom("emailAccount");
      }, 1200);
    } else {
      setTimeout(() => {
        return Utils.initiateDriver(false, "emailAccount");
      }, 3000);
    }
  };

  const closeAction = () => {
    initialWelcomePopupState.setShowPopup(false);
  };

  const updatedName = userFirstName || userLastName || userName;
  const capitalizedName = updatedName
    ? updatedName?.replace(/^./, str => str.toUpperCase())
    : "";

  return (
    initialWelcomePopupState.showPopup && (
      <section className="initialWelcomePopupWrapper">
        <div className="card">
          {!initialWelcomePopupState?.isOldFreeUser && (
            <div
              className="popupClose"
              title="Click here to close"
              onClick={closeAction}
            >
              <i className="material-icons">close</i>
            </div>
          )}

          <header className="welcomePopupHeader">
            {initialWelcomePopupState?.isOldFreeUser ? (
              <h3 className="userName">{`🎉Welcome Back! We Got You a Gift!`}</h3>
            ) : (
              <h3 className="userName">{`🎉Welcome to Salesgear!`}</h3>
            )}
            {initialWelcomePopupState?.isOldFreeUser ? (
              <p className="headerPopupText">
                You’ve been away for a while, so we’ve added 100 free credits to
                help you jump back into finding verified contacts and automating
                outreach.
              </p>
            ) : (
              <p className="headerPopupText">
                You’ve got 100 free credits to start finding verified contacts
                and automating outreach. No trial limits—just full access with
                usage-based limits.
              </p>
            )}
          </header>
          <div className="planHeading">
            <div className="planHeadingWrapper">
              {initialWelcomePopupState?.isOldFreeUser ? (
                <div className="planHeadingTitle">
                  ✨ Plus, we’ve upgraded your Forever Free Plan:
                </div>
              ) : (
                <div className="planHeadingTitle">
                  What’s Included in Your Forever Free Plan?
                </div>
              )}
              <div className="planItems">
                <div className="planItem">
                  <div className="checkIcon">
                    <FreeCredits />
                  </div>
                  <div className="planItemDescription">
                    100 free credits every month
                  </div>
                </div>
                <div className="planItem">
                  <div className="checkIcon">
                    <Automate />
                  </div>
                  <div className="planItemDescription">
                    Automate LinkedIn, calls, and emails
                  </div>
                </div>
                <div className="planItem">
                  <div className="checkIcon">
                    <Workflow />
                  </div>
                  <div className="planItemDescription">
                    Smart workflows to drive real replies
                  </div>
                </div>
              </div>
            </div>
            <div className="upgradeAnytime">
              {initialWelcomePopupState?.isOldFreeUser ? (
                <div className="upgradeAnytimeWrapper">
                  <div className="scaleFaster">
                    🚀 Pick up where you left off and start reaching prospects
                    today!
                  </div>
                </div>
              ) : (
                <div className="upgradeAnytimeWrapper">
                  <UpgradeNew />
                  <div className="scaleFaster">
                    Upgrade anytime to scale faster
                  </div>
                </div>
              )}
            </div>
          </div>
          <footer className="welcomePopupFooter">
            <Button
              id={"letsGoBtn"}
              name="letsGoBtn"
              type="button"
              className="letsGoBtn"
              btnText={
                initialWelcomePopupState?.isOldFreeUser
                  ? "Claim Your Gift & Get Started"
                  : "Get Started"
              }
              icon={true}
              iconName="arrow_right_alt"
              loader={false}
              click={() => {
                if (initialWelcomePopupState?.isOldFreeUser) {
                  createFreePlanFromWlcmPopup();
                } else {
                  handleLetsGoBtnClick();
                }
              }}
            />
          </footer>
        </div>
        <div className="common-popup-mask" />
      </section>
    )
  );
};

export { InitialWelcomePopup, initialWelcomePopupState };
export default observer(InitialWelcomePopup);
