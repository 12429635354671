/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  withRouter,
  useHistory
} from "react-router-dom";
import { Utils } from "Utils/utils";
import { userDetail } from "Stores/userDetail";
import RegisterModule from "Pages/Register/RegisterModule";
import DashboardModule from "Pages/Dashboard/DashboardModule";
import {
  checkTeamStatus,
  createFreePlan,
  getFeatureFlagWithTeam,
  loginValidate
} from "Utils/commonAPI";
import JumpBallLoader from "Components/common/JumpBallLoader";
import ConnectAppsRouting from "Routes/ConnectApps";
import { observer } from "mobx-react";
import { captchaStoreValue } from "Stores/captcha";
import TeamInvite from "Pages/Dashboard/team/TeamInvite";
import TeamInviteAcceptSuccess from "Pages/Dashboard/team/TeamInviteAcceptSuccess";
import UnsubscribePage from "Pages/Unsubscribe/UnsubscribePage";
import UnsubscribeSuccess from "Pages/Unsubscribe/UnsubscribeSuccess";
import SignupSuccessPage from "Pages/Dashboard/SignupSuccessPage";
import LoggedInDealify from "Pages/Register/Dealify/LoggedInDealify";
import makeApi, { URLS } from "Utils/apiURL";
import PricingStore from "Stores/Pricing";
import { emailVerificationLoggedInState } from "Pages/Register/EmailVerificationLoggedIn";
import {
  MP_EVENT,
  CAPTCHA_INIT_PAGES,
  WEBSITE_TRACKING_ENV,
  TRACKING_KEY,
  WEBSITE_URL
} from "static/constant";
import HeadTag from "Components/common/HeadTag";

const Routes = (props = {}) => {
  const history = useHistory();
  let isAutoLogin = false;
  let location = useLocation();
  let url = Utils.checkLocationPathname(location.pathname, "/teamInvite/");
  const userData = {};
  let queryParams = Utils.getQueryParams(location.search);
  const activationCode = queryParams.get("key");
  const memberId = queryParams.get("id");
  let pluginUser = queryParams?.get("source")?.includes("plugin");
  pluginUser && localStorage.setItem("isPluginUser", true);

  const [isLoggedIn, setIsLoggedIn] = useState("");

  const loginCheck = async redirectUrl => {
    let res = await loginValidate();
    if (res) {
      setIsLoggedIn(userDetail.userInfo.userLoggedIn);
      redirectUrl && history.push(redirectUrl);
    }
  };

  const captchaInitialize = () => {
    if (location?.pathname) {
      let pathName = location?.pathname;
      pathName.indexOf("resetPassword") >= 0 && (pathName = "/resetPassword");
      if (emailVerificationLoggedInState.showPopup) {
        captchaStoreValue.setCaptcha(true);
      } else {
        captchaStoreValue.setCaptcha(CAPTCHA_INIT_PAGES.includes(pathName));
      }
    }
  };

  const makeAutoLoginApi = async () => {
    let queryParams = Utils.getQueryParams(location?.search);
    let autoLoginKey = queryParams.get("ak") || false;
    let redirectUrl = queryParams.get("redirectUrl") || false;
    if (autoLoginKey) {
      const config = {
        url: URLS.loginUser,
        data: { ak: autoLoginKey },
        method: "POST"
      };
      let response = await makeApi(config);
      if (response?.data?.success) {
        await loginCheck(
          (autoLoginKey &&
            redirectUrl === "connectInbox" &&
            "/settings/account") ||
            location?.pathname ||
            ""
        );
      }
    }
  };

  const checkForAutoLogin = async () => {
    isAutoLogin =
      location?.search.includes("?ak=") ||
      location?.search?.includes("&ak=") ||
      false;
    if (isAutoLogin) {
      makeAutoLoginApi();
    }
  };

  const handleUnsubscribeRedirection = props => {
    const url = props?.match?.url;
    if (url && url.indexOf("/success") !== -1) {
      return <UnsubscribeSuccess />;
    } else {
      const unsubId = url?.split("/unsubscribe/").pop();
      return <UnsubscribePage id={unsubId} />;
    }
  };

  const getDefaultUserCountry = async () => {
    const config = {
      url: URLS.getIpInfo,
      method: "GET"
    };
    const response = await makeApi(config, undefined, false);
    const { data: { country = "" } = {} } = response || {};
    userDetail.setDefaultUserCountry(country?.toLowerCase());
    localStorage.setItem("userDefaultCountry", country?.toLowerCase());
  };

  const createTeam = async data => {
    const config = {
      url: URLS.initializeTeam,
      method: "POST"
    };
    let res = await makeApi(config);
    const userEmail = data?.businessEmail || data?.email;
    Utils.mixpanelTrack(MP_EVENT.TEAM_CREATED, {
      userEmail,
      source:
        localStorage.getItem("isPluginUser") || pluginUser
          ? "PLUGIN"
          : "web-direct",
      phonenumber: data?.phone
    });
    if (res?.data?.teamMember) {
      Utils.mixpanelTrack(MP_EVENT.TEAM_HAS_MEMBER, {
        userEmail,
        source:
          localStorage.getItem("isPluginUser") || pluginUser
            ? "PLUGIN"
            : "web-direct",
        phonenumber: data?.phone
      });

      const resData = await createFreePlan();
      if ([200, "200"].includes(resData?.data?.statusCode)) {
        Utils.mixpanelTrack(MP_EVENT.FREEPLAN_STARTED, {
          userEmail,
          source:
            localStorage.getItem("isPluginUser") || pluginUser
              ? "PLUGIN"
              : "web-direct",
          phonenumber: data?.phone
        });
        userDetail.setUserFeatureFlag(await getFeatureFlagWithTeam());
        userData.team = await checkTeamStatus();
        userDetail.setIsTeamCreated(true);
        userData.email = userEmail?.trim()?.toLowerCase() || "";
        userData.userLoggedIn = true;
        userDetail.setUserInfo(userData);
      } else {
        Utils.mixpanelTrack(MP_EVENT.FREEPLAN_INITIATION_FAILED, {
          userEmail,
          source:
            localStorage.getItem("isPluginUser") || pluginUser
              ? "PLUGIN"
              : "web-direct",
          phonenumber: data?.phone,
          resData: JSON.stringify(resData)
        });
      }
    } else {
      Utils.mixpanelTrack(MP_EVENT.TEAM_FAILED, {
        userEmail,
        source:
          localStorage.getItem("isPluginUser") || pluginUser
            ? "PLUGIN"
            : "web-direct",
        phonenumber: data?.phone
      });
    }
  };

  const constructProfileData = async () => {
    const {
      hasTeam = false,
      email = "",
      firstName = "",
      status = 0,
      phone = ""
    } = userDetail?.userInfo;

    if (status === 2) {
      emailVerificationLoggedInState.setShowPopup(true);
    } else {
      if (!hasTeam) {
        const data = {
          businessEmail: email?.trim()?.toLowerCase(),
          phoneNumber: phone,
          firstName
        };

        await createTeam(data);
      } else {
        userDetail.setIsTeamCreated(true);
      }
    }
  };

  const activateUser = async () => {
    try {
      const userData = {};
      const config = {
        url: URLS.activateUser ? URLS.activateUser : "",
        method: "POST",
        data: {
          activateKey: activationCode,
          memberId: memberId
        }
      };
      let res = await makeApi(config);
      const { isLoggedIn = false, status = "" } = res?.data || {};
      if (res?.data) {
        switch (status) {
          case "success":
            userData.userLoggedIn = isLoggedIn;
            userDetail.setUserInfo(userData);
            break;
          default:
            history.push(isLoggedIn ? "/dashboard" : "/login");
            break;
        }
      } else {
        history.push("/login");
      }
    } catch (err) {
      console.error(err, "Activate user failed");
    }
  };

  const initializeLogin = async () => {
    await activateUser();
    await loginCheck();
  };

  useEffect(() => {
    if (memberId && activationCode) {
      initializeLogin();
    } else {
      !isAutoLogin && loginCheck();
    }
    checkForAutoLogin();
    const userDefaultCountry = localStorage.getItem("userDefaultCountry");
    Utils.checkTeamUrl(url, location.pathname);
    // Pricing Table
    PricingStore.getActivePlanDetails();
    !userDefaultCountry
      ? getDefaultUserCountry()
      : userDetail.setDefaultUserCountry(userDefaultCountry);
    if (window && window.sg) {
      var sg = window.sg;
      WEBSITE_TRACKING_ENV === "prod"
        ? sg.init(TRACKING_KEY)
        : sg.init(TRACKING_KEY, "staging");
    }
  }, []);

  useEffect(() => {
    captchaInitialize();
    url && Utils.checkTeamUrl(url, location.pathname);
  }, [location]);

  useEffect(() => {
    let loggedUser = userDetail?.userInfo?.userLoggedIn;
    setIsLoggedIn(loggedUser);
    if (
      loggedUser === false &&
      Utils.checkLocationPathname(location?.pathname, "/pricing")
    ) {
      window.open(`${WEBSITE_URL}/pricing`, "_self", "noopener");
    }
  }, [userDetail?.userInfo?.userLoggedIn]);

  useEffect(() => {
    if (
      userDetail.userInfo.userLoggedIn &&
      userDetail?.userInfo?.hasTeam !== null
    ) {
      constructProfileData();
    }
  }, [userDetail.userInfo.hasTeam]);

  return (
    <>
      <HeadTag pathname={location?.pathname || ""} />
      {isLoggedIn === true ? (
        <Switch>
          <Route path="/dealify-signup" render={() => <LoggedInDealify />} />
          <Route path="/connect" component={ConnectAppsRouting} />
          <Route
            path="/unsubscribe*"
            render={props => {
              return handleUnsubscribeRedirection(props);
            }}
          />
          <Route
            path="/teamInvite/success"
            exact
            render={() => <TeamInviteAcceptSuccess />}
          />
          <Route path="/teamInvite/:id" exact render={() => <TeamInvite />} />
          <Route path="/signupSuccess" component={SignupSuccessPage} />
          <Route path="/" render={() => <DashboardModule />} />
        </Switch>
      ) : isLoggedIn === false ? (
        <Switch>
          <Route path="/pricing" component={JumpBallLoader} />
          <Route path="/connect" component={ConnectAppsRouting} />
          <Route
            path="/unsubscribe*"
            render={props => {
              return handleUnsubscribeRedirection(props);
            }}
          />
          <Route
            path="/"
            render={() => <RegisterModule fn={setIsLoggedIn} />}
          />
          <Redirect from="/" to="/" />
        </Switch>
      ) : (
        <JumpBallLoader />
      )}
    </>
  );
};

export default withRouter(observer(Routes));
