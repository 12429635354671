import React from "react";

const CollapsiveContent = props => {
  const { title, content } = props;

  const collapseBtn = e => {
    e.target.classList.toggle("active");
    let content = e.target.nextElementSibling;
    if (content.style.maxHeight) {
      content.style.maxHeight = null;
    } else {
      content.style.maxHeight = content.scrollHeight + "px";
    }
  };

  return (
    <>
      <div className="collapsible" onClick={e => collapseBtn(e)}>
        {title}
      </div>
      <div className="collapsiveContentBody">
        <p
          className="collapsiveTextTp"
          dangerouslySetInnerHTML={{ __html: content }}
        ></p>
      </div>
    </>
  );
};

export default CollapsiveContent;
