/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import Lottie from "react-lottie";
import { setStorage, Utils } from "Utils/utils";
import Button from "Components/common/Button";
import Logo from "Assets/website/logo.png";
import LiveFeedBrowserImg from "Assets/png/liveFeedBrowser.png";
import { ReactComponent as PuzzlePieceIcon } from "Assets/svg/puzzlePieceIcon.svg";
import PluginPinPopupImg from "Assets/png/pluginSuccess.png";
import TickAnim from "Utils/LottieFiles/tickOrangeAnim.json";
import GreenTick from "Assets/png/greentick.png";
import Chrome from "Assets/png/chrome.png";
import { ReactComponent as RightArrow } from "Assets/svg/rightArrowBlue.svg";
import { ReactComponent as ChromeExtension } from "Assets/svg/chromeExtension.svg";
import { ReactComponent as Success } from "Assets/svg/success.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LINKEDIN_URL } from "Static/constant";

const defaultOptions = {
  loop: false,
  autoplay: true,
  animationData: TickAnim,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};

const pluginContent = [
  `<b>Go to LinkedIn</b> and open any profile`,
  `Click the <b>floating Salesgear icon</b> on the right`,
  `Click “<b>View Email/Mobile Number</b>” to reveal verified contact details`
];

const PinPluginPopup = (props = {}) => {
  const { handleClick = () => {} } = props || {};
  const chromeDescription = [
    `Salesgear for chrome has installed successfully.`,
    <>
      Click the <ChromeExtension className="chromeExtensionIcon" /> icon to pin
      Salesgear for easy access.
    </>
  ];
  return (
    <div className="pinPluginPopupWrapper">
      <span className="sharpPointer"></span>
      <div className="chrome">
        <img className="greenTick" src={Chrome} alt="green tick" />
      </div>
      <div className="chromeDescriptionWrapper">
        <div className="chromeDescription">
          {chromeDescription.map((item, ind) => (
            <div className="descpPoints" key={ind}>
              {item}
            </div>
          ))}
        </div>
      </div>
      <div className="imageWrapper">
        <img
          className="pluginPinImg"
          src={PluginPinPopupImg}
          alt="plugin pin"
        />
      </div>
      <div className="buttonWrapper">
        <button className="pluginPinOkButton" onClick={handleClick}>
          <span>Ok, Done</span>
          <RightArrow />
        </button>
      </div>
    </div>
  );
};

const PluginSuccess = () => {
  let location = useLocation();
  let queryParams = Utils.getQueryParams(location.search);
  let isNewUser = queryParams.get("type") === "signup" || false;
  const history = useHistory();

  const [showPluginPopup, setShowPluginPopup] = useState(true);
  const [btnText, setBtnText] = useState("");
  const [urlPath, setUrlPath] = useState("");

  const createUrlPath = (
    name = "Gmail",
    url = "https://mail.google.com/mail/u/0/"
  ) => {
    setBtnText(name);
    setUrlPath(url);
  };

  const getQueryParamsRedirect = () => {
    switch (queryParams.get("pageType")) {
      case "salesforce":
        createUrlPath("Salesforce", queryParams.get("url"));
        break;
      case "pipedrive":
        createUrlPath("Pipedrive", queryParams.get("url"));
        break;
      case "zoho":
        createUrlPath("Zoho", queryParams.get("url"));
        break;
      case "hubspot":
        createUrlPath("Hubspot", queryParams.get("url"));
        break;
      default:
        createUrlPath();
        break;
    }
  };

  const navToUrlPathFromPlugin = () => {
    window.open(urlPath, "_blank", "noopener");
  };
  const navigateToDashBoard = () => {
    history.push("/dashboard");
  };

  const navigateToLinkedin = () => {
    window.parent.postMessage(
      { reload: true, type: "PLUGIN_SUCCESS_REDIRECT" },
      "*"
    );
    window.open(LINKEDIN_URL, "_blank", "noopener noreferrer");
  };

  const setUpMyAccount = () => {
    // window.parent.postMessage(
    //   { data: "message", type: "OPEN_LIVE_FEED_SETTING" },
    //   "*"
    // );
    window.open("https://mail.google.com/mail/u/0/", "_self", "noopener");
  };

  const rewards = [
    {
      description: `✨ Works with <b>LinkedIn & Sales Navigator</b>`
    },
    {
      description: `🎁<b> 100 free lookups per month</b>`
    }
  ];

  useEffect(() => {
    getQueryParamsRedirect(location);
  }, []);

  return (
    <section id="pluginSuccessPage" className="pluginSuccessPage">
      <div className="container">
        <div className="pluginSuccessBody">
          <div className="succesImage">
            <div className="succesImageContainer">
              <Success />
            </div>
          </div>
          {isNewUser && (
            <div className="signupPluginContent">
              <p className="textDesc">
                You have successfully activated your
                <br />
                <b>Salesgear account</b>
              </p>
              <Button
                id="setUpMyAccount"
                name="setUpMyAccount"
                btnText={`Go to your Inbox`}
                icon={true}
                iconName="keyboard_backspace"
                type="button"
                className="setUpMyAccount rotateForwardIcon"
                loader={false}
                click={() => setUpMyAccount()}
              />
              <img
                src={LiveFeedBrowserImg}
                alt=""
                className="liveFeedBrowserImg"
              />
            </div>
          )}
          {!isNewUser && (
            <div className="loginPluginContent">
              <p className="textDesc">
                <b>You’re All Set! Start Finding Emails on LinkedIn</b>
              </p>
              <ul className="pluginDescriptionWrapper">
                {pluginContent?.map((item, index) => (
                  <li
                    key={`pluginContent${index}`}
                    className="pluginDescription"
                    dangerouslySetInnerHTML={{ __html: item }}
                  />
                ))}
              </ul>
              <div className="rewardsWrapper">
                <div className="rewards">
                  {rewards?.map((item, ind) => (
                    <div
                      className="rewardDescription"
                      dangerouslySetInnerHTML={{ __html: item.description }}
                    />
                  ))}
                </div>
              </div>
              <div className="navTodashboardUrl">
                <div className="dashboardButtonWrapper">
                  <Button
                    btnText={`Go to your DashBoard`}
                    type="button"
                    className="goToDashBoard"
                    click={navigateToDashBoard}
                  />
                </div>

                <div className="linkedinButtonWrapper">
                  <Button
                    btnText={`Go to LinkedIn`}
                    type="button"
                    className="goToLinkedin"
                    icon={true}
                    iconName="arrow_forward"
                    click={navigateToLinkedin}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {showPluginPopup && (
        <PinPluginPopup handleClick={() => setShowPluginPopup(false)} />
      )}
    </section>
  );
};

export default withRouter(observer(PluginSuccess));
export { PluginSuccess };
