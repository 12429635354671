import React from "react";
import LinearProgressBar from "Components/common/LinearProgressBar";
import CustomTooltip from "Components/common/CustomTooltip";
import Button from "Components/common/Button";

const EmailCredits = props => {
  const { creditsData = {}, creditsCardLoader = false } = props || {};

  return (
    <>
      {creditsData?.emailCreditsData?.map((emailItem, ind) => {
        const {
          heading = "",
          icon = "",
          paraContent = "",
          usageLimits = [],
          btnObj = {}
        } = emailItem || {};

        return (
          <div className="emailCard" key={ind}>
            <div className="cardHeader">
              <div className="iconWrapper">{icon}</div>
              <div className="headingWrapper">
                <h3>{heading}</h3>
                <p>{paraContent}</p>
              </div>
            </div>

            <div className="linearProgessWrapper">
              {usageLimits?.map((creditsLimit, idx) => {
                const {
                  id,
                  heading = "",
                  tooltip = "",
                  totalCredits = "",
                  remainingCredits = "",
                  classNameText = ""
                } = creditsLimit || {};

                const total = +totalCredits || 0;
                const remaining = +remainingCredits || 0;
                return (
                  <div className="linearProgessBody" key={id || idx}>
                    <div className="limit">
                      <div className="limitContainer">
                        {heading}
                        <span className="freecallingIcon">
                          <i className="material-icons-outlined">info</i>
                          <div className={`tooltipWrapper ${classNameText}`}>
                            <CustomTooltip text={tooltip} />
                          </div>
                        </span>
                      </div>
                      <span>
                        {remaining}/{total}
                      </span>
                    </div>
                    <LinearProgressBar
                      width={(remaining / total) * 100 || 0}
                      bgColor="#3953fb"
                      creditsCardLoader={creditsCardLoader}
                    ></LinearProgressBar>
                  </div>
                );
              })}
            </div>

            <div className="buttonWrapper">
              <div className="buttonContainer">
                <Button {...btnObj} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default EmailCredits;
export { EmailCredits };
