import React from "react";
import VendisysImg from "Assets/clients/vendisys.png";
import AdaptImg from "Assets/clients/adapt.png";
import BlaxtairImg from "Assets/clients/blaxtair.png";
import { ReactComponent as MailCredits } from "Assets/Register/mailCredits.svg";
import { ReactComponent as MailSendNumber } from "Assets/Register/mailNumber.svg";
import { ReactComponent as Call } from "Assets/Register/call.svg";

const LeftContent = (props = {}) => {
  const { isPlugin = false, isDealify = false } = props || {};
  const keylist = [
    "Engage prospects across LinkedIn, email, and phone seamlessly",
    "Save time with powerful, automated workflows",
    "Turn inbound signups into high-value meetings faster"
  ];

  const heading = "Hit Your Targets with Salesgear";
  const dealifyHeading = "Claim your Salesgear Lifetime Deal";
  const pluginHeading = "Find & Connect with Prospects on LinkedIn";
  const pluginKeyList = [
    "Reveal verified emails & phone numbers directly from LinkedIn profiles",
    "Engage leads across LinkedIn, email, and phone seamlessly",
    "Save time with automated prospecting workflows"
  ];

  const title = isPlugin ? pluginHeading : isDealify ? dealifyHeading : heading;
  const bulletPoints = isPlugin ? pluginKeyList : keylist;
  const clientImg = [
    {
      name: "Vendisys",
      imgSrc: VendisysImg
    },
    {
      name: "Adapt",
      imgSrc: AdaptImg
    },
    {
      name: "Blaxtair",
      imgSrc: BlaxtairImg
    }
  ];

  const planItems = [
    {
      icon: <MailCredits />,
      descriptions: isPlugin ? (
        <span>
          <strong>100 free email</strong> lookups every month
        </span>
      ) : (
        <span>
          <strong>100 free email</strong> credits every month
        </span>
      )
    },
    {
      icon: <MailSendNumber />,
      descriptions: isPlugin ? (
        <span>
          <strong>Find & extract contacts</strong> from LinkedIn in one click
        </span>
      ) : (
        <span>
          <strong>1,000 monthly</strong> email sends
        </span>
      )
    },
    {
      icon: <Call />,
      descriptions: isPlugin ? (
        <span>
          <strong>Set up email & LinkedIn automation</strong> to engage with
          prospects
        </span>
      ) : (
        <span>
          <strong>60 free</strong> US calling minutes
        </span>
      )
    }
  ];
  return (
    <div className="leftRegisterContent">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <ul className="keylist">
        {bulletPoints.map((item, index) => (
          <li key={`keyList${index}`}>{item}</li>
        ))}
      </ul>
      <div className="plans">
        <div className="planItemHeading">What you get when you sign up:</div>
        <div className="planItems">
          {planItems.map((item, index) => {
            return (
              <div className="planItemWrapper" key={index}>
                <div className="items">
                  <div className="planIcon">{item.icon}</div>

                  <div className="itemDescription">{item.descriptions}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <section className="clientSection">
        <h4 className="text-center">
          High performing sales teams from fast growing companies trust us
        </h4>
        <div className="clientsLogo">
          {clientImg.map(item => {
            const { name = "", imgSrc = "" } = item || {};
            return <img src={imgSrc} alt={name} key={name} />;
          })}
        </div>
      </section>
    </div>
  );
};

export default LeftContent;
