import React from "react";

export const LimitErr = (props = {}) => {
  const { headingContent = "", limitMessage = "" } = props || {};

  return (
    <div>
      {headingContent}
      {limitMessage && <div class="infoPromptContent">{limitMessage}</div>}
    </div>
  );
};

export default LimitErr;
