import React, { useState, useEffect, createContext } from "react";
import { useLocation, withRouter } from "react-router-dom";
import { observer } from "mobx-react";
import userDetail from "Stores/userDetail";
import { calendarState } from "Pages/Dashboard/settings/Calendar/State";
import Header from "Pages/Dashboard/settings/Calendar/Pages/Layout/Header";
import CalendarPrimaryUrl from "Pages/Dashboard/settings/Calendar/Components/CalendarPrimaryUrl";
import TabNavigation from "Components/common/TabNavigation";
import { CALENDAR_NAVIGATION } from "./Constants";
import CalendarRouting from "Routes/CalendarRouting";
import CreateEvent from "Pages/Dashboard/settings/Calendar/Pages/Events/CreateEvent/Main";
import { upgradePopupState } from "Components/Paywall/UpgradePopup";
import { getGlobalTimeZones } from "Utils/commonAPI";
import PaywallUtils from "Utils/PaywallUtils";

const CalendarContext = createContext({});

const Main = observer(
  withRouter(() => {
    let location = useLocation();
    const {
      calendarIntegrationEnabled = false,
      noOfCalendarEventsEligible = 0,
      noOfCalendarEventsUsed = 0,
      inFreePlan = false,
      planName = ""
    } = userDetail?.userFeatureFlag || {};

    const [showCreateEvent, setCreateEvent] = useState(false);

    const upgradeCalender = (calenderEventType = "") => {
      PaywallUtils.upgradeUtilsFunction(
        calenderEventType,
        false,
        planName,
        inFreePlan
      );
    };

    const isCalendarHasLimit = () => {
      if (noOfCalendarEventsEligible - noOfCalendarEventsUsed < 1) {
        upgradeCalender("calendarLimitReached");
        return false;
      }
      return true;
    };

    const isCalendarEnabled = () => {
      if (!calendarIntegrationEnabled) {
        upgradeCalender("calendarUpgrade");
        return false;
      }
      return true;
    };

    const isFreeUser = () => {
      if (inFreePlan) {
        upgradeCalender("calendarBrandLogoUpgrade");
        return false;
      }
      return true;
    };

    const headerObject = {
      name: showCreateEvent
        ? `${calendarState?.editEventInputValue ? "Edit" : "Create"} Event`
        : "Calendar",
      childComponent: (!showCreateEvent && <CalendarPrimaryUrl />) || ""
    };

    useEffect(() => {
      isCalendarEnabled();
      getGlobalTimeZones();
      return () => {
        calendarState.resetState();
        setCreateEvent(false);
      };
    }, []);

    return (
      <CalendarContext.Provider
        value={{
          location,
          setCreateEvent,
          isCalendarEnabled: () => isCalendarEnabled(),
          isCalendarHasLimit: () => isCalendarHasLimit(),
          isFreeUser: () => isFreeUser()
        }}
      >
        <div
          className={`calendarSetting ${!isCalendarEnabled() ? "blurBg" : ""}`}
        >
          <Header {...headerObject} />
          {showCreateEvent ? (
            <CreateEvent setCreateEvent={setCreateEvent} />
          ) : (
            <div className="calendarCont">
              <div className="navTabCont">
                <TabNavigation tabList={CALENDAR_NAVIGATION} />
              </div>
              <div className="calendarMainContent">
                <CalendarRouting />
              </div>
            </div>
          )}
        </div>
      </CalendarContext.Provider>
    );
  })
);

export default Main;
export { CalendarContext };
