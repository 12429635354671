import React, { useState, useEffect, createContext } from "react";
import { observer } from "mobx-react";
import { withRouter } from "react-router-dom";
import Utils from "Utils/utils";
import TemplateHeaderComponent from "./TemplateHeader";
import TemplateFolderComponent from "./TemplateFolder";
import TemplatesRouting from "Routes/TemplatesRouting";
import PricingDiscountBanner from "Components/common/PricingDiscountBanner";
import userDetail from "Stores/userDetail";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export const TemplatesContext = createContext({});

const TemplateModule = observer(() => {
  const {
    noOfTemplatesEligible = 0,
    noOfTemplatesUsed = 0,
    inFreePlan = false
  } = userDetail?.userFeatureFlag || {};

  const [productDiscount, setProductDiscount] = useState(
    Utils.showProductDiscountBanner()
  );

  const checkTemplatesEligible = () => {
    return noOfTemplatesEligible > noOfTemplatesUsed;
  };

  let isTemplatesCreationAllowed = checkTemplatesEligible();

  useEffect(() => {
    setProductDiscount(Utils.showProductDiscountBanner());
  }, []);

  return (
    <section className="template-section">
      <TemplatesContext.Provider
        value={{
          isTemplatesCreationAllowed
        }}
      >
        <TemplateHeaderComponent />
        {!productDiscount && <PricingDiscountBanner />}
        <div className="templateBodyWrapper">
          <section
            className={`template-body ${
              !productDiscount ? "productDiscount" : ""
            }`}
          >
            <DndProvider backend={HTML5Backend}>
              <TemplateFolderComponent />
              <main>
                <TemplatesRouting />
              </main>
            </DndProvider>
          </section>
        </div>
      </TemplatesContext.Provider>
    </section>
  );
});

export default withRouter(TemplateModule);
